import React from 'react';
import {ProfileQItem} from './ProfileQItem';
import {BottomNav} from './BottomNav';

/*------------------------------------------------------------------------------------------------- 
Vrací editor dotazníku "temperament".
------------------------------------------------------------------------------------------------- */ 
export function profile_edit_qt(props, tr_def)
{
 const profile = props.profile;
 const media = props.media;
 const narrow = media.narrow;

 const t = tr_def.translate;

 return <>
      <h1>{props.back_btn}{t('qt.lbl')}</h1>

      <div className = "desc">
       <p>{t('qt.desc')}</p>
       <p>{t('qt.desc2')}</p>
       <p>{t('qt.desc3')}</p>
      </div>

      <ProfileQItem data_id = "qt1"
                    text_min = {t('qt1.min')}
                    text_max = {t('qt1.max')}
                    acc_icon
                    profile = {profile}/>

      <ProfileQItem data_id = "qt2"
                    text_min = {t('qt2.min')}
                    text_max = {t('qt2.max')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qt3"
                    text_min = {t('qt3.min')}
                    text_max = {t('qt3.max')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qt4"
                    text_min = {t('qt4.min')}
                    text_max = {t('qt4.max')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qt5"
                    text_min = {t('qt5.min')}
                    text_max = {t('qt5.max')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qt6"
                    text_min = {t('qt6.min')}
                    text_max = {t('qt6.max')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qt7"
                    text_min = {t('qt7.min')}
                    text_max = {t('qt7.max')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qt8"
                    text_min = {t('qt8.min')}
                    text_max = {t('qt8.max')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qt9"
                    text_min = {t('qt9.min')}
                    text_max = {t('qt9.max')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qt10"
                    text_min = {t('qt10.min')}
                    text_max = {t('qt10.max')}
                    profile = {profile}/>

      {/* Poutité otázky z psychologie/A psychometric evaluation of the Highly Sensitive Person Scale The components of sensory-processing sensitivity and their relation to the BISBAS and “Big Five.” (2006).pdf':
      14. Do you get rattled when you have a lot to do in a short amount of time? (EOE)
      23. Do you find it unpleasant to have a lot going on at once? (EOE)
      6. Are you particularly sensitive to the effects of caffeine? (LST)
      7. Are you easily overwhelmed by things like bright lights, strong smells, coarse fabrics, or sirens close by? (LST)
      9. Are you made uncomfortable by loud noises? (LST)
      25. Are you bothered by intense stimuli, like loud noises or chaotic scenes? (LST) */}

      <ProfileQItem data_id = "qt11"
                    text_min = {t('qt11.min')}
                    text_max = {t('qt11.max')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qt12"
                    text_min = {t('qt12.min')}
                    text_max = {t('qt12.max')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qt13"
                    text_min = {t('qt13.min')}
                    text_max = {t('qt13.max')}
                    profile = {profile}/>

      <BottomNav left  = {narrow ? {tid: 'prf_ls', url: "/profile/ls"}
                                 : {tid: 'prf_q',  url: "/profile/q"}}
                 right = {{tid: 'qr.lbl', url: "/profile/qr"}}/>
    </>;
}

