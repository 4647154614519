import React, {useEffect} from 'react';

/*------------------------------------------------------------------------------------------------- 
Zobrazuje okno popup-menu.

Props:
 content   .. prvky renderované do okna
 anchor    .. volitelně: obdélník, k němuž je okno připojeno (typicky získaný pomocí 
              event.target.getBoundingClientRect())
 align_right .. pokud je nastaveno, zarovnává se menu s pravým okrajem obdélníku anchor, jinak s levým.
 wnd_x_offset .. vodorovné odsazení okna oproti optimální pozici
 wnd_style .. volitelně: objekt, který se předá jako atribut "style" elementu implementujícímu okno.
              může obsahovat především v hodnoty left, top, right, bottom pro vzdálenost příslušného
              okraje okna a vykreslovací plochy (okno má nastaveno display: fixed)
 on_click  .. funkce s parametry (node, e) vyvolaná, když se klikne na prvek okna s 
              className=="wnd-item" nebo mimo okno.
              Parametr node potom bude příslušný prvek nebo null, poku se kliklo mimo okno.
              Parmetr e je event kliknutí.
------------------------------------------------------------------------------------------------- */ 
export function PopupWindow(props) 
{
 //------------
 // Call-back na kliknutí kamkoli do okna browseru
 // (je nastaven na okno browseru, tudíž vyvolá se až po všech handlerech na ostatních uzlech.
 //  proto je kliknutí na vlastní položky menu ošetřeno zvlášť v on_click_popup, aby
 //  se dalo zabránit šíření eventu do nadřazených uzlů).
 const on_click_anywhere = e => 
    {
     let node = e.target;
     let in_wnd = false;

     for(;;)
        {
         if(node.className==="popup-window")
            in_wnd = true;

         node = node.parentNode;

         if(!node || node.tagName==="BODY")
           { // kliknutí jinam ⇒ zavřít okno
            if(!in_wnd)
               props.on_click(null, e);
            break;
           }
        }
    }


 useEffect(() => {window.addEventListener("click", on_click_anywhere);
                  return () => {window.removeEventListener("click", on_click_anywhere);}});


 //----------
 // Call-back na kliknutí do okna menu
 const on_click_popup = e =>
    {
     let node = e.target;

     for(;;)
        {
         if(!node || node.className==="wnd-item")
           {
            props.on_click(node, e);
            break;
           }

         if(node.className==="popup-window")
            break;

         node = node.parentNode;
        }

     e.stopPropagation();
    }

 let wnd_style = props.wnd_style || {};

 if(props.anchor)
   {
    const vh = document.documentElement.clientHeight;
    const vw = document.documentElement.clientWidth;
    const r = props.anchor;
    
    // prostor pod a nad selectionem:
    let above = r.y;
    let below = vh-r.bottom;
    
    // do stanu nastavit styl prvku representujícího okno; ve stylu je vynucená absolutní pozice okna:
    if(props.align_right)
       wnd_style = {right: vw-r.right + (props.wnd_x_offset || 0)};
    else
       wnd_style = {left: r.x + (props.wnd_x_offset || 0)};

    if(above>below)
      {
       wnd_style.bottom = vh-r.top + 6; // souřadnice "bottom" je vzdálenost spodku prvku od spodku plochy
       wnd_style.maxHeight = vh-wnd_style.bottom-4;
      }
    else
      {
       wnd_style.top = r.bottom + 6;
       wnd_style.maxHeight = vh-wnd_style.top-4;
      }
   }

 return (<span className="wnd-container">
           <div className = "popup-window" 
                style     = {wnd_style}
                onClick   = {on_click_popup}>
              {props.content}
           </div>
         </span>);
}
