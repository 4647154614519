import React from 'react';
//import {profile_def} from '../profile_def';
import {get_profile_access,
        get_profile_access_class} from '../user';
import {AccControl} from '../controls/AccControl'
import {LineEditor} from '../lib/LineEditor'
import {RadioButtons} from '../lib/RadioButtons'
import {Toggle} from '../lib/Toggle'
import {Selection} from '../lib/Selection'
import {db_set_data,
        Data_op} from '../db/app_state';
import {useTranslation} from '../translations/useTranslation';
const {profile_def} = require('../profile_def.js');

/*-------------------------------------------------------------------------------------------------
Editor jedné položky profilu

props:
 profile        .. objekt profilu
 data_id        .. identifikátor položky, která se má nastavit
 value          .. stavající hodnota položky
 label          .. popisek položky UI (default: z profile_def)
 mandatory      .. true, pokud jde o povinnou položku (zobrazí se hvězdička u popisku)
 sub            .. podtitulek položky
 type           .. typ položky (default: z profile_def)
 maxlength      .. pro řetězce maximální délka (default: z profile_def)
 control        .. ovládací prvek (default z profile_def nebo podle typu)
                   Hodnota může být buď přímo ovládací prvek nebo jeden z řetězců:
                   "line_editor", "radio_buttons", "selection", "toggle".
 placeholder    .. pro "line_editor": text zobrazený v prázdném editoru
 write_throught .. pro "line_editor": všechny změny okamžitě vyvolávají setter
 optional       .. pro "radio_buttons": výběr lze úplně vypnout
 content        .. seznam položek pro "radio_buttons" nebo "selection" (default: z profile_def)
 validate       .. validační funkce (default: z profile_def)
 setter         .. nastavovací funkce (default: nastavení příslušné položky profilu podle 'data_id')
 no_right_padding .. pokud je true, user-interface není zprava odsazeno
-------------------------------------------------------------------------------------------------*/
export function ProfileItem(props)
{
 const t = useTranslation();

 const profile = props.profile;
 const data_id = props.data_id;

 let label = props.label;
 let type = props.type;
 let maxlength = props.maxlength;
 let control = props.control;
 let content = props.content;
 let validate = props.validate;

 if(data_id && profile_def[data_id])
   {
    if(label === undefined)
       label = t(data_id+'.lbl');

    if(!type)
       type = profile_def[data_id].type;

    if(!maxlength)
       maxlength = profile_def[data_id].maxlength;

    if(!control)
       control = profile_def[data_id].control;

    if(!content)
       content = profile_def[data_id].enum;
   }

 if(!control)
   {
    if(type === "int" && content)
      {
       control = "selection";
      }
    else
      {
       control = "line_editor";
      }
   }

 const val_setter = props.setter ||
                    (val => db_set_data(new Data_op(['profile', data_id], 'set', val)));

 return (<div className={"pr-item " + get_profile_access_class(profile, data_id)} 
              style={props.no_right_padding?{paddingRight:"0"}:null}
              id={data_id}>
           <div className="pr-inner">
             <div className="label">
               {label} 
               {props.mandatory && <span className="mandatory">*</span>}
             </div>
               {data_id  && 
                <AccControl value  = {get_profile_access(profile, data_id)}
                            setter = {profile_def[data_id]!==undefined && !profile_def[data_id].acc_fix ?
                                         (val => db_set_data(new Data_op(['profile', 'access', data_id],
                                                                         'set', val))
                                          /*val => app_state_dispatch({type: "profile_acc_set",
                                                                    data_id: data_id,
                                                                    value: val})*/)
                                         : null}/>}

               {control==="line_editor" ? (<LineEditor
                                            type = {type}
                                            data_id = {data_id}
                                            value = {props.value || (profile[data_id]!==null ? profile[data_id] : "")}
                                            validate = {validate}
                                            maxlength = {maxlength}
                                            placeholder = {props.placeholder}
                                            write_through = {props.write_through}
                                            on_focus = {props.on_focus}
                                            setter = {val_setter}
                                          />) : 
                control==="radio_buttons" ? (<RadioButtons
                                            type = {type}
                                            optional = {props.optional}
                                            data_id = {data_id}
                                            value = {props.value || profile[props.data_id]}
                                            content = {content}
                                            setter = {val_setter}
                                          />) : 
                control==="selection" ? (<Selection
                                            type = {type}
                                            data_id = {data_id}
                                            value = {props.value || profile[props.data_id]}
                                            content = {content}
                                            setter = {val_setter}
                                          />) : 
                control==="toggle" ? (<Toggle
                                            data_id = {data_id}
                                            value = {props.value || profile[props.data_id]}
                                            setter = {val_setter}
                                          />) : 
                control}
           </div>
           {props.sub && <div className="sub">{props.sub}</div>}
         </div>);
}

