import React from 'react';
import {NavLink}   from 'react-router-dom'
import './TreeItem.css';

/*-------------------------------------------------------------------------------------------------
Prvek obsahu tvořící stromeček v levém panelu 

props  title    .. titulek položky
       path     .. cesta URL asociovaná s položkou
                   pokud není zadána, titulek nebude reference
       external .. pokud je true, path je URL na externí zdroj - otevře se v novém okně
       url_path .. aktuální cesta z URL
       exact    .. pokud je nastaven, path je přesná cesta, jinak prefix
                   (odpovídá atributu 'exact' elementů <Route> a <NavLink>
       bold     .. titulek má být tučný
       nf_val   .. text umístěný do závorky za titulek (typicky počet nepřečtených zpráv)
                   Pokud není nastavena nebo je 0, false..., hodnota se nevykresluje
                   Pokud se notifikační hodnota vykresluje, je titulek zároveň bold.
       subtree  .. podřízené položky
       default_collapse .. true, pokud má být defaultně sbalený
-------------------------------------------------------------------------------------------------*/
export function TreeItem(props) 
{
 const init_state = 
    {
     exp: !props.default_collapse // true, pokud je obsah položky rozbalený
    }

 const [state, set_state] = React.useState(init_state);

 const cls = props.nf_val || props.bold ? " bold" : "";

 const active = props.url_path && props.path &&
                (props.exact ? props.url_path === props.path
                             : props.url_path.startsWith(props.path));

 return <div className = {"tree-item" + (state.exp ? "" : " collapsed")}>
          <div className = {"title" + (active ? " active" : "")}>
            {props.subtree && <span className="img" onClick={()=>set_state({...state, exp: !state.exp})}></span>}
            {props.path ? 
               (props.external ?
                 <a href={props.path} target='_blank'>
                  {props.title}
                 </a> :
                 <NavLink className = {cls}
                           exact = {props.exact}
                                     to = {props.path}>
                              {props.title}
                           </NavLink>) :
               <span className = {cls}>
                   {props.title}
                </span>}
            {props.nf_val ? <span className="nf-val">{props.nf_val}</span> : null}
           </div>
         {props.subtree && state.exp && <div className="subtree">{props.subtree}</div>}
        </div>
}
