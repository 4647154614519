/*#################################################################################################

 • Pomocné funkce.

#################################################################################################*/

import {t} from './translations/translation';

/*------------------------------------------------------------------------------------------------- 
Naplánuje vyvolání funkce fn na další průběh hlavní smyčky.
------------------------------------------------------------------------------------------------- */ 
export const defer_call = fn => setTimeout(fn, 0);

/*------------------------------------------------------------------------------------------------- 
Vrátí promise, která resolvuje po zadaném časovém intervalu.
------------------------------------------------------------------------------------------------- */ 
export const async_wait = timeout_ms => new Promise(resolve => setTimeout(() => resolve(), timeout_ms));

/*------------------------------------------------------------------------------------------------- 
Porovná dvě pole na rovnost prvků. Prvky se porovnávají operátorem ===
Parametry mohou být i null (pak se rovnají, když jsou oba null)
------------------------------------------------------------------------------------------------- */ 
export function array_shallow_equal(a1, a2)
{
 if(a1 === a2) return true; // pole jsou identická už na top-levelu
 if(!a1 !== !a2) return false; // false, pokud jeden je null a druhý není

 if(a1.length !== a2.length)
    return false;
 
 for(let i=0; i<a1.length; i++)
    {
     if(a1[i] !== a2[i])
        return false;
    }

 return true;
}

/*------------------------------------------------------------------------------------------------- 
Přesune prvek v poli z old_idx na new_idx. Pole mění na místě, nedělá kopii.
------------------------------------------------------------------------------------------------- */ 
export function array_move(arr, new_idx, old_idx)
{
 let e = arr[old_idx];

 if(new_idx > old_idx)
    arr.copyWithin(old_idx+1, old_idx, new_idx);
 else
    arr.copyWithin(new_idx+1, new_idx, old_idx+1);

 arr[new_idx] = e;
}

/*------------------------------------------------------------------------------------------------- 
Vrací true, pokud objetk obj nemá žádné vlastní členy. Jinak false.
------------------------------------------------------------------------------------------------- */ 
export function object_is_empty(obj)
{
 for(var key in obj) 
    {
     if(obj.hasOwnProperty(key))
        return false;
    }
 return true;
}

/*-------------------------------------------------------------------------------------------------
Členy objektu obj2 přidá do obj1. Objekt obj1 mění na místě. Funkci volá rekursivně na pořízené
objekty. Pokud je obj2 null nebo nedefinovaný, nedělá nic a vrací false.
Vrací true, pokud byl objekt obj1 změněn (nepřiřazuje položky, které byly rovné), jinak false.

Parametry:
  obj1, obj2 .. slučované objekty
  type_def   .. definice typu objektu (typicky user.js:profile_def, nebo undefined),
                Pokud je zadán, složky typu objekt, který nemají v definic typ "map" 
                se berou jako skaláry a nemergují se po složkách (typicky geo_point)
-------------------------------------------------------------------------------------------------*/
export function object_assign(obj1, obj2, type_def)
{
 let chng = false;

 if(obj2)
   {
    for(let key in obj2)
       {
        const val = obj2[key];
    
        if((val && typeof val === "object") && 
           (!type_def || !type_def[key] || type_def[key].type === "map"))
          {
           if(val && typeof val === "object")
             {
              if(!obj1[key] || typeof obj1[key] !== "object")
                 obj1[key] = Array.isArray(val) ? [] : {};
    
              if(object_assign(obj1[key], val))
                 chng = true; 
             }
          }
        else
          {
           if(obj1[key] !== val)
             {
              obj1[key] = val;
              chng = true;
             }
          }
       } 
   }

 return chng;
}

/*-------------------------------------------------------------------------------------------------
Vytvoří nový objekt, vzniklý sloučením obj1 a obj2. Členy obj2 připíšou případné stejnojmenné
členy obj1. Objekt obj1 nechá beze změny. Funkci volá rekursivně na pořízené objekty. 
Pokud se vzhldem k typu mají objektové členy obj2 přiřadit celé (nemergovat do hloubky),
přiřazuje je přímo, neklonuje je.
Pokud je obj2 null nebo nedefinovaný, vrátí obj1.
Pokud by výsledek byl totožný s obj1, vrátí obj1.

Parametry:
  obj1, obj2 .. slučované objekty
  type_def   .. definice typu objektu (typicky user.js:profile_def, nebo undefined),
                Pokud je zadán, složky typu objekt, který nemají v definic typ "map" 
                se berou jako skaláry a nemergují se po složkách (typicky geo_point)
-------------------------------------------------------------------------------------------------*/
export function object_merge(obj1, obj2, type_def)
{
 if(obj2 === undefined || obj2 === null) 
    return obj1;

 if(typeof obj1 !== "object" || !obj1)
    return obj2;

 let chng = false;
 let result = Array.isArray(obj1) ? [...obj1] : {...obj1};

 for(let key in obj2)
    {
     const val = obj2[key];
 
     if((val && typeof val === "object") && 
        (!type_def || !type_def[key] || type_def[key].type === "map"))
       {
        if(val && typeof val === "object")
          {
           if(!result[key] || typeof result[key] !== "object")
              result[key] = Array.isArray(val) ? [] : {};
 
           const subr = result[key] = object_merge(obj1[key], val);
           if(subr !== obj1[key])  // mělké porovnání stačí
              chng = true;
          }
       }
     else
       {
        if(!object_is_equal(obj1[key], val))
          {
           result[key] = val;
           chng = true;
          }
       }
    } 

 return chng ? result : obj1;
}

/*-------------------------------------------------------------------------------------------------
Odstraní všechny členy daného objektu.
-------------------------------------------------------------------------------------------------*/
export function object_clear(obj)
{
 const keys = Object.keys(obj);
 for(let i = 0; i < keys.length; i++)
    {
     delete obj[keys[i]];
    }
}

/*-------------------------------------------------------------------------------------------------
Zístká složku objektu. Např. object_get(obj, ["a", "b"]) je ekvivalentní obj.a.b .
Pokud složka neexistuje, vrací undefined.

Parametry:
 obj .. objekt, ze kterého se má získat složka
 ref .. pole identifikátorů, které jsou postupně aplikovány na obj
 start_idx .. index, na kterém začíná objekt obj v referenci ref
-------------------------------------------------------------------------------------------------*/
export function object_get(obj, ref, start_idx = 0)
{
 for(let i = start_idx; i < ref.length; i++)
    {
     if(!obj)
        return obj;

     obj = obj[ref[i]];
     if(obj === undefined)
        break;
    }
 return obj;
}

/*-------------------------------------------------------------------------------------------------
Změní hodnotu složku objektu. Vytvoří kopii objektu, kterou vrátí. Pokud nastavení nezmění hodnotu
objektu, vrací původní objekt.

Parametry:
 obj  .. objekt, jehož složka se má změnit
 op   .. operace:
         "set" = celá hodnota se nahradí
         "merge" = původní a předaná hodnota se sloučí po složkách
 data .. nová hodnota
         pokud je undefined a operace je 'set', hodnota se v cíli odstraní
 ref  .. sekvence (pole) identifikátorů, které jsou postupně aplikovány na obj (tj. refernce měněné složky)
 start_idx .. index, na kterém začíná objekt obj v referenci ref
 type_def .. definice typu na nejvyšší úrovni (nyní pouze pro "profile" profile_def)

Výsledek:
 Změněný objekt, nebo původní obj, pokud ho operace nezměnila.
-------------------------------------------------------------------------------------------------*/
export function object_modify(obj, op, data, ref, start_idx = 0, type_def = null)
{
 if(op === "merge" && (data === undefined || data === null))
    return obj;

 if(start_idx > ref.length)
   {
    console.error("object_modify: bad ref");
    return obj;
   }

 if(start_idx === ref.length)
   {
    if(op !== "merge")
      {
       if(object_is_equal(obj, data))
          return obj;
       return data;
      }

    return object_merge(obj, data, type_def);
   }

 let result = {...obj};
 let r = result;

 for(let i = start_idx; ; i++)
    {
     if(i === ref.length-1)
       {
        if(op === "merge")
          {
           if(!r[ref[i]])
             r[ref[i]] = Array.isArray(data) ? [] : {};
           
           const rm = object_merge(r[ref[i]], data);

           if(rm === r[ref[i]]) return obj; // nestavení nic nezměnilo

           r[ref[i]] = rm;
           return result;
          }

        if(object_is_equal(r[ref[i]], data))
           return obj;

        if(data !== undefined)
           r[ref[i]] = data;
        else
           delete r[ref[i]];

        return result;
       }

     // po cestě dělat mělké kopie procházených objektů (takže se rozdělý jen ty, kterými procházíme)
     if(!r[ref[i]])
        r = r[ref[i]] = {};
     else 
        r = {...r[ref[i]]};        
    }
}

/*-------------------------------------------------------------------------------------------------
Změní hodnotu složku objektu obj. Objekt mění na místě.

Parametry:
 obj  .. objekt, ze kterého se má získat složka
 op   .. operace:
         'set' = celá hodnota se nahradí
         'merge' = původní a předaná hodnota se sloučí po složkách
 data .. nová hodnota
         pokud je undefined a operace je 'set', hodnota se v cíli odstraní
 ref  .. pole identifikátorů, které jsou postupně aplikovány na obj
 start_idx .. index, na kterém začíná objekt obj v referenci ref
 type_def .. definice typu na nejvyšší úrovni (nyní pouze pro "profile" profile_def)

Výsledek:
 true, pokud byla cílová data změněna, jinak false 
-------------------------------------------------------------------------------------------------*/
export function object_set(obj, op, data, ref, start_idx = 0, type_def = null)
{
 if(op === "merge" && !data)
    return false;

 if(start_idx === ref.length)
   {
    if(op !== "merge")
      {
       const r = !object_is_equal(obj, data);
       object_clear(obj);
       Object.assign(obj, data)
       return r;
      }

    return object_assign(obj, data, type_def);
   }

 for(let i = start_idx; ; i++)
    {
     if(i === ref.length-1)
       {
        if(op === "merge")
          {
           if(!obj[ref[i]])
             obj[ref[i]] = Array.isArray(data) ? [] : {};
           return object_assign(obj[ref[i]], data);
          }

        const r = !object_is_equal(obj[ref[i]], data);

        if(data !== undefined)
           obj[ref[i]] = data;
        else
           delete obj[ref[i]];

        return r;
       }

     if(!obj[ref[i]])
        obj = obj[ref[i]] = {};
     else 
        obj = obj[ref[i]];        
    }
}

/*-------------------------------------------------------------------------------------------------
Porovná dva objekty po složkách. Pokud jde o skaláry, porovná přímo jejich hodnoty.
Pokud je loose true, neberou se v úvahu nadbytečné prvky v obj2.
-------------------------------------------------------------------------------------------------*/
export function object_is_equal(obj1, obj2, loose=false)
{
 if(!obj1) return obj1===obj2;
 if(!obj2) return false;
 if(typeof obj1 !== "object") return obj1 === obj2;
 if(typeof obj2 !== "object") return false;

 const keys = Object.keys(obj1);
 if(!loose && keys.length !== Object.keys(obj2).length) return false;

 for(let i = 0; i < keys.length; i++)
    {
     const k = keys[i];
     if(!object_is_equal(obj1[k], obj2[k]))
        return false;
    }
 return true;
}

/*-------------------------------------------------------------------------------------------------
Porovná objekty po složkách. Pokud jde o skaláry, porovná přímo jejich hodnoty.
Parametry obj2a a obj2b bere jako jeden objekt – když není daná hodnota přítomna v obj2a, bere 
se z obj2b
Pokud je loose true, neberou se v úvahu nadbytečné prvky v obj2.
-------------------------------------------------------------------------------------------------*/
export function object_is_equal2(obj1, obj2a, obj2b, loose=false)
{
 if(obj2a === undefined)
    return object_is_equal(obj1, obj2b);

 if(obj2b === undefined)
    return object_is_equal(obj1, obj2a);

 if(!obj1) return obj1 === obj2a;
 if(!obj2a) return false;
 if(typeof obj1 !== "object") return obj1 === obj2a;
 if(typeof obj2a !== "object") return false;

 const keys = Object.keys(obj1);
 if(!loose && keys.length !== Object.keys({...obj2b, ...obj2a}).length) return false;

 if(!obj2b) obj2b = {};

 for(let i = 0; i < keys.length; i++)
    {
     const k = keys[i];
     if(!object_is_equal2(obj1[k], obj2a[k], obj2b[k]))
        return false;
    }
 return true;
}

/*-------------------------------------------------------------------------------------------------
Z objektu obj1 odstraní členy, které mají stejnou hodnotu jako v obj2. Členy, které v obj1
nejsou přítomny a v obj2 jsou, nastaví na null. (tj. pokud se výsledek přimerguje k obj2,
získá se obj1).
Pokud je obj2 null nebo undefined, nechá se obj1 beze změny
Předpokládá, že členy jsou skaláry. Pokud jsou členy objekty, rovnost je definována člen po členu
Funkce mění obj1 na místě.
-------------------------------------------------------------------------------------------------*/
export function object_diff(obj1, obj2)
{
 if(!obj2) 
    return;

 for(let key in obj2)
    {
     const v1 = obj1[key];

     if(v1 === undefined)
        obj1[key] = null;
     else if(v1 === obj2[key])
        delete obj1[key];
    }
}

/*-------------------------------------------------------------------------------------------------
Zkrátí řetězec na maximální délku. Pokud je delší, zbytek uřízne a nahradí znakem ellipsis.
(vysledná délka je max_len včetně přidané ellipsis)
-------------------------------------------------------------------------------------------------*/
export function chop_string(str, max_len)
{
 if(!str) return str;
 if(str.length > max_len-1)
    return str.substr(0, max_len-1) + '…';
 return str;
}

/*-------------------------------------------------------------------------------------------------
Vrátí řetězec odpovídající danému seznamu jazyků.

Parametry:
  lang Jeden jazyk, nebo pole přípustných jazyků (dvojpísmenné zkratky) uspořádané podle relevance
  str  objekt, jehož složky jsou pojmenovány kódy jazyků. Vrátí ten, který nejlépe 
       odpovídá parametru lang. Pokud žádný jazyk neodpovídá, vrátí první složku objektu str.
       Pokud je str null, undefined nebo false nebo "", vrací "".
-------------------------------------------------------------------------------------------------*/
export function get_loc_string(lang, str)
{
 if(!str)
    return "";
  
 if(typeof lang === "string")
   {
    if(str[lang] !== undefined)
       return str[lang];
    if(str['en'] !== undefined)
       return str['en'];
   }
 else if(!lang)
   {
    for(let i = 0; i < lang.length; i++)
       {
        if(str[lang[i]] !== undefined)
           return str[lang[i]];
       }
   }
 
 return str[Object.keys(str)[0]];
}

/*-------------------------------------------------------------------------------------------------
Pro definici výčtového typu vrátí řetězec popisující danou hodnotu. Pokud není nalezena, vrací null.

Parametry:
  val      .. hledaná hodnota
  enum_def .. definice; pole objektů s prvky:
              v   - hodnota, 
              tid - identifikátor překladu
              t   - titulek (řetězec příslušný hodnotě) 
                    (je přítomno buď tid nebo t)
 t         .. překladová funkce
 tr_opt    .. (volitelně) parametry překladové funkce
-------------------------------------------------------------------------------------------------*/
export function get_enum_text(val, enum_def, t, tr_opt)
{
 for(let i = 0; i < enum_def.length; i++)
    {
     if(enum_def[i].v === val)
        return enum_def[i].t || t(enum_def[i].tid, tr_opt);
    }
 return null;
}

/*-------------------------------------------------------------------------------------------------
Paramemetrem se seznam jazyků, jak je obsažen v profilu (objekt se složkami pojmenovanými 
zkratkami jazyků a hodnotami úrovně). Funkce vrátí seznam (pole) zkratek jazyků uspořádaný
podle úrovně a následěn podle abecedy. Parametr lang_loc je objekt s lokalizovanými názvy
jazyků pro uspořádání v druhém kole.
Pokud je parametr nedefinovaný, vrací prázdné pole.
-------------------------------------------------------------------------------------------------*/
export function get_lang_list(languages, lang_loc)
{
 let lang_list = [];

 for(let lc in languages)
    {
     if(languages[lc] !== null)
        lang_list.push(lc);
    }

 // uspořádat zkratky jazyků (nejprve podle levelu, potom podle abecedy):
 lang_list.sort((e1, e2) => {if(languages[e1]!==languages[e2]) 
                                return languages[e1]-languages[e2]; 
                             return lang_loc[e1].localeCompare(lang_loc[e2]);});
 return lang_list;
}

/*-------------------------------------------------------------------------------------------------
Vrátí preferovaný jazyk podle jazyka browseru.
-------------------------------------------------------------------------------------------------*/
export function get_default_language()
{
 const nav_lang = navigator.language || navigator.userLanguage || "en";
 const lp = nav_lang.match(/^(..)(-(..))?/)
 const lang =  lp[1].toLowerCase();
 return (lang === 'cs' || lang === 'sk') ? 'cs' : 'en';
}

/*-------------------------------------------------------------------------------------------------
Funkce pro porovnání dvou timestampů (objektů se členty 'sec' a 'ns').
Vrací číslo:
< 0, pokud a < b
= 0, podku a = b 
> 0, pokud a > b
-------------------------------------------------------------------------------------------------*/
export const compare_ts = (a, b) => a.sec === b.sec ? a.ns - b.ns : a.sec - b.sec;

/*-------------------------------------------------------------------------------------------------
Převede čas v milisekundách na lokalizovaný řetězec datu a času
Parametr loc je identifikátor locale Javascriptu.
Pokud je parametr spec = true, nahradí dnešní a včerejší datum (lokalizovanými) slovy
"dnes" a "včera".
-------------------------------------------------------------------------------------------------*/
export function date_time_to_loc_string(ts, loc, spec)
{
 let d = new Date();
 const day_org = d.setHours(0,0,0); 
 if(spec && ts < day_org + 86400000 && ts > day_org - 86400000)
   {
    let r = t(ts > day_org ? 'today' : 'yesterday') + " ";
    r += new Date(ts).toLocaleString(loc, // options vypínají zobrazení data a sekund
                                     {hour: '2-digit', minute: '2-digit'});
    return r;
   }

 return new Date(ts).toLocaleString(loc, // options vypínají zobrazení sekund
                                    {year: 'numeric', month: 'numeric', day: 'numeric', 
                                     hour: '2-digit', minute: '2-digit'});
}

/*-------------------------------------------------------------------------------------------------
Převede řetezec obsahující HTML na definici uzlů Reactu.
-------------------------------------------------------------------------------------------------*/
export function html(str)
{
 return <span dangerouslySetInnerHTML={{__html: str}} key={str}></span>;
}

/*-------------------------------------------------------------------------------------------------
Převede řetezec obsahující HTML na definici uzlů Reactu. Výskyty řetězce "Glagoli" obalí
tagy <strong></strong>.
-------------------------------------------------------------------------------------------------*/
export function html_gls(str)
{
 str = str.replace(/Glagoli/g, "<strong>Glagoli</strong>");
 return <span dangerouslySetInnerHTML={{__html: str}}></span>;
}