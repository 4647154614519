/*#################################################################################################

Vyhledání podobných uživatelů pro chat/setkání.

#################################################################################################*/

import React from 'react';
import {firebase_functions,
        analytics} from '../config';
import {norm_time_to_ms,
        ms_to_norm_time,
        test_profile_mandatory_items} from '../user';
import {master} from '../db/master';
import {db_set_data} from '../db/app_state';
import {process_peer_state} from '../db/user_state';
import {get_user_profile_async} from '../db/other_users';
import {useDbData} from '../db/useDbData';
import {Toggle} from '../lib/Toggle';
import {WaitIndicator} from '../lib/WaitIndicator';
import {useTranslation} from '../translations/useTranslation';
import './MatchControl.css';
const {MATCH_LIMIT_ODATE,
       MATCH_LIMIT_OCHAT} = require('../profile_def.js');


/*-------------------------------------------------------------------------------------------------
props  profile .. profil přihlášeného uživatele
       aim     .. stránka, na které je control zobrazen; buď 'ochat' nebo 'odate'.
 -------------------------------------------------------------------------------------------------*/
export function MatchControl(props) 
{
 const profile = props.profile;
 const aim = props.aim;
 const result_ref = ['state', aim+'_match'];

 const init_state = 
   {
    searching: false,
    msg: "",
    err: "",
   }

 const [state, set_state] = React.useState(init_state);
 const result_obj = useDbData(result_ref) || {};
 const t = useTranslation();

 // odstranit z výsledku staré uživatele: 
 React.useEffect(() =>
                {
                 const ts_lim = Date.now() - (aim === 'odate' ? 48*3600000/*2 dny*/ : 3600000/*1 hodiny*/);

                 let upd = false;
                 for(const uid in result_obj)
                    {
                     if(result_obj[uid] < ts_lim)
                       {
                        delete result_obj[uid];
                        upd = true;
                       }
                    }

                 if(upd) db_set_data(result_ref, 'set', result_obj);
                });

 let enable_data_id;
 let toggle_tid;
 let enabled_msg_tid;
 let disabled_msg_tid;
 let last_match_ts_id;
 let match_count_id;
 let match_limit;

 if(aim === 'odate')
   {
    enable_data_id = 'enable_open_date';
    toggle_tid = 'odate_toggle';
    enabled_msg_tid  = 'odate_emsg'; 
    disabled_msg_tid = 'odate_dmsg';
    last_match_ts_id = 'lmodts';
    match_count_id = 'modc';
    match_limit = MATCH_LIMIT_ODATE;
   }
 else
   {
    enable_data_id = 'enable_open_chat';
    toggle_tid = 'ochat_toggle';
    enabled_msg_tid  = 'ochat_emsg'; 
    disabled_msg_tid = 'ochat_dmsg';
    last_match_ts_id = 'lmocts';
    match_count_id = 'mocc';
    match_limit = MATCH_LIMIT_OCHAT;
   }

 let out = {};
 const profile_valid = test_profile_mandatory_items(profile, aim, out);
 const missing_msg = out.msg;

 // Počet již provedených hledání:
 let last_match_ts = profile[last_match_ts_id] ? norm_time_to_ms(profile[last_match_ts_id]) : null;
 let match_count = profile[match_count_id] || 0;

 if(last_match_ts)
   { // Zjistit, jestli jsme překročili hranici dne a počet vyhledaných se má resetovat:
    let now = Date.now();
    let ts500 = (new Date(now)).setHours(5, 0, 0, 0); // dnes 5:00 místního času v UTC
    if(ts500 > now) // pro čas mezi 0:00 a 5:00 je třeba se posunout o den zpět
       ts500 -= 24*60*60000; 

    if(last_match_ts < ts500)
       match_count = 0;
   }

 const exhausted = match_count >= match_limit;
 const enabled = profile_valid && profile[enable_data_id];
 const msg = enabled && exhausted ? t("match_exh") : state.msg;


 //----------
 const set_result = resp =>
    {
     let r_obj = resp.data;
     let r = Object.keys(r_obj);

     if(r.length)
       {
        process_peer_state(r_obj);

        const ts = Date.now();
        let nr = {...result_obj};
        let promises = [];

        r.forEach((uid, idx) =>
                 {
                  nr[uid] = ts + r.length-idx; // přičíst obrácený index, aby bylo zachováno uspořádání
                  // Naplánovat načtení všech vyhledaných profilů:
                  promises.push(get_user_profile_async(uid));
                 });

        db_set_data(result_ref, 'set', nr);

        // Aktualizovat informaci o počtu vyhledávání:
        let pr_upd = {[last_match_ts_id]: ms_to_norm_time(Date.now()),
                      [match_count_id]: match_count+r.length};
        db_set_data(['profile'], 'merge', pr_upd);

        // ... nyní má každá UserEntry svůj wait-indicator, tak není potřeba čekat s tímhle hlavním
        set_state({...state, searching: false, msg: "", err: ""});
        // Wait-indicator odstranit, až když se načtou profily vyhledaných uživatelů:
        //const finish = () => set_state({...state, searching: false, msg: "", err: ""});
        //Promise.all(promises).then(finish, finish); // Pozn:. get_user_profile_async nikdy nerejectuje, místo toho vrátí profil s '$error'
       }
     else
       {
        set_state({...state, searching: false, msg: t('match_notf'), err: ""});
       }
    }

 //----------
 const match = () => 
    {
     analytics.logEvent("match_"+aim);

     set_state({...state, searching: true, msg: "", err: ""});

     const user_manip = firebase_functions.httpsCallable('user_manip');

     let result_list = Object.keys(result_obj);
     result_list.sort((a, b) => result_obj[b] - result_obj[a]);

     master.db_flush('profile')
     .then(() =>
          {
           user_manip({op: 'match_users', aim, exclude: result_list})
           .then(set_result)
           .catch(err => 
                 {
                  console.error("match_users failed", err);
                  set_state({...state, 
                             searching: false, 
                             err: t('err_server2')});
                 });
          });
    }

 //------------
 // Funkci vyvolaná při kliknutí na položku uživatele ve výsledku hledání
/* const click_on_user = profile => 
    {
     //let value = aim==='odate' ? {open_date: true} : {open_chat: true};
     //nav_view_user(url_history, view_url, profile, value);
     nav_view_user(url_history, view_url, profile, false);
    }*/

 //------------
 // Nastavuje změnu stavu povoleno/zakázáno
 const toggle_setter = v =>
    {
     db_set_data(['profile', enable_data_id], 'set', v);
     if(!v) set_state(init_state);
    }

 return <>
        <div className = 'match-control'>
         {!profile_valid && <div className='error top'>{missing_msg}</div>}
         <div className = 'toggle-row'>
           <Toggle value = {enabled}
                   setter = {profile_valid ? toggle_setter : null}/>
           {t(toggle_tid)} 
         </div>
         <div className = 'indent'>
          <div className = 'msg'>{t(enabled ? enabled_msg_tid : disabled_msg_tid)}</div>
          {enabled && !exhausted && !state.searching &&
           <button className = 'black'
                   onClick   = {match}>
            {t('btn_find_people')}
           </button>}

          {state.searching &&
           <WaitIndicator size  = {16} 
                          delay = {0}
                          title = {t('wait_match')}/>}

          {!state.searching && !!msg && enabled && 
           <div className = 'msg2'>{msg}</div>}

          {!state.searching && !!state.err && enabled && 
           <div className = 'error'>{state.err}</div>}

         </div>
        </div>
        </>;
}
