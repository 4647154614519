/*#################################################################################################

  Výběr typu zobrazení profilu

#################################################################################################*/

import React from 'react';
import {Selection} from '../lib/Selection';
import {useTranslationDef} from '../translations/useTranslationDef';
import './ProfDispSel.css';

/*-------------------------------------------------------------------------------------------------
 props  subpage     .. id postránky profilu
        pg_view     .. true, pokud jde o zobrazení, jinak o editor
        narrow      .. jde o úzký layout
        url_history .. výsledek useHistory()
-------------------------------------------------------------------------------------------------*/
export function ProfDispSel(props)
{
 let   subpage     = props.subpage;
 const pg_view     = props.pg_view;
 const narrow      = props.narrow;
 const url_history = props.url_history;

 const tr_def = useTranslationDef();


 if(subpage !== "" && subpage !== "public" && subpage !== "acq" && subpage !== "friend")
    subpage = "";

 const main_pg = narrow ? "index" : "main";

 let sc = [{v: "public",  tid: "prfd_public"},
           {v: "acq",     tid: "prfd_acq"},
           {v: "friend",  tid: "prfd_friend"},
           {v: "",        tid: "prfd_all"},
           {v: main_pg,   tid: "prfd_edit"}];

 for(let i = 0; i < sc.length; i++)
    {
     let e = sc[i];
     const sid = e.tid+".s";
     if(tr_def.dict[sid])
        e.sid = sid;
    }

 return <Selection content = {sc}
                    value = {pg_view ? subpage : main_pg}
                    setter = {val => {if(val === main_pg && !pg_view) return;
                                      url_history.push(val ? "/profile/"+val : "/profile")}}
                    align_right = {narrow}
                    className = "prof-disp-sel"/>
}