import {normalize_value} from './normalize_value';

/*------------------------------------------------------------------------------------------------- 
Pomocná funkce pro nastavení hodnoty do dat z ovládacích prvků.
Funkce vrací null, pokud nastavení problěhlo v pořádku nebo string - chybové hlášení, pokud
hodnota neodpovídá validátoru nebo ji nelze přetypovat na požadovaný typ.

Parametry:
 props  .. objekt props daného volajícího ovládacího prvku. Obsahuje tyto prvky:
           data_id       .. předá se jako druhý parametr setteru
           type          .. volitelně typ hodnoty, která se má zapsat ("int" nebo "string"). 
                            Pokud není přítomna, použije se parametr val bez konverze. 
                            null se nikdy nekonvertuje
           required      .. prázdná hodnota není přípustná. Pokud není 'required', prázdný řetězec
                            se převede na null.
           allow_empty   .. pro string: je přípustná prázdná hodnota, ale ponechá se "" 
                            (nepřevede se na null)
           setter        .. funkce vyvolaná při změně hodnoty. Nová hodnota se předá jako první  
                            parametr. 
           validator     .. funkce vrací string - chybové hlášení, 
                            pokud je hodnota neplatná nebo null, pokud je v pořádku.
                            První parametr je hodnota, druhý je data_id.
           write_invalid .. pokud je true, má se zapisovat i invalidní hodnota
                        
 val    .. hodnota, která se má předat do funkce props.setter.
------------------------------------------------------------------------------------------------- */ 
export function call_setter(props, val) 
{
 let v = normalize_value(props, val);
 let err = null;

 if(v instanceof Error)
    err  = v.message;

 if((!err || props.write_invalid) && props.setter)
    props.setter(v, props.data_id);

 return err;
}
