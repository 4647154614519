import React, {useEffect} from 'react';
import {get_profile_access,
        get_profile_access_class} from '../user';
import {open_url} from '../lib/open_url';
import {AccControl} from './AccControl'
import {LineEditor} from '../lib/LineEditor';
import {PopupWindow} from '../lib/PopupWindow';
import {db_set_data,
        Data_op} from '../db/app_state';
import {useTranslationDef} from '../translations/useTranslationDef';
import './GeoLocation.css';
//import * as addressFormatter from '@fragaria/address-formatter'; 
let addressFormatter; // odloženě načítaný modul '@fragaria/address-formatter'

// seznam nejčastějších prvků adresy v Nominatim s jejich kanonickými variantami:
// Poznámka: seznam byl získán z modulu @fragaria/address-formatter, soubor 'src/templates/aliases.json', 
const address_item_canonical = {
  street_number: "house_number",
  house_number: "house_number",
  building: "house",
  public_building: "house",
  house: "house",
  footway: "road",
  street: "road",
  street_name: "road",
  //residential: "road",  FIX_ME ve  @fragaria/address-formatter, soubor 'src/templates/aliases.json' je zde duplicita
  path: "road",
  pedestrian: "road",
  road_reference: "road",
  road_reference_intl: "road",
  square: "road",
  place: "road",
  road: "road",
  hamlet: "village",
  locality: "village",
  croft: "village",
  village: "village",
  suburb: "neighbourhood",
  city_district: "neighbourhood",
  district: "neighbourhood",
  quarter: "neighbourhood",
  residential: "neighbourhood",
  commercial: "neighbourhood",
  industrial: "neighbourhood",
  houses: "neighbourhood",
  subdivision: "neighbourhood",
  neighbourhood: "neighbourhood",
  town: "city",
  municipality: "city",
  city: "city",
  local_administrative_area: "county",
  county_code: "county_code",
  county: "county",
  state_district: "state_district",
  postcode: "postcode",
  province: "state",
  state_code: "state",
  state: "state",
  region: "region",
  island: "island",
  country_name: "country",
  country: "country",
  country_code: "country_code",
  continent: "continent"};

// kódy place_rank přiřazené kanonickým prvků adresy
// (podle: https://wiki.openstreetmap.org/wiki/Nominatim/Development_overview#Country_to_street_level)
const address_place_rank = {
  house_number: 30,
  house: 28,
  road: 26,
  neighbourhood: 22,
  postcode: 21, // hodnota nahodile vybraná z rozsahu 11-25 ve specifikaci
  village: 18,
  island: 17,
  city: 16,
  county: 12,
  state_district: 10,
  region: 10,
  state: 8,
  country: 4,
  country_code: 4,
  continent: 2};

// Pro jednotlivé země seznamy (kanonizovaných) položek adresy, které se nemají vypisovat:
const country_omit_items =
{
 cz: ["state", "region", "county"],
 sk: ["state", "region", "county"]
}


/*------------------------------------------------------------------------------------------------- 
Pod-položka profilu.
Props:
 data_id   .. identifikátor datové položky, jíž položka profilu náleží
 content   .. obsah položky
 profile   .. objekt profilu uživatele
------------------------------------------------------------------------------------------------- */ 
function ProfileSubItem(props)
{
 let profile = props.profile;

 return <div className={"pr-sub-item " + get_profile_access_class(profile, props.data_id)} 
             id={props.data_id}>
          <AccControl value  = {get_profile_access(profile, props.data_id)}
                      setter = {val => db_set_data(new Data_op(['profile', 'access', props.data_id],
                                                               'set', val))}/>
         {props.content}
        </div>;
}       

/*------------------------------------------------------------------------------------------------- 
Editor geografického umístění.

props:
  language       .. aktuální jazyk
  value          .. aktuální hodnota (objekt: {country, city, address, geo_loc, geo_rx, geo_ry, prnk}
                    (geo_loc má členy {lat: -90..90, lon: -180..180})
  home           .. objekt stejné struktury jako value. Hodnoty, které se mají nastavit,
                    když se stiskne tlačítko "Nastvati 'doma'"
  data_id_prefix .. prefix názvů datových položek (buď "" nebo "home_")
  setter         .. funkce vyvolaná při změně hodnoty. Nová hodnota se předá jako jediný objekt.
  profile        .. objekt profilu

Poznámky k implementaci:
- Geo-coding je realizováno pomocí služby nominatim.org, která využívá dat openstreetmap.org
  (Poznámka: např. mapy Seznam.cz nejspíš masivně využívají databázy OpenStreetMap)
- Dokumentace API Nominatim.org: https://nominatim.org/release-docs/develop/api/Overview/
  - používáme formát výsledku 'format=geojason'; search vrací objekt, kde je pole 'features',
    každý prvek obsahuje položku 'type', která je vždy rovna 'feature' a dále tyto položky:
    'properties' - vlastnosti objektu
    'geometry' - obsahuje definiční bod
  - v 'properties' jsou tyto údaje:
    'place_id' - dočasný identifikátor daného objektu v rámci Nominatim
    'osm_type'   - typ elementu v rámci OpenStreetMaps (viz https://wiki.openstreetmap.org/wiki/Elements)
                   (může být 'node'     = prostorový bod,
                             'way'      = prostorová linie,
                             'relation' = sdružení/vztah více elementů)
    'osm_id'     - relativně stabilní identifikátor elementu v rámci OpenStreetMap. 
                   Může se změnit/přestat existovat když se daný záznam v databázi OpenStreetMap
                   reviduje. Skutečně stabilní identifikátory jsou problém.
                   Viz: https://nominatim.org/release-docs/develop/api/Output/#place_id-is-not-a-persistent-id
                   (Pozn.: Stabilní id ani nepotřebujeme, stačí nám geo-location)
    'category'   - zřejmě odpovídá primárním features OSM (https://wiki.openstreetmap.org/wiki/Map_Features)
    'type'       - nižší členění kategorie (viz jednotlivé sekce stránky výše)
                 - pro nás jsou relevantní:
                   'boundary'/'administrative' - hranice správních oblastí 
                                               - výsledek hledání státu, města, čtvrti... 
                   'place'                     - místo, které není administrativní oblastí
                                                 (někdy vrací pouze 'boundary', někdy obojí; např: "Kelčice")
                   'place'/'house'             - výsledek konkrétní adresy                               
    'place_rank' - úroveň objektu (např. stát, město, adresa..) Pro úplný seznam viz:
                   https://wiki.openstreetmap.org/wiki/Nominatim/Development_overview#Country_to_street_level
                   Většina kódů je 2*admin_level OSM; pro význam tagu admin_level viz:
                   https://wiki.openstreetmap.org/wiki/Tag:boundary%3Dadministrative#10_admin_level_values_for_specific_countries
                   Konkrétní adrese má place_rank = 30
    'address'    - (relativně) strukturovaná adresa objektu

                   Projekt pro formátování adres podle států (zdá se, že úplný):
                   https://github.com/OpenCageData/address-formatting
                   Tady je rudimentární pokus sepsat formáty adres podle států (zatím jen 3 státy):
                   https://wiki.openstreetmap.org/wiki/Nominatim/Country_Address_Format
                 
                                                               
 

------------------------------------------------------------------------------------------------- */ 
export function GeoLocation(props) 
{
 const init_state = 
   {
    edit: false,
    sel_open: false, // true, pokud je okno selectionu otevřené
    sel_idx: null, // index položky vybrané ve výběrovém okně
    query: null, // dotaz během edit=true
    results: null, // seznam výsledků hledání
    loading: null, // čeká se na načtení informací
    loading_timeout: false, // čekalo se na načtení informací déle než 1 s => zobrazit indikátor
    error: null
   }

 const [state, set_state] = React.useState(init_state);
 const tr_def = useTranslationDef();
 const t = tr_def.translate;
 const cc_loc = tr_def.country_names;

 // loadovací indikátor zobrazit až po 1 s, aby zbytečně neproblikl, když se informace načtou hned:
 useEffect(() => {if(!state.loading) return;
                  let t = setTimeout(() => set_state(state => ({...state, loading_timeout: state.loading})), 1000);
                                                    // ^ do set_state se musí přede funkce, která dostane aktuální stav!!!
                  return () => clearTimeout(t);});

 //----------
 // Zahájí úpravy
 const start_edit = e => 
    {
     set_state({...state, edit: true});
    }

 //-------------
 // Normalizuje adresu získanou geo-kódováním na objekt, který má prvky {address, city, country, place_rank}. 
 // První dva jsou nepovinné.
 const normalize_address = async addr =>
    {
     let r = {country: addr.country_code, place_rank: addr.place_rank};

     if(r.place_rank <= 4/*country*/) 
        return r;
     
     // Bez této úpravy se špatně formátuje např. "Štúrova 8, Smižany, sk" (ve výsledku chybí obec)
     if(addr.country_code === "sk")
       { // na slovensku normalizovat okres a obec
        if(addr.city && addr.city_district)
          {
           addr = {...addr, city: addr.city_district, municipality: addr.city};
           delete addr.city_district;
          }
       }

     if(r.place_rank < 28/*building; adresa je většinou=30*/ )
       { // místa, která nejsou přesná adresa, ošetřit speciálně
         // (standardní display_name je příliš obsáhlé a formátování adresy nedává úplně uspokojivý výsledek

        // (kanonizované) položky adresy, které se nemají vypisovat:
        let omit_items = ["country", "country_code", "continent", "postcode"];

        if(addr.country_code && country_omit_items[addr.country_code])
           omit_items = omit_items.concat(country_omit_items[addr.country_code]);

        // Převést položky na kanonická jména, konfliktní nekanonické uložit do zvláštního objektu:
        let norm_addr = {}; // normalizované položky
        let denorm_addr = {}; // nekanonické položky, které mají odlišnou hodnotu od jiné, ekvivalentní

        for(let k in addr)
           {
            let ck = address_item_canonical[k];
            if(!ck)
               continue; // neznámé položky přeskočit

            if(omit_items.includes(ck))
               continue; // položky označené k vypouštění přeskočit

            if(ck === k)
              { // kanonická položka
               norm_addr[k] = addr[k];
              }
            else
              { // nekanonická
               if(!addr[ck])
                 { // kanonická alternativa neexistuje
                  if(norm_addr[ck])
                    { // existuje jiná ekvivalentní nekanonická položka
                     if(norm_addr[ck]!==addr[k])
                        denorm_addr[k] = addr[k]; // obě ekvivalentní nekanonické mají různé hodnoty, druhou uložit do denormálních
                    }
                  else
                    { // jinou ekvivalentní položku jsme zatím nepotkali, uložit hodnotu této pod kanonickém jménem
                     norm_addr[ck] = addr[k];
                    }
                 }
               else
                 { // kanonická alternativa existuje
                  if(addr[k] !== addr[ck])
                     denorm_addr[k] = addr[k]; // nekanonická má jinou hodnotu než kanonická => uložit ji do denormálních
                  // jinak nedělat nic, použije se kanonická ve svém průchodu
                 }
              }
           }

        // Normalizovat state a state_code:
        // (nadále zůstává jen "state")
        if(norm_addr.state_code)
          {
           if(!norm_addr.state)
              norm_addr.state = norm_addr.state_code

           delete norm_addr.state_code;
          }

        delete denorm_addr.state;
        delete denorm_addr.state_code;

        // Speciálně normalizovat ČR a Slovensko:
        if(addr.country_code==="cz" || addr.country_code==="sk")
          {
           if(denorm_addr.municipality && 
             (denorm_addr.municipality === "okres "+norm_addr.city || 
              denorm_addr.municipality === "okres Hlavní město Praha"))
             { // Nominatim kóduje okresy jako 'municipality'; pro okresní města okre nevypisovat
              delete denorm_addr.municipality; 
             }
          }

        if(r.place_rank === 20/*Suburb, croft, subdivision, farm, locality, islet*/ && 
           norm_addr.neighbourhood === norm_addr.city)
          { // Pokud je čtvrť stejnojmenná s městem, nastavit place_rank na město (týká se to např. vyhledání "Kromlov", kde je jeden výsledek za čtvrť)
           r.place_rank = 16; /*City*/
          }

        // Všechny položky uspořádat:
        let order = {}; // seznam priorit pořadí jednotlivých prvků adresy (každému prvku je přiřazeno číslo)

        // - priority kanonických položek:
        for(let k in norm_addr)
           {
            order[k] = address_place_rank[k]*2 + 1; // kanonické pložky první
           }

        // - priority nekanonických položek:
        for(let k in denorm_addr)
           {
            order[k] = address_place_rank[address_item_canonical[k]]*2; // nekanonické pložky mají prioritu jako kanonické, jen o 1 nižší
           }

        let aitems = Object.keys(norm_addr).concat(Object.keys(denorm_addr));
        aitems.sort((e1, e2) => order[e2]-order[e1]);

        // sestavit pole položek, vypouštět duplicity:
        let aa = [];
        for(let i=0; i<aitems.length; i++)
           {
            let val = norm_addr[aitems[i]] || denorm_addr[aitems[i]];
            if(!aa.includes(val))
               aa.push(val);
           }

        r.city = aa.join(", ");
        return r;
       }
      
     if(!addressFormatter)
        addressFormatter = await require('@fragaria/address-formatter');

     // Převést adresu na pole stringů pomocí package '@fragaria/address-formatter'
     // Cílem je změť prvků normalizovat (typicky vypadně 1 nebo 2 prvkové pole).
     // Odstraníme název země z adresy, takže nebude ve formátovaném výstupu.
     // Kód země necháme, podle něj se vybere šablona adresy:
     let aa = addressFormatter.format({...addr, country: null, place_rank: undefined},
                                        {//abbreviate: true,
                                         output: "array"});

     // FIX_ME  na slovensku to dává první položku "undefined" (např pro "Štúrova 8, sk")
     if(aa && aa[0]==="undefined")
        aa = aa.splice(1);

     if(!aa || aa.length===0)
        return r;

     if(aa.length===1)
       {
        r[r.place_rank < 28 ? "city" : "address"] = aa[0]
       }
     else  
       {
        r.address = aa[0];
        r.city    = aa.splice(1).join(', ');
       }

     return r;
    }

 //----------
 // Uloží výsledek výběru. Parametr je objekt vytvořený funkcí process_search_result
 // (má členy {cc, addr, g, bbox, key})
 const set_result = r =>
    {
     const lon = Number(r.g[0]);
     const lat = Number(r.g[1]);

     let val = {country: r.cc, 
                geo_loc: {lon: lon, lat:lat}, 
                prnk:    r.addr.place_rank};

     if(r.addr.city)
        val.city = r.addr.city;

     if(r.addr.address)
        val.address = r.addr.address;

     // Pokud je znám bounding-box a je v některém rozměru větší než 35 km, zaznamenat i rozsah území:
     if(r.bbox)
       {
        // přepočítávácí faktor geografických stupňů na kilometry:
        const lon_km = Math.cos(lat/180*Math.PI) * 110.574; // stupně délky přepočítat na km vzhledem k šířce
        const lat_km = 111.320; // 1 stupeň šířky = 111 km (liší se mírně, protože země není přesná koule)
        const dx = Math.abs(r.bbox[2]-r.bbox[0]) * lon_km;
        const dy = Math.abs(r.bbox[3]-r.bbox[1]) * lat_km;

        if(dx > 35.0 || dy > 35.0) // Praha má napříč 34.4, co je větší, nebrat jako bod, ale jako území
          {
           val.geo_rx = Math.abs(r.bbox[2]-r.bbox[0])/2;
           val.geo_ry = Math.abs(r.bbox[3]-r.bbox[1])/2;
          }
       }
     
     set_state(init_state);

     props.setter(val);
    }

 //----------
 // Zpracuje JSON objekt vrácený jako výsledek geocoding
 const process_search_result = async (result, wnd_anchor) =>
    {
     let rlist = [];
     if(result.features)
       {
        // projít všechny prvky výsledku a použít ty, se správným typem:
        for(let i=0; i<result.features.length; i++)
           {
            const f = result.features[i];
            const p = f.properties;

            // Pokud prvek není administrativní území (město, kraj, čtvrť) nebo název místa 
            // nebo nemá potřebné náležitosti, přeskočit ho:
            const cat = p.category;
            const typ = p.type;

            if(((cat!=="boundary" || typ!=="administrative") && cat!=="place"))
               continue;

            if(p.place_rank < 4/*výšší než 'country'*/ || 
               !p.address.country_code || !f.geometry || f.geometry.type!=="Point")
               continue;

            let addr = p.address;
            addr.place_rank = p.place_rank;

            addr = await normalize_address(addr);

            let r = {cc:   p.address.country_code, 
                     addr: addr, 
                     g:    f.geometry.coordinates, 
                     bbox: f.bbox, 
                     key:  p.osm_id};


            // Přidat, pokud už místo se stejnou adresou ve výsledku neexistuje:
            // (duplicity jsou způsobeny tím, že je totéž místo zahrnuto pod jiným rankem)
            for(let j=0; ; j++)
               {
                if(j === rlist.length)
                  {
                   rlist.push(r);
                   break;
                  }

                if(rlist[j].addr.city    === r.addr.city && 
                   rlist[j].addr.address === r.addr.address)
                   break;
               }
           }
       }

     if(rlist.length === 0)
       {
        set_state({...state, sel_open: false, 
                             sel_idx: null, 
                             results: null, 
                             loading: null, 
                             loading_timeout: false, 
                             error: t('err_noth_found')});
       }
     else if(rlist.length === 1)
       { // jediný prvek přímo použít jako výsledek
        set_result(rlist[0]);
       }
     else
       { // jinak zobrazit popup-window:
        set_state({...state, results: rlist,
                             sel_open: true, 
                             sel_idx: null,
                             wnd_anchor: wnd_anchor,
                             loading: null, 
                             loading_timeout: false,
                             error: null});
       }
    }

 //----------
 // Vyvolá požadavek na geocoding
 const search = e => 
    {
     if(state.edit && state.query)
       {
        set_state({...state, loading: true, 
                             loading_timeout: false, 
                             sel_open: false, 
                             sel_index: null, 
                             results: null, 
                             error: null});

        let url = "https://nominatim.openstreetmap.org/search?format=geojson&addressdetails=1";
        url += "&q="+encodeURI(state.query);
        if(props.language)
           url += "&accept-language="+props.language;

        const wnd_anchor = e.target.getBoundingClientRect();

        open_url(url)
        .then(result => result.json())
        .then(result => process_search_result(result, wnd_anchor))
        .catch(error => set_state({...state, loading: null, 
                                             loading_timeout: false, 
                                             error: t('err_geo_fail')}));
       }
    }

 // ----------------
 // Kliknutí při otevřeném okně na položku okna nebo mimo okno
 const on_click_selection = (node, e) => 
    {
     if(node !== null)
       {
        set_result(state.results[node.attributes.value.value]);
        e.stopPropagation();
       }
     else  
       {
        if(state.sel_open && e.target.id!=="geo-loc")
           set_state(init_state);
       }
    }

 // ----------------
 // Myš se pohybuje na položkou menu.
 const mouse_over_sel_item = e =>
    {
     let idx = Number(e.currentTarget.attributes.value.value);
     if(idx !== state.sel_idx)
        set_state({...state, sel_idx: idx});
    }

 //----------------
 // Funkce vyvolaná při stisknutí klávesy v řádkovém editoru.
 const on_key = e =>
    {
     if(e.key==="Enter" || e.keyCode === 13)
       {
        if(state.sel_open && state.sel_idx !== null)
          {
           set_result(state.results[state.sel_idx]);
          }
        else
          {
           search(e);
           e.stopPropagation();
          }
       }
     else if(e.key==="Escape" || e.keyCode === 27)
       {
        set_state(init_state);
       }
     else if(e.key==="ArrowUp" || e.keyCode === 38)
       {
        if(state.sel_open)
          {
           let new_idx = state.sel_idx;
           if(new_idx === null)
              new_idx = state.results.length-1;
           else if(new_idx > 0)
              new_idx--;
           
           if(new_idx !== state.sel_idx)
              set_state({...state, sel_idx: new_idx});
           
           e.stopPropagation();
          }
       }
     else if(e.key==="ArrowDown" || e.keyCode === 40)
       {
        if(state.sel_open)
          {
           let new_idx = state.sel_idx;
           if(new_idx === null)
              new_idx = 0;
           else if(new_idx < state.results.length-1)
              new_idx++;
           
           if(new_idx !== state.sel_idx)
              set_state({...state, sel_idx: new_idx});
           
           e.stopPropagation();
          }
       }
    }

 //----------------
 // Nastaví člen props.home jako aktuální umístění
 const set_home = () =>
    {
     if(props.home)
        props.setter(props.home);
    }

 let disp;

 if(state.edit)
   {
    let sel_list = null;
    if(state.sel_open)
      {
       sel_list = state.results.map((r, idx) => <div className = {state.sel_idx===idx ? "sel-item" : ""}
                                                     key = {r.key} >  
                                                  <div className = "wnd-item"
                                                       value = {idx}
                                                       onMouseOver = {mouse_over_sel_item}>
                                                  
                                                    <div className="first">
                                                      {r.addr.address || r.addr.city || cc_loc[r.addr.country]}
                                                    </div>
                                                    <div className="second">
                                                      {r.addr.address && r.addr.city ? 
                                                       r.addr.city + ", " + cc_loc[r.addr.country] : 
                                                       !r.addr.city || cc_loc[r.addr.country]}
                                                    </div>
                                                  </div>
                                                </div>
                                    );
      }

    disp = <div className="pr-sub-item disp-row">
             <LineEditor value       = {state.query || ""} 
                         data_id     = "geo-loc"
                         placeholder = {t('geol_pholder')}
                         setter      = {val => set_state({...state, query: val})}
                         maxlength   = {256}
                         autoFocus 
                         write_through
                         on_key      = {on_key}/>
             <button className="btn-search" onClick={search}>{t('btn_search')}</button>
             {state.sel_open>0 && 
              <PopupWindow content={sel_list}
                           anchor={state.wnd_anchor}
                           align_right = {true}
                           on_click={on_click_selection}/>}
           </div>
   }
 else
   {
    let home_btn = null;

    if(props.home && props.home.country)
       home_btn = <div className="btn-home" >
                    <button onClick={set_home} title={t('geol_h2c.title')}>{t('geol_h2c.btn')}</button>
                  </div>

    if(!props.value.address && !props.value.city && !props.value.country)
       disp = <> 
                <div className="btn-edit disp-row" >
                  <button onClick={start_edit}>{t('geol_set.btn')}</button>{home_btn}
                </div>
              </>;
    else
       disp = <>
               {props.value.address && <ProfileSubItem data_id = {props.data_id_prefix+"address"}
                                                       content = {props.value.address}
                                                       profile = {props.profile}/>}

               {props.value.city    && <ProfileSubItem data_id = {props.data_id_prefix+"city"}
                                                       content = {props.value.city}
                                                       profile = {props.profile}/>}

               {props.value.country && <ProfileSubItem data_id = {props.data_id_prefix+"country"}
                                                       content = {cc_loc[props.value.country]}
                                                       profile = {props.profile}/>}
               <div className="btn-edit disp-row" >
                 <button onClick={start_edit}>
                   {t(props.value.country && !props.value.city && !props.value.addresss ? 'btn_specify' : 'btn_change')}
                 </button>
                 {home_btn}
               </div>
              </>;
   }

 return <div className="geo-location disp-col" onKeyDown={on_key}>
         {disp}
         {state.error && <div className="error">{state.error}</div>}
         {state.loading && state.loading_timeout && <div className="ld-box">{t('wait_search')}</div>}
        </div>
}
