/*#################################################################################################

Modul s konfigurací aplikace. Převážně napojení na Google Cloud

Aplikace musí zavolat firebase_init()

Funkce Google Cloud se musejí volat přes firebase_functions:
   Např.:  firebase_functions.httpsCallable('NÁZEV-FUNKCE');

#################################################################################################*/

import firebase from 'firebase/app';
// Moduly používané projektem jsou importovány zde, v ostatních modulech stačí 
// importovat základní modul "import firebase from 'firebase/app';"
import 'firebase/auth';
import "firebase/firestore";
import "firebase/functions";
import "firebase/storage";
import "firebase/remote-config";
import "firebase/analytics";

// Moduly Firebasu (měly by se používat tyto proměnné a nikoli nové volání firebase.xxx())
export let firestore;           // = firebase.firestore();
export let firebase_functions;  // = firebase.app().functions("europe-west3");
export let remote_config;       // = firebase.remoteConfig(); Může zůstat undefined, pokud není jeho použití přípustné
export let analytics;           // = firebase.analytics(); (Google Analytics)

// Konfigurace aplikace:
export const config = 
   {
    app_version:   9,                 // Verze aplikace. Musí odpovídat verzi v Remote Config nastavené ve webové konzoli Firebase
                                      // (Pokud je v Remote Config novější verze, provede se reload stránky)
    app_load_time: Date.now(),        // čas spuštění aplikace (tj. poslední reload stránky)
    images_bucket: null,              // název bucketu pro ukládání obrázků
                                      // Pozn.: pro veřejný přístup k souborům je vhodné uvolnit CORS
                                      // V rootu projektu je soubor public_cors.json s pravidly povolujícími přístup odkudkoli
                                      // Na bucket se aplikuje příkazem:
                                      //    gsutil cors set public_cors.json gs://<BUCKET>
                                      // (viz: https://firebase.google.com/docs/storage/web/download-files#cors_configuration)
   };

/*-----------------------------------------------------------------------------------------------*/
export function firebase_init()
{
 // když se tady změní projekt, měl by se změnit i na command-lině: firebase use <projectId>

 // Projekt Glagoli:
 const firebase_config = {
       apiKey:            "AIzaSyBFyH5bPe-IFTHtlr4MvWZSI_LrISU97sg",
       authDomain:        "glagoli-1.firebaseapp.com",
       databaseURL:       "https://glagoli-1.firebaseio.com",
       projectId:         "glagoli-1",
       storageBucket:     "glagoli-1.appspot.com",
       messagingSenderId: "153771536827",
       appId:             "1:153771536827:web:ae70e95c45859f6d79549e",
       measurementId:     "G-BP1PQX5YCH"};

 config.images_bucket = 'glagoli-img';

 // Projekt Firebase-TEST:        
/* const firebase_config = {
       apiKey:            "AIzaSyCmNZ5JwXL8F8eVkN1DWORmTxvMrHVxiOg",
       authDomain:        "fir-test-f1e7a.firebaseapp.com",
       databaseURL:       "https://fir-test-f1e7a.firebaseio.com",
       projectId:         "fir-test-f1e7a",
       storageBucket:     "fir-test-f1e7a.appspot.com",
       messagingSenderId: "240062526282",
       appId:             "1:240062526282:web:1987128168c5589aa9c7b3",
       measurementId:     "G-R2K76YH9G0"};

 config.images_bucket = 'fbase-test-img';
*/
  
 firebase.initializeApp(firebase_config);

 analytics = firebase.analytics();
 firestore = firebase.firestore();

 firebase_functions = firebase.app().functions("europe-west3");

 // Detekce, jestli je Firefox v Private Mode (v Private módu Firefox nemá indexedDB, 
 // kterou potřebuje Google Remote Config.)
 // FIXME .. až to Firefox nebo Google opraví, může se to odstranit
 let _idb = indexedDB.open("test");
 _idb.onerror   = () => console.warn("IndexedDB missing"); // musí tu být oba call-backy
 _idb.onsuccess = () => 
                  { // **** Pokud se test na indexDB odstrantí nechat tu pouze tuto inicializaci: ****
                   remote_config = firebase.remoteConfig();
                   remote_config.defaultConfig = {app_version: config.app_version};

                   //remote_config.settings.minimumFetchIntervalMillis = 3600000; // minimální čas mezi dvěma načteními Remote Config
                  }

 if(process.env.NODE_ENV === 'development') 
   {// Tento kód by měl být v release verzi Webpackem odstraněn 
    // (viz https://stackoverflow.com/questions/45776264/remove-some-code-lines-in-production-distribution-files) 

    // Pokud jsme na lokálním počítači, přesměrovat požadavky na Firebase Emulator:
    if(window.location.hostname === "localhost") 
      {
       //firebase.auth().useEmulator('http://localhost:9099/');
       //firebase_functions.useFunctionsEmulator("http://localhost:5001"); 
       //firestore.settings({host: "localhost:8080", ssl: false});
      }
   }
}