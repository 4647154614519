/*#################################################################################################

  Zobrazuje blok nepřečtených zpráv.

#################################################################################################*/

import React from 'react';
import {useHistory} from 'react-router-dom';
import {date_time_to_loc_string} from '../utils';
import {nav_user_chat} from '../navigate';
import {UserIcon} from './UserIcon';
import {useTranslationDef} from '../translations/useTranslationDef';
import './UnreadMessages.css';

/*-------------------------------------------------------------------------------------------------
Vytvoří UI seznamu nových zpráv s nadpisem pro jednu skupinu uživatelů.
-------------------------------------------------------------------------------------------------*/
function msg_user_group(title_id, user_list, ctx)
{
 if(!user_list.length)
    return null;

 let content = []; 

 let msg_count = 0;

 for(let i = 0; i < user_list.length; i++)
    {
     const uid = user_list[i];
     const msgs = ctx.urm[uid];
     if(!msgs) continue;

     msg_count += msgs.length;

     const conn = ctx.profile.conn[uid];
     const title = conn ? conn.nick_name : "";
     const time = "  " + date_time_to_loc_string(msgs[msgs.length-1].t, ctx.ui_lang, true);
     content.push(<div className = "message-group"
                       key = {msgs[0].t}
                       onClick = {() => nav_user_chat(ctx.url_history, uid)}
                       title = {ctx.t('nav_user_chat')}>
                    <UserIcon title = {title}
                              uid   = {uid}/>
                    <div className="msgs">
                      <div className="title">{title}</div>
                      <div className="time">{time}</div>
                      {msgs.map(m => <div className="msg" key={m.t}>{m.m}</div>)}
                    </div>
                  </div>)
    }

 return <>
         <h2>{ctx.t(title_id, {number: msg_count > 1})}</h2>
         {content}
        </>;
}

/*-------------------------------------------------------------------------------------------------
props  conn_groups .. seznam skupin uživatelů 
                      (použijí se členy new_odate, new_ochat, new_other a unread_msgs)
       profile     .. profil přihlášeného uživatele
       page_id     .. 'chat', 'odate' nebo '' podle toho, kde má být seznam zobrazen.
                      Podle hodnoty odfiltruje zprávy.
-------------------------------------------------------------------------------------------------*/
export function UnreadMessages(props) 
{
 const profile     = props.profile;
 const conn_groups = props.conn_groups;
 const page_id     = props.page_id;

 const url_history = useHistory();
 const tr_def      = useTranslationDef();
 const t           = tr_def.translate;
 const ui_lang     = tr_def.lang;

 const new_odate   = page_id !== 'chat'  ? conn_groups.new_odate : []; 
 const new_ochat   = page_id !== 'odate' ? conn_groups.new_ochat : []; 
 const new_other   = page_id !== 'odate' ? conn_groups.new_other : [];
 const unread_msgs = page_id === 'odate' ? conn_groups.unread_msgs_odate :
                     page_id === 'chat'  ? conn_groups.unread_msgs_chat :
                     conn_groups.unread_msgs;

 const urm = profile.cache && profile.cache.unread_msgs;
 
 if(!urm || (!new_odate.length && !new_ochat.length && !new_other.length && !unread_msgs.length)) 
    return null;

 let ctx = {urm, profile, url_history, ui_lang, t};

 let content = []; 

 content.push(<div key="um-odate">
               {msg_user_group('msg_new_odate', new_odate, ctx)}
              </div>);

 content.push(<div key="um-ochat">
               {msg_user_group('msg_new_ochat', new_ochat, ctx)}
              </div>);

 content.push(<div key="um-other">
               {msg_user_group('msg_new_other', new_other, ctx)}
              </div>);

 content.push(<div key="um-msg">
               {msg_user_group('msg_new_msg',   unread_msgs, ctx)}
              </div>);

 return <div className="unread-messages">{content}</div>;
}
