/*#################################################################################################

Obecné funkce pro práci s profilem uživatele.

#################################################################################################*/

import firebase from 'firebase/app';
import {object_assign,
        object_is_empty} from './utils';
import {db_set_data,
        db_get_data} from './db/app_state';
import {t} from './translations/translation';
const  {profile_def,
        ACCESS_PRIVATE,
        ACCESS_FRIEND,
        ACCESS_ACQ,
        ACCESS_PUBLIC} = require('./profile_def.js');

/*-------------------------------------------------------------------------------------------------
Pro danou položku profilu vrátí její přístupová práva (konstaly ACCESS_...)

Parametry:
  profile data profilu
  data_id identifikátor datové položky, pro kterou se přístupová práva vracejí
          (pokud není zadána, funkce vrací null)
-------------------------------------------------------------------------------------------------*/
export function get_profile_access(profile, data_id)
{
 if(!data_id || !profile.access) return null;

 let acc = profile.access[data_id];
 if(acc !== undefined)
    return acc;
 
 acc = profile_def[data_id].acc;
 if(acc !== undefined)
    return acc;
 
 return ACCESS_PRIVATE;
}

/*-------------------------------------------------------------------------------------------------
Pro danou položku profilu vrátí příslušné jméno třídy HTML elementu

Parametry:
  profile data profilu
  data_id identifikátor datové položky, pro kterou se přístupová práva vracejí
          (pokud není zadána, funkce vrací "")
-------------------------------------------------------------------------------------------------*/
export function get_profile_access_class(profile, data_id)
{
 switch(get_profile_access(profile, data_id))
       {
        case ACCESS_PUBLIC:
             return "acc-public-bg";
        case ACCESS_ACQ:
             return "acc-acq-bg";
        case ACCESS_FRIEND:
             return "acc-friend-bg";
        case ACCESS_PRIVATE:
             return "acc-private-bg";
        default: 
             return "";
       }
}


/*-------------------------------------------------------------------------------------------------
Konvertuje hodnotu podle definice profilu. Pokud se hodnota převést nedá, vrátí null.

Parametry:
  _pdef .. buď prvek profile_def nebo řetězec odpovídající položce 'type' tohoto objektu.
           null = jakýkoli typ
  val   .. hodnota, která se má převést
  db    .. pokud je true, výsledkem je hodnota příslušná Firebase, jinak formátu aplikace
           (tj. zapouzdří resp. rozpouzdří "geo_point" a "tstamp")
-------------------------------------------------------------------------------------------------*/
export function profile_normalize_value(_pdef, val, db = false)
{
 if(val === null || val === undefined)
    return null;

 let type;
 let pdef;

 if(_pdef && typeof _pdef === 'object')
   {
    pdef = _pdef;
    type = pdef.type;
   }
 else
   {
    pdef = null;
    type = _pdef;
   }

 switch(type)
       {
        case "bool":
             return !!val;
        case "int": 
             if(val === false && pdef && pdef.q) return val;
             if(typeof val == "number") return val | 0;
             return parseInt(val) || null;
        case "float":
             if(typeof val == "number") return val;
             return parseFloat(val) || null;
        case "string":
             if(typeof val === "object") return null;
             return val.toString();
        case "map":
             if(typeof val !== "object") return null;
             return val;
        case "set":
        case "array":
             if(!Array.isArray(val)) return null;
             return val;
        case "geo_point":
             if(typeof val !== "object") return null;
             if(db)
               {
                if(val instanceof firebase.firestore.GeoPoint)
                   return val;

                if(typeof val.lat != "number" || typeof val.lon != "number")
                   return null;

                return new firebase.firestore.GeoPoint(val.lat, val.lon)
               }
             else
               {
                if(val instanceof firebase.firestore.GeoPoint)
                   return {lat: val.latitude, lon: val.longitude};
                
                if(typeof val.lat != "number" || typeof val.lon != "number")
                   return null;

                return {lat: val.lat, lon: val.lon};
               }

        case "tstamp":
             if(typeof val !== "object") return null;
             if(db)
               {
                if(val instanceof firebase.firestore.Timestamp)
                   return val;

                if(typeof val.sec != "number" || typeof val.ns != "number")
                   return null;

                return new firebase.firestore.Timestamp(val.sec, val.ns)
               }
             else
               {
                if(val instanceof firebase.firestore.Timestamp)
                   return {sec: val.seconds, ns: val.nanoseconds};
                
                // Hodnoty s podtržítky dostaneme z volání Cloud Functions:
                if(val._seconds !== undefined && val._nanoseconds !== undefined)
                   return {sec: val._seconds, ns: val._nanoseconds};

                if(typeof val.sec != "number" || typeof val.ns != "number")
                   return null;

                return {sec: val.sec, ns: val.ns};
               }

        default:
             return val;
       }
}

/*-------------------------------------------------------------------------------------------------
Vrátí objekt s defaultními přístupovými právy k profilu.
-------------------------------------------------------------------------------------------------*/
export function profile_default_access()
{
 let acc = {};

 for(let k in profile_def)
    {
     const pdef = profile_def[k];
     if(pdef.access === undefined || pdef.acc_fix)
        continue;
     acc[k] = pdef.access;
    }

 return acc;
}

/*-------------------------------------------------------------------------------------------------
Vytvoří prázdný objekt profilu.
-------------------------------------------------------------------------------------------------*/
export function empty_profile()
{
 return {access: profile_default_access(), conn: {}};
}

/*-------------------------------------------------------------------------------------------------
K danému profilu vytvoří objekt propojení (User_connection). 
Pokud není zadán new_conn, přidá položku 'no_connection': true.
Pokud !profile, vrací null.
-------------------------------------------------------------------------------------------------*/
export function create_user_conn(profile, new_conn=false)
{
 if(!profile || !profile.ts)
    return null;

 let r = {nick_name:     profile.nick_name || null,
          sex:           profile.sex || null,
          profile_ts:    norm_time_to_ms(profile.ts),
          update_ts:     Date.now()};

 if(!new_conn)
    r.no_connection = true;

 let name = null;
 if(profile.first_name && profile.last_name)
    name = profile.first_name + ' ' + profile.last_name;
 else if(profile.first_name)
    name = profile.first_name;
 else if(profile.last_name)
    name = profile.last_name;

 r.full_name = name;

 return r;
}

/*-------------------------------------------------------------------------------------------------
Normalizuje profil. Pokud je předán objekt changes, zapíše do něj změněné položky (změny zároveň
vždy provede i v objektu profile).
Vrací true, pokud byl profil změněn, jinak false.
-------------------------------------------------------------------------------------------------*/
export function normalize_profile(profile, changes = null)
{
 let chng = {};

 if(!profile.child_num)
   {
    if(profile.yc_birth_year!==null)
       chng.yc_birth_year = null;

    if(profile.oc_birth_year!==null)
       chng.oc_birth_year = null;
   }

 if(profile.child_num === 1)
   {
    if(profile.yc_birth_year)
      {
       if(profile.yc_birth_year !== profile.oc_birth_year)
          chng.oc_birth_year = profile.yc_birth_year;
      }
   }

 if(profile.education != null && profile.education < 4)
   {
    if(profile.edu_subj)
       chng.edu_subj = null;
    if(profile.schools)
       chng.schools = null;
   }

 // Zakázat open-chat a open-date, pokud nejsou přítomny všechny povinné položky profilu:
 let out = {};
 test_profile_mandatory_items(profile, null, out);

 if(!out.odate)
    chng.enable_open_date = false;

 if(!out.ochat)
    chng.enable_open_chat = false;
 
 // Promítnou změny:   
 if(changes)
    object_assign(changes, chng);

 return object_assign(profile, chng);
}

/*-------------------------------------------------------------------------------------------------
Otestuje, jestli jsou dané položky přítomné v profilu.

Parametry:
  profile .. objekt profilu, který se má testovat
  attrs   .. polže id položek, které se mají testovat (pokud přítomno 'geo_loc' testuje se
             alternativně i 'home_geo_loc')
  out     .. objekt výstupních dat. Pokud je přítomnem a chybějí některé položky, uloží se
             do něj člen 'msg' obsahující hlášení o chybějících položkách profilu.

Výstedek:
  true, pokud jsou všechny požadované položky přítomny, false jinak.
-------------------------------------------------------------------------------------------------*/ 
function test_profile_items(profile, attrs, out)
{
 let missing = [];

 for(let i = 0; i < attrs.length; i++)
    {
     const a = attrs[i];
     const v = profile[a];

     if(v === null || v === undefined || (typeof v === 'object' && object_is_empty(v)))
       {
        if(a === 'geo_loc' && profile.home_geo_loc)
           continue;

        missing.push(a);
       }
    }

 if(missing.length === 0)
    return true;

 if(out)
   {
    out.msg = t('prof_miss') + " ";

    for(let i = 0; i < missing.length; i++)
       {
        const a = missing[i];

        if(i)
          {
           if(i === missing.length-1)
              out.msg += t('list_and');
           else
              out.msg += ", ";
          }

        out.msg += "'";
        out.msg += t(a+".lbl");
        out.msg += "'";

        if(a === 'geo_loc')
          {
           out.msg += "/'";
           out.msg += t("home_geo_loc.lbl");
           out.msg += "'";
          }
       }
   }

 return false;
}

/*-------------------------------------------------------------------------------------------------
Otestuje, zda profil obsahuje položky požadované k danému účelu.

Parametr:
  profile .. objekt profilu, který se má testovat
  aim     .. 'ochat', 'odate' nebo null
  out     .. objekt výstupních dat.
             Pro aim !== null:
               - Pokud je out zadán a chybějí některé položky, uloží se
                 do něj člen 'msg' obsahující hlášení o chybějících položkách profilu.
             Pro aim === null:
               - Objekt musí být zadán. Uloží se do něj členy 'odate' a 'ochat' nastavené na 
                 true, pokud jsou přítomny položky pro daný účel, jinak false.

Výstp:
 true, pokud jsou všechny požadované položky přítomny, false jinak. 
-------------------------------------------------------------------------------------------------*/ 
export function test_profile_mandatory_items(profile, aim, out)
{
 const mandatory_attr = {odate: ["nick_name", "sex", "languages", "geo_loc", "birth_year", "relation_status"],
                         ochat: ["nick_name", "sex", "languages"]};

 if(aim)
    return test_profile_items(profile, mandatory_attr[aim], out);

 out.odate = true;
 out.ochat = true;

 if(!test_profile_items(profile, mandatory_attr.odate))
    out.odate = false;

 if(!test_profile_items(profile, mandatory_attr.ochat))
    out.ochat = false;

 return out.odate || out.ochat;
}

//-------------------------------------------------------------------------------------------------
// Převede čas z firestore.Timestamp na číslo v milisekundách:
export const db_time_to_ms = ts => ts ? ts.toMillis() : ts;

//-------------------------------------------------------------------------------------------------
// Převede čas z čísla v milisekundách na firestore.Timestamp:
export const ms_to_db_time = ms => ms ? new firebase.firestore.Timestamp((ms/1000)|0, (ms%1000)*1000000) : ms;

//-------------------------------------------------------------------------------------------------
// Normalizovaný čas v nanosekundách převede na timestamp v milisekundách:
export const norm_time_to_ms = nt => nt ? nt.sec*1000 + ((nt.ns/1000000)|0) : nt;

//-------------------------------------------------------------------------------------------------
// Timestamp v milisekundách převede na normalizovaný čas v nanosekundách:
export const ms_to_norm_time = ts => {return ts ? {sec: (ts/1000)|0, ns: (ts%1000)*1000000} : ts};


export const XP_CHAT_WEIGHT = 0.2; // váha imprese z chatu pro XP relativně k váze osobního setkání

/*-------------------------------------------------------------------------------------------------
Vrátí změnu ve zdrojové hodnotě XP po změně profilu.

Parametry:
  pdb .. původní obsah profilu (uložený databázi)
  pchng .. změněné hodnoty
-------------------------------------------------------------------------------------------------*/ 
export function profile_xp_diff(pdb, pchng)
{
 // atributy, které se nezapočítávají do XP (buď jsou povinné, nebo souvisejí s jinou položkou):
 const ignore = 
   {
    sex:           1,
    languages:     1,
    home_geo_rx:   1,
    home_geo_ry:   1,
    geo_rx:        1,
    geo_ry:        1,
    oc_birth_year: 1
   };

 let result = 0;

 for(let k in pchng)
    {
     if(ignore[k])
        continue;

     const pdef = profile_def[k];
     if(!pdef || !pdef.match)
        continue;

     const old_val = pdb[k];
     const new_val = pchng[k];

     let old_set;
     let new_set;

     if(pdef.q)
       {
        old_set = typeof old_val === 'number';
        new_set = typeof new_val === 'number';
       }
     else
       {
        old_set = old_val !== null && old_val !== undefined;
        new_set = new_val !== null && new_val !== undefined;
       }

     result += (new_set|0) - (old_set|0);
    }

 return result*0.12; // Položek profilu je 77, úplně vyplněný profil brát jako ekvivalent 1 setkání.
                     // 1 faktor imprese odpovídá 1 bodu zdrojové XP, faktorů je 9, 
                     // tj. ekvivalent 1 imprese = 9/77
}

/*-------------------------------------------------------------------------------------------------
Vrátí hodnotu XP z daného profilu přepočtenou na aktuální čas.

Parametry:
  profile    .. zdrojový profil
  xp_id      .. 'real_xp' nebo 'net_xp'
  user_scale .. pokud je true, hodnota se přepočte, aby byla v intervalu 0..1000,
                jinak se vrací aktualizovaná zdrojová hodnota.
-------------------------------------------------------------------------------------------------*/ 
export function get_xp(profile, xp_id, user_scale)
{
 const REAL_XP_DECREASE_PER_DAY = 0.3; // denní pokles zdrojové hodnoty Real XP; 
                                       // Měsíční pokles odpovídá nárůstu za jedno setkání
 const NET_XP_DECREASE_PER_DAY = 0.9;  // denní pokles zdrojové hodnoty Chat XP – 3× rycheji než Real XP

 if(!profile) return 0

 let base = profile[xp_id];

 if(!base) return 0;

 let ts;
 let day_dec;

 if(xp_id==='real_xp')
   {
    ts = profile.rxp_ts;
    day_dec = REAL_XP_DECREASE_PER_DAY;
   }
 else
   {
    ts = profile.nxp_ts;
    day_dec = NET_XP_DECREASE_PER_DAY;
   }

 if(ts) 
   {
    const dt = (Date.now() - norm_time_to_ms(ts)) / (24*3600000); // doba uplynulá od posledního nastavení XP ve dnech
    base = Math.max(0, base - dt*day_dec);
   }

 if(!user_scale) return base;
 
 base /= 100; // Cca po deseti setkáních bude XP na polovině
 return (base / (1+base))*1000; 
}


/*-------------------------------------------------------------------------------------------------
Spočítá, kolik ze zadaných prvků profilu nemá nastavenou hodnotu.

Parametry:
  profile  objekt profilu
  items    pole názvů kontrolovaných položek               
-------------------------------------------------------------------------------------------------*/
function count_missing(profile, items)
{
 let r = 0;

 if(profile)
   {
    for(let i = 0; i < items.length; i++)
       {
        const v = profile[items[i]];
        if(v === undefined || v === null || v === "" || (typeof v === 'object' && object_is_empty(v)))
           r++;
       }
   }

 return r;
}


/*-------------------------------------------------------------------------------------------------
Vypočte souhrnné informace o profilu a vrátí je jako jeden objekt.
-------------------------------------------------------------------------------------------------*/ 
export function get_profile_summary(profile)
{
 // Počty nevyplněných otázek profilu:
 let r = {main_missing:  count_missing(profile, ["nick_name", "sex", "languages", "profile_text"]),
          qt_missing:    count_missing(profile, ["qt1", "qt2", "qt3", "qt4", "qt5", "qt6", "qt7", "qt8", "qt9", "qt10", "qt11", "qt12", "qt13"]),
          qr_missing:    count_missing(profile, ["qr1", /*"qr2", "qr3", "qr4",*/ "qr5", "qr6", "qr7", "qr8", "qr9", "qr10"]),
          qm_missing:    count_missing(profile, ["qm1", "qm2", "qm3", "qm4", "qm5", "qm6", "qm7", "qm8", "qm9", "qm10", "qm11", "qm12"]),
          qe_missing:    count_missing(profile, ["qe1", "qe2", "qe3", "qe4", "qe5", "qe6", "qe7", "qe8", "qe9", "qe10", "qe11"]),
          qv_missing:    count_missing(profile, ["qv1", "qv2", "qv3", "qv4", "qv5", "qv6", "qv7", "qv8", "qv9", "qv10"])};

 r.q_missing = (r.qt_missing!==0) + 
               (r.qr_missing!==0) + 
               (r.qm_missing!==0) + 
               (r.qe_missing!==0) + 
               (r.qv_missing!==0);

 // Počet uživatelů od nichž máme nepřečtené zprávy:
 let n_unread_msgs_chat  = 0;
 let n_unread_msgs_odate = 0;
 
 if(profile.cache && profile.cache.unread_msgs)
   {
    const urm = profile.cache.unread_msgs;

    for(let k in urm)
       {
        if(!urm.hasOwnProperty(k) || !urm[k])
           continue;
        if(urm[k].length)
          {
           if(profile.conn[k] && profile.conn[k].open_date)
              n_unread_msgs_odate++ /*+= urm[k].length*/;
           else
              n_unread_msgs_chat++ /*+= urm[k].length*/;
          }
       }
   }

 r.n_unread_msgs_chat  = n_unread_msgs_chat;
 r.n_unread_msgs_odate = n_unread_msgs_odate;

 return r;
}

/*-------------------------------------------------------------------------------------------------
Pokud je uživatel ve výsledcích hledání, ostraní ho.
-------------------------------------------------------------------------------------------------*/ 
export function remove_from_search_result(uid)
{
 let result_ref = ['state', 'ochat_match'];
 let sr = db_get_data(result_ref);
 if(sr && sr[uid])
   {
    delete sr[uid];
    db_set_data(result_ref, 'set', sr);
   }

 result_ref = ['state', 'odate_match'];
 sr = db_get_data(result_ref);
 if(sr && sr[uid])
   {
    delete sr[uid];
    db_set_data(result_ref, 'set', sr);
   }
}

