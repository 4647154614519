/*#################################################################################################

 Definice typu profilu uživatele

 Modul má formát CommonJS užívaný v Node.js, protože je zároveň importován do 
 Google Cloud Functions.

 Příkaz pro import:

 const {profile_def,        
        ACCESS_PRIVATE,     
        ACCESS_FRIEND,      
        ACCESS_ACQ,         
        ACCESS_PUBLIC,      
        CONN_REL_BASE,      
        CONN_REL_NONE,      
        CONN_REL_ACQ,       
        CONN_REL_FRIEND,
        CONN_REL_REMOVED,   
        CONN_REL_BLOCKED,   
        CONN_REL_FAVORITE,  
        CONN_REL_OPEN_DATE} = require('./profile_def.js');


#################################################################################################*/

// konstatny přístupových práv:
/*export*/ const ACCESS_PRIVATE  = 0x00000;
/*export*/ const ACCESS_FRIEND   = 0x10000;
/*export*/ const ACCESS_ACQ      = 0x20000;
/*export*/ const ACCESS_PUBLIC   = 0x30000;

// Konstanty pro comm/*/relation:
/*export*/ const CONN_REL_BASE      = 0xFF0000; // maska základního vztahu
/*export*/ const CONN_REL_NONE      =  0x00000; // - žádný vztah
/*export*/ const CONN_REL_ACQ       =  0x10000; // - známí
/*export*/ const CONN_REL_FRIEND    =  0x20000; // - přátelé
/*export*/ const CONN_REL_REMOVED   = 0x800000; // - uživatel odstraněný ze seznamu (nemá se nabízet znovu)
/*export*/ const CONN_REL_BLOCKED   = 0x810000; // - blokovaný uživatel (nevidí se navzájem)
/*export*/ const CONN_REL_BLOCKING  = 0x820000; // - blokující uživatel (zůstává v conn, aby se vědělo, že se nemá propojovat)
                                                //   (pozn.: uživatelé s CONN_REL_BLOCKING se ze seznamu conn odstraní při načítání za databáze v conn_db_conv())
/*export*/ const CONN_REL_FAVORITE  = 0x000001; // Příznak, že uživatel byl nastaven jako 'oblíbený'
/*export*/ const CONN_REL_OPEN_DATE = 0x000002; // Jsou ve spojení kvůli otevřenému setkání
/*export*/ const CONN_REL_OPEN_CHAT = 0x000004; // Jsou ve spojení kvůli otevřenému chatu

/*export*/ const LANGUAGES_MAX = 6; // maximální počet jazyků v profilu

// Denní limity vyhledávání uživatelů (den končí v 5:00):
/*export*/ const MATCH_LIMIT_ODATE = 2;
/*export*/ const MATCH_LIMIT_OCHAT = 4;

// pro profile_def: položka dotazníku s hodnotami v rozmezí -50 .. 50:
const q_n50_50 = {type: "int", 
                  min: -50,
                  max: 50,
                  q: true,
                  match: true,
                  acc: ACCESS_PRIVATE,   
                  acc_fix: true};

// pro profile_def: položka dotazníku s hodnotami v rozmezí 0 .. 100:
const q_0_100  = {type: "int", 
                  min: 0,
                  max: 100,
                  q: true,
                  match: true,
                  acc: ACCESS_PRIVATE,   
                  acc_fix: true};

// pro profile_def: položka dotazníku s hodnotami v rozmezí -20 .. 80:
const q_n20_80 = {type: "int", 
                  min: -20,
                  max: 80,
                  q: true,
                  match: true,
                  acc: ACCESS_PRIVATE,   
                  acc_fix: true};


/*-------------------------------------------------------------------------------------------------
Položky definice profilu:
 type:     "int", "bool", "string", "array", "map", "set", "geo_point" ({lat: -90..90, lon: -180..180}), 
           "float", "tstamp" ({sec, ns} (odpovídá firebase.firestore.Timestamp, https://firebase.google.com/docs/reference/js/firebase.firestore.Timestamp))
           
           Poznámka: 
           v profilu se timestamp v aplikaci kóduje objektem {sec, ns}. Pro překódování
           se používá funkce profile_normalize_value(). Pro porovnání: compare_ts().
           Ve zprávách se timestamp v aplikaci kóduje číslem počtu milisekund 
           (funkce db_time_to_ms() a ms_to_db_time).
           V databáze se timestamp vždy kóduje typem firebase.firestore.Timestamp.

 label:    Zobrazovaný popisek
 acc:      výchozí přístupová práva
 acc_fix:  true, pokud přístupová práva nelze měnit
 internal: interní položka (nezobrazovaná uživateli v profilu)
 nostore:  položka se neukládá do databáze
 q:        položka dotazníku (implikuje, že je interní)
 index:    true, pokud má být položka v databázi indexovaná
 enum:     výčet možností.
           Pole objektů, kde každý má tyto položky:
           v: hodnota
           t: titulek (string)
           c: volitelný název třídy, který se v HTML přidá k prvku titulku
           (Tj. pole lze přímo předat komponentě Selection nebo RadioButtons jako 'content')
           (Text k hodnotě lze vyhledat funkcí get_enum_text() z utils.js)
 match:    true, pokud se položka používá k hodnocení shody uživatelů
 min, max: rozsah hodnot. Pokud je nastavena hodnota max, matchovací funkce považuje hodnotu za 
           číselnou (metrika je rozdíl hodnot), jinak za výčtovou (metrika je dvojhodnotová 1/0)
-------------------------------------------------------------------------------------------------*/ 
const profile_def = 
{
 uid:               {internal: true,  // UID uživatele. Neukládá se do databáze (je přítomno implicite jako id dokumentu,
                     type: "string",  // konverzní funkce z formátu databáze ho přidá)
                     nostore: true},

 user_state:        {internal: true,
                     type: "int",
                     index: true,  // indexování je nyní vypnuto ve "firestore.indexes.json", položka ale zůstává na top-levelu
                     acc: ACCESS_PRIVATE,
                     acc_fix: true}, // stav uživatele: hodnota nepřítomna = nově vytvořený 
                                     //                  0 = běžný, 
                                     //                 -1 = deaktivovaný
                                     //                 -2 = profil je naplánovaný k odstranění
                                     //                 -3 = odstraněný, zůstávají jen anonymizovaná data kvůli matchování
                    
 search_str:        {type: "set", // pole řetězců, podle kterých jde uživatel vyhledat (nyní: mail, pokud je to povoleno a přezdívka)
                     index: true, // řetězce jsou v reduceru převedeny na malá písnena, aby bylo vyhledávání case-insensitivní
                     acc: ACCESS_PUBLIC}, // primární mail uživatele

 primary_mail:      {type: "string",
                     acc: ACCESS_PRIVATE}, // primární mail uživatele

 ui_lang:           {type: "string",  // jazyk uživatelského rozhraní
                     acc: ACCESS_PRIVATE,
                     acc_fix: true},

 enable_open_chat:  {internal: true,
                     type: "bool",
                     index: true,    // indexování je nyní vypnuto ve "firestore.indexes.json", položka ale zůstává na top-levelu
                     acc: ACCESS_PUBLIC, // informace o open-chatu je veřejná (každý s ním pak může chatovat)
                     acc_fix: true}, // povolení vyhledat uživatele k chatu cizím člověkem

 enable_open_date:  {internal: true,
                     type: "bool",
                     index: true,    // indexování je nyní vypnuto ve "firestore.indexes.json", položka ale zůstává na top-levelu
                     acc: ACCESS_PRIVATE, // informace o open-date není veřejná (když k danému člověku přijdeme odjinud, než z open-date, nevíme, že je na open-date)
                     acc_fix: true}, // povolení vyhledat uživatele k chatu cizím člověkem

 real_xp:           {internal: true,
                     type: "float",
                     acc: ACCESS_PRIVATE,
                     acc_fix: true},

 rxp_ts:            {internal: true, // čas poslední aktualizace real_xp
                     type: "tstamp",
                     acc: ACCESS_PRIVATE,
                     acc_fix: true},

 rxpi:              {internal: true, // inkrement real_xp (do databáze se neukládá)
                     type: "float",  // při uložení do databáze se přičte k real_xp, aby XP nezvrostlo okamžitě
                     acc_fix: true},

 net_xp:            {internal: true,
                     type: "float",
                     acc: ACCESS_PRIVATE,
                     acc_fix: true},

 nxp_ts:            {internal: true, // čas poslední aktualizace net_xp
                     type: "tstamp",
                     acc: ACCESS_PRIVATE,
                     acc_fix: true},

 nxpi:              {internal: true, // inkrement net_xp (do databáze se neukládá)
                     type: "float",  // při uložení do databáze se přičte k net_xp, aby XP nezvrostlo okamžitě
                     acc_fix: true},

                    
/* enable_newsletter: {internal: true,
                     type: "bool",
                     acc: ACCESS_PRIVATE,
                     acc_fix: true},*/ // true = povoleno zasílání novinek na mail

 find_by_mail:      {internal: true,
                     type: "bool",
                     acc: ACCESS_PUBLIC,
                     acc_fix: true},

 nick_name:         {type: "string",
                     maxlength: 32,
                     acc: ACCESS_PUBLIC,
                     acc_fix: true}, // veřejná přezdívka

 sex:               {type: "int",
                     match: true,
                     acc: ACCESS_PUBLIC,
                     enum:[{v: 1, t: "muž"},
                           {v: 2, t: "žena"}]},

 languages:         {type: "map",
                     match: true,
                     acc: ACCESS_PUBLIC}, // objekt pojmenovaný kódy jazyka, hodnoty (úroveň): 0=native 1=proficient 2=advanced 3=intermediate

 //profile_pic_url: null, // URL profilového obrázku
 profile_text:      {type: "map",
                     acc: ACCESS_PUBLIC,
                     acc_fix: true}, // objekt, jehož členy jsou pojmenovány zkratkami jazyků a hodnoty jsou profilové texty v jednotlivých jazycích

 images:            {type: "array",     // pole definic obrázků; každému obrázku odpovídá struktura:
                     acc: ACCESS_PUBLIC,//  - id .. (int) identifikátor obrázku jednoznačný v rámci uživatele
                     acc_fix: true},    //  - acc .. (int) úroveň přístupovách práv, jedna z konstant ACCESS_...
                                        // Přístupová práva se ošetřují pro každý obrázek jednotlivě (na serveru user_profile.js/adjust_profile())
                                        
 
 first_name:        {type: "string",
                     maxlength: 32,
                     acc: ACCESS_ACQ}, 

 last_name:         {type: "string",
                     maxlength: 32,
                     acc: ACCESS_FRIEND}, 
 
 // trvalý pobyt:
 home_country:      {type: "string",
                     acc: ACCESS_PUBLIC},    //dvojpísmenný country-code
 home_city:         {type: "string",
                     acc: ACCESS_PUBLIC},    // město v rodné řeči uživatele
 home_address:      {type: "string",
                     acc: ACCESS_FRIEND},    // adresa v rodné řeči uživatele
 home_geo_loc:      {type: "geo_point",
                     match: true,
                     acc: ACCESS_PRIVATE},   // v databázi firebase.firestore.GeoPoint, 
                                             // v JavaScriptu: objekt {lat: -90..90, lon: -180..180}
 home_geo_rx:       {type: "float",          // x-ová poloosa (tj. zeměpisná délka, longitude) elipsy okolo home_geo_loc, pokud nejde o bodobý útvar (ve stupních; tj. polovina bbox)
                     match: true,            // (za bod se bere území, které je v obou směrech meníš než 35 km)
                     internal: true,
                     acc: ACCESS_PRIVATE,
                     acc_fix: true},         
 home_geo_ry:       {type: "float",          //  y-ová poloosa (latitude); analogicky
                     match: true,
                     internal: true,
                     acc: ACCESS_PRIVATE,
                     acc_fix: true},         

 home_prnk:         {type: "int",
                     internal: true,
                     acc: ACCESS_PRIVATE,
                     acc_fix: true},       //  place_rank podle Nominatim.org (https://wiki.openstreetmap.org/wiki/Nominatim/Development_overview#Country_to_street_level)
 
 // aktuální pobyt (jako položky home_...):
 country:           {type: "string",
                     acc: ACCESS_FRIEND}, 
 city:              {type: "string",
                     acc: ACCESS_FRIEND}, 
 address:           {type: "string",
                     acc: ACCESS_FRIEND}, 
 geo_loc:           {type: "geo_point",
                     match: true,
                     acc: ACCESS_PRIVATE},
 geo_rx:            {type: "float",
                     match: true,
                     internal: true,
                     acc: ACCESS_PRIVATE,
                     acc_fix: true},
 geo_ry:            {type: "float",
                     match: true,
                     internal: true,
                     acc: ACCESS_PRIVATE,
                     acc_fix: true},
 prnk:              {type: "int",
                     internal: true,
                     acc: ACCESS_PRIVATE,
                     acc_fix: true},

 // ### Sekce "Životní styl"
 birth_year:        {type: "int",
                     match: true,
                     acc: ACCESS_FRIEND},

 birth_day:         {type: "int",
                     acc: ACCESS_ACQ},

 relation_status:   {type: "int",
                     match: true,
                     max: 170,
                     acc: ACCESS_PUBLIC,
                     enum: [{v:   1, tid: "rs_single"},
                            {v:  20, tid: "rs_divorced"},
                            {v:  30, tid: "rs_widow"},
                            {v: 100, tid: "rs_separated"},
                            {v: 110, tid: "rs_compl"},    
                            {v: 120, tid: "rs_free"},     
                            {v: 130, tid: "rs_in_rel"},
                            {v: 140, tid: "rs_dom_rel"}, 
                            {v: 150, tid: "rs_engaged"},
                            {v: 160, tid: "rs_reg_p"},
                            {v: 170, tid: "rs_married"},
                            {v: null,tid: 'not_indicated', c:"italic"}]},
                        //    1 Single                         Nezadaný/nezadaná
                        //   20 Divorced                       Rozvedený/rozvedená
                        //   30 Widowed                        Ovdovělý/ovdovělá
                        //  100 Separated                      Žijící odděleně
                        //  110 It's complicated               Ve složitém vztahu
                        //  120 In an open relationship        Ve volném vztahu
                        //  130 In a relationship              Zadaný
                        //  140 In a domestic partnership      Ve společné domácnosti
                        //  150 Engaged                        Zasnoubený/zasnoubená
                        //  160 In a civil union               V registrovaném partnerství
                        //  170 Married                        V manželství

 education:         {type: "int",
                     match: true,
                     max: 6,
                     acc: ACCESS_PUBLIC,
                     enum: [{v: 1, tid: "edu_prim"},
                            {v: 2, tid: "edu_voc"},
                            {v: 3, tid: "edu_sec"},
                            {v: 4, tid: "edu_bc"},
                            {v: 5, tid: "edu_ma"},
                            {v: 6, tid: "edu_phd"},
                            {v: null, tid: 'not_indicated', c:"italic"}]}, 
                        //  1 záklaní (primary school)
                        //  2 vyučený (vocational training)
                        //  3 střední škola (secondary school)
                        //  4 vysoká škola - Bc. (Bachelor)
                        //  5 vysoká škola - Mgr./Ing. (Master)
                        //  6 vysoká škola - PhD. 

 edu_subj:          {type: "int",
                     match: true,
                     acc: ACCESS_PUBLIC,
                     enum: [{v:  1, tid: "es_med"},
                            {v:  2, tid: "es_hc"},
                            {v:  3, tid: "es_sci"},
                            {v:  4, tid: "es_math"},
                            {v:  5, tid: "es_eng"},
                            {v:  6, tid: "es_arch"},
                            {v:  7, tid: "es_hum"},
                            {v:  8, tid: "es_paed"},
                            {v:  9, tid: "es_law"},
                            {v: 10, tid: "es_byz"},
                            {v: 11, tid: "es_news"},
                            {v: 12, tid: "es_art"},
                            {v: null, tid: 'not_indicated', c:"italic"}]},     
                        // Volně podle The Higher Education Classification of Subjects (HECoS) (https://www.hesa.ac.uk/innovation/hecos)
                        // Viz též: https://www.czso.cz/csu/czso/klasifikace-oboru-vzdelani-cz-isced-f-2013
                        //          https://en.wikipedia.org/wiki/Joint_Academic_Coding_System
                        //          https://en.wikipedia.org/wiki/International_Standard_Classification_of_Education
                        //          http://uis.unesco.org/sites/default/files/documents/international-standard-classification-of-education-fields-of-education-and-training-2013-detailed-field-descriptions-2015-en.pdf
                        // !  1 medicína
                        //    2 zdravotní a sociální péče
                        //    3 přírodní vědy
                        //    4 matematika, IT
                        //    5 inženýrství a technologie
                        //    6 architektura a stavebnictví
                        //    7 humanitní vědy
                        //    8 pedagogika 
                        //    9 právo
                        //   10 obchod a management
                        //   11 média a žurnalistika
                        //   12 design a umění

 schools:           {type: "string",
                     acc: ACCESS_PUBLIC},       // seznam škol (záznamy <url, name, loc_name> odělené '\n'; jednotlivé položky záznamu jsou odděleny tabulátory (loc_name je nepoviné, potom chybý i tabulátor)
 srnk:              {type: "int",
                     match: true,
                     max: 21268,
                     internal: true,
                     acc: ACCESS_PRIVATE,
                     acc_fix: true},            //  ranking školy

 occupation:        {type: "int",
                     match: true,
                     acc: ACCESS_ACQ,
                     enum: [{v:   1, tid: "occ_mngr"},
                            {v:   2, tid: "occ_sci"},
                            {v:   3, tid: "occ_spec"},
                            {v:   4, tid: "occ_adm"},
                            {v:   5, tid: "occ_srvc"},
                            {v:   6, tid: "occ_agri"},
                            {v:   7, tid: "occ_crft"},
                            {v:   8, tid: "occ_mop"},
                            {v:   9, tid: "occ_lbr"},
                            {v:  10, tid: "occ_mil"},
                            {v:  11, tid: "occ_semp"},
                            {v:  12, tid: "occ_byz"},
                            {v: 100, tid: "occ_st"},
                            {v: 101, tid: "occ_ret"},
                            {v: 102, tid: "occ_rnt"},
                            {v: 103, tid: "occ_uemp"},
                            {v: null, tid: 'not_indicated', c:"italic"}]},    
                        // Podle: https://en.wikipedia.org/wiki/International_Standard_Classification_of_Occupations

 income:            {type: "int",
                     match: true,
                     max: 6,
                     acc: ACCESS_PRIVATE,
                     enum: [{v: 1, tid: "icm_haa"},
                            {v: 2, tid: "icm_aa"},
                            {v: 3, tid: "icm_a"},
                            {v: 4, tid: "icm_ba"},
                            {v: 5, tid: "icm_min"},
                            {v: 6, tid: "icm_none"},
                            {v: null, tid: 'not_indicated', c:"italic"}]},
 
 child_num:         {match: true,
                     type: "int",
                     acc: ACCESS_FRIEND},
 yc_birth_year:     {type: "int",
                     match: true,
                     acc: ACCESS_FRIEND},   // rok narození nejmladšího dítěte
 oc_birth_year:     {type: "int",
                     match: true,
                     acc: ACCESS_FRIEND},   // rok narození nejstaršího dítěte
 wchild:            {type: "int",
                     match: true,
                     max: 4,
                     acc: ACCESS_FRIEND,
                     enum: [{v: 1, tid: "wc_no"},
                            {v: 2, tid: "wc_perhaps"},
                            {v: 3, tid: "wc_fut"},
                            {v: 4, tid: "wc_yes"},
                            {v: null, tid: 'not_indicated', c:"italic"}]},

 sex_o:             {type: "int",
                     match: true,
                     acc: ACCESS_PRIVATE,
                     enum: [{v: 1, tid: "so_het"},
                            {v: 2, tid: "so_hom"},
                            {v: 3, tid: "so_other"},
                            {v: null, tid: 'not_indicated', c: "italic"}]},

 height:            {match: true,   // výška v cm
                     type: "int",
                     acc: ACCESS_PRIVATE},

 weight:            {match: true,   // tělesná hmotnost v kg
                     type: "int",
                     acc: ACCESS_PRIVATE},

 health:            {match: true,   
                     type: "int",
                     acc: ACCESS_PRIVATE,
                     enum: [{v: 1, tid: "hlt_sport"},
                            {v: 2, tid: "hlt_good"},
                            {v: 3, tid: "hlt_minp"},
                            {v: 4, tid: "hlt_majp"},
                            {v: 5, tid: "hlt_minh"},
                            {v: 6, tid: "hlt_majh"},
                            {v: null, tid: 'not_indicated', c: "italic"}]},

 religion:          {type: "int",
                     match: true,
                     max: 80,
                     acc: ACCESS_PRIVATE,
                     enum: [{v:   0, tid: "rlg_none"},
                            {v:   1, tid: "rlg_ath"},
                            {v:   2, tid: "rlg_agn"},
                            {v:   3, tid: "rlg_symp"},
                            {v:   4, tid: "rlg_spir"},
                            {v:  20, tid: "rlg_ch"},
                            {v:  21, tid: "rlg_ch_cath"},
                            {v:  22, tid: "rlg_ch_prot"},
                            {v:  23, tid: "rlg_ch_orth"},
                            {v:  60, tid: "rlg_is"},
                            {v:  61, tid: "rlg_is_sn"},
                            {v:  62, tid: "rlg_is_sh"},
                            {v:  80, tid: "rlg_hind"},
                            {v:  40, tid: "rlg_budh"},
                            {v: null, tid: 'not_indicated', c:"italic"}]},

 religion_rel:      {type: "int",
                     match: true,
                     max: 5,
                     acc: ACCESS_PRIVATE,
                     enum: [{v: 0, tid: "rr_indif"},
                            {v: 1, tid: "rr_neg"},
                            {v: 2, tid: "rr_toler"},
                            {v: 3, tid: "rr_form"},
                            {v: 4, tid: "rr_spir"},
                            {v: 5, tid: "rr_fund"},
                            {v: null, tid: 'not_indicated', c:"italic"}]},

 //@@ kouření, drogy, dluhy, alkohol, rasa
 
 // Temperament 
 // (podle "Between facets and domains 10 aspects of the Big Five.pdf" (DOI: 10.1037/0022-3514.93.5.880), Table 4
 qt1:               q_n50_50,           // Extraversion – Enthusiasm
 qt2:               q_n50_50,           // Extraversion – Assertivness
 qt3:               q_n50_50,           // Intellect
 qt4:               q_n50_50,           // Openness
 qt5:               q_n50_50,           // Neuroticism – Volatility
 qt6:               q_n50_50,           // Neuroticism – Withdrawl
 qt7:               q_n50_50,           // Agreeableness – Compassion
 qt8:               q_n50_50,           // Agreeableness – Politeness
 qt9:               q_n50_50,           // Conscientiousness – Industriousness
 qt10:              q_n50_50,           // Conscientiousness – Orderliness
 // - následující otázka testuje "Highly Sensitive Person" (pro odkazy viz Glagoli.docx)
 qt11:              q_n50_50,           // Highly Sensitive Person
 // - následující otázky jsou podle tabulky „Knihy/Psychologie/IPIP-ItemAssignmentTable.xlsx“ (https://ipip.ori.org/ItemAssignmentTable.htm)
 qt12:              q_n50_50,           // Negative-valence
 qt13:              q_n50_50,           // Attractiveness (Pozitivní valence)

 // Vztahy
 // - Attachment Theory Four Category Model 
 //   (viz: https://en.wikipedia.org/wiki/Attachment_measures#Self-report_questionnaires
 //         http://labs.psychology.illinois.edu/~rcfraley/measures/measures.html; staženo: psychologie/Self-Report Measures of Adult Attachment.html
 //         dotazník pochází z: https://sci-hub.tw/10.1037/0022-3514.61.2.226 (Bartholomew, K., & Horowitz, L. M. (1991). Attachment styles among young adults: A test of a four-category model. Journal of Personality and Social Psychology, 61(2), 226–244. doi:10.1037/0022-3514.61.2.226); 'psychologie/Attachment styles among young adults (1991, Bartolomew)'
 //                 - viz Appendix B
 qr1:               q_0_100,            // Secure
 qr2:               q_0_100,            // Dismissing
 qr3:               q_0_100,            // Preoccupied
 qr4:               q_0_100,            // Fearful
 // -  Color wheel theory of love (https://en.wikipedia.org/wiki/Color_wheel_theory_of_love)
 //    Položky dotazníku podle: Love Attitudes Scale (Hendrick, C., Hendrick, S. S., & Dicke, A. (1998). The Love Attitudes Scale: Short Form. Journal of Social and Personal Relationships, 15(2), 147–159. doi:10.1177/0265407598152001)
 //         - staženo v 'psychologie/The Love Attitudes Scale; Short Form (1998), Hendrick, Hendrick, Dicke.pdf'; Table 1, strana 151 
 qr5:               q_0_100,            // LAS - Erós
 qr6:               q_0_100,            // LAS - Ludus
 qr7:               q_0_100,            // LAS - Storgé
 qr8:               q_0_100,            // LAS - Pragma
 qr9:               q_0_100,            // LAS - Mania
 qr10:              q_0_100,            // LAS - Agapé

 // Morálka
 // - Kohlbergova teorie morálního vývoje
 //    [Lawrence_Kohlberg]_Essays_on_Moral_Development I.pdf; Apendix, str. 409
 //    A Longitudinal Study of Moral Development (1983) Colby, Kohlberg.pdf; str. 3
 qm1:               q_0_100,            // A (1) Preconventional - Heteronomous morality
 qm2:               q_0_100,            // A (2) Preconventional - Instrumental purpose 
 qm3:               q_0_100,            // B (3) Conventional - Interpersonal conformity
 qm4:               q_0_100,            // B (4) Conventional - Social system
 qm5:               q_0_100,            // B/C (4 1/2) - Subjective moral
 qm6:               q_0_100,            // C (6) - Postconventional

 // - Haidtova teorie emočních základů morálky
 //   "Clifford, S., Iyengar, V., Cabeza, R., & Sinnott-Armstrong, W. (2015). Moral foundations vignettes: a standardized stimulus database of scenarios based on moral foundations theory. Behavior Research Methods, 47(4), 1178–1198. doi:10.3758/s13428-014-0551-2"
 //   staženo: 'Psychologie/Moral foundations vignettes a standardized stimulus database of scenarios based on moral foundations theory (2015).pdf"
 //       - obsahuje konkrétní situace morálního prohřešku s hodnocením,
 //         ke kterému morálnímu základu patří a do jaké míry ho respondenti považují za špatný
 //         (Tabulka 1, strana 1183)
 //         Otázky zde jsou založeny na výběru dvou situací z každého emocionální základu.
 //         Vybrány jsou ty, které nejsilněji charakterizují daný emocionální základ a 
 //         mají empirickou míru závažnosti zhruba 3 (sloupec "Wrong").
 //         Otázky byly také vybírány (a mírně upraveny), aby byly kulturně neutrální.
 //   "Graham, J., Nosek, B. A., Haidt, J., Iyer, R., Koleva, S., & Ditto, P. H. (2011). Mapping the moral domain. Journal of Personality and Social Psychology, 101(2), 366–385. doi:10.1037/a0021847"
 //   staženo: 'Psychologie/Mapping the moral domain (2011).pdf'

 qm7:               q_0_100,            // Moral Foundation - Care/Harm
 qm8:               q_0_100,            // Moral Foundation - Fairness
 qm9:               q_0_100,            // Moral Foundation - Loyalty
 qm10:              q_0_100,            // Moral Foundation - Authority
 qm11:              q_0_100,            // Moral Foundation - Sanctity
 qm12:              q_0_100,            // Moral Foundation - Liberty

 // Úspěchy a nezdary (explanatory style)
 //   "Peterson, C., & Villanova, P. (1988). An Expanded Attributional Style Questionnaire. Journal of Abnormal Psychology, 97(1), 87–89. doi:10.1037/0021-843x.97.1.87"
 //     staženo: 'Psychologie/An Expanded Attributional Style Questionnaire (1988).pdf'
 //   "Whitley Jr., B. E. (1991). A Short Form of the Expanded Attributional Style Questionnaire. Journal of Personality Assessment, 56(2), 365–369. doi:10.1207/s15327752jpa5602_14"
 //     staženo: 'Psychologie/A Short Form of the Expanded Attributional Style Questionnaire (1991).pdf'
 qe1:               q_0_100,            // Explanatory Style - Success - Internal 
 qe2:               q_0_100,            // Explanatory Style - Success - External personal
 qe3:               q_0_100,            // Explanatory Style - Success - External arbitrary
 qe4:               q_n50_50,           // Explanatory Style - Success - Stability
 qe5:               q_n50_50,           // Explanatory Style - Success - Globality
 qe6:               q_0_100,            // Explanatory Style - Failure - Internal 
 qe7:               q_0_100,            // Explanatory Style - Failure - External personal
 qe8:               q_0_100,            // Explanatory Style - Failure - External arbitrary
 qe9:               q_n50_50,           // Explanatory Style - Failure - Stability
 qe10:              q_n50_50,           // Explanatory Style - Failure - Globality
 qe11:              q_n50_50,           // Regulatory focus (viz 'psychologie/Cybernetic Big Five Theory (2015) DeYoung.pdf')

 // Hodnoty (Personal values) 
 // viz 'Basic Personal Values and the Meaning of Left-Right Political Orientations in 20 Countries (2011)'
 // Škála: -10..90 (-10 = hodnota odporuje mým zásadám, 0 = hodnota pro mě není důležitá, 100 = hodnota je velmi důležitá)
 qv1:               q_n20_80,           // Self-direction (soběstačnost)
 qv2:               q_n20_80,           // Universlism
 qv3:               q_n20_80,           // Benevolence
 qv4:               q_n20_80,           // Tradition
 qv5:               q_n20_80,           // Conformity
 qv6:               q_n20_80,           // Security
 qv7:               q_n20_80,           // Power
 qv8:               q_n20_80,           // Achievement
 qv9:               q_n20_80,           // Hedonism
 qv10:              q_n20_80,           // Stimulation

 qlang:             {type: "string",      // jazyk, kterým byl vyplňován dotazník, pokude se všechny položky vyplňovaly jedním jazykem
                     internal: true,
                     acc: ACCESS_PRIVATE,
                     acc_fix: true},

 qlangm:            {type: "map",         // jazyky, kterými byl vyplňován dotazník, v případě, že jich bylo víc
                     internal: true,      // objekt indexovaný názvy položek, každé odpovídá kód jazyka
                     acc: ACCESS_PRIVATE,
                     acc_fix: true},

 created:           {internal: true,   // čas vytvoření profilu
                     type: "tstamp",
                     acc: ACCESS_PUBLIC,
                     acc_fix: true},

 ts:                {internal: true, // poslední změna profilu 
                     type: "tstamp",
                     index: true,
                     acc: ACCESS_PUBLIC,
                     acc_fix: true},
                     // článek, jak vynutit nastavení timestamp: https://medium.com/firebase-developers/the-secrets-of-firestore-fieldvalue-servertimestamp-revealed-29dd7a38a82b

 mdts:              {internal: true,     // poslední změna dat ovlivňujících matchování uživatelů 
                     type: "tstamp",     // (tj. těch, která zde mají nastaveno 'match')
                     index: true,         
                     acc: ACCESS_PUBLIC,  
                     acc_fix: true},      

 pending_delete:    {internal: true,     // čas, na který je naplánováno odstranění profilu
                     type: "tstamp",     
                     index: true,         
                     acc: ACCESS_PRIVATE,  
                     acc_fix: true},      

 // Limity vyhledávání uživatelů:
 // (čtou se serveru v match_users(), nastavují se po odbržené odpovědi v MatchControl)
 lmodts:            {internal: true,    // vyhledávání 'open date': timestamp posledního vyhledávání
                     type: "tstamp",     
                     acc: ACCESS_PRIVATE,
                     acc_fix: true},     

 modc:              {internal: true,     // vyhledávání 'open date': počet vyhledaných osob v daném dni
                     type: "int",        // (Den končí v 5:00, potom se počty resetují)
                     acc: ACCESS_PRIVATE,
                     acc_fix: true},     

 lmocts:            {internal: true,    // vyhledávání 'open chat': timestamp posledního vyhledávání
                     type: "tstamp",     
                     acc: ACCESS_PRIVATE,
                     acc_fix: true},     

 mocc:              {internal: true,     // vyhledávání 'open chat': počet vyhledaných osob v daném dni
                     type: "int",        // (Den končí v 5:00, potom se počty resetují)
                     acc: ACCESS_PRIVATE,
                     acc_fix: true},     

 // Aktivita uživatele:
 last_active:       {internal: true,     // poslední aktivita uživatele (jen přibližně, aby se pořád neukládala)
                     type: "tstamp",     // (tj. těch, která zde mají nastaveno 'match')
                     index: true,        // indexování je nyní vypnuto ve "firestore.indexes.json", položka ale zůstává na top-levelu
                     acc: ACCESS_PUBLIC,
                     acc_fix: true},

 days_active:       {internal: true,     // počet dní, po které byl uživatel aktivní
                     type: "int",        // (tj. těch, která zde mají nastaveno 'match')
                     acc: ACCESS_PUBLIC,
                     acc_fix: true},


 // přístupová práva k jednotlivý položkám:
 // Objekt se členy pojmenovanými podle položek profilu, každá má hodnotu ACCESS_... 
 // (default je ACCESS_PRIVATE, v databázi jsou hodnoty ACCESS_PRIVATE vynechány)
 access:            {type: "map",         
                     internal: true},

 conn:              {type: "map",         // seznam propojených uživatelů; prvky jsou indexovány user-id
                     internal: true,      // Tento záznam se označuje jako typ User_connection
                     acc: ACCESS_PRIVATE, // V aplikace má každý záznam tyto členy (v závorce je zkrácený název užitý v databázi):
                     acc_fix: true},      // - rel (r)           .. typ vztahu, konstanty CONN_REL_...
                                          //                        V databázi obsahuje i příznaky CONN_REL_FAVORITE a CONN_REL_OPEN_DATE,
                                          //                        v aplikace jen CONN_REL_BASE, ostatní příznaky jsou separovány do členů
                                          //                        'favorite' a 'open_data'
                                          // - favorite          .. true, pokud uživatel označil daný kontakt za oblíbený
                                          //                        V databázi je kódován jako CONN_REL_FAVORITE členu 'r'
                                          // - open_date         .. true, pokud uživatel výsledkem vyhledání open-date
                                          //                        V databázi je kódován jako CONN_REL_OPEN_DATE členu 'r'
                                          //                        (příznak mají obě strany i ta co vyhledání iniciovala, i vyhledaná)
                                          // - open_chat         .. true, pokud uživatel výsledkem vyhledání open-chat
                                          //                        V databázi je kódován jako CONN_REL_OPEN_CHAT členu 'r'
                                          //                        (příznak mají obě strany i ta co vyhledání iniciovala, i vyhledaná)
                                          // - create_time (c)   .. čas vytvoření propojení - tj přechod ze stavu CONN_REL_NONE
                                          //                        do jiného (počítá se i nastavení bitů relation, favorite a 
                                          //                        odeslání zprávy)
                                          // - engage_time (e)   .. pro relation = CONN_REL_NONE, když si ještě nepsali, 
                                          //                        čas posledního zobrazení profilu (vylučuje se s created a lmm/lom)
                                          // - msg_time (m)      .. čas posledního odeslání nebo přijetí zprávy
                                          // - last_read (lr)    .. timestamp poslední přečtené zprávy od daného uživatele
                                          // - remove_time (x)   .. čas odstranění nebo blokování uživatele
                                          //                        (pak nemá engaged, last_msg, lim a lom)
                                          // - nick_name (nk)    .. poslední známý nick uživatele
                                          // - full_name (fn)    .. poslední známé jméno uživatele (jméno a příjmení)
                                          // - sex (sx)          .. pohlaví uživatele
                                          // - lom               .. "last outgoing message" - text poslední odeslané zprávy
                                          //                        omezený na 64 znaků 
                                          //                        (pokud je oříznutá, má doplněnn zna ellipsis na konci
                                          // - lim               .. "last incomming message" - text poslední přijaté zprávy
                                          //                        omezený na 64 znaků
                                          //                        (lom a lim nejsou nikdy přítomné obě)
                                          // - ic                .. impression chat (poslední dojem z daného uživatele)
                                          // - ip                .. impression personal
                                          //                        obojí řetězec 9 znaků; jejich kód určuje dojem na 
                                          //                        jednotlivých dimenzích:
                                          //                        0 = bez odpovědi
                                          //                        (c - 51) = hodnocení -50..50
                                          // - profile_ts (pts)  .. timestamp profilu, ke kterému byla data převzata
                                          // - update_ts (uts)   .. timestamp, kdy byla data převzata z profilu uživatele
                                          //
                                          // Časové údaje jsou v aplikaci typu int v ms (jako vrací Date.now()), 
                                          // v databázi jako firestore.Timestamp
                                          // Konverzi mezi formátem aplikace a databáze zajišťuje funkce conn_db_conv() v own_profile.js

 cache:             {type: "map",         // Cachované položky uložené zde na jednom místě, aby se načetly najendou při přihlášení
                     internal: true},     // Položky:
                                          // - mrt_limit .. message read time limit timestamp okamžiku, po který byly naposledy sledovány
                                          //                příchozí zprávy
                                          // - unread_msgs  .. seznam nepřečtených zpráv od jednotlivých uživatelů
                                          //                   indexováno uid odesílatele, pro každého pole 
                                          //                   max PCACHE_UNREAD_MSGS_LIMIT zpráv (konstanta definována v messages.js)
                                          //                   formát zprávy je stejný jako v objektu 'messages'
                                          //                   Poslední zpráva na konci.
};


module.exports.profile_def            = profile_def;
module.exports.ACCESS_PRIVATE         = ACCESS_PRIVATE;
module.exports.ACCESS_FRIEND          = ACCESS_FRIEND;
module.exports.ACCESS_ACQ             = ACCESS_ACQ;
module.exports.ACCESS_PUBLIC          = ACCESS_PUBLIC;
module.exports.CONN_REL_BASE          = CONN_REL_BASE;   
module.exports.CONN_REL_NONE          = CONN_REL_NONE;   
module.exports.CONN_REL_ACQ           = CONN_REL_ACQ;    
module.exports.CONN_REL_FRIEND        = CONN_REL_FRIEND; 
module.exports.CONN_REL_REMOVED       = CONN_REL_REMOVED;
module.exports.CONN_REL_BLOCKED       = CONN_REL_BLOCKED;
module.exports.CONN_REL_BLOCKING      = CONN_REL_BLOCKING;
module.exports.CONN_REL_FAVORITE      = CONN_REL_FAVORITE;
module.exports.CONN_REL_OPEN_DATE     = CONN_REL_OPEN_DATE;
module.exports.CONN_REL_OPEN_CHAT     = CONN_REL_OPEN_CHAT
module.exports.LANGUAGES_MAX          = LANGUAGES_MAX;
module.exports.MATCH_LIMIT_ODATE      = MATCH_LIMIT_ODATE;
module.exports.MATCH_LIMIT_OCHAT      = MATCH_LIMIT_OCHAT;
