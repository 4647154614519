/*#################################################################################################

  Editor nastavení.

#################################################################################################*/

import React from 'react';
import {db_set_data} from '../db/app_state';
import {ms_to_norm_time} from '../user';
import {ProfileItem} from './ProfileItem';
import {message_box} from '../controls/MessageBox';
import {useTranslation} from '../translations/useTranslation';
import './Settings.css';


/*-------------------------------------------------------------------------------------------------
Iniciace deaktivace profilu.
-------------------------------------------------------------------------------------------------*/
function deactivate_confirm(t)
{
 message_box({style: 'query',
              msg: t('prof_deact.confirm'),
              btn: [{title: t('btn_yes'), 
                     fn() 
                       {
                        db_set_data("profile.user_state", 'set', -1);
                       }},
                    {title: t('btn_no')}]});
}

/*-------------------------------------------------------------------------------------------------
Iniciace zrušení profilu.
-------------------------------------------------------------------------------------------------*/
function delete_confirm(t)
{
 message_box({style: 'query',
              msg: t('prof_delete.confirm'),
              btn: [{title: t('btn_yes'), 
                     fn() 
                       {
                        db_set_data('profile', 
                                    'merge', 
                                    {user_state: -2,
                                     pending_delete: ms_to_norm_time(Date.now() + 14*24*3600000)});
                       }},
                    {title: t('btn_no')}]});
}

/*-------------------------------------------------------------------------------------------------
props  profile  .. objekt profilu
       back_btn .. pokud se má v titulku zobrazovat šipka "zpět", zde je její definice.
                   jinak undefined. (Pro úzké zobrazení.)
       media    .. vlastnosti okna browseru; objekt s členy:
                   narrow .. okno je užší než 900px   
-------------------------------------------------------------------------------------------------*/
export function Settings(props) 
{
 //const profile = props.profile;
 //const media = props.media;
 //const narrow = media.narrow;

 const t = useTranslation();
 
 return <div id = "layout-content">
          <div className = "page-content unindented profile-editor">
            <h1>{props.back_btn}{t('prf_settings')}</h1>
              <ProfileItem label     = {t('prof_deact')}
                           control   = {<button onClick = {() => deactivate_confirm(t)}>
                                         {t('prof_deact.btn')}
                                        </button>}
                           sub       = {t('prof_deact.sub')}/>
              <ProfileItem label     = {t('prof_delete')}
                           control   = {<button onClick = {() => delete_confirm(t)}>
                                         {t('prof_delete.btn')}
                                        </button>}
                           sub       = {t('prof_delete.sub')}/>
          </div>
        </div>;
}
