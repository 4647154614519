
/*-------------------------------------------------------------------------------------------------
Funkce otevře URL pomocí funkce fetch. Zároveň zkotroluje chybové kódy HTTP a hodí při nich 
výjimku (instance standardní třídy Error). Funkce má stejné parametry jako fetch.
-------------------------------------------------------------------------------------------------*/
export function open_url(url, init)
{
 return fetch(url, init)
        .then(resp =>
             {
              if(!resp.ok) throw Error(resp.statusText);
              return resp;
             });
}