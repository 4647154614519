/*#################################################################################################

  Menu profilu. Použito v položkách stromečku uživatelů a ve zobrazeném profilu.

#################################################################################################*/

import React from 'react';
import {useHistory,
        useLocation} from 'react-router-dom';
import {useTranslation} from '../translations/useTranslation';
import {db_update_conn} from '../db/own_profile'
import {nav_view_user} from '../navigate';
import {DropdownMenu} from '../lib/DropdownMenu';
import {open_impression_editor} from '../controls/ImpressionEditor';
import impression_icon from '../graphics/impression.svg';
import favorite_user from '../graphics/favorite-user.svg';
import acc_acq_icon from '../graphics/acc-acq.svg';
import acc_friend_icon from '../graphics/acc-friend.svg';
import odate_icon from '../graphics/odate-icon.svg';
import chat_icon from '../graphics/chat-icon.svg';
import profile_icon from '../graphics/profile-icon.svg';
import delete_icon from '../graphics/delete-icon.svg';
import block_user_icon from '../graphics/block-user.svg';
const {CONN_REL_NONE,
       CONN_REL_FRIEND,
       CONN_REL_ACQ,
       CONN_REL_REMOVED,
       CONN_REL_BLOCKED} = require('../profile_def.js');

/*-------------------------------------------------------------------------------------------------
props  conn        .. položka propojení daného uživatele (z profile.conn  přihlášeného uživatele)
       profile     .. profil druhého uživatele
       page_id     .. identifikátor stránky, kde je uzel zobrazen ('' = home, 'chat', 'odate')
       profile_hdr .. true, pokud je v ProfileHeaderu
       height      .. výška klikací položky menu
       impress     .. true, pokud má být zobrazena položka dojmu uživatele.
       hidden      .. menu má být skryto
       align_right .. menu má být zarovnáno na pravý okraj
-------------------------------------------------------------------------------------------------*/
export function ProfileMenu(props)
{
 const conn = props.conn || {};
 const rel = conn.rel || CONN_REL_NONE;
 const page_id = props.page_id;
 const profile = props.profile;
 const uid = profile.uid;
 const nick_name = profile.nick_name || "";

 const url_history = useHistory();
 const location = useLocation();
 const t = useTranslation();


 //----------------
 // Nastaví uživateli stav "remove" nebo "blocked"
 const remove_user = new_rel => 
    {
     db_update_conn(profile, false, {rel: new_rel});

     const url_params = new URLSearchParams(location.search);
     if(url_params.get("view_user") === uid)
       { // zrušit zobrazení profilu odstraňovaného uživatele
        url_params.delete("view_user");

        let params = url_params.toString();
        let url = location.pathname;
        if(params) url += '?' + params;
       
        url_history.push(url);
       }
    }

 let content = [];

 if(props.impress && !conn.no_connection)
    content.push({t:  t('impress_title', nick_name), 
                  i:  impression_icon,
                  fn: () => open_impression_editor(url_history, location, 
                                                   uid, (page_id!=='odate')|0)});

/* if(!page_id || props.profile_hdr)
    content.push({t:  t('pmenu_send_msg'), 
                  i:  message_grey_icon,
                  fn: () => nav_user_chat(url_history, uid)});
 else*/ // ..původně kliknutí na user-entry na hlavní stránce zobrazilo profil a z menu se vybralo odeslání zprávy; nyní user-entry na všech stránkách chová stejně
    content.push({t:  t('pmenu_vprof'), 
                  i:  profile_icon,
                  fn: () => nav_view_user(url_history, location.pathname, profile)});

 if(!conn.no_connection)
   {
    if(conn.favorite)
       content.push({t:  t('pmenu_fav_rm'),
                     i:  favorite_user, 
                     fn: () => db_update_conn(profile, false, {favorite: null})});
    else
       content.push({t:  t('pmenu_fav_add'), 
                     i:  favorite_user, 
                     fn: () => db_update_conn(profile, false, {favorite: true})});
    
    if(rel === CONN_REL_FRIEND)
       content.push({t:  t('pmenu_friend_rm'), 
                     i:  acc_friend_icon,
                     fn: () => db_update_conn(profile, false, {rel: CONN_REL_NONE})});
    else
       content.push({t:  t('pmenu_friend_add'), 
                     i:  acc_friend_icon,
                     fn: () => db_update_conn(profile, false, {rel: CONN_REL_FRIEND})});
    
    if(rel === CONN_REL_ACQ)
       content.push({t:  t('pmenu_acq_rm'), 
                     i:  acc_acq_icon,
                     fn: () => db_update_conn(profile, false, {rel: CONN_REL_NONE})});
    else
       content.push({t:  t('pmenu_acq_add'), 
                     i:  acc_acq_icon,
                     fn: () => db_update_conn(profile, false, {rel: CONN_REL_ACQ})});
    
    if(page_id === 'odate')
       content.push({t:  t('pmenu_odate_rm'), 
                     i:  chat_icon,
                     fn: () => db_update_conn(profile, false, {open_date: false})});
    else
       content.push({t:  t('pmenu_odate_add'), 
                     i:  odate_icon,
                     fn: () => db_update_conn(profile, false, {open_date: true})});
   }
   

 content.push({t:  t(conn.no_connection ? 'pmenu_new_rm' : 'pmenu_conn_rm'), 
               i:  delete_icon,
               fn: () => remove_user(CONN_REL_REMOVED)},

              {t:  t('pmenu_block'), 
               i:  block_user_icon,
               fn: () => remove_user(CONN_REL_BLOCKED)});
 
return <DropdownMenu 
             height = {props.height}
             hidden = {props.hidden}
             align_right = {props.align_right}
             content = {content}/>
}