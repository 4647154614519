/*#################################################################################################

Rámeček pro vyhledávání

#################################################################################################*/

import React from 'react';
import search_icon from '../graphics/search-icon.svg';
import delete_icon from '../graphics/delete-icon.svg';
import './SearchBox.css';

/*-------------------------------------------------------------------------------------------------
Rámeček pro vyhledávání

props  placeholder .. titulek zobrazený v line editoru
       tool_tip    .. text zobrazený v tool tipu
       search      .. funkce vyvolaná při odeslání hledání
                      předá se jí buď vyhledávací řetězec, nebo null,
                      pokud bylo vyhledávání ukončeno
-------------------------------------------------------------------------------------------------*/
export function SearchBox(props) 
{
 const init_state = 
   {
    open: false, // je zobrazen line-editor
    str: "", // obsah editoru
   }

 const [state, set_state] = React.useState(init_state);

 //----------
 const on_click_search = event => 
    {
     if(state.open)
       {
        if(state.str)
           props.search(state.str);
        else
           set_state({...state, open: false});
       }
     else
       {
        set_state({...state, open: true});
       }
    }

 //----------
 // Kliknutí na zavírací ikonu
 const on_click_delete = event => 
    {
     set_state({...state, str: "", open: false});
     props.search(null);
    }

 //----------------------
 // napsání znaku v line-editoru
 const on_input_change = event => 
    {
     set_state({...state,
                str: event.target.value});
    };
 
 //----------------------
 // stisknutí Enteru v line-editoru
 const key_pressed = e =>
    {
     if(e.key==="Enter" || e.keyCode === 13)
       {
        if(state.str)
           props.search(state.str);
        else
           set_state({...state, open: false});
       }
     else if(e.key==="Escape" || e.keyCode === 27)
       {
        set_state({...state,
                   open: false,
                   str: ""});
       }
    }


 return <div className = {"search-box" + (state.open ? " open" : "")}
             title = {props.tool_tip}>
         {state.open 
           ? <div className = "input-box">
               <img className="si"
                    src = {search_icon} 
                    onClick = {on_click_search} 
                    alt = {props.tool_tip} />
               <input type = "text"
                      autoFocus 
                      id = "search-field" 
                      name = "search-field" 
                      placeholder = {props.placeholder} 
                      value = {state.str} 
                      onKeyDown = {key_pressed}
                      onChange = {on_input_change}/>
               <img className="di"
                    src = {delete_icon} 
                    onClick = {on_click_delete} 
                    alt = {props.tool_tip} />
             </div> 
           : <img src = {search_icon} 
                  onClick = {on_click_search} 
                  height = "16" 
                  width = "16" 
                  alt = {props.tool_tip} />}
        </div>;
}
