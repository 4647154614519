/*#################################################################################################

Pomocní funkce pro přechod mezi stránkami aplikace

#################################################################################################*/

import {db_update_conn} from './db/own_profile';
import {db_get_data} from './db/app_state';


/*-------------------------------------------------------------------------------------------------
Přepne aplikaci na zobrazení profilu uživatele (jiného než přihlášeného).
Přepnutí se realizuje přidáním parametru 'view_user' do URL.
Zároveň aktualizuje informace o propojení s uživatelem.

Parametry:
 url_history  .. objekt získaný voláním useHistory()
 url_pathname .. cesta aktuální stránky.
 profile      .. profil uživatele, který se má zobrazit
 value        .. (volitelně) hodnoty, které se mají nastavit do objektu spojení (profile.conn)
                 pro daného uživatele. (Použito především pro nastavení 'open_date' ve spojení)
-------------------------------------------------------------------------------------------------*/
export function nav_view_user(url_history, url_pathname, profile, value=null)
{
 db_update_conn(profile, true, value); 
 url_history.push(url_pathname + "?view_user=" + profile.uid);
}

/*-------------------------------------------------------------------------------------------------
Přepne aplikaci na chat s uživatelem (buď na stránku 'chat' nebo 'odate' podle typu spojení 
s uživatelem).
Zároveň označí za přečtené případné nepřečtené zprávy od daného uživatele.
Parametr url_hisotry je získán pomocí useHistory().
-------------------------------------------------------------------------------------------------*/
export function nav_user_chat(url_history, uid)
{
 //mark_messages_read(uid); .. zprávy se označí za přečtené až po kliknutí do panelu zpráv
 const conn = db_get_data(['profile', 'conn', uid]);
 const pg = conn && conn.open_date ? "/odate/" : "/chat/";
 url_history.push(pg + uid);
}