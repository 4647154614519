/*#################################################################################################

Záhlaví stránky.

#################################################################################################*/

import React from 'react';
import {Link, 
        NavLink} from 'react-router-dom'
import {master} from './db/master';
import {get_xp} from './user';
import {useTranslation} from './translations/useTranslation';
import glagoli_logo from './graphics/Glagoli-logo.svg';
import home_icon from    './graphics/home-icon.svg';
import odate_icon from   './graphics/odate-icon.svg';
import chat_icon from    './graphics/chat-icon.svg';
import profile_icon from './graphics/profile-icon.svg';
import logout_icon from  './graphics/logout-icon.svg';
import {UserIcon} from './controls/UserIcon';
import './Header.css';
import {NodeBubble} from './lib/NodeBubble';

/*-------------------------------------------------------------------------------------------------
Záhlaví stránky.

props  profile         .. profil uživatele (volitelné)
       profile_summary .. (volitelné) výsledek volání get_profile_summary()
       conn_groups     .. skupiny propojených uživatelů                     
       page_ref        .. url-path rozdělený po položkách do pole.
       media           .. vlastnosti okna browseru; objekt s členy:
                          narrow .. okno je užší než 900px   
-------------------------------------------------------------------------------------------------*/ 
export function Header(props) 
{
 const t = useTranslation();

 const profile = props.profile;
 const page_ref = props.page_ref;
 const page_id = page_ref[0] || "";
 const conn_groups = props.conn_groups || {};
 const ps = props.profile_summary;
 const pm = ps ? ps.main_missing + ps.q_missing : 0;
 const narrow = props.media && props.media.narrow;

 let display_real_xp = page_id !== 'chat';
 let display_net_xp  = page_id !== 'odate';

 const real_xp = display_real_xp && Math.round(get_xp(profile, 'real_xp', true));
 const net_xp  = display_net_xp  && Math.round(get_xp(profile, 'net_xp', true));

 let xp_view = <div className={"xp" + (!display_real_xp||!display_net_xp ? ' single' : '')}>
                {display_real_xp &&
                 <div className="disp-row" title={t('xp_desc_real')+"\n"+t('xp_desc_common')}>
                   <div className="xp-lbl">Real XP:</div>
                   <div className="xp-bar" 
                        style={{boxShadow: `inset ${real_xp/10}px 0 0 0 #ff8400`}}>
                   </div>
                 </div>}
                {display_net_xp &&
                 <div className="disp-row" title={t('xp_desc_net')+"\n"+t('xp_desc_common')}>
                   <div className="xp-lbl">Net XP:</div>
                   <div className="xp-bar" 
                        style={{boxShadow: `inset ${net_xp/10}px 0 0 0 #00C3FF`}}>
                   </div>
                 </div>}
               </div>;

 let user_info;
 if(profile)
   {
    user_info = <>
                  <UserIcon title = {profile.nick_name}
                            uid   = {profile.uid}/>
                 <div id="user-info">
                   <div className="name">{profile.nick_name}</div>
                   {xp_view}
                 </div>
                </>;
   }

 return <>
          <div id="header">
            {narrow &&
             <div className = 'top'>
              <Link to = "/">
                <img id  = "logo" 
                     src = {glagoli_logo} alt="Glagoli.net"/>
              </Link>
                <div className = "align-right">
                  {user_info}
                </div>
             </div>}

            <nav>

              {!narrow &&
               <Link to = "/">
                 <img id  = "logo" 
                      src = {glagoli_logo} alt="Glagoli.net"/>
               </Link>}

              {profile && <>
                <NavLink to        = "/" 
                         exact     
                         title     = {t('hdr_home')} 
                         className = {"button " + (!narrow ? "first" : "no-margin")}>
                  <img src = {home_icon} 
                       alt = {t('hdr_home')}/>
                </NavLink>
                
                <NavLink to        = "/odate" 
                         title     = {t('hdr_odate')} 
                         className = "button">
                  <img src = {odate_icon} 
                       alt = {t('hdr_odate')}/>
                
                  {ps && !!ps.n_unread_msgs_odate && 
                   <span className="nf-val">{ps.n_unread_msgs_odate}</span>}
                  {(page_id !== 'odate' || page_ref.length > 1) && // nejsme přímo na hlavní stránce open-date
                   conn_groups.new_ochat && conn_groups.new_odate.length > 0 && 
                   <NodeBubble content = {t('msg_new_odate')} right/>}
                </NavLink>
                
                <NavLink to        = "/chat" 
                         title     = {t('hdr_chat')} 
                         className = "button">
                  <img src = {chat_icon} 
                       alt = {t('hdr_chat')}/>
                
                  {ps && !!ps.n_unread_msgs_chat && 
                   <span className="nf-val">{ps.n_unread_msgs_chat}</span>}
                  {(page_id !== 'chat' || page_ref.length > 1) && // nejsme přímo na hlavní stránce chatu
                   conn_groups.new_ochat && conn_groups.new_ochat.length > 0 && 
                   <NodeBubble content = {t('msg_new_ochat')}/>}
                </NavLink>
                
                <NavLink to        = "/profile" 
                         title     = {t('hdr_profile')} 
                         className = "button">
                  <img src = {profile_icon} 
                       alt = {t('hdr_profile')}/>
                  {!!pm && <span className="nf-val">{pm}</span>}
                </NavLink>
                
                <div className = "align-right">
                  {!narrow && user_info}
                  <img id      = "logout" 
                       src     = {logout_icon} 
                       onClick = {() => master.db_logout()} 
                       height  = "16" 
                       width   = "14" 
                       title   = {t('hdr_logout')} 
                       alt     = {t('hdr_logout')}/>
                </div>
              </>}
            </nav>
          </div>
        </>;
}
