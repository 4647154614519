import React from 'react';
import {ProfileQItem} from './ProfileQItem';
import {BottomNav} from './BottomNav';

/*------------------------------------------------------------------------------------------------- 
Vrací editor dotazníku "temperament".
------------------------------------------------------------------------------------------------- */ 
export function profile_edit_qr(props, tr_def)
{
 const profile = props.profile;

 const t = tr_def.translate;

 const att_group = ['qr1', 'qr2', 'qr3', 'qr4']; // skupina attachmentových položek

 return <>
      <h1>{props.back_btn}{t('qr.lbl')}</h1>

      <div className = "desc">
       <p>{t('qr.desc')}</p>
       <p>{t('qr.desc2')}</p>
      </div>

      <ProfileQItem data_id = "qr1"
                    text_main = {"(1/4) " + t('qr1.main')}
                               // "It is relatively easy for me to become emotionally close to others. I am comfortable depending on others and having others depend on me. I don't worry about being alone or having others not accept me."
                    text_min = {t('q_dagr')}
                    text_max = {t('q_agr')}
                    group = {att_group}
                    acc_icon
                    profile = {profile}/>

      <ProfileQItem data_id = "qr2"
                    text_main = {"(2/4) " + t('qr2.main')}
                                // "I am comfortable without close emotional relationships. It is very important to me to feel independent and self-sufficient, and I prefer not to depend on others or have others depend on me."
                    text_min = {t('q_dagr')}
                    text_max = {t('q_agr')}
                    group = {att_group}
                    profile = {profile}/>

      <ProfileQItem data_id = "qr3"
                    text_main = {"(3/4) " + t('qr3.main')}
                                // "I want to be completely emotionally intimate with others, but I often find that others are reluctant to get as close as I would like. I am uncomfortable being without close relationships, but I sometimes worry that others don't value me as much as I value them."
                    text_min = {t('q_dagr')}
                    text_max = {t('q_agr')}
                    group = {att_group}
                    profile = {profile}/>

      <ProfileQItem data_id = "qr4"
                    text_main = {"(4/4) " + t('qr4.main')}
                                // "I am somewhat uncomfortable getting close to others. I want emotionally close relationships, but I find it difficult to trust others completely, or to depend on them. I sometimes worry that I will be hurt if I allow myself to become too close to others."
                    text_min = {t('q_dagr')}
                    text_max = {t('q_agr')}
                    group = {att_group}
                    profile = {profile}/>

      <div className = "desc">
       <p>{t('qr.desc3')}</p>
      </div>

      <ProfileQItem data_id = "qr5"
                    text_main = {t('qr5.main')}
                    text_min = {t('q_dagr')}
                    text_max = {t('q_agr')}
                    acc_icon
                    profile = {profile}/>

      <ProfileQItem data_id = "qr6"
                    text_main = {t('qr6.main')}
                    text_min = {t('q_dagr')}
                    text_max = {t('q_agr')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qr7"
                    text_main = {t('qr7.main')}
                    text_min = {t('q_dagr')}
                    text_max = {t('q_agr')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qr8"
                    text_main = {t('qr8.main')}
                    text_min = {t('q_dagr')}
                    text_max = {t('q_agr')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qr9"
                    text_main = {t('qr9.main')}
                    text_min = {t('q_dagr')}
                    text_max = {t('q_agr')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qr10"
                    text_main = {t('qr10.main')}
                    text_min = {t('q_dagr')}
                    text_max = {t('q_agr')}
                    profile = {profile}/>

      <BottomNav left  = {{tid: 'qt.lbl', url: "/profile/qt"}}
                 right = {{tid: 'qm.lbl', url: "/profile/qm"}}/>
    </>;
}

