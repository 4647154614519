import React from 'react';
import {Link}   from 'react-router-dom'


/*------------------------------------------------------------------------------------------------- 
Vrací rozcestních osobnostního dotazníku.
Parametr ps je objekt profile_summary (používá se jen pro úzké zobrazení)
------------------------------------------------------------------------------------------------- */ 
export function profile_edit_q(tr_def, ps)
{
 const t = tr_def.translate;

 return <>
          <h1>{t('prf_q')}</h1>
          <div className = "desc">
            <p>{t('q.desc1')}</p>
            <p>{t('q.desc2')}</p>
          </div>
        
          <div className="pr-item">
           <div>
            <Link className="big" to="/profile/qt">{t('qt.lbl')}</Link>
            {ps && ps.qt_missing ? <span className="nf-val">{ps.qt_missing}</span>: null}
           </div>
           <div className="sub">{t('qt.desc')}</div>
          </div>
        
          <div className="pr-item">
           <div>
            <Link className="big" to="/profile/qr">{t('qr.lbl')}</Link>
            {ps && ps.qr_missing ? <span className="nf-val">{ps.qr_missing}</span>: null}
           </div>
           <div className="sub">{t('qr.desc')}</div>
          </div>
        
          <div className="pr-item">
           <div>
            <Link className="big" to="/profile/qm">{t('qm.lbl')}</Link>
            {ps && ps.qm_missing ? <span className="nf-val">{ps.qm_missing}</span>: null}
           </div>
           <div className="sub">{t('qm.desc')}</div>
          </div>
        
          <div className="pr-item">
           <div>
            <Link className="big" to="/profile/qe">{t('qe.lbl')}</Link>
            {ps && ps.qe_missing ? <span className="nf-val">{ps.qe_missing}</span>: null}
           </div>
           <div className="sub">{t('qe.desc')}</div>
          </div>
        
          <div className="pr-item">
           <div>
            <Link className="big" to="/profile/qv">{t('qv.lbl')}</Link>
            {ps && ps.qv_missing ? <span className="nf-val">{ps.qv_missing}</span>: null}
           </div>
           <div className="sub">{t('qv.desc')}</div>
          </div>
        </>;
}

