/*#################################################################################################

Definuje hook na přístup k aktuální verzi překladu.

#################################################################################################*/

import React from 'react';
import {TranslationContext} from './translation';

/*-------------------------------------------------------------------------------------------------
Vrátí funkci pro vyhle 
-------------------------------------------------------------------------------------------------*/
export function useTranslation() 
{
 return React.useContext(TranslationContext).translate;
}
