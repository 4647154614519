import React from 'react';
import {PopupWindow} from './PopupWindow';

/*-------------------------------------------------------------------------------------------------
props   content      .. obsah menu; pole objektů s těmito členy:
                           t: titulek
                           i: ikona (název souboru nebo "" pro prázdné místo)
                           sub: subtitle (menší podtitulek)
                           fn: funkce vyvolaná při kliknutí na položku
                           node: místo jednotlivých údajů výše uzel representující položku menu
         height      .. výška menu
         width       .. šířka menu (když není zadána, určí se jako height/4)
         align_right .. pokud má být okno menu zarovnáno na pravý okraj
         hidden      .. menu je skryté, ale generuje prázdný obdélník příslušné velikosti
--------------------------------------------------------------------------------------------------*/
export function DropdownMenu(props) 
{
 const init_state = 
    {
     is_open: false // true, pokud je okno menu otevřené
    };

 const [state, set_state] = React.useState(init_state);

 // ----------------
 // Kliknutí na selection
 const on_click_menu = event => 
    {
     if(state.is_open)
        set_state({...state, is_open: false});
     else
        set_state({...state, is_open: true, wnd_anchor: event.target.getBoundingClientRect()});

     event.stopPropagation();
    }

 // ----------------
 // Kliknutí při otevřeném okně na položku okna nebo mimo okno
 const on_click_window = (node, event) => 
    {
     if(node !== null)
       {
        const e = props.content[node.attributes.value.value];
        if(e.fn) 
           e.fn();
        event.stopPropagation();
       }

     if(state.is_open)
        set_state({...state, is_open: false, wnd_anchor: undefined});
    }

 return <> 
         <div className = "dropdown-menu"
              onClick   = {props.hidden ? null : on_click_menu}>
            <div className = {props.hidden && !state.is_open ? "" : "dm-icon"}
                  style     = {{height: props.height, 
                                width:  props.width || ((props.height/4)|0)}}>
            </div>
         </div>
         {state.is_open && 
          <PopupWindow content      = {props.content.map((e, idx) => 
                                          {
                                           let style = null;
                                           if(typeof e.i === "string")
                                             {
                                              style = {paddingLeft: "40px"};
                                              if(e.i !== "")
                                                {
                                                 style.backgroundImage = "url('"+e.i+"')";
                                                 style.backgroundPosition = "12px center";
                                                 style.backgroundRepeat = "no-repeat";
                                                 style.backgroundSize = "16px 16px";
                                                }
                                             }

                                           return <div className = "wnd-item"
                                                       key = {idx}
                                                       value = {idx}
                                                       style = {style}>
                                                    {e.t}
                                                    {e.sub && <div className="sub">{e.sub}</div>}
                                                    {e.node}
                                                  </div>;
                                          })}
                       anchor       = {state.wnd_anchor}
                       wnd_x_offset = {-10}
                       align_right  = {props.align_right}
                       on_click     = {on_click_window}/>}
        </>;
}
