/*#################################################################################################

Obsahuje funkce pro správu překladů.

Definice překladů:
  Překlady jsou uloženy v souborech ve formátu JSON. Soubory jsou pojmenovány kódem jazyka.
  Překlad obsahuje definice zpráv pojmenované identifikátory.
  Definice zprávy může být řetězec (šablony zprávy) nebo objekt obsahující alternativy zprávy.
  
  Případy objektů definujících zprávy:
  - gramatický rod uživatele programu (gender):
    {"f": <definice zprávy pro ženský rod>
     "m": <definice zprávy pro mužský rod>}
  - gramatický rod osoby, se kterou uživatel komunikuje (peer_gender):
    {"pf": <definice zprávy pro ženský rod>
     "pm": <definice zprávy pro mužský rod>}
  - gramatické číslo:
    {"sg": <definice zprávy pro singulár>
     "pl": <definice zprávy pro plurál>}


  Objekty mohou být do sebe vloženy.
  Koncová šablona zprávy může obsahovat parametry $1, $2..., za které se dosadí hodnoty předané
  překladové funkci

Setup aplikace
  Aplikace v kořeni vytvoří provider ke kontextu překladu definovaného níže v modulu 
  (TranslationContext) a jako aktuální hodnotu mu předá výsledek volání funkce set_language(),
  které se předá kód aktuálního jazyka. Funkce vrací objek definice aktuálního jazyka. Tj:

        <TranslationContext.Provider value = {set_language(ui_lang)}>
          {aplication}
        </TranslationContext.Provider>;

  - objekt definice má tyto členy:
    - lang          .. kód jazyka, jehož se definice týká
    - dict          .. slovník zpráv
    - country_names .. seznam lokalizovaných názvů států
    - lang_names    .. seznam lokalizovaných názvů jazyků 
    - translate     .. překladová funkce (viz níže)


Užití překladu
  Komponenta, která chce používat překlad, použije hook useTranslation(), který vrací překladovou
  funkci

  Příklad:
         import {useTranslation} from '/translations/useTranslation'
         
         function COMPONENT()
         {
          const t = useTranslation();

          ...

          msg = t('msg-id', {gender: 0}, value1)
         }

   Kromě hooku useTranslation lze užít useTranslationDef, který vrací celý definiční objekt

  Parametry překladové funkce:
  - msg_id  .. identifikátor zprávy v překladu
  - options .. (nepovinný) volby vybírající variantu překladu. Jde o objekt s těmito členy:
               gender      .. gramatický rod uživatele programu (0 = mužský, 1 = ženský)
                              Odpovídá definici zprávy "m"/"f".
               peer_gender .. gramatický rod osoby, s níž uživatel komunikuje (0 = mužský, 1 = ženský)
                              Odpovídá definici zprávy "pm"/"pf".
               number:     .. false = singulár, true = plurál
  - další parametry .. musejí být typu 'number' nebo 'string'; doplní se do zprávy za $1, $2...
               Pokud není zadán objekt options, parametry následují hned za msg_id.
  
#################################################################################################*/

import React from 'react';
import {html} from '../utils';
import dict_cs from './cs.json';
import cc_cs from './lists/cc_cs';
import lang_cs from './lists/lang_cs';

let def = {'cs': {lang: 'cs',
                  dict: dict_cs,        // překlady frází
                  country_names: cc_cs, // názvy států
                  lang_names: lang_cs}  // názvy jazyků
          };

let curr_lang;
let curr_def;
export const TranslationContext = React.createContext({});

/*-------------------------------------------------------------------------------------------------
Přepne jazyk.
Vrací překladovou funkci
-------------------------------------------------------------------------------------------------*/
export function set_language(lang, dflt_opt) 
{
 // FIXME pořádně implementovat
 lang = 'cs'; // zatím jen čeština

 if(def[lang])
    curr_lang = lang; 
 else
    curr_lang = 'en'; 

 curr_def = {...def[lang],
             default_options: dflt_opt || {},
             translate: function() {return translate.apply(null, [def[curr_lang], ...arguments])}}

 return curr_def
}

/*-------------------------------------------------------------------------------------------------
Vrátí text zprávy msg_id ve stávajícím jazyce.

Parametry: 
 tder .. definiční objekt překladu
 opt  .. (volitelný) volby překladu. Pokud je zadán, musí jít o objekt s těmi to příupstnými členy:
          gender      .. gramatický rod uživatele programu (0 = mužský, 1 = ženský)
          peer_gender .. gramatický rod osoby, s níž uživatel komunikuje (0 = mužský, 1 = ženský)
 další parametry 
      .. musejí být typu 'number', 'string' nebo element Reactu; doplní se do zprávy za $1, $2...
         Pokud je parametrem element Reactu, funkce zároveň převede na elementy případné
         HTML tagy obsažení v řetězci.

Pokud je zpráva nenalezena, vrací "".
-------------------------------------------------------------------------------------------------*/
export function translate(tdef, msg_id, opt)
{
 const dict = tdef.dict;

 let o = opt;

 if(typeof o !== 'object' || React.isValidElement(opt))
    o = {};

 const dfo = curr_def.default_options;

 // Na základě parametrů msg_id a opt aléz šablonu zprávy ve slovníku:
 let md = dict[msg_id];

 for(;;)
    {
     if(typeof md === 'string')
        break;

     if(typeof md !== 'object')
       {
        console.error('No translation', msg_id);
        return "";
       }
     
     const k = Object.keys(md)[0];

     if(k === 'sg' || k === 'pl')
       {
        let sel;

        if(o.number !== undefined && o.number !== null)
           sel = o.number;
        else
           sel = dfo.number;

        md = md[sel ? 'pl' : 'sg'];
       }
     else if(k === 'm' || k === 'f')
       {
        const g = o.gender !== undefined ? o.gender : dfo.gender;
        md = md[g ? 'f' : 'm'];
       }
     else if(k === 'pm' || k === 'pf')
       {
        const g = o.peer_gender !== undefined ? o.peer_gender : dfo.peer_gender;
        md = md[g ? 'pf' : 'pm'];
       }
     else
       {
        console.error('Bad translation def', msg_id);
        return "";
       }
    }
 
 // Nahradit $<n> příslušnými parametry (elementy Reactu se doplňují ve zvláštním průchodu):
 let params = {}; // překódovací tabulka parametrů a jejich hodnot
 let pbase = (o === opt) + 1;
 let react_arg = false;
 for(let i = 1; typeof arguments[pbase + i] !== 'undefined'; i++)
    {
     const arg = arguments[pbase + i];
     if(React.isValidElement(arg))
        react_arg = true;
     else
        params['$'+i] = arg+"";
    }
 
 let result = md.replace(/\$[0-9]+/g, p => params[p] !== undefined ? params[p] : p);

 // Pokud je mezi argumenty element Reactu, převést celý řetězec na element 
 // a na příslušná místa doplnit argumenty:
 if(react_arg)
   {
    let ch = [];

    let rx = /([^$]*)\$([0-9]+)/y;
    let lidx = 0;

    for(;;)
       {
        const m = rx.exec(result);

        if(!m) 
          {
           if(lidx !== result.length)
             {
              let str = result.substr(lidx)
              ch.push(str.indexOf('<') >= 0 ? html(str) : str);
             }

           break;
          }

        const arg = arguments[pbase + (+m[2])];

        if(arg && React.isValidElement(arg))
          {
           if(m[1])
              ch.push(m[1].indexOf('<') >= 0 ? html(m[1]) : m[1]);
           ch.push(arg);
          }
        else
          {
           ch.push(m[1]+'$'+m[2]);
          }

        lidx = rx.lastIndex;
       }

    return React.createElement('span', {}, ch);
   }

 return result;
}

/*-------------------------------------------------------------------------------------------------
Překládací funkce pro použití mimo komponenty Reactu.
Pro popis parametrů viz translate() výše.
-------------------------------------------------------------------------------------------------*/
export function t(msg_id, opt)
{
 return translate.apply(null, [curr_def, ...arguments]);
}