/*#################################################################################################

  Stránka zobrazovaná pokud je profil deaktivovaný nebo naplánovaný k odstranění.

#################################################################################################*/

import React from 'react';
import {master} from './db/master';
import {db_set_data} from './db/app_state';
import {norm_time_to_ms} from './user';
import {Header} from './Header';
import {useTranslationDef} from './translations/useTranslationDef';
import './DisabledPage.css';

/*-----------------------------------------------------------------------------------------------*/
function reactivate_profile()
{
 db_set_data('profile', 
             'merge', 
             {user_state: 0,
              pending_delete: null});
}

/*-------------------------------------------------------------------------------------------------
props profile .. profil přihlášeného uživatele
-------------------------------------------------------------------------------------------------*/
export function DisabledPage(props) 
{
 const profile = props.profile;

 const tr_def   = useTranslationDef();
 const t = tr_def.translate;
 const ui_lang = tr_def.lang;

 let title;
 let btn;

 if(profile.user_state === -1)
   {
    title = t('prof_deact.msg');
    btn = t('prof_deact.back');
   }
 else
   {
    const dd = new Date(norm_time_to_ms(profile.pending_delete)).toLocaleDateString(ui_lang);
    title = t('prof_delete.msg', dd);
    btn = t('prof_delete.back');
   }

 return <>
         <Header page_ref = {[]} />
         <div className = "disabled-page">
           <div className = "title">{title}</div>
           <button onClick={reactivate_profile}>{btn}</button>
           <button onClick={() => master.db_logout()}>{t('hdr_logout')}</button>
         </div>
        </>;
}
