/*#################################################################################################

Ikona uživatele

#################################################################################################*/

import React from 'react';
import './UserIcon.css';

/*------------------------------------------------------------------------------------------------- 
props  title  .. titulek, podle kterého se má ikona vytvořit
       uid    .. UID uživatele
       active .. true, pokud se má k ikoně vykreslit zelená tečka
------------------------------------------------------------------------------------------------- */ 
export function UserIcon(props) 
{
 const title = props.title || "";
 const uid = props.uid || "  ";

 let first_letter = title.substr(0, 1);
/* let pic_hue = title.charCodeAt(1);
 const c3 = title.charCodeAt(2);*/
 let pic_hue = uid.charCodeAt(1);
 const c3 = uid.charCodeAt(2);
 if(c3) pic_hue += c3*1223;
 if(pic_hue)
    pic_hue = (pic_hue*2731)%360;
 else
    pic_hue = 11;

 return <div className = "user-icon" style = {{backgroundColor: `hsl(${pic_hue},70%,70%)`}} title={props.title}>
          <div className = "letter">{first_letter}</div>
          {!!props.active && <span className = "active-dot"></span>}
        </div>;
}
