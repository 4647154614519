import React from 'react';
import {useTranslation} from '../translations/useTranslation';
import './Slider.css';

/*-------------------------------------------------------------------------------------------------
Ovládací prvek pravítko s posuvníkem pro zadání spojité hodnoty.

 props data_id   .. (nepovinné) identifikátor data předávaný setteru jako druhý 
                    parametr
       value     .. aktuální hodnota (int)
       min       .. minimální hodnota
       max       .. maximální hodnota
       red_min   .. hodnota, na které končí červený úsek zleva
       red_max   .. hodnota, na které začíná červený úsek zprava
       step      .. krok hodnoty
       width     .. šířka pravítka v pixelech
       text_main .. hlavní text pro celou škálu; pokud je přítomen 
                    text_min a text_max jsou pod škálou
       text_min  .. text pro levou část pravítka
       text_max  .. text pro pravou část pravítka
       hide_na   .. pokud je true, není zobrazena možnot "bez odpovědi"
       setter    .. funkce pro nastavení nové hodnoty
       ncount    .. počet rysek, koncové nepočítaje (default = 5)
-------------------------------------------------------------------------------------------------*/
export function Slider(props) 
{
 const t = useTranslation();
 const KNOB_WIDTH = 10; // odpovídá šířce čudlíku v CSS .slider .knob

 //----------
 // Nastaví novou pozici čudlíku; x = 0..1
 const set_value = x =>
    {
     if(x < 0 || x > 1)
        return;
     
     x = Math.round(x * (props.max-props.min) + props.min)|0;

     props.setter(x, props.data_id);
    }

 //----------
 const on_mouse_down_bar = e => 
    {
     if(e.button === 0)
       {
        let bounds = e.currentTarget.getBoundingClientRect();
        set_value((e.clientX - bounds.left) / bounds.width);
        e.preventDefault();
       }
    }

 //----------
 const on_mouse_move_bar = e => 
    {
     if(e.buttons === 1)
       {
        let bounds = e.currentTarget.getBoundingClientRect();
        set_value((e.clientX - bounds.left) / bounds.width);
       }
    }


 //----------
 const on_mouse_down_na = e => 
    {
     if(e.button === 0)
       {
        props.setter(false, props.data_id);
        e.preventDefault();
       }
    }

 //----------
 const on_mouse_move_na = e => 
    {
     if(e.buttons === 1)
        props.setter(false, props.data_id);
    }

 // normalizovat hodnotu:
 const val = props.value!==undefined ? props.value : null;

 const range = props.max - props.min;

 // Rysky:
 const ncount = (props.ncount || 5) + 1;
 const lred_width = ((props.red_min-props.min)/range*100);
 const rred_width = ((props.max-props.red_max)/range*100);

 let notches = [];
 
 if(props.red_min !== undefined && props.red_min !== null)
   {
    notches.push(<span className="lred" 
                       key="lred"
                       style={{left: 0, width: lred_width+"%"}}>
                 </span>);
   }

 for(let i = 0; i <= ncount; i++)
    {
     const np = i/ncount;
     const nv = Math.round(np*range + props.min);
     let notch = nv === 0 && props.min !== 0 
                   ? <span className="n0" key={np} style={{left: np!==0.5 ? np*100+"%" : null}}></span>
                   : <span className="n" key={np} style={{left: np*100+"%"}}></span>
     notches.push(notch);
    }

 if(props.red_max !== undefined && props.red_max !== null)
   {
    notches.push(<span className="rred" 
                       key="rred"
                       style={{right: 0, width: rred_width+"%"}}>
                 </span>);
   }

 return (<div className="slider">
          <div className="bar-row">
           <div className="bar-group">
            <div className="txt"  style = {null/*@@@{width: props.width}*/}>
             {props.text_main || props.text_bold ?
                <div className="disp-col">
                 {props.text_bold && <div className="main"><b>{props.text_bold}</b></div>}
                 {props.text_main && <div className="main">{props.text_main}</div>}
                </div>
              : <>
                  {props.text_red_min && <div className="red-min"
                                              style={{width: (lred_width*0.6)+"%"}}></div>}
                  <div className="min">{props.text_min}</div>
                  <div className="max">{props.text_max}</div>
                  {props.text_red_max && <div className="red-max"
                                              style={{width: (rred_width*0.6)+"%"}}></div>}
                </>}
            </div>
            <div className={"bar-wrap" + (val !== null && val !== false ? " set" : "")}
                 style = {null/*@@@{width: props.width}*/}
                 onMouseDown={on_mouse_down_bar} 
                 onMouseMove={on_mouse_move_bar}>
             <div className="bar">
              {notches}
              {val !== null && val !== false &&
               <span className="knob" 
                     style = {{left: `calc(${(props.value-props.min)/range*100}% - ${KNOB_WIDTH/2}px)`/*(props.value-props.min)/range * props.width-KNOB_WIDTH/2*/}}></span>}
             </div>
            </div>
           </div>
           <div className="noans-group">
            {!props.hide_na &&
             <>
              <div className="txt">{t('q_no_answer')}</div>
              <div className="btn" 
                   set={val===false ? "1" : "0"}
                   onMouseDown={on_mouse_down_na} 
                   onMouseMove={on_mouse_move_na}>
              </div>
             </>}
           </div>
          </div>
            {(props.text_red_min || props.text_red_max) &&
              <div className="txt below" style = {null/*{width: props.width}*/}>
               {props.text_red_min && <div className="red-min"
                                           style={{width: (lred_width*2)+"%"}}>{props.text_red_min}</div>}
               <div className="mid"></div>
               {props.text_red_max && <div className="red-max"
                                           style={{width: (rred_width*2)+"%"}}>{props.text_red_max}</div>}
              </div>
            }
            {props.text_main &&
               (props.text_zero ?
                 <div className="txt below" style = {null/*{width: props.width}*/}>
                   <div className="pre" style={{width: lred_width+"%"}}>{props.text_min}</div>
                   <div className="disp-row">
                     <div className="min">{props.text_zero}</div>
                     <div className="max">{props.text_max}</div>
                   </div>
                 </div>
               : <div className="txt below" style = {null/*{width: props.width}*/}>
                   <div className="min">{props.text_min}</div>
                   <div className="max">{props.text_max}</div>
                 </div>)}
         </div>
);
}
