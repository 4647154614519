/*#################################################################################################

Editor dojmu z uživatele

#################################################################################################*/

import React from 'react';
import {useHistory,
        useLocation} from 'react-router-dom';
import {XP_CHAT_WEIGHT} from '../user'
import {db_get_data,
        db_set_data} from '../db/app_state';
import {master} from '../db/master';
import {WaitIndicator} from '../lib/WaitIndicator';
import {ImpressionItem} from './ImpressionItem';
import './ImpressionEditor.css';
import {useTranslation} from '../translations/useTranslation'


/*-------------------------------------------------------------------------------------------------
Iniciuje otevření editoru dojmu z uživatele.
Parametry:
  url_history .. objekt získaný voláním useHistory()
  location    .. objekt získaný voláním useLocation();
  uid         .. uid uživatele, který se má hodnotit
  tab         .. počáteční zobrazená karta: 
                  0 .. setkání
                  1 .. chat
-------------------------------------------------------------------------------------------------*/
export function open_impression_editor(url_history, location, uid, tab)
{
 const url_params = new URLSearchParams(location.search);

 url_params.set('impress', uid);
 url_params.set('impress_tab', tab);

 url_history.push(location.pathname + '?' + url_params.toString());

/* const conn = db_get_data(['profile', 'conn', uid]);

 const ic = conn.ic || '\0\0\0\0\0\0\0\0';
 const ip = conn.ip || '\0\0\0\0\0\0\0\0\0';

 impression_editor_set_state({displayed: true,
                              uid: uid,
                              tab: tab,
                              ic: ic,
                              ip: ip,
                              ic_org: ic,
                              ip_org: ip});*/
}

/*-------------------------------------------------------------------------------------------------
Zavře editor dojmu z uživatele.
-------------------------------------------------------------------------------------------------*/
function close_impression_editor(url_history, location)
{
 sessionStorage.removeItem('impress_state');

 const url_params = new URLSearchParams(location.search);

 url_params.delete('impress');
 url_params.delete('impress_tab');

 let params = url_params.toString();
 let url = location.pathname;
 if(params) url += '?' + params;

 url_history.push(url);
}

/*-------------------------------------------------------------------------------------------------
Přepne panel (setkání <-> chat) editoru dojmu z uživatele.
-------------------------------------------------------------------------------------------------*/
function switch_tab(url_history, location, tab)
{
 const url_params = new URLSearchParams(location.search);
 url_params.set('impress_tab', tab);
 url_history.push(location.pathname + '?' + url_params.toString());

 // Odstranit případný error ze stavu:
 try
    {
     let state = JSON.parse(sessionStorage.getItem('impress_state'));
     if(state)
       {
        delete state.$error;
        sessionStorage.setItem('impress_state', JSON.stringify(state));
       }
    }
 catch(e) {}
}

/*-------------------------------------------------------------------------------------------------
Vrátí stav impression-editoru pro daného užvatele. Pokud stav neexistuje nebo je pro jiného
uživatele, vytvoří výchozí.
Pokud vrací null, uživatel neexistuje.

Stav má tyto členy:
  uid .. uid uživatele, kterému stav odpovídá
  ic  .. aktuální dojem z chatu
  ip  .. aktuální dojem z osobního setkání
  ic_org .. výchozí dojem z chatu
  ip_org .. výchozí dojem z osobního setkání
  
  Dojem je kódován jako řetězec 9 znaků (u chatu jen 9 znaků, poslední znak, 
  sociální status, chybí). Jejich kód určuje dojem na jednotlivých dimenzích:
  0 = bez odpovědi
  (c - 51) = hodnocení -50..50 
-------------------------------------------------------------------------------------------------*/
function get_state(conn)
{
 if(!conn) return null;

 let state;

 try
    {
     state = JSON.parse(sessionStorage.getItem('impress_state'));
    }
 catch(e)
    {
     state = null;
    }

 if(!state || state.uid !== conn.uid)
   {
    const ic = conn.ic || '\0\0\0\0\0\0\0\0';
    const ip = conn.ip || '\0\0\0\0\0\0\0\0\0';
    
    state = {uid: conn.uid,
             ic: ic,
             ip: ip,
             ic_org: ic,
             ip_org: ip};    
   }

 return state;
}


/*-------------------------------------------------------------------------------------------------
props url_params .. parametry URL (new URLSearchParams(location.search))
      profile    .. profil přihlášeného uživatele
      media      .. vlastnosti okna browseru; objekt s členy:
                    narrow .. okno je užší než 900px   
-------------------------------------------------------------------------------------------------*/
export function ImpressionEditor(props) 
{
 const url_params = props.url_params;
 const media = props.media;
 const narrow = media.narrow;


 let url_history = useHistory();
 const location = useLocation();
 const uid = url_params.get('impress');
 const conn = db_get_data(['profile', 'conn', uid]);
 const chat = url_params.get('impress_tab') !== '0';
 const [state, _set_state] = React.useState(get_state(conn));

 //--------
 // Změní stav editoru. Zároveň stav ukládá do session-storage, aby byl se zachoval
 // při reloadování stránky nebo přechodu vpřed a zpět v browseru
 const set_state = state =>
    {
     sessionStorage.setItem('impress_state', JSON.stringify(state));
     _set_state(state);
    }

 const t = useTranslation();
 let tab_node = React.useRef(null);

 // Když se změní stránka, odscrollovat nahoru:
 React.useLayoutEffect(() =>tab_node.current.scrollTo(0, 0),
                       [chat]);

 if(!state)
   {
    console.error('ImpressionEditor: unknown user', uid);
    close_impression_editor(url_history, location);
    return null;
   }

 const val_id = chat ? 'ic' : 'ip';
 const val = state[val_id];

 //---------------
 // Uložení dat do databáze
 const write_data = () =>
    {
     if(val.charCodeAt(0) === 0)
       {
        set_state({...state, $error: t('impress_err_g')});
        return;
       }

     set_state({...state, 
                $writing: true,
                $error: null});

     master.db_write_user_impression(uid, val_id, val, chat ? state.ic_org : state.ip_org)
     .then(r => 
          {
           if(r)
             {
              // Cachovat poslední impression u propojení:
              db_set_data(['profile', 'conn', uid, val_id], 'set', val); 

              // Aktualizovat zdrojovou hodnotu XP:
              let xp_diff = 0;
              const org_val = chat ? state.ic_org : state.ip_org;

              for(let i = 0; i < val.length; i++)
                 {
                  xp_diff += (!!val.charCodeAt(i)|0) - (!!org_val.charCodeAt(i)|0);
                 }

              if(chat)
                 db_set_data(['profile', 'nxpi'], 'set', xp_diff*XP_CHAT_WEIGHT);
              else
                 db_set_data(['profile', 'rxpi'], 'set', xp_diff);

              close_impression_editor(url_history, location); 
             }
           else
             {
              set_state({...state, 
                         $writing: null, 
                         $error: t('err_save')});
             }
          });
    }

 //---------------
 // Nastevení hodnoty položky
 const setter = (i, data_id) =>
    {
     data_id = data_id|0;

     const c = i === false ? '\0' : String.fromCharCode((i|0)+51);
     const new_val = val.substr(0, data_id) + c + val.substr(data_id+1);

     set_state({...state, [val_id]: new_val, $error: null});
    }
 
 //===============================
 let tab_content;

 // Parametry překladu:
 const o = {peer_gender: conn.sex-1};

 // @@@ Anglická verze v "OneDrive:/Glagoli/impression-výsledek.txt" 
 tab_content = <>
                <ImpressionItem data_id  = {0} // Celkové hodnocení (target)
                                data     = {val}
                                setter   = {setter}
                                acc_icon
                                text_red_min = {t('impress_g--', o)}
                                text_min     = {t(chat ? "impress_gc-" : "impress_gp-", o)}
                                text_max     = {t(chat ? "impress_gc+" : "impress_gp+", o)}
                                text_red_max = {t('impress_g++', o)}/>

                {!chat && // Osobní setkání
                 <>
                  <ImpressionItem data_id  = {8}  // Sociální status
                                  data     = {val}
                                  setter   = {setter}
                                  text_red_min  = {t("impress_ss--", o)}
                                  text_min      = {t("impress_ss-", o)} 
                                  text_max      = {t("impress_ss+", o)}
                                  text_red_max  = {t("impress_ss++", o)}/>

                  <ImpressionItem data_id  = {1} // Sexuální přitažlivost
                                  data     = {val}
                                  setter   = {setter}
                                  text_red_min  = {t("impress_sa--", o)}
                                  text_min      = {t("impress_sa-", o)} 
                                  text_max      = {t("impress_sap+", o)}
                                  text_red_max  = {t("impress_sap++", o)}/>
                 </>}
                  

                <ImpressionItem data_id  = {2}  // Extraversion
                                data     = {val}
                                setter   = {setter}
                                text_red_min  = {t('impress_ex--', o)}
                                text_min      = {t(chat ? "impress_exc-" : "impress_exp-", o)}
                                text_max      = {t(chat ? "impress_exc+" : "impress_exp+", o)}
                                text_red_max  = {t('impress_ex++', o)}/>

                <ImpressionItem data_id  = {3} // Neuroticism
                                data     = {val}
                                setter   = {setter}
                                text_red_min  = {t("impress_ne--", o)}
                                text_min      = {t("impress_ne-", o)} 
                                text_max      = {t("impress_ne+", o)}
                                text_red_max  = {t("impress_ne++", o)}/>

                <ImpressionItem data_id  = {4} // Agreeableness
                                data     = {val}
                                setter   = {setter}
                                text_red_min  = {t("impress_ag--", o)}
                                text_min      = {t("impress_ag-", o)} 
                                text_max      = {t("impress_ag+", o)}
                                text_red_max  = {t("impress_ag++", o)}/>

                <ImpressionItem data_id  = {5} // Conscientiousness
                                data     = {val}
                                setter   = {setter}
                                text_red_min  = {t("impress_cs--", o)}
                                text_min      = {t("impress_cs-", o)} 
                                text_max      = {t("impress_cs+", o)}
                                text_red_max  = {t("impress_cs++", o)}/>

                <ImpressionItem data_id  = {6} // Opennes
                                data     = {val}
                                setter   = {setter}
                                text_red_min  = {t("impress_op--", o)}
                                text_min      = {t("impress_op-", o)} 
                                text_max      = {t("impress_op+", o)}
                                text_red_max  = {t("impress_op++", o)}/>

                <ImpressionItem data_id  = {7}
                                data     = {val}
                                setter   = {setter}
                                text_red_min  = {t("impress_iq--", o)}
                                text_min      = {t("impress_iq-", o)} 
                                text_max      = {t("impress_iq+", o)}
                                text_red_max  = {t("impress_iq++", o)}/>

                {!!chat && // Chat
                 <>
                  <ImpressionItem data_id  = {1} // Sexuální přitažlivost - pro chat přesunuto na konec
                                  data     = {val}
                                  setter   = {setter}
                                  text_red_min  = {t("impress_sa--", o)}
                                  text_min =      {t("impress_sa-", o)} 
                                  text_max =      {t("impress_sac+", o)}
                                  text_red_max  = {t("impress_sac++", o)}/>
                 </>}
               </>;
   
 return <div className = 'impression-editor'>
         <span className = 'close-button' 
               onClick   = {() => close_impression_editor(url_history, location)}></span>
         <h1 onClick = {narrow ? () => close_impression_editor(url_history, location) 
                               : null}>
           {conn.full_name ? t('impress_title_fn', conn.nick_name, conn.full_name)
                           : t('impress_title', conn.nick_name)}
         </h1>
         <div className='narrow-scroll'>
          <div className='desc'>{t('impress_desc')}</div>
          {state.$writing && <WaitIndicator size = {16} title = {t('wait_saving')}/>}
          {state.$error && <div className='error'>{state.$error}</div>}
          <div>
           <span className = {'sel' + (!chat ? ' active' : '')}
                 onClick = {() => switch_tab(url_history, location, 0)}>
            {t("impress_pers")}
           </span>
           <span className = {'sel' + (chat ? ' active' : '')}
                 onClick = {() => switch_tab(url_history, location, 1)}>
            {t("impress_chat")}
           </span>
          </div>
          <div className = 'tab' ref = {tab_node}>
           {tab_content}
          </div>
          <div className = 'btn-row'>
            {state[val_id] !== state[val_id+'_org'] &&
             <button className = 'black'
                     onClick   = {write_data}>
               {t("btn_save")}
             </button>}
            <button onClick={() => close_impression_editor(url_history, location)}>
               {t("btn_cancel")}
            </button>
          </div>
         </div>
        </div>;

}
