import React from 'react';
import {LineEditor} from '../lib/LineEditor';
import {PopupWindow} from '../lib/PopupWindow';
import {WaitIndicator} from '../lib/WaitIndicator';
import {useTranslationDef} from '../translations/useTranslationDef';
import './SchoolEditor.css';


/*------------------------------------------------------------------------------------------------- 
Parametr str je seznam škol oddělených \n. Každá škola je seznam položek oddělených \t:
<url, name, loc_name>.
Výsldkem je pole objektů s členy {url, name, loc_name}. Člen loc_name je nepovinný.
------------------------------------------------------------------------------------------------- */ 
export function parse_schools(str)
{
 if(!str)
    return [];

 return str.split('\n').map(e => {let m = e.match(/^([^\t]*)\t([^\t]*)(\t([^\t]*))?/);
                                  let r = {url: m[1], name: m[2]};
                                  if(m[4]) r.loc_name = m[4];
                                  return r;});
}

/*------------------------------------------------------------------------------------------------- 
Editor seznamu škol

props  value       .. iniciální hodnota zobrazená v editoru (řetězce oddělený \n, položky \t)
       rank        .. ranking školy
       setter      .. funkce vyvolaná při změně obsahu editoru. Parametr: seznam kódovaný jako string
       rank_setter .. funkce pro nastavení ranku školy
------------------------------------------------------------------------------------------------- */ 
export function SchoolEditor(props) 
{
 const init_state = 
    {
     input: null, // pokud se zadává nová škola, tady je řetězec zapsaný uživatelem
     sel_list: null, // seznam škol na výběr v popup-okně
     sel_input: null, // vstup, pro který byl sel_list vypočítán
     sel_url: null, // URL školy vybrané v popup-menu
    };

 const [state, set_state] = React.useState(init_state);
 const tr_def = useTranslationDef();
 const [univ_list, set_univ_list] = React.useState(null); // dynamicky stahovaný seznam univerzit
                                                          // = null .. probíhá stahování
                                                          // = false .. error

 const cc_loc = tr_def.country_names;
 const t = tr_def.translate;

 React.useEffect(() =>
                 {
                  import('../data/univ_list.json')
                  .then(ul => set_univ_list(ul))
                  .catch(err => {console.error("SchoolEditor, univ_list.json", err);
                                 set_univ_list(false)});
                 }, []);

 const school_list = parse_schools(props.value);

 //----------------
 // Vrátí seznam max. 10 škol, které odpovídají řetězci str.
 // Položky mají strukturu: {url, name, loc_name, country} 
 // Pokud žádná položka neodpovídá, vrací null.
 const sugestions = str =>
    {
     if(str==="") return null;

     let result = [];

     // sestavit ze vstupního řetězce regulární výraz:     
     // (izoluje slova a okolo každého dá (?=.*SLOVO)) - tj. lookahead daného slova na libovolné pozici. 
     //  Tj. řetězec musí obsahovat všechna slova v libovolném pořadí.)
     let pat = str.split(" ") // rozdělit na slova
               .filter(s => s.length>0) // vypustit prázdná slova
               .map(s => "(?=.*"+s.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')+")") // oescapovat speciální znaky regexpu a přidat regex
               .join(""); // zase všechno spojit

     pat = "^"+pat;

     let rx = RegExp(pat, 'i');

     for(let url in univ_list)
        {
         const u = univ_list[url];
         if(rx.test(u.n) || (u.l && rx.test(u.l)) || rx.test(url))
           {
            let present = false;

            // pokud již je v seznamu, nenavrhovat ji znovu:
            for(let i=0; i<school_list.length; i++)
               {
                if(school_list[i].url === url)
                  {
                   present = true;
                   break;
                  }
               }

            if(!present)
              {
               let r = {name: u.n, url:url, country: cc_loc[u.c]};
               if(u.l) r.loc_name = u.l;
               result.push(r);
               if(result.length >= 10)
                  break;
              }
           }
        }

     return result.length!==0 ? result : null;
    }

 //----------------
 // Zahájí zadávání nové školy
 const school_edit = e =>
    {
     set_state({...state, input: "", wnd_anchor: e.target.getBoundingClientRect()});
    }

 //----------------
 // Uloží do dat nově přidanou školu
 const school_add = url =>
    {
     let val = school_list
               .map(s => (s.url+'\t'+s.name+(s.loc_name?'\t'+s.loc_name:"")))
               .join('\n');

     if(!univ_list) return;

     const ns = univ_list[url];
     if(!ns) return;

     if(val.length > 0)
        val += '\n';

     val += url + '\t' + ns.n;
     if(ns.l)
        val += '\t' + ns.l;
     
     props.setter(val);

     if(!props.rank || props.rank > ns.r)
        props.rank_setter(ns.r);
     
     set_state(init_state);
    }

 //----------------
 // odstraní školu ze seznamu
 const school_del = url =>
    {
     let new_rank = null;
     let val = "";

     for(let i = 0; i<school_list.length; i++)
        {
         const s = school_list[i];
         if(s.url !== url)
           {
            if(univ_list)
              {
               const sdef = univ_list[s.url];
               if(sdef)
                 {
                  if(new_rank === null || new_rank > sdef.r)
                     new_rank = sdef.r;
                 }
              }

            if(val.length > 0) val += '\n';

            val += s.url + '\t' + s.name;

            if(s.loc_name)
               val += '\t' + s.loc_name;
           }
        }
     
     if(!val)
       {
        props.setter(null);
        props.rank_setter(null);
       }
     else
       {
        props.setter(val);

        if(new_rank && props.rank !== new_rank)
           props.rank_setter(new_rank);
       }
    }

 // ----------------
 // Kliknutí při otevřeném okně na položku okna nebo mimo okno
 const on_click_selection = (node, e) => 
    {
     if(node !== null)
       {
        school_add(node.attributes.value.value);
        e.stopPropagation();
       }
     else  
       {
        if(state.sel_open && e.target.id!=="school-search")
           set_state(init_state);
       }
    }

 // ----------------
 // Myš se pohybuje na položkou menu.
 const mouse_over_sel_item = e =>
    {
     let url = e.currentTarget.attributes.value.value;
     if(url !== state.sel_url)
        set_state({...state, sel_url: url});
    }

 //----------------
 // Funkce vyvolaná při stisknutí klávesy v řádkovém editoru.
 const on_key = e =>
    {
     if(e.key==="Enter" || e.keyCode === 13)
       {
        if(state.sel_url !== null)
           school_add(state.sel_url);
       }
     else if(e.key==="Escape" || e.keyCode === 27)
       {
        set_state(init_state);
       }
     else if(e.key==="ArrowUp" || e.keyCode === 38)
       {
        if(state.sel_list)
          {
           let new_url;
           if(state.sel_url)
             {
              new_url = state.sel_url;
              for(let i = 0; i<state.sel_list.length; i++)
                 {
                  if(state.sel_list[i].url === state.sel_url)
                    {
                     if(i > 0)
                        new_url = state.sel_list[i-1].url
                     break;
                    }
                 }
             }
           else
             {
              new_url = state.sel_list[state.sel_list.length-1].url;
             }
           
           if(new_url !== state.sel_url)
              set_state({...state, sel_url: new_url});
           
           e.stopPropagation();
          }
       }
     else if(e.key==="ArrowDown" || e.keyCode === 40)
       {
        if(state.sel_list)
          {
           let new_url;
           if(state.sel_url)
             {
              new_url = state.sel_url;
              for(let i = 0; i<state.sel_list.length; i++)
                 {
                  if(state.sel_list[i].url === state.sel_url)
                    {
                     if(i < state.sel_list.length-1)
                        new_url = state.sel_list[i+1].url
                     break;
                    }
                 }
             }
           else
             {
              new_url = state.sel_list[0].url;
             }
           
           if(new_url !== state.sel_url)
              set_state({...state, sel_url: new_url});
           
           e.stopPropagation();
          }
       }
    }

  // User interface:
 let slist = school_list.map(e => <tr className="school-item" key={e.url}>
                                    <td className="sch-name">
                                      {e.loc_name ?
                                       <>
                                        <div>{e.loc_name}</div>
                                        <div className="l2">{e.name}</div>
                                       </>
                                       :
                                        e.name
                                      }
                                    </td>
                                    <td className="sch-del" title={t('sched_del')} onClick={() => school_del(e.url)}>
                                    </td>
                                  </tr>);
 let sadd;

if(state.input === null)
   {
    sadd = <div className="school-edit" onClick={school_edit}><i>+ {t('sched_add')}</i></div>
   }
 else
   {
    let sel_list;
    let sel_url;
    let loading = false;
    let err_msg = "";
    
    if(univ_list)
      {
       if(state.input !== state.sel_input)
         { // změnil se vstupní text => znovu vytvořit obsah nabídky
          sel_list = sugestions(state.input);
       
          // aktualizovat vybrané url:
          if(sel_list)
            {
             if(sel_list.length === 1)
               { // pokud je ve výběru jediná škola, nastavit ji jako vybranou (Enter ji nastaví)
                sel_url = sel_list[0].url;
               }
             else 
               { // více škol
                sel_url = null;
                if(state.sel_url)
                  { // pokud již nějaká škola byla vybraná, zkontrolovat, že je stále v seznamu
                   for(let i=0; i<sel_list.length; i++)
                      {
                       if(sel_list[i].url === state.sel_url)
                         {
                          sel_url = state.sel_url;
                          break;
                         }
                      }
                  }
               }
            }
          else
            {
             sel_url = null;
            }
          
          set_state({...state, sel_list: sel_list, sel_input: state.input, sel_url: sel_url});
         }
       else
         {
          sel_list = state.sel_list;
          sel_url = state.sel_url;
         }

       if(!sel_list && state.input.length > 0)
          err_msg = t('sched_not_found');
      }
    else
      {
       if(univ_list === false)
          err_msg = t('err_server');
       else
          loading = true;
      }


    let wnd_content;
    if(sel_list)
      {
       wnd_content = sel_list.map(r => <div className = {state.sel_url===r.url ? "sel-item" : ""}
                                            key = {r.url} >  
                                         <div className = "wnd-item"
                                              value = {r.url}
                                              onMouseOver = {mouse_over_sel_item}>
                                           {r.loc_name ? 
                                              <>
                                               <div className="first">
                                                 {r.loc_name}
                                               </div>
                                               <div className="second">
                                                 {r.name}
                                               </div>
                                              </>
                                           :
                                              <div className="first">
                                                {r.name}
                                              </div>
                                           }
                                          <div className="second">
                                            {r.country}
                                          </div>
                                         </div>
                                       </div>);
      }

    sadd = <div className="pr-sub-item">
             <LineEditor value={state.input || ""} 
                         data_id="school-search"
                         placeholder={t('sched_lbl')}
                         setter={val => set_state({...state, input: val})}
                         maxlength="256"
                         on_focus = {f => {if(!f) setTimeout(() => set_state(init_state), 200);}}
                                    // Pokud editor ztratí focus, zrušit editaci
                                    // musí se to udělat trochu opožděně, protože tento call-back
                                    // se vyvolá i při kliknutí do menu škol, takže by se na kliknutí nenastavila
                         autoFocus 
                         write_through
                         on_key={on_key}/>
             {loading && <WaitIndicator size = {16} title = {t('wait_server')}/>}
             {err_msg && <div className="error">{err_msg}</div>}
             {sel_list &&
              <PopupWindow content={wnd_content}
                           anchor={state.wnd_anchor}
                           align_right = {true}
                           on_click={on_click_selection}/>}
           </div>

   }

 return <div className="school-editor">
         <table><tbody>
           {slist}
         </tbody></table>
         {sadd}
        </div>
}
