import React from 'react';
import {Selection} from '../lib/Selection';
import {PopupWindow} from '../lib/PopupWindow';
import {call_setter} from '../lib/call_setter';
import {useTranslationDef} from '../translations/useTranslationDef';
import lang_nat from '../translations/lists/lang_nat';
import './LanguageEditor.css';
const {LANGUAGES_MAX} = require('../profile_def');

//const lang_level_labels = ["native", "proficient", "advanced", "intermediate"];

const lang_levels_sel = [
   {v: 0,  tid: 'lngl.nat'}, 
   {v: 1,  tid: 'lngl.perf'}, 
   {v: 2,  tid: 'lngl.adv'}, 
   {v: 3,  tid: 'lngl.interm'},
   {v: -1, tid: 'delete', c: 'italic'}];

/*-------------------------------------------------------------------------------------------------
Editor jazyků

props  value .. stávající seznam jazyků (objekt se složkami pojmenovanými jazyky, hodnota složky
                udává jazykovou úroveň (0..3)
-------------------------------------------------------------------------------------------------*/
export function LanguageEditor(props) 
{
 const init_state = 
   {
    add: false, // true, pokud je otevřeno okno pro přidání jazyka
    all_lang: false, // je zobrazen výběr všech jazyků (jinak jenom zkrácený)
    added: [] // seznam přidaných jazyků, které ještě nemají nastavenou úroveň
   }

 const [state, set_state] = React.useState(init_state);

 const lang_loc = useTranslationDef().lang_names; 
 const lang_short = ["cs", "sk", "en", "fr", "de", "es", "it"]; // zkrácený seznam jazyků


 //------------------
 // Otevření okna na přidání jazyka
 const on_click_add = e => 
    {
     set_state({...state, add: true, wnd_anchor: e.target.getBoundingClientRect()});
    }

 // ----------------
 // Kliknutí při otevřeném okně na položku okna nebo mimo okno
 const on_click_add_window = (node, e) => 
    {
     if(node !== null)
       {
        if(node.id === "all-lang")
          {
           set_state({...state, all_lang: true});
          }
        else 
          {
           let new_state = {...state, add: false, all_lang: false, wnd_anchor: undefined}
           let lang_code = node.attributes.value.value;

           // pokud daný jazyk není mezi původními ani nově přidanými, přidat ho do nových:
           if(state.added.indexOf(lang_code) === -1 && 
              (!props.value || props.value[lang_code] === undefined || props.value[lang_code] === null))
              new_state.added = [...state.added, lang_code];

           set_state(new_state);
          }

        e.stopPropagation();
        return;
       }

     if(state.add)
        set_state({...state, add: false, all_lang: false, wnd_anchor: undefined});
    }

 //--------------------
 // Nastavení úrovně jazyka
 const set_lang_level = (lang_code, lvl) =>
    {
     let new_val = {...props.value, [lang_code]: parseInt(lvl, 10)}
     call_setter(props, new_val);

     // pokud byl jazyk mezi nově přidanými, odstranit ho ze seznamu:
     let lidx = state.added.indexOf(lang_code);
     if(lidx !== -1)
       {
        let a = [...state.added];
        a.splice(lidx, 1);
        set_state({...state, added: a});
       }
    }


 //--------------------
 // Smazat jazyk
 const lang_del = lc =>
    {
     let lidx = state.added.indexOf(lc);
     if(lidx !== -1)
       { // smazat z nově přidaných
        let a = [...state.added];
        a.splice(lidx, 1);
        set_state({...state, added: a});
       }
     else
       { // smazat z původních
        let new_val = {...props.value, [lc]: null};
        call_setter(props, new_val);
       }
    }


 // Udělat kopii vstupní hodnoty, vypustit vymazané jazyky:
 const sval = props.value;
 const val = {};

 for(let k in props.value)
    {
     if(!sval.hasOwnProperty(k) || sval[k] === null || sval[k] === undefined)
        continue;
     val[k] = sval[k];
    }

 // - přidat nově přidané jazyky:
 for(let i = 0; i<state.added.length; i++)
    {
     val[state.added[i]] = 1000;
    }

 let lang = Object.keys(val);

 // uspořádat jazyky (nejprve podle levelu, potom podle abecedy):
 lang.sort((e1, e2) => {if(val[e1]!==val[e2]) 
                           return val[e1]-val[e2]; 
                        return lang_loc[e1].localeCompare(lang_loc[e2]);});

 let llist = lang.map(e => <tr className="lang-item" key={e}>
                              <td className="lang-name">{lang_loc[e]+ (lang_loc[e]!==lang_nat[e] ? " ("+lang_nat[e]+")" : "")}</td>
                              <td className="lang-level">
                               <Selection type   = "int"
                                          value  = {val[e]}
                                          content = {lang_levels_sel}
                                          placeholder = "Zvolte úroveň"
                                          setter = {val => {if(val === -1) 
                                                               lang_del(e);
                                                            else
                                                               set_lang_level(e, val);}}/>
                              </td>
                              <td>
                                <div className="lang-del" title="Smazat jazyk" onClick={() => lang_del(e)}>
                                 &nbsp;
                                </div>
                              </td>
                            </tr>);

 let add_wnd_content;

 if(state.add)
   {
    let lang_codes = state.all_lang ? Object.keys(lang_loc) : lang_short;
    lang_codes.sort((e1, e2) => lang_loc[e1].localeCompare(lang_loc[e2]));
    let lang_btns = lang_codes.map(e => <div className="wnd-item" 
                                             key = {e}
                                             value = {e}>
                                           {lang_loc[e]+ (lang_loc[e]!==lang_nat[e] ? " ("+lang_nat[e]+")" : "")}
                                        </div>);
    if(state.all_lang)
       add_wnd_content = lang_btns;
    else
      {
       add_wnd_content = <>
                           {lang_btns}
                           <div className="wnd-item" id="all-lang">
                              Další jazyky...
                           </div>
                          </>;
      }
   }


 return (<div className="lang-list">
          <table><tbody>
          {llist}
          </tbody></table>
          {lang.length < LANGUAGES_MAX && 
           <div className="lang-add"
                onClick={on_click_add}>
             {state.add &&
              (state.all_lang ? <PopupWindow content={add_wnd_content}
                                             wnd_style={{top: 0, bottom:0}}
                                             on_click={on_click_add_window} />
                              : <PopupWindow content={add_wnd_content}
                                             anchor={state.wnd_anchor}
                                             on_click={on_click_add_window} />)
             }
             <i>+ přidat jazyk</i>
           </div>}
         </div>);
}
