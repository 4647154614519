/*#################################################################################################

Pokud je aplikace načtena ve více panelech, zvolí jeden jako master. Ten potom udržuje stav
celé aplikace a na něj jsou delegována definované volání, především pro komunikaci se
serverem. Funkce, které se mají vždy provádět u mastera, jsou definovány jako členy
objektu master_def.

Publikované symboly:
  session_id       - (string) jednoznačný indetifikátor této session
  current_master   - (string) identifikátor session, která je master
  is_master        - (bool) true, pokud je tato session master
  master_def       - funkce, která se mají vykonávat v kontextu mastera musejí být
                     definovány uložením do tohoto objektu 
                     Např.: master_def.název_funkce = function(a, b, c)
                     Funkce, které vracejí hodnotu (musí to být promise) to musejí označit
                     takto: master_def.response.název_funkce = true;
  master           - funkce definované pomocí master_def se vyvolávají skrze tento objekt
                     Např. master.název_funkce(1, 2, 3); Tím ze zajistí přesměrování
                     volání z klienta na mastera.
                   
  session_init     - musí být zavoláno při inicializaci aplikace (nyní se volá z db_init)
  session_shutdown - musí být zavoláno před zavřením okna (nyní se volá z db_shutdown)

#################################################################################################*/

console.log("LOAD master");

// Jednoznačný identifikátor session v rámci browseru:
export let session_id = (performance.now() + "" + Math.random()).replace(/\./g, "");
console.log("session_id: ", session_id)

// Všechny funkce, jejichž volání má být delegováno 
// na mastera musejít být definovány jako položky tohoto objektu


export let master_def = {response: {}, // Funkce, které vracejí výsledek, musejí navíc do podobjektu 'response'
                                       // nastavit u svého jména true. Musejí vracet promise.
                         at_client: {}, // kód vyvolaný na klientovi, pokud se volání předává masterovi
                                        // Parametr: arg .. pole argumentů volání; funkce ho může změnit
                                        // Výsleded: true, pokud se má volání provést, false, pokud se má cancelovat
                                        //           pokud vrací instanci Promise, vrátí se jako výsledek bez volání mastera
                         at_master: {}  // kód vyvolaný na klientovi, pokud se volání předalo z klienta
                                        // Parametry: arg       .. pole argumentů volání; funkce ho může změnit
                                        //            client_id .. id session klienta, který funkce vyvolal
                                        //            master_id .. id session mastera v době volání
                                        //            ts        .. timestamp v době volání
                                        //  Výsledek: true, pokud se má volání provést, false, pokud se má cancelovat
                                        }; 

let _master_def;            // Sem se při inicializaci okopíruje master_def a master_def se nastaví na null, 
                            // aby nebylo možné měnit interface mastera za běhu.

let client_interface;       // Interface na mastera v případě, že instance běží jako klient
                            // (obsahuje stuby funkcí z master_def delegující volání na mastera)
let master_call_id = 0;
const gen_master_call_id = () => master_call_id++;

let master_resolve = {};    // Funkce na klientu, která vracejí hodnotu, sem uloží resolvovací funkci,
                            // která se použije, když master vrátí výsledek. Funkce je uložena
                            // pod příslušným call-id.

export let master;          // Interface mastera. Pro master instanci ukazuje na master_def,
                            // pro klientské instance na client_interface.
                            // 
                             
let client_list = {}; // seznam klientů, objekt indexovaný id klientů, hodnotou je 
                      // čas posledního volání (aby se dalo zjistit, který byl naposledy aktivní)

export let current_master = null;
let session_state = {current_master: null}; // duplikuje current_master, aby byla změna vyditelná z closures

export let is_master = false; // true:  jsme master (tj. current_master === session_id
                              // false: nejsme master
let master_initialized = false;


let on_master_chng = null; // call-back při změně mastera (parametr je true, pokud byl označen 
                           // za mastera, false, pokud byl odvolán jako master)
let on_app_shutdn = null; // call-back na ukončení celé aplikace (zavření poslední instance)

/*-------------------------------------------------------------------------------------------------
Provede volání mastera.
-------------------------------------------------------------------------------------------------*/
function do_client_call(call_data_str, client_id, call_id)
{
 console.log("do_client_call", call_data_str, client_id, call_id);

 const call_data = JSON.parse(call_data_str);
 const fn = call_data.fn;
 if(!fn || !_master_def[fn])
   {
    console.error("do_client_call bad req", call_data_str);
    return;
   }

 const call_delay = Date.now() - call_data.ts;

 console.log(" - delay:", call_delay, "ms");

 if(_master_def.at_master[fn] && 
   !_master_def.at_master[fn](call_data.arg, client_id, call_data.master, call_data.ts))
   {
    console.log("master call cancelled at master side");
    return;
   }

 // Pokud není volání starší než 2s a bylo volání původně odesláno pro nás, 
 // aktualizovat klienta v seznamu:
 if(call_delay <= 2000 && call_data.master === session_id)
    client_list[client_id] = call_data.ts;
 
 let result = _master_def[fn](...call_data.arg);

 if(_master_def.response[fn])
   {
    const ls_name = ".mrsp." + client_id + "." + call_id;
    result.then(val =>
       {
        localStorage.setItem(ls_name, JSON.stringify(val));
       })
    .catch(err =>
       {
        console.error("Error in master-function", err);
        localStorage.setItem(ls_name, "null");
       });
   }
}

/*-------------------------------------------------------------------------------------------------
Nastaví informaci o novém masterovi.
-------------------------------------------------------------------------------------------------*/
function accept_master(master_id)
{
 session_state.current_master = current_master = master_id || localStorage.getItem(".master");
 if(current_master)
    master.$register_client();
}

/*-------------------------------------------------------------------------------------------------
Přepne session do režimu klienta.
-------------------------------------------------------------------------------------------------*/
function set_client()
{
 console.log("set_client");

 if(!client_interface) 
   { // Vytvořit klientský interface podle definice mastera
    client_interface = {};
    
    for(let id in _master_def)
       {
        if(!_master_def.hasOwnProperty(id))
           continue;

        const resp = _master_def.response[id];

        // lokální kopie proměnných, kvůli ESlint colsure následující funkce:
        const __master_def = _master_def;
        const _session_state = session_state;

        client_interface[id] = function()
                                       {
                                        console.log("calling master", id, arguments);

                                        let arg = [...arguments]; // objekt arguments se musí kvli serializaci převést na pole

                                        if(__master_def.at_client[id])
                                          {
                                           const atc = __master_def.at_client[id](arg);
                                           if(atc instanceof Promise)
                                              return atc;

                                           if(!atc)
                                             {
                                              console.log("master call cancelled at client side");
                                              return;
                                             }
                                          }

                                        const call_id = gen_master_call_id();
                                        const call_obj = {fn: id, 
                                                          arg: arg,
                                                          master: _session_state.current_master,
                                                          ts: Date.now()}; 

                                        const ls_name = ".mcl." + session_id + "." + call_id;

                                        let result;
                                        if(resp)
                                           result = new Promise(resolve => master_resolve[call_id] = resolve);

                                        localStorage.setItem(ls_name, JSON.stringify(call_obj));

                                        // Pokud master nezareaguje na volání do 2 sekund, pokusit se převzít mastera:
                                        setTimeout(() =>
                                                  {
                                                   if(localStorage.getItem(ls_name))
                                                     {
                                                      console.error("master not responding");
                                                      master_challenge();
                                                     }
                                                  }, 
                                                  2000);

                                        return result;
                                       };
       }
   }

 master = client_interface;

 accept_master();

 if(master_initialized)
   {
    console.warn("terminating master");

    is_master = false;
    master_initialized = false;

    if(on_master_chng) on_master_chng(false);
   }
}

/*-------------------------------------------------------------------------------------------------
Přepne session do režimu mastera.
-------------------------------------------------------------------------------------------------*/
function set_master()
{
 console.log("set_master");

 master = _master_def;

 is_master = true;
 master_initialized = true;

 // Sestavit frontu volání, uspořádat ji podle call_id, a vyvolat je:
 let cq = [];

 for(let key in localStorage)
    {
     let m = key.match(/^\.mcl\.([^.]+)\.([0-9]+)$/);
     if(m)
       {
        const client_id = m[1];
        const call_id = m[2]|0;
        cq.push({cd: localStorage.getItem(key), cc: client_id, cs: call_id});
        localStorage.removeItem(key)
       }
    }
 
 cq.sort((a, b) => a.cs - b.cs);

 for(let i = 0; i < cq.length; i++)
    {
     const c = cq[i];
     do_client_call(c.cd, c.cc, c.cs);
    }

 // vyvolat případný call-back:
 if(on_master_chng) on_master_chng(true);
}

/*-------------------------------------------------------------------------------------------------
Vyvoláno, když je tato session potenciálně master. Pokud není master, nic nedělá, pokud ano,
provede vlastní inicializaci jako master.
-------------------------------------------------------------------------------------------------*/
function test_master()
{
 console.log("test_master");

 accept_master();

 console.log("test_master, .master = ", current_master);

 if(current_master !== session_id)
   {
    console.log(" - master overtaken");
    set_client();
    return;   
   }
 
 set_master();
}

/*-------------------------------------------------------------------------------------------------
Funkce vyvolaná v případě, že dosavadní master neodpovídá na výzvu nebo byl ukončen.
Funkce prohlásí sebe za mastera a s prodlevou 1 sekundy vyvolá funkci test_master(),
která zkontroluje, zda skutečně jsme master. V tom případě provede inicializaci sebe jako mastera.
-------------------------------------------------------------------------------------------------*/
function claim_master()
{
 console.log("claim_master", session_id);

 // Prohlásit se za mastera a po 1 sekundě zkontrolovat, jestli nás někdo nepřebil.
 // Pokud ne, inicializovat se jako master.
 session_state.current_master = current_master = session_id;
 localStorage.setItem(".master", session_id);
 setTimeout(test_master, 1001);
}


/*-------------------------------------------------------------------------------------------------
Funkce vyvolaná po prodlevě, když jsme vznesli výzvu masterovi. Pokud během prodlevy master
odpověděl smaznáním výzvy, call-back on_storage_change() v reakci na to už převzal informaci
o stávajícím masterovi. Tady zkontrolujeme, jestli je nějaký stávající master nastaven,
pokud ne, prohlásit se za mastera.
-------------------------------------------------------------------------------------------------*/
function resolve_master_challenge()
{
 if(localStorage.getItem(".challenge_master") === session_id)
   { // master na výzvu neodpověděl, zrušit výzvu a označit se za mastera
    console.log("resolve_master_challenge: no response");
    localStorage.removeItem(".challenge_master");
    claim_master();
   }
 else
   {
    console.log("resolve_master_challenge: master exists ", current_master);
  }
}


/*-------------------------------------------------------------------------------------------------
Vyvoláno při inicializaci, pokud je local-storage již označena nějaká instance jako master, 
nebo v případě podezření, že master neodpovídá.
Funkce zapíše hodnotu ".challenge_master", na kterou musí stávající master odpovědět do dvou
sekund jejím smazáním.
-------------------------------------------------------------------------------------------------*/
function master_challenge()
{
 console.log("master_challenge");

 session_state.current_master = current_master = null;
 localStorage.setItem(".challenge_master", session_id);
 setTimeout(resolve_master_challenge, 2000);
}

/*-------------------------------------------------------------------------------------------------
Call-back na local-storage. V local storage se primárně udržuje stávající stav připojení k 
databázi. Pomocí local storage zároveň probíhá komunikace mezi jednotlivými instancemi.
-------------------------------------------------------------------------------------------------*/
function on_storage_change_master(e)
{
 switch(e.key)
       {
        case '.challenge_master':
             console.log("on_storage_change: ", e.key, e.newValue);

             if(e.newValue)
               { // Někdo vznesl výzvu masterovi
                if(localStorage.getItem('.master') === session_id) // pokud jsme my master, odpovědět jejím smazáním
                  {
                   console.log("- reinforcing master");
                   localStorage.removeItem('.challenge_master');
                  }
               }
             else if(current_master === null)
               { // Probíhá výzva masterovi a master právě odpověděl smazáním výzvy
                is_master = false;

                accept_master();

                if(!current_master) // v patologickém případě, když není master označen v local-storage se pokusit prohlásit sebe za mastera
                  {
                   console.log("- challenge without master");
                   claim_master();
                  }
               }
             break;

        case '.master':
             console.log("on_storage_change: ", e.key, e.newValue);

             if(e.newValue)
               { // Byl prohlášen nový master -> zaznamenat ho
                if(e.newValue === session_id)
                  { // starý master nás prohlásil za nového mastera
                   console.log("Master assumed from ", current_master);
                   set_master();
                  }
                else if(current_master !== null)
                  { // když probíhá challenge, nezaznamenávat mastera, až challenge doběhne, načíst vítěze
                   set_client(); // jinak se nastavit jako client (tím se zároveň načte id nového mastera)
                  }
               }
             else
               { // Master byl ukončen -> po náhodné prodlevě se pokusit prohlásit sebe za mastera
                 // Prodleva je tu pro snížení pravděpodobnosti konfliktu mezi několika instancemi
                 // (v každém případě zvítězí ta, která mastera claimovala poslední)
                console.log("- master terminated, after delay testing master");
                setTimeout(() =>
                          {
                           if(localStorage.getItem(".master") === null)
                              claim_master();
                          }, Math.random()*100);
               }
             break;

        default: 
            { // ošetřit komunikaci master ↔ client 
             if(e.newValue !== null)
               { 
                let m = e.key.match(/^\.(mcl|mrsp)\.([^.]+)\.([0-9]+)$/);
                if(m)
                  {
                   const op = m[1]
                   const client_id = m[2];
                   const call_id = m[3]|0;
                   localStorage.removeItem(e.key);
                   if(op === 'mcl')
                     { // Volání mastera
                      if(is_master)
                        do_client_call(e.newValue, client_id, call_id);
                     }
                   else
                     { // Vracený výsledek
                      if(client_id === session_id)
                        { // je to výsledek pro nás
                         const r = master_resolve[call_id];
                         if(r) r(JSON.parse(e.newValue));
                         delete master_resolve[call_id];
                        }
                     }
                  }
               }
             break;
            }
       }
}

/*-------------------------------------------------------------------------------------------------
Musít být vyvoláno při inicializaci aplikace.
-------------------------------------------------------------------------------------------------*/
export function session_init(on_master_changed, on_app_shutdown)
{
 _master_def = master_def
 master_def = null; // ukončit možnost definovat interface mastera

 on_master_chng = on_master_changed;
 on_app_shutdn  = on_app_shutdown;

 window.addEventListener("storage", on_storage_change_master); 

 set_client(); // Dokud se nevyřeší, kdo je master, případná volání mastera ukládat do localStorage
               // Až bude master zvolen, provede je.
               
 // Pokud se restartuje několik panelů najednou, žádný na začátku nevidí nastavenou položku ".master".
 // Proto vyvolat inicializaci mastera s náhodným zpožděním - nejrychlejší vyvolá claim_master(),
 // ostatní master_challenge(). Pokud se dva sejdnou, tak že vyvolají oba claim_master(),
 // konflikt se vyřeší až v test_master().
 setTimeout(() => 
           {
            if(localStorage.getItem(".master"))
               master_challenge();
            else
               claim_master();
           }, Math.random()*100);
}

/*-------------------------------------------------------------------------------------------------
Musít být vyvoláno při zavírání okna.
-------------------------------------------------------------------------------------------------*/
export function session_shutdown()
{
 if(is_master)
   {// Master se ukončuje, označit nového mastera.
    // Vzít klienta, který nás volal naposledy:
    let tmax = 0;
    let new_master;

    for(let id in client_list)
       {
        if(id !== session_id && client_list[id] > tmax)
          {
           new_master = id;
           tmax = client_list[id];
          }
       }

    if(new_master)
      {
       localStorage.setItem('.master', new_master);
      }
    else
      {
       if(on_app_shutdn) on_app_shutdn();
       localStorage.removeItem('.master');
      }
   }
 else
   {
    master.$unregister_client(session_id);
   }

 on_master_chng = null;
}

/*-------------------------------------------------------------------------------------------------
Registrace klienta u mastera. Klient ji vyvolá, když se dozví o novém masterovi.
-------------------------------------------------------------------------------------------------*/
master_def.$register_client = function()
{
 return;  // zde se nedělá nic, registrace se provede v do_client_call, jako u všech volání
}

/*-------------------------------------------------------------------------------------------------
Odregistrace klienta u master. Klient ji vyvolá těsně před ukončením.
-------------------------------------------------------------------------------------------------*/
master_def.$unregister_client = function(client_id)
{
 console.log("$unregister_client", client_id);

 delete client_list[client_id];
}
