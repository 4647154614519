/*#################################################################################################


#################################################################################################*/

import React from 'react';
import {useDbData} from './useDbData';
import {master,
        current_master} from './master';

/*-------------------------------------------------------------------------------------------------
Zjistí, jestli v posloupnosti paketů zpráv chybí načtení některého paketu.

Parametry:
  msg  ... načtený objekt zpráv
  out ... true = mají se načítat odchozí zprávy; jinak příchozí.
  other_uid ... uid druhé strany
  lmp ... id paketu zpráv, který se má poslední načíst. Pokud je null, načítá se jen záklaní paket.

Vrací true, pokud je potřeba některý paket načíst
-------------------------------------------------------------------------------------------------*/
function test_read(msg, out, lmp)
{
 let archive = out ? msg.out_archive : msg.in_archive;
 let pkt = out ? msg.out : msg.in;
 let aid = null;

 // Smyčka přes posloupnost paketů počínajíc základním až po lmp.
 // Pokud se narazí na nenačtený, vynutí jeho načtení:
 for(;;)
    {       // FIXME oštřit, že když nastane error, že se nebude pokoušet načítat zprávy furt dokola, do nekonečna
     if(!pkt || (pkt.$loading && pkt.$loading !== current_master) || pkt.$error)
        return true;
     
     if(aid === lmp)
        break;
     
     aid = pkt.aid;

     if(!aid)
        break;

     pkt = archive && archive[aid];
    }

 return false;
}

/*-------------------------------------------------------------------------------------------------
Vrátí objekt zpráv.
Parametry ilmp a olmp jsou identifikátory paketů (příchozích a odchozích) zpráv v archivu, kam až 
se mají zprávy načíst. (Tj. načtou se všechny zřetězené archivní pakety až po ilmp, resp. olmp
a další už se nečtou). Pokud je ilmp, resp. olmp null, nenačítají se žádné archivní pakety,
jen základní dokument zpráv. Pokud je ilmp, resp. olmp undefined, nenačítá se ani základní 
dokument odchozích zpráv.
-------------------------------------------------------------------------------------------------*/
export function useMessages(other_uid, ilmp, olmp) 
{ 
 const messages = useDbData(['messages', other_uid]) || {in: null, out: null};

 if(messages.in)
   {
    if(!messages.in.msg)
       messages.in.msg = {};
    if(!messages.in.stat)
       messages.in.stat = {};
   }

 if(messages.out)
   {
    if(!messages.out.msg)
       messages.out.msg = {};
    
    if(!messages.out.stat)
       messages.out.stat = {};
   }

 React.useEffect(() =>
                {
                 // Následující kód testuje, jestli je třeba zahájit načítání některé
                 // složky objektu zpráv. Vzhledem k tomu, že se všechny useEffecty 
                 // nejprve naplánují a pak všechny provedou, je možné, že by bylo načtení
                 // některé složky vyvoláno současně dvakrát. Proto se nejprve otestuje,
                 // zda podle původního stavu objektu není třeba zahájit načítání.
                 // Pokud ano, data se načtou znovu, aby se zjistilo, jestli již mezi tím
                 // načítání neprobíhá. Pokud ne, skutečně se začne načítat.

                 if(test_read(messages, false, ilmp))
                    master.db_read_msg_stream(false, other_uid, ilmp);

                 if(test_read(messages, true, olmp))
                    master.db_read_msg_stream(true, other_uid, olmp);
                });

 return messages;
}


