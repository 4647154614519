/*#################################################################################################

Definuje hook na složku data globálního stavu

#################################################################################################*/

import React from 'react';
import {db_watch_data,
        db_unwatch_data,
        db_get_data} from './app_state';
import {object_modify,
        array_shallow_equal} from '../utils';

/*-------------------------------------------------------------------------------------------------
React hook zpřístupňující složku globálního stavu. Parametrem je reference na složku stavu.
Vrací hodnotu příslušné složky stavu.
Parametr ref může být false/null/undefined, potom vrací vždy null. To lze použít pro podmíněný 
přístup k datům.
-------------------------------------------------------------------------------------------------*/
export function useDbData(ref) 
{
 const [state, set_state] = React.useState(() => ref ? {ref: ref,  data: db_get_data(ref)}
                                                     : {ref: null, data: null});

 // Normalizovat objekt reference, aby se zbytečně neinvalidoval useEffect:
 const norm_ref = array_shallow_equal(ref, state.ref) ? state.ref : ref;

 React.useEffect(() => {if(!norm_ref) return;
                        
                        let mounted = true; // Proměnná zaznamenávající, jestli je komponenta připojená.
                           // Do stavu 'odpojeno' je nastavena níže ve vracené clean-up funkci.
                           // Testována je v call-backu na změnu stavu sledovaných dat,
                           // aby se nevolalo set_state v případě, že je komponenta již odpojena
                           // (vede to k warningům).

                        // Call-back na změnu stavu:
                        const on_data_change = data_op => 
                            {
                             if(!mounted)
                                return;

                             if(data_op.ref.length >= norm_ref.length && data_op.op)
                               { // pokud je známa operace, která ke změně vedla, aktualizovat dat pomécí této operace
                                let new_data = object_modify(state.data, 
                                                             data_op.op, 
                                                             data_op.data, 
                                                             data_op.ref, 
                                                             norm_ref.length);
                                if(new_data !== state.data)
                                   set_state({ref: norm_ref, data: new_data});
                               }
                             else
                               { // jinak načíst celá data znovu
                                set_state({ref: norm_ref, data: db_get_data(norm_ref)});
                               }
                            }

                        db_watch_data(norm_ref, on_data_change);

                        return () => {mounted = false;
                                      db_unwatch_data(norm_ref, on_data_change);}
                       },
                 [norm_ref, state]);
 
 // Pokud nejsou data pro danou referenci cachována, načíst je znovu
 if(norm_ref !== state.ref)
   {
    const data = norm_ref ? db_get_data(norm_ref) : null; 
    set_state({ref: norm_ref, data: data});
    return data;
   }

 return state.data;
}
