/*#################################################################################################

Okon hlášení zobrazení přes aplikaci.

#################################################################################################*/

import React from 'react';
import './MessageBox.css';

export let message_box_set_state; // call-back na změnu stavu message-boxu
                                  // nastaven z AppLoggedIn

export const set_message_box_set_state = fn => message_box_set_state = fn;

/*-------------------------------------------------------------------------------------------------
Zobrazí message-box podle dané definice. Pokud je def = null/undefined/false, message-box skryje.

Členy definice:
 style .. identifikátor přidaný do 'class' elementu. (Předdefinováno: 'error' a 'query')
 msg   .. obsah message-boxu (buď string nebo definice elementů)
 btn   .. pole tlačítek. Definice každé tlačítka je objekt s členy:
          - title .. titulek tlačítka
          - value .. (volitelně) libovolná hodnota předaná call-back funkci
          - fn    .. (volitelně) call-back funkce tlačítka. Parametrem je hodnota tlačítka.
                     pokud není definovaná u tlačítka, vyvolá se globální.
 fn    .. (volitelně) call-back funkce tlačítek. Parametrem je hodnota tlačítka.
          pokud není definovaná u tlačítka, vyvolá se globální.
-------------------------------------------------------------------------------------------------*/
export function message_box(def)
{
 if(!def)
   {
    message_box_set_state({displayed: false});
    return;
   }
 
 message_box_set_state({...def, displayed: true});
}

/*-------------------------------------------------------------------------------------------------
props def .. definice message-boxu (viz popis u funkce message_box())
-------------------------------------------------------------------------------------------------*/
export function MessageBox(props) 
{
 const def = props.def;

 let btn_list = [];

 //------------
 // Call-back na kliknutí na tlačítko. 
 // Parametrem je pořadové číslo tlačítka:
 const btn_click = n => 
    {
     const bd = def.btn[n];
     const fn = bd.fn || def.fn;
     if(typeof fn === 'function')
        fn(bd.value);
     message_box(null);
    }

 for(let i = 0; i < def.btn.length; i++)
    {
     const bd = def.btn[i];

     btn_list.push(<button onClick = {() => btn_click(i)}
                           key = {i}>
                    {bd.title}
                   </button>);
    }

 let cname = 'message-box';

 if(def.style)
    cname += ' ' + def.style;

 return <div className = {cname}>
         <div className = 'content'>
          <div className = 'msg-icon'></div>
          <div className = 'msg'>{def.msg}</div>
         </div>
         <div className = 'btn-row'>
          {btn_list}  
         </div>
        </div>;
}
