/*#################################################################################################

Funkce pro příjem a odesíláním zpráv a správu jejich lokálních kopií.

#################################################################################################*/

import firebase from 'firebase/app';
import {firestore,
        firebase_functions} from '../config';
import {chop_string,
        compare_ts} from '../utils';
import {db_time_to_ms,
        ms_to_db_time,
        profile_normalize_value,
        remove_from_search_result} from '../user';
import {session_id,
        master_def,
        master} from './master';
import {current_uid} from './db';
import {db_set_data,
        db_get_data,
        storage_set,
        storage_get} from './app_state';
import {db_update_conn} from './own_profile';
import {get_user_profile_async} from './other_users';
import {message_box} from '../controls/MessageBox';
import {remove_uid_from_url} from '../AppLoggedIn';
import {t} from '../translations/translation';
import juntos_mp3 from '../graphics/juntos.mp3'; // zvuk při přijetí zprávy (získán z https://notificationsounds.com/message-tones/juntos-607,
                                                 // konvertován do MP3, aby byl menší
const {CONN_REL_BLOCKED} = require('../profile_def');

const PCACHE_UNREAD_MSGS_LIMIT   = 4;     // Maximální počet nepřečtených zpráv v cachi u profilu.
const BASE_PKG_MSG_LIMIT_LOW     = 16;    // Počet zpráv v základním paketu, které se v něm při archivaci vždy ponechají.
const BASE_PKG_MSG_LIMIT_HIGH    = 32;    // Počet zpráv v základním paketu, po jehož překročení se zprávy archivují.
const ARCHIVE_PKG_MSG_SIZE_LIMIT = 65536; // Limit velikost celkové zpráv v jednom archivním paketu v počtu znaků.

/*-------------------------------------------------------------------------------------------------
Zkonvertuje data paketu zpráv z formátu databáze do formátu aplikace.

Parametry:
  msg_data .. paket zpráv ve formátu databáze (nemění ho, vytvoří kopii)
  nf_list  .. výstupní parametr: pole, do koterého uloží notifikace (interní zprávy aplikace)
              Notifikace se pozná podle složky 'nf'. Funkce do konvertuje její timestamp ('t')
              na milisenkundy a přidá do ní člen 'id' s identifikátorem zprávy v původním paketu.

Do výsledného objektu přidá následující položky:
  msg_count    .. počet obsažených zpráv.
  first_msg_id .. id první zprávy v paketu nebo null, pokud je prázdný
  last_msg_id  .. id poslední zrpávy v paketu nebo null, pokud je prázdný
  first_msg    .. první zpráva v paketu nebo null, pokud je prázdný
  last_msg     .. poslední zrpáva v paketu nebo null, pokud je prázdný
-------------------------------------------------------------------------------------------------*/
function msgs_from_db(msg_data, nf_list)
{
 let r = {};

 if(msg_data.ts)
    r.ts = profile_normalize_value('tstamp', msg_data.ts, false);

 if(msg_data.profile_ts)
    r.profile_ts = profile_normalize_value('tstamp', msg_data.profile_ts, false);

 if(msg_data.last_read)
    r.last_read = db_time_to_ms(msg_data.last_read);

 if(msg_data.aid)
    r.aid = msg_data.aid;

 if(msg_data.asize)
    r.asize = msg_data.asize;

 r.stat = msg_data.stat || {};

 r.msg = {};

 for(let key in msg_data.msg)
    {
     let m = {...msg_data.msg[key]};
     m.t = db_time_to_ms(m.t);
     if(m.nf)
       {
        m.id = key;
        nf_list.push(m);
       }
     else
       {
        r.msg[key] = m;
       }
    }

 return r;
}

/*-------------------------------------------------------------------------------------------------
Zkonvertuje data archivního paketu zpráv z formátu databáze do formátu aplikace.
Do výsledného objektu přidá následující položky:
  msg_count    .. počet obsažených zpráv.
  first_msg_id .. id první zprávy v paketu
  last_msg_id  .. id poslední zrpávy v paketu
-------------------------------------------------------------------------------------------------*/
function msgs_from_db_arc(msg_data)
{
 let r = {};

 if(msg_data.ts)
    r.ts = profile_normalize_value('tstamp', msg_data.ts, false);

 if(msg_data.aid)
    r.aid = msg_data.aid;

 r.msg = {};

 for(let key in msg_data.msg)
    {
     let m = {t: parseInt(key), m: msg_data.msg[key]};
     const k = '#'+key;
     r.msg[k] = m;
    }

 return r;
}

/*-------------------------------------------------------------------------------------------------
Do paketu zpráv přidá následující položky:
  msg_count    .. počet obsažených zpráv.
  first_msg_id .. id první zprávy v paketu
  first_msg    .. první zpráva v paketu
  last_msg_id  .. id poslední zprávy v paketu
  last_msg     .. poslední zpráva v paketu
-------------------------------------------------------------------------------------------------*/
export function normalize_msg_packet(pkt)
{
 if(!pkt)
    return;

 let msg_count = 0;
 let first_msg_id = null;
 let first_msg_ts = Number.MAX_SAFE_INTEGER;
 let last_msg_id = null;
 let last_msg_ts = 0;

 for(let key in pkt.msg)
    {
     let m = pkt.msg[key];

     if(first_msg_ts > m.t)
       {
        first_msg_ts = m.t;
        first_msg_id = key;
       }

     if(last_msg_ts < m.t)
       {
        last_msg_ts = m.t;
        last_msg_id = key;
       }

     msg_count++;
    }

 pkt.msg_count    = msg_count;
 pkt.first_msg_id = first_msg_id;
 pkt.last_msg_id  = last_msg_id;
 pkt.first_msg    = first_msg_id && pkt.msg[first_msg_id];
 pkt.last_msg     = last_msg_id  && pkt.msg[last_msg_id];
}


/*-------------------------------------------------------------------------------------------------
Funkce vyvolaná při napsání znaku zprávy pro uživatele rcv_uid.
Odkládá zapsání informace o přečtené zprávy od odesílatele, aby se v průběhu aktivní konverzace
zbytečně nezapisovala informace o přečtení zprávy (a tím nezvyšovyla počty zápisů/čtení do databáze).
-------------------------------------------------------------------------------------------------*/
export function on_message_typing(rcv_uid)
{
 mark_messages_read(rcv_uid);

 let chng = storage_get('.chng');
 if(chng && chng.rd_mark)
   {
    const rd_mark = chng.rd_mark;
    if(rd_mark[rcv_uid])
       master.db_data_changed(['rd_mark', rcv_uid]);
   }
}

let rd_marks_write_pending = {};

/*-------------------------------------------------------------------------------------------------
Zapíše do databáze informaci o přečtení zpráv. Parametr uids je buď pole uid uživatelů, jejichž
zprávy byly přečteny, nebo je nezadán, pak se zapíší informace o všech doposud nezapsaných 
zprávách.
Vrací promise resolvovanou na null po zápisu všechn informací.
-------------------------------------------------------------------------------------------------*/
export function write_rd_marks(uids)
{
 let chng = storage_get('.chng');

 if(!chng || !chng.rd_mark)
    return Promise.resolve(null);

 console.log("write_rd_marks", uids);
 
 const rd_mark = chng.rd_mark;

 if(!uids)
    uids = Object.keys(rd_mark);
 
 const profile = db_get_data(['profile']);
 if(!profile || !profile.conn || !profile.ts)
    return Promise.resolve(null);

 const profile_ts = profile_normalize_value('tstamp', profile.ts, true);

 const conn = profile.conn;

 let promises = [];

 for(let i = 0; i < uids.length; i++)
    {
     const uid = uids[i];

     if(rd_mark[uid] && conn[uid] && conn[uid].last_read && !rd_marks_write_pending[uid])
       {
        console.log("- writing", uid);

        rd_marks_write_pending[uid] = true;

        promises.push(firestore.doc(`user/${uid}/inbox/${current_uid}`)
                      .set({last_read: ms_to_db_time(conn[uid].last_read),
                            profile_ts: profile_ts,
                            ts:        firebase.firestore.FieldValue.serverTimestamp()},
                           {merge: true})
                      .then(() =>
                           {
                            delete rd_marks_write_pending[uid];
                      
                            let c = storage_get('.chng');
                            if(c && c.rd_mark)
                              {
                               delete c.rd_mark[uid];
                               storage_set('.chng', c);
                              }
                           })
                      .catch(err => 
                            {
                             delete rd_marks_write_pending[uid];
                      
                             console.error("write_rd_marks", err);
                            }));
       }
    }
 return Promise.all(promises);
}

/*-------------------------------------------------------------------------------------------------
Označí zprávy od uživatele sender_uid jako přečtené.
-------------------------------------------------------------------------------------------------*/
export function mark_messages_read(sender_uid)
{
 console.log("mark_messages_read", sender_uid);

 const msg_cache_ref = ['profile', 'cache', 'unread_msgs', sender_uid];
 let mc = db_get_data(msg_cache_ref);

 if(mc && Array.isArray(mc) && mc.length) // Existují nepřečtené zprávy od daného uživatele
   {
    // Odstranit z cache nepřečtených zpráv: 
    db_set_data(msg_cache_ref, 'set', null);
    
    // Nastavit informaci o přečtení do propojení s uživatelem:
    const ts = mc[mc.length-1].t || Date.now();
    console.log("mark_messages_read", sender_uid, new Date(ts).toLocaleString('cs', // options vypínají zobrazení sekund
                                                                              {year: 'numeric', month: 'numeric', day: 'numeric', 
                                                                               hour: '2-digit', minute: '2-digit', second: '2-digit'}));
    db_set_data(['profile', 'conn', sender_uid, 'last_read'], 'set', ts);
    // Poznámka: db_data_changed(['rd_mark', sender_uid]) se vyvolá automaticky v db_set_data
   }
}

let pending_archive_transactions = {}; // zaznamenává, které transakce na archivaci zpráv probíhají,
                                       // aby se nevyvoválavy dvakrát

/*-------------------------------------------------------------------------------------------------
Zprávy, které překračují BASE_PKG_MSG_LIMIT_LOW přesune do archivního paketu.
-------------------------------------------------------------------------------------------------*/
function archive_messages(sender_uid)
{
 if(pending_archive_transactions[sender_uid])
    return;

 pending_archive_transactions[sender_uid] = true;

 console.log("archive_messages", sender_uid);

 const base_packet_db_ref = firestore.doc(`user/${current_uid}/inbox/${sender_uid}`)

 firestore.runTransaction(transaction =>
    {
     return transaction.get(base_packet_db_ref)
     .then(snapshot =>
          {
           console.log("archive_messages", sender_uid, " - transakction started");

           let msg_data = snapshot.data();
           if(!msg_data) return;

           // Sestavit seznam zpráv, konvertovat timestamps a utřídit podle času:
           let mlist = [];

           for(let key in msg_data.msg)
              {
               let m = {...msg_data.msg[key]};
               m.t = db_time_to_ms(m.t);
               m.key = key;
               mlist.push(m);
              }

           // - pro jistotu otestovat, že zpráv je dost:
           if(mlist.length <= BASE_PKG_MSG_LIMIT_LOW)
              return; 

           mlist.sort((a, b) => a.t-b.t);

           // Připravit aktulizační objekty pro přesun přebytečných zpráv do archivu:
           let msg_upd = {msg: {}}; // aktualizační data pro základní paket
           let arc_upd = {msg: {}}; // aktualizační data pro archivní paket
           let asize = msg_data.asize || 0; // celková velikost zpráv v aktivním archivním paketu
           let aid = msg_data.aid; // id aktuálního archivního paketu
           
           // - otestovat, zda není potřeba vytvořit nový archivní paket:
           if(asize > ARCHIVE_PKG_MSG_SIZE_LIMIT || !aid)
             {
              msg_upd.aid = aid = mlist[0].t+""; // identifikátor paketu podle času nejstarší zprávy
              asize = 0;
              if(msg_data.aid) // pokud nejde o první archnivní paket, přidat předchozí do spojového seznamu
                 arc_upd.aid = msg_data.aid;

              console.log("archive_messages", sender_uid, " - creating new packet: ", aid);
             }
           
           // - překopírovat zprávy do archivního paketu, v základním je označit pro odstranění
           for(let i = 0; i < mlist.length-BASE_PKG_MSG_LIMIT_LOW; i++)
              {
               const m = mlist[i];
               msg_upd.msg[m.key] = firebase.firestore.FieldValue.delete();
               if(!m.m) continue; // notifikace jen vymazat a nearchivovat (stejně by tu neměly být)
               arc_upd.msg[m.t+""] = m.m;
               asize += m.m.length;
              }
           
           console.log("archive_messages", sender_uid, " - moving ", mlist.length-BASE_PKG_MSG_LIMIT_LOW," messages to ", aid);

           msg_upd.asize = asize;

           // - timestamp aktualizovat jen v archivním paketu, v základním ho neměnit, aby se zbytečně nenačítal znovu
           arc_upd.ts = firebase.firestore.FieldValue.serverTimestamp();

           // Vlastní zápis dat:
           transaction.set(base_packet_db_ref,
                           msg_upd,
                           {merge: true});

           transaction.set(firestore.doc(`user/${current_uid}/inbox/${sender_uid}/archive/${aid}`),
                           arc_upd,
                           {merge: true});
          })
    })
 .then(() =>
    {
     delete pending_archive_transactions[sender_uid];
     console.log("archive_messages", sender_uid, " - transaction OK");
    })
 .catch(err =>
    { // error není vážný, při nejhorším se zprávy archivují při další příležitosti
     delete pending_archive_transactions[sender_uid];
     console.warn("archive_messages", sender_uid, " - transaction error", err);
    });
}

/*-------------------------------------------------------------------------------------------------
Zpracuje notifikace doručené přes zprávy (interní zprávy aplikace).
Zpracované notifikace zároveň ostraní z databáze, aby se nezpracovávaly podruhé.
-------------------------------------------------------------------------------------------------*/
function process_notifications(sender_uid, nf_list)
{
 if(!nf_list.length) return;

 let upd = {msg:{}};

 for(let i = 0; i < nf_list.length; i++)
    {
     const nf = nf_list[i];

     if(nf.nf === 'invp')
        master.db_read_profile(sender_uid);
     else
        console.error("Invalid notification", nf);

     upd.msg[nf.id] = firebase.firestore.FieldValue.delete();
    }

 // Odstranit notifikace:
 firestore.doc(`user/${current_uid}/inbox/${sender_uid}`)
          .set(upd, {merge: true})
          .catch(err => 
                {
                 console.error("process_notifications", err);
                });
 
}

/*-------------------------------------------------------------------------------------------------
Call-back na přijetí nové zprávy.
-------------------------------------------------------------------------------------------------*/
function on_new_msg(snapshot)
{
 console.log("on_new_msg", new Date());

 snapshot.forEach(doc =>
                 {
                  const sender_uid = doc.id;
                  const doc_data = doc.data();

                  if(doc_data.ts === null || doc_data.last_read === null)
                     return; // místo time-stampu je null => jde o předběžné vyvolání call-backu
                             // v případě, že zápis vyvolala jiná funkce téže aplikace.
                             // Místo FieldValue.serverTimestamp() je v objektu před dokončením
                             // zápisu null. Po dokončení zápisu bude funkce bude znovu vyvolaná 
                             // se skutečnými hodnotami.

                  // Otestovat, jestli není odesílatel blokovaný. Pokud ano, zprávu ignorovat.
                  // FIXME odstranit ji hned z databáze?
                  // (Poznámka: když se tam nechá a uživatel je odblokován, zprávy se doručí jako nové)
                  if(sender_uid)
                    {
                     const sconn = db_get_data(['profile', 'conn', sender_uid]);
                     if(sconn && sconn.rel === CONN_REL_BLOCKED)
                        return;
                    }

                  console.log(" - ", doc_data.ref, doc_data);

                  if(doc_data)
                    {
                     let nf_list = [];
                     let msg_data = msgs_from_db(doc_data, nf_list);
                     process_notifications(sender_uid, nf_list);

                     console.log("msg received from", sender_uid, "last_read:", 
                                 doc_data.last_read && doc_data.last_read.toDate());
                     const msg_ref = ['messages', sender_uid, 'in'];

                     // Zjistit, jestli některé zprávy nebyly z paketu odstraněny.
                     // To znamená, že byly přesunuty do archivu => přesunout je tam taky:
                     if(msg_data.aid)
                       {
                        let arc_ref = ['messages', sender_uid, 'in_archive', msg_data.aid];
                        let arc_pkt = db_get_data(arc_ref);
                        // Když se archivní paket načítá, nebo neexistuje, nebo je ve špatném stavu,
                        // nedělat nic:
                        if(arc_pkt && arc_pkt.msg && !arc_pkt.$loading && !arc_pkt.$error)
                          {
                           const prev_pkt = db_get_data(msg_ref);
                           let any_upd = false;
                           for(let k in prev_pkt.msg)
                              {
                               if(!msg_data.msg[k])
                                 {
                                  const m = prev_pkt.msg[k];
                                  arc_pkt.msg['#'+m.t] = m;
                                  any_upd = true;
                                 }
                              }

                           if(any_upd)
                              db_set_data(['messages', sender_uid, 'in_archive', msg_data.aid], 'set', arc_pkt);
                          }
                       }
                     

                     // Uložit nově přijaté zprávy:
                     db_set_data(msg_ref, 'set', msg_data);

                     normalize_msg_packet(msg_data); // rozšiřující datové členy přidat až po uložení do local-storage,
                       // aby se tam zbytečně neukládaly - vytvoří se znovu po každém načtení z local-storage
                       // FIXME ... možná je spíš vytvořit a uložit tady a nevytvářet při načítání

                     const last_msg = msg_data.last_msg;

                     if(last_msg)
                       {
                        // Čas zprávy nastavit jako nový limit pro sledování příchozích zpráv:
                        db_set_data('profile.cache.mrt_limit', 'set', msg_data.ts);
   
                        // Zjistit, jestli se má přehrát notifikační zvuk: 
                        const conn = db_get_data(['profile', 'conn', sender_uid]);
                        const prev_msg_time = (conn && conn.msg_time) || 0;
                        
                        const now = Date.now();

                        if(last_msg.t > prev_msg_time && now - prev_msg_time > 180000/*3 minuty*/)
                          { // je to zpráva, kterou jsme ještě neviděli a od předchozí zprávy uběhlo víc než 3 minuty
                           const msg_nf_ref = ['state', 'msg_nf'];
                           const last_msg_nf = db_get_data(msg_nf_ref) || 0;
                           if(now - last_msg_nf > 60000/*1 minuta*/)
                             { // zabránit, aby se přehrál zvuk častěji, než 1 za minutu, a to i mezi různými instancemi
                              db_set_data(msg_nf_ref, 'set', now);
                              let audio = new Audio(juntos_mp3);
                              let p = audio.play();
                              if(p) p.catch(err => console.error(err));
                             }
                          }

                        // Pokud byl zpráva odeslána nedávno, označit uživatele jako aktivního:
                        if(last_msg.t > now-15*60000/*15 minut*/)
                          {
                           const sender_state_ref = ['state', 'peer_state', sender_uid];
                           const sender_state = db_get_data(sender_state_ref);
                           if(!sender_state || sender_state.ts < last_msg.t)
                              db_set_data(sender_state_ref, 'set', {ts: last_msg.t, active: 1}); 
                          }

                        // Aktualizovat propojení s uživatelem (zapamatovat poslední zprávu, a její čas):
                        if(!conn) 
                           last_msg.new_user = true;

                        let conn_data = {msg_time: last_msg.t,
                                         lim: chop_string(last_msg.m, 64)};

                        if(last_msg.aim === 'odate')
                           conn_data.open_date = true;

                        if(last_msg.aim === 'ochat')
                           conn_data.open_chat = true;

                        db_update_conn(sender_uid, false, conn_data);

                        // Aktualizovat cache nepřečtených zpráv:
                        const last_read = (conn && conn.last_read) || 0; 
                        let um = []; // seznam zpráv pozdějších než last_read
                        
                        for(let key in msg_data.msg)
                           {
                            const m = msg_data.msg[key];
                            if(m.t > last_read)
                               um.push(m);
                           }
                        
                        if(um.length > 0)
                          {// sloučit seznam nových zpráv se zprávami již v cachi
                           um.sort((a, b) => a.t - b.t);
                        
                           const msg_cache_ref = ['profile', 'cache', 'unread_msgs', sender_uid];
                           let mc;
                        
                           if(um.length >= PCACHE_UNREAD_MSGS_LIMIT)
                             {
                              mc = um.slice(-PCACHE_UNREAD_MSGS_LIMIT);
                             }
                           else
                             {
                              mc = db_get_data(msg_cache_ref) || [];
                        
                              if(!mc.length)
                                {
                                 mc = um.slice(-PCACHE_UNREAD_MSGS_LIMIT);
                                }
                              else
                                {
                                 // Projít všechny nové zprávy a zatřídit je do stávajícího pole:
                                 for(let i = 0; i < um.length; i++)
                                    {
                                     let m = um[i];
                        
                                     // Najít nejbližsí starší zprávu v mc a m vložit před ní
                                     for(let j = mc.length-1; ; j--)
                                        {
                                         if(j === -1 || mc[j].t < m.t)
                                           {
                                            mc.splice(j+1, 0, m);
                                            break;
                                           }
                        
                                         if(mc[j].t === m.t)
                                            break; // duplicitní zpráva
                                        }
                                    }
                                }
                             }
                        
                           db_set_data(msg_cache_ref, 'set', mc);
                          }
                        
                        if(msg_data.msg_count > BASE_PKG_MSG_LIMIT_HIGH)
                           archive_messages(sender_uid);
                       }
                     
                     // Zkontrolovat, jestli odesilatel neaktualizoval profil. 
                     // V tom případě ho načíst znovu:
                     const user_profile = db_get_data(['user', sender_uid]);
                     if(user_profile && user_profile.ts && msg_data.profile_ts && 
                        compare_ts(user_profile.ts, msg_data.profile_ts) < 0)
                       {
                        master.db_read_profile(sender_uid);
                       }
                    }
                 });
}

/*-------------------------------------------------------------------------------------------------
Call-back na chybu v příjmu zpráv.
-------------------------------------------------------------------------------------------------*/
function on_msg_error(err)
{
 console.error("on_msg_error", err);
 debugger;
}


/*-------------------------------------------------------------------------------------------------
Nastaví listener na příchozí zprávy.
-------------------------------------------------------------------------------------------------*/
export function set_msg_listener()
{
 console.log("set_msg_listener");

 const mrt_limit = db_get_data('profile.cache.mrt_limit');

 let inbox_query = firestore.collection('user').doc(current_uid).collection('inbox');

 if(mrt_limit)
   {
    const t0 = profile_normalize_value('tstamp', mrt_limit, true);
    inbox_query = inbox_query.where('ts', '>', t0);
   }

 return inbox_query.onSnapshot(on_new_msg, on_msg_error);
}

/*-------------------------------------------------------------------------------------------------
Zahájí načítání zpráv přijatých/odeslaných uživatelem sender_uid. 

Parametry:
  out       .. true, pokud se mají načíst zprávy odeslané uživateli other_uid
               false, pokud se mají načíst zprávy přijaté
  othre_uid .. uid druhé strany
  aid       .. identifikátor archivního paketu, který se má načíst. Pokud je není zadán,
               načítá se základní paket.
-------------------------------------------------------------------------------------------------*/
master_def.db_read_messages = function(out, other_uid, aid)
{
 console.log("db_read_messages", out?"out":"in", other_uid + (aid?" aid: "+aid:""));

 let state_ref = ['messages', other_uid, out ? 'out' : 'in'];
 let db_ref = out ? `user/${other_uid}/inbox/${current_uid}`
                  : `user/${current_uid}/inbox/${other_uid}`;

 if(aid)
   {
    state_ref[2] += '_archive';
    state_ref.push(aid);
    db_ref += `/archive/${aid}`;
   }

 db_set_data(state_ref,
             'merge',
             {$loading: session_id,
              $error: null}); // zda jde o stejné session_id se testuje v useMessages
 
 firestore.doc(db_ref).get()
 .then(snapshot =>
      {
       const doc = snapshot.data();

       if(!doc)
         {
          db_set_data(state_ref,
                      'merge',
                      {$loading: null,
                       $error: null});
          return null;
         }

       let msg_data;
       if(aid)
         {
          msg_data = msgs_from_db_arc(doc)
         }
       else
         {
          let nf_list = [];
          msg_data = msgs_from_db(doc, nf_list);
          process_notifications(other_uid, nf_list);
         }
       db_set_data(state_ref, 'set', msg_data);
       return msg_data;
      })
 .catch(err =>
       {
        console.error("db_read_messages", err);
        db_set_data(state_ref,
                    'merge',
                    {$loading: null,
                     $error: true});
       });
}

/*-------------------------------------------------------------------------------------------------
Vynutí načtení všech paketů zpráv až po paket is id = lmp.

Parametry:
  out ... true = mají se načítat odchozí zprávy; jinak příchozí.
  other_uid ... uid druhé strany
  lmp ... id paketu zpráv, který se má poslední načíst. Pokud je null, načítá se jen záklaní paket.
-------------------------------------------------------------------------------------------------*/
master_def.db_read_msg_stream = function(out, other_uid, lmp)
{
 const msg = db_get_data(['messages', other_uid]) || {};

 let archive = out ? msg.out_archive : msg.in_archive;
 let pkt = out ? msg.out : msg.in;
 let aid = null;

 // Smyčka přes posloupnost paketů počínajíc základním až po lmp.
 // Pokud se narazí na nenačtený, vynutí jeho načtení:
 for(;;)
    {
     if(!pkt || (pkt.$loading && pkt.$loading !== session_id) || pkt.$error)
        master.db_read_messages(out, other_uid, aid); 
     
     if(!pkt || aid === lmp)
        break;
     
     aid = pkt.aid;

     if(!aid)
        break;

     pkt = archive && archive[aid];
    }
}


/*-------------------------------------------------------------------------------------------------
Odešle uživateli zprávu.
Pokud je zadán parametr resent_msg_id, jde o znovu-odeslání zprávy, jejíž předešlé odeslání selhalo.
V tom případě parametr msg ignoruje.
-------------------------------------------------------------------------------------------------*/
master_def.db_send_message = async function(rcv_uid, msg, resent_msg_id)
{
 console.log("db_send_message", rcv_uid, chop_string(msg, 20), new Date());

 const ts = Date.now();
 const msg_id = resent_msg_id || '#'+ts;

 const msg_ref = ['messages', rcv_uid, 'out', 'msg', msg_id];

 if(resent_msg_id)
   {
    const rm = db_get_data(msg_ref);
    if(!rm)
      {
       console.error("db_send_message, resent: msg not found");
       return;
      }

    msg = rm.m;
   }

 if(!msg)
    return;

 let msg_loc_obj = {m: msg,
                    $sending: session_id,
                    t: ts}

 // Zjistit, jestli nejde o první zprávu naleznému kontaktu:
 const msg_packet = db_get_data(['messages', rcv_uid, 'out']);
 const conn_data = {msg_time: ts,
                    lom: chop_string(msg, 64)}

 if(!msg_packet || !msg_packet.first_msg)
   {
    const uc = db_get_data(['profile', 'conn', rcv_uid]);

    if(uc)
      { // .. tato větev je tu nyní nadbytečná, protože do 'conn' se uživatel zařadí až po odeslání první zprávy
       if(uc.open_date)
          msg_loc_obj.aim = 'odate'; // označit, že píšeme kvůli open-date
       if(uc.open_chat)
          msg_loc_obj.aim = 'ochat'; // označit, že píšeme kvůli open-chat
      }
    else
      {
       if(db_get_data(['state', 'odate_match', rcv_uid]))
         {
          msg_loc_obj.aim = 'odate'; // označit, že píšeme kvůli open-date
          conn_data.open_date = true;
         }
       else if(db_get_data(['state', 'ochat_match', rcv_uid]))
         {
          msg_loc_obj.aim = 'ochat'; // označit, že píšeme kvůli open-chat
          conn_data.open_chat = true;
         }
      }
   }

 // Pokud jde o první zprávu po matchi, dotázat se, jestli je stále přípustné uživatele kontaktovat:
 if(msg_loc_obj.aim)
   {
    try
       {
        const user_manip = firebase_functions.httpsCallable('user_manip');

        console.log("test_msg_aim - invoking");

        const r = await user_manip({op: 'test_msg_aim', uid: rcv_uid, aim: msg_loc_obj.aim});
        const permit = r.data;

        console.log("test_msg_aim - result:", permit);

        if(!permit)
          {
           let nick = "";
           let gender;

           try
              {
               const other_profile = await get_user_profile_async(rcv_uid);
               nick = other_profile.nick_name;
               gender = (other_profile.sex === 2)|0;
              }
           catch(err)
              {
               console.error("db_send_message - get_user_profile_async", err);
              }

           let msg = t(msg_loc_obj.aim === 'odate' ? 'msg_od_unavail' : 'msg_oc_unavail', 
                       {peer_gender: gender}, nick);

           message_box({style: 'error',
                        msg,
                        btn:   [{title: t('btn_ok')}],
                        fn()   {remove_uid_from_url(rcv_uid);
                                remove_from_search_result(rcv_uid)}});

           return;
          }
       }
    catch(err)
       {
        console.error("db_send_message - test_msg_aim", err);
        db_set_data(msg_ref,
                    'set',
                    {m: msg,
                     $error: true,
                     t: ts});
        return;
       }
   }

 db_set_data(msg_ref, 'set', msg_loc_obj);

 db_update_conn(rcv_uid, false, conn_data);

 // Vytvořit data pro aktualizaci dokumentu databáze pro příjem zpráv:
 let msg_db_obj = {m: msg, 
                   t: firebase.firestore.FieldValue.serverTimestamp()}

 if(msg_loc_obj.aim)
    msg_db_obj.aim = msg_loc_obj.aim;

 let upd = {msg:{[msg_id]: msg_db_obj},
            ts: firebase.firestore.FieldValue.serverTimestamp()};
 
 // - připojit timestamp vlastního profilu:
 const profile = db_get_data(['profile']);
 if(profile)
   {
    if(profile.ts)
       upd.profile_ts = profile_normalize_value('tstamp', profile.ts, true);
   }
 else
   {
    console.error("db_send_message: profile missing");
   }

 // - zjistit jestli není naplánované odeslání notifikace o přečtení zprávy příjemce:
 let chng = storage_get('.chng');

 if(chng && chng.rd_mark && chng.rd_mark[rcv_uid])
   {
    // Zrušit naplánované odeslání notifikace:
    delete chng.rd_mark[rcv_uid]; 
    storage_set('.chng', chng);

    // Čas přečtení poslendí zprávy přidat do odchozích dat:
    const conn = profile.conn;
    if(conn && conn[rcv_uid] && conn[rcv_uid].last_read)
       upd.last_read = ms_to_db_time(conn[rcv_uid].last_read);
   }

 // - aktualizovat statistiky:
 const messages = db_get_data(['messages', rcv_uid]);
 const msg_in = messages.in;
 const msg_out = messages.out;

 const ml = msg.length; // message length
 const mll = Math.log10(ml);

 let stat = upd.stat = {};

 stat.n = firebase.firestore.FieldValue.increment(1); // celkový počet odeslaných zpráv

 stat.ml_sum   = firebase.firestore.FieldValue.increment(ml);
 stat.ml2_sum  = firebase.firestore.FieldValue.increment(ml*ml);
 stat.mll_sum  = firebase.firestore.FieldValue.increment(mll);
 stat.mll2_sum = firebase.firestore.FieldValue.increment(mll*mll);

 if(msg_in && msg_out)
   {
    const lm_in  = msg_in.last_msg;
    const lm_out = msg_out.last_msg;

    if(lm_in)
      {
       if(!lm_out) // první odpověď na zprávu
          stat.frt = ts - lm_in.t; // first reply time
       
       if(!lm_out || lm_in.t > lm_out.t)
         { // odpověď na zprávu protějšku (druhá část podmínky: jde o první odpověď na poslední doručenou zprávu)
          const rml = lm_in.m.length;
         
          const mlr = ml/rml; // message lenght ratio
          const mlrl = Math.log10(mlr);
         
          stat.mlr_n     = firebase.firestore.FieldValue.increment(1);
          stat.mlr_sum   = firebase.firestore.FieldValue.increment(mlr);
          stat.mlr2_sum  = firebase.firestore.FieldValue.increment(mlr*mlr);
          stat.mlrl_sum  = firebase.firestore.FieldValue.increment(mlrl);
          stat.mlrl2_sum = firebase.firestore.FieldValue.increment(mlrl*mlrl);
         
          const mrt = ts - lm_in.t; // message reply time
          if(mrt > 0)
            {
             const mrtl = Math.log10(mrt);
             
             stat.lrt = mrt; // last reply time

             stat.mrt_n     = firebase.firestore.FieldValue.increment(1);
             stat.mrt_sum   = firebase.firestore.FieldValue.increment(mrt);
             stat.mrt2_sum  = firebase.firestore.FieldValue.increment(mrt*mrt);
             stat.mrtl_sum  = firebase.firestore.FieldValue.increment(mrtl);
             stat.mrtl2_sum = firebase.firestore.FieldValue.increment(mrtl*mrtl);

             if(msg_in.ms_lrt)
               {
                const mrtr = mrt / msg_in.ms_lrt; // message reply time ratio
                const mrtrl = Math.log10(mrtr);

                stat.mrtr_n     = firebase.firestore.FieldValue.increment(1);
                stat.mrtr_sum   = firebase.firestore.FieldValue.increment(mrtr);
                stat.mrtr2_sum  = firebase.firestore.FieldValue.increment(mrtr*mrtr);
                stat.mrtrl_sum  = firebase.firestore.FieldValue.increment(mrtrl);
                stat.mrtrl2_sum = firebase.firestore.FieldValue.increment(mrtrl*mrtrl);
               }
            }
         }
      }
   }
 else
   {
    console.error("db_send_message: stat failed");
   }

 firestore.doc(`user/${rcv_uid}/inbox/${current_uid}`)
          .set(upd, {merge: true})
          .then(()  => 
               {
                db_set_data(msg_ref,
                            'set',
                            {m: msg,
                             t: Date.now()}); // přenastavit timestamp, protože čas při návratu se více blíží času serveru
               })
          .catch(err => 
                {
                 console.error("db_send_message", err);
                 db_set_data(msg_ref,
                             'set',
                             {m: msg,
                              $error: true,
                              t: ts});
                });
}


/*-------------------------------------------------------------------------------------------------
Odešle druhému uživateli informaci, že má znovu načíst náš profil.
(Voláno, když se změní typ vztahu s uživatelem a nedávno jsme s ním komunikovali)

Parametry:
  rcv_uid  .. UID příjemnce
  nf_id    .. id notifikace (nyní jen 'invp' pro invalidování profilu při změně typu vztahu)
-------------------------------------------------------------------------------------------------*/
master_def.send_user_notification = function(rcv_uid, nf_id)
{
 const msg_id = '#'+Date.now();

 // Vytvořit data pro aktualizaci dokumentu databáze pro příjem zpráv:
 let upd = {msg:{[msg_id]: {nf: nf_id, 
                            t: firebase.firestore.FieldValue.serverTimestamp()}
                 },
            ts: firebase.firestore.FieldValue.serverTimestamp()};

 firestore.doc(`user/${rcv_uid}/inbox/${current_uid}`)
          .set(upd, {merge: true})
          .catch(err => 
                {
                 console.error("send_user_notification", err);
                });

}