/*#################################################################################################

Položka seznamu uživatelů

#################################################################################################*/

import React from 'react';
import {useHistory,
        useLocation} from 'react-router-dom';
import {useUserProfile} from '../db/useUserProfile';
import {create_user_conn} from '../user';
import {UserIcon} from './UserIcon';
import {ImpressionIcon} from './ImpressionIcon';
import {ProfileMenu} from '../profile/ProfileMenu';
import {WaitIndicator} from '../lib/WaitIndicator';
import {useTranslationDef} from '../translations/useTranslationDef';
import './UserEntry.css';

/*-------------------------------------------------------------------------------------------------
props  conn     .. seznam všech propojených uživatelů (profile.conn)
       uid      .. uid uživatele
       selected .. true, pokud má mít zvýrazněné pozadí
       active   .. true, pokud má mít u ikony zelenou tečku (jako, že je aktivní)
       bold     .. true, pokud má být text tučně
       page_id  .. identifikátor stránky, kde je uzel zobrazen ("" = home, "chat")
       media    .. vlastnosti okna browseru; objekt s členy:
                   narrow .. okno je užší než 900px   
       on_click .. funkce vyvolaná při kliknutí na položku; 
                   parametrem je profile daného uživatele
-------------------------------------------------------------------------------------------------*/
export function UserEntry(props) 
{
 const uid = props.uid;
 const conn = props.conn;
 const profile = useUserProfile(uid, true);
 const uc = conn[uid] || create_user_conn(profile);
 const media = props.media;
 const narrow = media.narrow;
 const hover = media.hover;
 
 const init_state = 
   {
    show_menu: !hover // když zařízení neumí hover, zobrazit menu vždy
   }

 const url_history = useHistory();
 const location = useLocation();
 const [state, set_state] = React.useState(init_state);
 const cc_loc = useTranslationDef().country_names;
 
 // FIXME wait-indicator zobrazovat, když se profil skutečně načítá
 if(!uc || uc.nick_name===null || uc.nick_name===undefined)
    return <div className = "user-entry">
            <WaitIndicator size={16} dalay={0}/>
           </div>;

 let nick_name = uc.nick_name || "???";
 let full_name = uc.full_name || "";
 let sub1/* = uc.full_name || ""*/;
 let sub2 = "";
 
 if(uc.lom)
    sub2 = "← " + uc.lom;
 else if(uc.lim)
    sub2 = /*"→ " + */uc.lim;
 else if(profile)
   {
    const age = profile.birth_year &&  new Date().getFullYear() - profile.birth_year;
    const country = profile.country || profile.home_country;
    const city = profile.city || profile.home_city;
    let sep = false;

    sub2 = "";

    if(age)
      {
       sub2 += age;
       sep = true;
      }

    if(city)
      {
       if(sep) sub2 += ", ";
       sub2 += city;
       sep = true;
      }

    if(country)
      {
       if(sep) sub2 += ", ";
       sub2 += cc_loc[country];
      }
   }

 if(!sub1 && sub2)
   {
    sub1 = sub2;
    sub2 = "";
   }

/* if(uc.sex)
    sub1 = (profile.sex === 1 ? "♂ " : "♀ ") + sub1;*/


 return <div className   = {"user-entry" + (props.selected ? " selected" : "") + (props.bold ? " bold" : "")}
             onMouseEnter = {() => {if(hover && !state.show_menu) set_state({...state, show_menu: true})}}
             onMouseLeave = {() => {if(hover &&  state.show_menu) set_state({...state, show_menu: false})}}
             onClick     = {props.on_click && (() => props.on_click(profile))}>

          <UserIcon title  = {nick_name} 
                    uid    = {uid}
                    active = {props.active}/>

          <div className = "txt">
            <div className = "name">{nick_name}{full_name && <small> ({full_name})</small>}</div>
            {sub1 && <div className="sub">{sub1}</div>}
            {sub2 && <div className="sub">{sub2}</div>}
          </div>

          <div className="overlay">
           {state.show_menu && !!conn[uid] &&
            <ImpressionIcon uid         = {uid}
                            nick_name   = {nick_name}
                            page_id     = {props.page_id}
                            location    = {location}
                            url_history = {url_history}/>}
           
           <ProfileMenu conn        = {uc}
                        profile     = {profile}
                        page_id     = {props.page_id}
                        impress     = {!!conn[uid]}
                        height      = {14}
                        align_right = {narrow}
                        hidden      = {!state.show_menu}/>
          </div>
        </div>;
}
