/*#################################################################################################

Modul pro napojení na databázi serveru. Pokud je otevřeno více panelů aplikace, na server je 
napojen jen jeden (tzv. master; viz master.js). Voláni pro přístup k databáze na jiné
session než je master jsou předána masteru. Napojení na databázi se pojmově liší od přihlášení
uživatele v rámci Firebase. Přihlášeny jsou všechny instance. Aktuální uid přihlášeného uživatele
publikuje tento modul v proměnné current_uid (je zkratka za firebase.auth().currentUser, přístupná
rovněž ze všech modulů aplikace)

#################################################################################################*/

import firebase from 'firebase/app';
import {firestore,
        analytics} from '../config';
import {user_activity_init,
        note_user_activity,
        note_user_activity_global} from '../user_activity';
import {is_master,
        session_init,
        session_shutdown,
        session_id,
        master_def,
        master} from './master';
import {db_set_data,
        db_get_data,
        storage_get,
        schedule_write, 
        app_state_init} from './app_state';
import {db_on_own_profile_snapshot} from './own_profile';
import {user_state_login, 
        user_state_shutdown,
        user_state_master_changed} from './user_state';
import {set_msg_listener} from './messages';
import {t} from '../translations/translation';

console.log("LOAD db");

export let current_uid = null; // uid přihlášeného uživatele nebo null, pokud není přihlášen žádný
                               // (kopie hodnoty firebase.auth().currentUser)

let app_uid_callback = null; // komponenta App se uloží call-back na změnu uživatele
export const set_app_uid_callback = fn => app_uid_callback = fn;

let firebase_auth_unsubscribe = null; // funkce pro odhlášení onAuthStateChanged()
let db_connected = false;
let unsubscribe_profile_listener = null;
let unsubscribe_msg_listener = null;
let profile_loaded = false; // true po prvním načtení snapshotu vlastního profilu



/*-------------------------------------------------------------------------------------------------
Ukončení modulu. Vyvolána jednou při zavření stránky.
Pokud je master, oznámí ukončení smazáním informace z local-storage.
-------------------------------------------------------------------------------------------------*/
function db_shutdown()
{
 db_disconnect();
 session_shutdown();
}

/*-------------------------------------------------------------------------------------------------
Inicializace modulu. Vyvolána jednou po načtení stránky.
Vyvoláno z index.js po inicializace Firebase.
-------------------------------------------------------------------------------------------------*/
export function db_init()
{
 console.log("db_init");

 const auth = firebase.auth();
 current_uid = auth.currentUser && auth.currentUser.uid;
 firebase_auth_unsubscribe = auth.onAuthStateChanged(firebase_on_auth_change);
 window.addEventListener("beforeunload", db_shutdown);
 app_state_init();
 session_init(on_master_changed, on_app_shutdown);
 user_activity_init();
}

/*-------------------------------------------------------------------------------------------------
Call-back vyvolaný, když se zavírá poslední panel aplikace (tj. celá aplikace končí)
-------------------------------------------------------------------------------------------------*/
function on_app_shutdown()
{
 user_state_shutdown();
}

/*-------------------------------------------------------------------------------------------------
Call-back vyvolaný, když se tato session stala/přestala být masterem.
-------------------------------------------------------------------------------------------------*/
function on_master_changed(is_master)
{
 console.log("on_master_changed");

 if(is_master)
   { // jsme nový master
    if(current_uid)
       db_connect(firebase.auth().currentUser);

    note_user_activity_global();

    user_state_master_changed(true);
   }
 else
   { // přestali jsme být master
    user_state_master_changed(false);

    if(firebase_auth_unsubscribe) 
      {
       firebase_auth_unsubscribe();
       firebase_auth_unsubscribe = null;
      }

    db_disconnect();
   }
}

/*-------------------------------------------------------------------------------------------------
Call-back na změnu uživatele přihlášeného k Firebase. Při přihlášení se připojí k databázi
(tj. načte informace o uživateli a sleduje jeho změny), při odhlášení se odpojí.
-------------------------------------------------------------------------------------------------*/
function firebase_on_auth_change(firebase_user)
{
 console.log("firebase_on_auth_change");

 note_user_activity();

 if(firebase_user)
   {
    current_uid = firebase_user.uid;

    profile_loaded = false;

    if(!firebase_user.emailVerified)
      { 
       // Pokud není email verifikován, najít providera k hlavnímu emailu a otestovat,
       // že jde o mail a heslo. V tom případě vyžadovat verifikaci.
       // (Např. pro Facebook Login je emailVerified vždy false, ale mail verifikovat nechceme)
       const prov = firebase_user.providerData.find(p => p.email === firebase_user.email);

       if(prov && prov.providerId === 'password')
          db_set_data('state.mail_ver_pending', 'set', true);
      }
    
    if(is_master)
      {
       note_user_activity_global();
       db_connect(firebase_user);
       user_state_login(true);
      }
   }
 else
   {
    db_disconnect();
    current_uid = null; 
   }

 if(app_uid_callback)
    app_uid_callback(current_uid);
}


/*-------------------------------------------------------------------------------------------------
Připojí se k databázi (tj. načte informace o uživateli a sleduje jeho změny).
K databázi je připojen jen master.
-------------------------------------------------------------------------------------------------*/
function db_connect(firebase_user)
{
 if(db_connected)
    return;
 console.log("db_connect");

 db_connected = true;

 if(unsubscribe_profile_listener)
   {
    if(current_uid === firebase_user.uid)
       return;

    unsubscribe_profile_listener();
   }
 
 if(unsubscribe_msg_listener)
   {
    unsubscribe_msg_listener();
    unsubscribe_msg_listener = null;
   }

 console.log("db_connect: ", firebase_user.email);

 //-----------
 // Call-back na sledování změn vlastního profilu v databázi.
 const on_profile_snapshot = doc =>
    {
     db_on_own_profile_snapshot(doc);

     if(!profile_loaded)
       {
        profile_loaded = true;
        db_resume_pending_operations();                                                        
       }

     if(!unsubscribe_msg_listener)
        unsubscribe_msg_listener = set_msg_listener();
    }

 //-----------
 // Call-back na chybu sledování změn vlastního profilu v databázi.
 const on_profile_snapshot_error = error =>
    {
     console.error("db_on_own_profile_snapshot_error", error);
     db_set_data('state.fatal_error', 'set', {code: 1, msg: t('err_prof_rd')});
    }
    
 db_set_data('profile_db',
             'merge',
             {uid: firebase_user.uid,
              $loading: session_id}); 

 let db_user_ref = firestore.collection('user').doc(firebase_user.uid);

 unsubscribe_profile_listener = db_user_ref.onSnapshot(on_profile_snapshot,
                                                       on_profile_snapshot_error);
}

/*-------------------------------------------------------------------------------------------------
Odpojí napojení na databází vytvoření db_connect().
-------------------------------------------------------------------------------------------------*/
function db_disconnect()
{
 if(!db_connected)
    return;

 db_connected = false;
 
 if(unsubscribe_profile_listener)
   { // pokud je null, nejsme vůbec připojeni, nic nedělat
    console.log("db_disconnect");

    unsubscribe_profile_listener();
    unsubscribe_profile_listener = null;
   }

 if(unsubscribe_msg_listener)
   {
    unsubscribe_msg_listener();
    unsubscribe_msg_listener = null;
   }
}

/*-------------------------------------------------------------------------------------------------
Zkontroluje, zda v globálním stavu nejsou nějaké nedokončené operace od jiného mastera.
Pokud ano, znovu je provede.
(Může nastat, když operace nebyla dokončena při reloadu celé stránky)

Poznámka: přerušené načítání objektů se ignoruje - pokud budou potřeba, vyvolá se načtení
          znovu tehdy.
-------------------------------------------------------------------------------------------------*/
function db_resume_pending_operations()
{
 // Vyhledat neodeslané zprávy:
 for(let k in localStorage)
    {
     // Když má klíč tvar 'messages.<uid>
     let m = k.match(/^messages\.([^.$]+)$/);
     if(m)
       {
        const other_uid = m[1];
        const messages = db_get_data(['messages', other_uid]);
        if(messages)
          {
           const msg_out = messages.out;
           for(let key in msg_out)
              {
               if(key.charAt(0) === '#')
                 {
                  let m = msg_out[key];
                  if(m.$error || (m.$sending && m.$sending !== session_id))
                     master.db_send_message(other_uid, null, key);
                 }
              }
          }
       }
    }

 // Naplánovat uložení případných změn:
 let chng = storage_get('.chng');
 if(chng)
    schedule_write(chng);
}

/*-------------------------------------------------------------------------------------------------
Z dat profilu vytvoří objekt, která se předá operaci 'update' dokumentu databáze.
Členy, které jsou null převede na požadavek k vymazání
-------------------------------------------------------------------------------------------------*/
master_def.db_logout = function()
{ 
 console.log("db_logout");

 if(!current_uid)
    return;

 user_state_login(false);

 master.db_flush();

 analytics.logEvent('logout');

 firestore.waitForPendingWrites()
 .then(() => 
      {
       // Zde se ošetří pouze error, vlastní aktualizace stavu se realizuje autonomě 
       // v call-backu firebase_on_auth_change.
       firebase.auth().signOut();
      })
 .catch(function(error) 
      {
       // FIXME udělat něco s errorem
       /* user.error_code = error.code;
       user.error_msg = error.message;
       user.set_state(USER_PROC_LOGOUT_FAIL);*/
      });
}
