/*#################################################################################################

  Malá nástrojová lišta pod titulkem chatu. Obsahuje odkaz na profil a impresi.

#################################################################################################*/

import React from 'react';
import {useHistory,
        useLocation} from 'react-router-dom';
import {nav_view_user} from '../navigate';
import {open_impression_editor} from './ImpressionEditor';
import {useTranslation} from '../translations/useTranslation';
import './ChatMiniToolbar.css';

/*------------------------------------------------------------------------------------------------
props profile .. profil cílového uživatele
      page_id .. id stránky, kde je prvek umístěn
------------------------------------------------------------------------------------------------*/ 
export function ChatMiniToolbar(props) 
{
 const profile = props.profile;

 const url_history = useHistory();
 const location = useLocation();
 const t = useTranslation();

 return <div className = 'chat-mini-toolbar'>
         <div className = 'profile'
              onClick   = {() => nav_view_user(url_history, location.pathname, profile)}
              title     = {t('disp_prof_title', profile.nick_name)}>
          {t('chmtb_profile')}
         </div>
         <div className = 'impress'
              onClick   = {() => open_impression_editor(url_history, location, 
                                                        profile.uid, (props.page_id!=='odate')|0)}
              title     = {t('impress_title', profile.nick_name)}>
          {t('chmtb_impress')}
         </div>
        </div>;
}
