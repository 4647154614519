import React from 'react';
import {LoginBox} from './LoginBox';
import {html_gls} from './utils';
import {useTranslation} from './translations/useTranslation';
import girl1_img from './graphics/girl1-fajn-lidi.jpg';
import './LoginPage.css';

/*-------------------------------------------------------------------------------------------------
Úvodní (přihlašovací) obrazovka aplikace.
-------------------------------------------------------------------------------------------------*/
export function LoginPage(props) 
{
 const t = useTranslation();

 // Detekce in-app browseru Facebooku/Messengeru:
 //  user-agent: FB: FB_IAB/FB4A;FBAV/<verze>;
 //              Messenger: FB_IAB/Orca-Android;FBAV/<verze>
 // - https://stackoverflow.com/questions/31569518/how-to-detect-facebook-in-app-browser
 //   https://stackoverflow.com/questions/43746998/force-facebook-to-open-link-in-external-browser-for-smart-phones
 //   https://www.npmjs.com/package/detect-inapp
 const ua = navigator.userAgent || navigator.vendor || window.opera;
 const in_app = ua.indexOf("FBAN") > -1 || ua.indexOf("FBAV") > -1;

 if(in_app)
    return <div className = "login-page"><p>{html_gls(t('inapp_msg'))}</p></div>;


 return <div className = 'login-top'>
          <div className = "login-page">
           <div id="front">
             <LoginBox/>
             <img id="girl1" src = {girl1_img} alt = ""/>
             <div id="title">
               <div className="l1">{html_gls(t('intro_title.1'))}</div>
               <div className="l2">{html_gls(t('intro_title.2'))}</div>
               <div className="l3">{html_gls(t('intro_title.3'))}</div>
             </div>
           </div>
           <div className="two-columns">
               <div className="col1">
                   <h1>{t('intro_hiw_title')}</h1>
                   <ol>
                     <li>{html_gls(t('intro_hiw1'))}</li>
                     <li>{html_gls(t('intro_hiw2'))}</li>
                     <li>{html_gls(t('intro_hiw3'))}</li>
                     <li>{html_gls(t('intro_hiw4'))}</li>
                     <li>{html_gls(t('intro_hiw5'))}</li>
                     <li>{html_gls(t('intro_hiw6'))}</li>
                   </ol>
                   <h1>{t('intro_wg.title')}</h1>
                   <p>{t('intro_wg.1')}</p>
                   <p>{t('intro_wg.2')}</p>
                   <p>{t('intro_wg.3')}</p>
                   <p>{t('intro_wg.4')}</p>
                   <p>{t('intro_wg.5')}</p>
                   <p>{t('intro_wg.6')}</p>
               </div>
               <div className="col2">
                   <h1>{t('intro_wiw_title')}</h1>
                   <ol>
                     <li>{html_gls(t('intro_wiw1'))}</li>
                     <li>{html_gls(t('intro_wiw2'))}</li>
                     <li>{html_gls(t('intro_wiw3'))}</li>
                   </ol>
                   <div id='login2'>
                    <h1>{t('intro_login_now')}</h1>
                    <div className='sub'>{t('intro_login_free')}</div>
                    <LoginBox/>
                   </div>
                   <h1>{t('intro_pl.title')}</h1>
                   <ol>
                     <li>{html_gls(t('intro_pl1'))}</li>
                     <li>{html_gls(t('intro_pl2'))}</li>
                     <li>{html_gls(t('intro_pl3'))}</li>
                   </ol>
               </div>
           </div>
           <p><small>Chcete se na něco zeptat? Napište nám: <a href="mailto:info@glagoli.net">info@glagoli.net</a></small></p>
         </div>
        </div>;
}
