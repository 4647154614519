/*#################################################################################################

Kořenová komponenta celé aplikace. Renderovaná z index.js.

#################################################################################################*/

import React from 'react';
import 'firebase/auth';
import {Redirect,
        useLocation} from 'react-router-dom';
import {config} from './config';
import {LoginPage} from './LoginPage';
import {set_app_uid_callback} from './db/db';
import {db_get_data,
        db_remove_app_state} from './db/app_state';
import {useDbData} from './db/useDbData';
import {AppLoggedIn} from './AppLoggedIn';
import {get_default_language} from './utils';
import {TranslationContext,
        set_language} from './translations/translation';

export function Main() 
{
 const init_state = 
    {
     current_uid: false, // false = neinicializováno; null = uživatel nepřihlášen
                         // Informace o uživateli se zde ukládá do stavu přes call-back,
                         // protože call-back Firebase na změnu uživatele se vyvolá
                         // až po prvním renderování Reactu. Proto je potřeba,
                         // aby aplikace nerenderovala nic netriviálního, dokud není
                         // inicializovaná informace o přihlášeném uživateli.
     ui_lang: null,
     media: {narrow: false, // true, pokud je šířka okna < 900px
             hover: window.matchMedia("(hover: hover)").matches} // podporuje zařízení hover? (předpokládáme, že se tento stav nemění)
    };
 
 const [state, set_state] = React.useState(init_state);
 const location = useLocation();

 // Call-back na překročení šířky okna 900px
 const on_size_change = e =>
    {
     set_state(state => {return {...state, media: {...state.media, narrow: e.matches}}});
    }
                 
 React.useEffect(() =>
                { 
                 // Nastavit call-back na změnu parametrů okna browseru:
                 const query_size = window.matchMedia("(max-width: 899px)");

                 on_size_change(query_size); // vyvolat iniciální nastavení stavu (vyžaduje se, aby parametr měl pouze metodu 'matches') 

                 query_size.addEventListener("change", on_size_change);

                 // nastavit call-back na změnu přihlášeného uživatele
                 set_app_uid_callback(uid => 
                                     {
                                      let ui_lang;

                                      if(uid)
                                        { // zkontrolovat, jestli data v localStorage 
                                          // nepatří jinému uživateli
                                         const profile = db_get_data(['profile']);

                                         if(profile && profile.uid)
                                           {
                                            if(profile.uid !== uid)
                                               db_remove_app_state();
                                            else
                                               ui_lang = profile.ui_lang;
                                           }
                                        }
                                       
                                      if(!ui_lang)
                                         ui_lang = get_default_language();

                                      set_state(state => set_state({...state, 
                                                                    current_uid: uid,
                                                                    ui_lang: ui_lang}));
                                     });

                 return () => 
                        {
                         query_size.removeListener(on_size_change);
                         set_app_uid_callback(null);
                        }
                }, []);

 const sex = useDbData(['profile', 'sex']);

 React.useEffect(() =>
                {
                 if(state.current_uid === null)
                   { // pokud nejsme přihlášení a nejde o inicializaci stavu, odstranit 
                     // data uživatele, pokud zůstala v profilu
                    if(localStorage.getItem('profile_db'))
                       db_remove_app_state();
                   }
                });



 if(!state || state.current_uid === false)
    return null; // Před vyvolání call-backu pro inicializaci přihlášení uživatele

 if(!state.current_uid && location.pathname !== '/')
    return <Redirect to="/" />;

 let content;

 if(!state.current_uid)
    content = <LoginPage media = {state.media}/>; // Nepřihlášený uživatel
 else 
    content = <AppLoggedIn media = {state.media}/>;  // Přihlášený uživatel

 // Pokud je úzké zobrazení (typicky mobil), dát nad celý layout prven s id='narrow'.
 // V CSS se pak úzké zobrazení styluje pravidly začínajícími #narrow 
 // (je to jednodušší než vytvářet obálku @media, navíc lze takto centrálně změnit definici
 //  úzkého zobrazeni). Na několika místech v CSS se nicméně používá @media.
 if(state.media.narrow) 
    content = <div id = 'narrow'>{content}</div>;

 return <TranslationContext.Provider value = {set_language(state.ui_lang, 
                                                           {gender: sex && +(sex===2)})}>
         {content}
         <div id='version-info'>{config.app_version}</div>
        </TranslationContext.Provider>;
}

