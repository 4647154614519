/*#################################################################################################

Funkce pro přístup k vlastnímu profilu v databázi a lokální kopii ve stavu aplikace.

#################################################################################################*/

import firebase from 'firebase/app';
import {firestore,
        firebase_functions} from '../config';
import {object_assign,
        object_merge,
        async_wait} from '../utils';
import {empty_profile,
        normalize_profile,
        profile_default_access,
        profile_normalize_value,
        create_user_conn,
        norm_time_to_ms,
        db_time_to_ms,
        ms_to_db_time,
        get_xp,
        profile_xp_diff,
        remove_from_search_result} from '../user';
import {master_def,
        master} from './master';
import {current_uid} from './db';
import {db_set_data,
        db_get_data,
        storage_set,
        storage_get} from './app_state';
import {get_user_profile_async} from './other_users';
import {profile_images_update_access} from './images';
import {t} from '../translations/translation';
import lang_nat from '../translations/lists/lang_nat';
import cc_en from '../translations/lists//cc_en';
const {profile_def,
       ACCESS_PRIVATE,
       CONN_REL_BASE,
       CONN_REL_FAVORITE,
       CONN_REL_OPEN_DATE,
       CONN_REL_OPEN_CHAT,
       CONN_REL_REMOVED,
       CONN_REL_BLOCKED,
       CONN_REL_BLOCKING} = require('../profile_def.js');

console.log("LOAD own_profile");

/*-------------------------------------------------------------------------------------------------
Vyvolá na serveru funkci 'user_manip(op: init_user)' (ohlášení registrace nového uživatele).
Funkce se zkouší vyvolat opakovaně, protože se stalo, že při prvním vyvolání selhala.
-------------------------------------------------------------------------------------------------*/
async function call_init_user(firebase_user)
{
 const user_manip = firebase_functions.httpsCallable('user_manip');
 const COUNT = 5;

 for(let i = 0; i < COUNT; i++)
    {
     try
        {
         await user_manip({op: 'init_user', email: firebase_user.email})
         break;
        }
     catch(err)
        {
         if(i < COUNT-1)
            console.warn("init_user failed - retrying", err);
         else
            console.error("init_user failed", err);
        }

     await async_wait((1<<i)*500);
    }
}

/*-------------------------------------------------------------------------------------------------
Funkce vyvolaná při změně vlastního profilu v databázi. Vyvolá se také vždy okamžitě po připojení
k databázi. Načte aktuální obsah profilu nebo vytvoří nový, pokud ještě v databázi neexistuje.
-------------------------------------------------------------------------------------------------*/
export async function db_on_own_profile_snapshot(doc)
{
 console.log("db_on_own_profile_snapshot");

 if(!doc.exists)
   {// profil ještě neexistuje, zapsat defaultní
    const firebase_user = firebase.auth().currentUser;

    // Stalo se, že se existující profil přepsal. Tak tu pro jistotu otestujeme znovu, že neexistuje:
    // TODO: jde to udělat nějak lépe?
    try
       {
        const doc_ss = await firestore.doc(`user/${current_uid}`).get();
        if(doc_ss.exists)
           return db_on_own_profile_snapshot(doc_ss);
       }
    catch(err)
       {
        // FIXME ošetřit chyby
        console.error(err);
        master.db_logout();
        return;
       }

    // Oznámit vytvoření nového uživatele na server:
    call_init_user(firebase_user);

    // Inicializovat profil:
    const new_profile = create_new_profile(firebase_user);
    db_set_data('profile_changes', 'set', new_profile);

    const profile_db = create_new_profile(firebase_user);
    const db_doc = profile_to_db_set(profile_db);
    db_doc.data.created = firebase.firestore.FieldValue.serverTimestamp();

    // Zapsat nový profil. (Nastavit merge pro napravděpodobný případ, že by se sešly dva zápisy z různých zdrojů):
    // Při zapsání se call-back sám vyvolá znovu se zapsaným profilem.
    doc.ref.set(db_doc, {merge: true})
    .catch(error => 
          {
           console.error("db_on_own_profile_snapshot", error);
           db_set_data('state.fatal_error', 'set', {code: 0, msg: t('err_prof_create')});
          });

    return;
   }

 const profile_db = profile_from_db(doc.data(), doc.id);

 // Pokud byl uživatel v databázi vyhozen z conn, vyhodit ho případně i z lokálních změn v conn,
 // protože jde o blokaci uživatele a nesmí být zachován v propojeních:
 const prev_profile_db = db_get_data('profile_db');
 if(prev_profile_db && prev_profile_db.conn && profile_db && profile_db.conn)
   {
    let profile_changes = db_get_data('profile_changes'); // db_get_data na 'profile_changes' musí brát celý objekt
    let pc_conn = profile_changes && profile_changes.conn;

    if(pc_conn)
      {
       let chng = false;

       for(const uid in pc_conn)
          {
           if(prev_profile_db.conn[uid] && !profile_db.conn[uid])
             {
              delete pc_conn[uid];
              chng = true;
             }
          }

       if(chng) db_set_data('profile_changes', 'set', profile_changes);
      }
   }

 db_set_data('profile_db', 'set', profile_db);
}

/*-------------------------------------------------------------------------------------------------
Vytvoří nový profil inicializovaný položkami autorizačních údajů z Firebase-auth.
-------------------------------------------------------------------------------------------------*/
function create_new_profile(firebase_user)
{
 let profile = empty_profile();
 profile.primary_mail = firebase_user.email;
 profile.nick_name = firebase_user.displayName || firebase_user.email;
 profile.days_active = 1;

 // jazyk a stát nastavit podle browseru:
 const nav_lang = navigator.language || navigator.userLanguage || "en";
 const lp = nav_lang.match(/^(..)(-(..))?/)
 const lang = lp[1].toLowerCase();
 const cc = lp[3] ? lp[3].toLowerCase() : null;

 if(lang_nat[lang])
    profile.languages = {[lang]: 0}; // jazyk browseru označit jako výchozí jazyk (mateřština)

 profile.ui_lang = (lang === 'cs' || lang === 'sk') ? 'cs' : 'en';

 if(cc_en[cc])
    profile.home_country = profile.home_country = cc;

 return profile;
}

// Definiční tabulka pro převod seznamu conn z formátu databáze do formátu aplikace
const conn_from_db_def = {r:   {fn: (src, dst) =>
                                    {
                                     dst.rel = src.r&CONN_REL_BASE;
                                     dst.favorite = !!(src.r&CONN_REL_FAVORITE);
                                     dst.open_date = !!(src.r&CONN_REL_OPEN_DATE);
                                     dst.open_chat = !!(src.r&CONN_REL_OPEN_CHAT);
                                    }},
                          c:   {id: "create_time", fn: db_time_to_ms},
                          e:   {id: "engage_time", fn: db_time_to_ms},
                          m:   {id: "msg_time",    fn: db_time_to_ms},
                          lr:  {id: "last_read",   fn: db_time_to_ms},
                          x:   {id: "remove_time", fn: db_time_to_ms},
                          nk:  {id: "nick_name"},
                          fn:  {id: "full_name"},
                          sx:  {id: "sex"},
                          lom: {id: "lom"},
                          lim: {id: "lim"},
                          ic:  {id: "ic"},
                          ip:  {id: "ip"},
                          pts: {id: "profile_ts", fn: db_time_to_ms},
                          uts: {id: "update_ts",  fn: db_time_to_ms}};

// Definiční tabulka pro převod seznamu conn do formátu databáze do formátu aplikace
const conn_to_db_def = {rel:         {fn: (src, dst) => dst.r |= src.rel},
                        favorite:    {fn: (src, dst) => {if(src.favorite) dst.r |= CONN_REL_FAVORITE}},
                        open_date:   {fn: (src, dst) => {if(src.open_date) dst.r |= CONN_REL_OPEN_DATE}},
                        open_chat:   {fn: (src, dst) => {if(src.open_chat) dst.r |= CONN_REL_OPEN_CHAT}},
                        create_time: {id: "c",  fn: ms_to_db_time},
                        engage_time: {id: "e",  fn: ms_to_db_time},
                        msg_time:    {id: "m",  fn: ms_to_db_time},
                        last_read:   {id: "lr", fn: ms_to_db_time},
                        remove_time: {id: "x",  fn: ms_to_db_time},
                        nick_name:   {id: "nk"},
                        full_name:   {id: "fn"},
                        sex:         {id: "sx"},
                        lom:         {id: "lom"},
                        lim:         {id: "lim"},
                        ic:          {id: "ic"},
                        ip:          {id: "ip"},
                        profile_ts:  {id: "pts", fn: ms_to_db_time},
                        update_ts:   {id: "uts", fn: ms_to_db_time}};


/*-------------------------------------------------------------------------------------------------
Převede seznam propojení z/do formátu databáze do vnitřního formátu aplikace
Parametry:
 conn_list  .. seznam propojení
 to_db      .. true, pokud se převádí z aplikace do databáze, false naopak
-------------------------------------------------------------------------------------------------*/
function conn_db_conv(conn_list, to_db)
{
 let conn_r = {};

 if(conn_list)
   {
    const conv_def = to_db ? conn_to_db_def : conn_from_db_def;
    for(let uid in conn_list)
       { // smyčka přes propojené uživatele
        const cdb = conn_list[uid];

        if(cdb.r === CONN_REL_BLOCKING)
           continue; // informace o blokujících uživatelích odstranit, potřebujících se jen na serveru 

        let capp = {};
        for(let k in conv_def)
           { // smyčka přes prvky záznamu jednoho uživatele
            const df = conv_def[k];
            if(df.id)
              {
               if(cdb[k] !== undefined)
                  capp[df.id] = df.fn ? df.fn(cdb[k]) : cdb[k];
              }
            else
               df.fn(cdb, capp);
           }
        conn_r[uid] = capp;
       }
   }

 return conn_r;
}

/*-------------------------------------------------------------------------------------------------
Převede položku profilu 'cache' z/do formátu databáze.
-------------------------------------------------------------------------------------------------*/
function profile_cache_conv(val, to_db)
{
 if(!val) return val;

 if(val.mrt_limit) 
    val.mrt_limit = profile_normalize_value('tstamp', val.mrt_limit, to_db);

 if(to_db && val.unread_msgs)
   {
    for(const uid in val.unread_msgs)
       {
        if(!val.unread_msgs[uid])
           val.unread_msgs[uid] = firebase.firestore.FieldValue.delete();
       }
   }

 return val;
}

/*-------------------------------------------------------------------------------------------------
Konvertuje snapshot profilu z databáze na formát profilu užitý v aplikaci.
Parmetr profile je profil ve formátu databáze (snapshot.data()). Funkce ho změní na místě a 
zároveň ho vrátí.
-------------------------------------------------------------------------------------------------*/
export function profile_from_db(profile, uid)
{
 if('data' in profile)
   {
    profile = Object.assign(profile, profile.data);
    delete profile.data;
   }
 
 if(uid)
    profile.uid = uid;

 // doplnit defaultní přístupová práva:
 let acc = profile_default_access();  
 object_assign(acc, profile.access);
 profile.access = acc;

 // Konvertovat seznam propojení do formátu aplikace:
 profile.conn = conn_db_conv(profile.conn, false);
 profile.cache = profile_cache_conv(profile.cache, false);

 // normalizovat typy hodnot:
 const keys = Object.keys(profile);
 for(let i = 0; i < keys.length; i++)
    {
     const key = keys[i];
     let val = profile[key];
     const pdef = profile_def[key];
     if(pdef)
        profile[key] = profile_normalize_value(pdef, val, false);
    }

 return profile;
}


/*-------------------------------------------------------------------------------------------------
Z dat profilu vytvoří objekt, která se předá operaci 'set' dokumentu databáze.
Tj. předpokládá se, že se starý stav dokumentu v databázi kompletně přepíše novým.
-------------------------------------------------------------------------------------------------*/
function profile_to_db_set(profile)
{
 let r = {data: {}};

 const keys = Object.keys(profile);
 for(let i = 0; i < keys.length; i++)
    {
     const key = keys[i];
     let val = profile[key];

     if(key === 'access')
       {
        let acc = {};
        for(const [ak, av] of Object.entries(val))
           {
            if(av !== ACCESS_PRIVATE && 
               profile_def[ak] && !profile_def[ak].acc_fix && /* položce se dají měnit přístupová práva */
               profile[ak] !== undefined && profile[ak] !== null/* položka musí být v profilu přítomna */)
              {
               acc[ak] = av;   
              }
           }
        r.access = acc;
       }
     else if(key === 'conn')
       {
        r.data.conn = conn_db_conv(profile.conn, true);
       }
     else if(key === 'cache')
       {
        r.data.conn = profile_cache_conv(profile.cache, true);
       }
     else 
       {
        const pdef = profile_def[key]

        if(!pdef)
          { 
           console.error(`Unknown profile member ${key}`);
           continue; // členy neodpovídající definici zahazovat
          }

        if(pdef.nostore)
           continue;
        
        const v = profile_normalize_value(pdef, val, true);

        if(v !== null)
          {
           if(pdef.index)
              r[key] = v;
           else
              r.data[key] = v;
          }
       }
    }

 r.mdts = firebase.firestore.FieldValue.serverTimestamp();
 r.ts = firebase.firestore.FieldValue.serverTimestamp();

 return r;
}

/*-------------------------------------------------------------------------------------------------
Rozpouzdří obsaho objektu obj do dest tak, že bude obsahovat rekursivně všechny složky obj
pojmenované plnými referencemi. 
Parametry:
  dest .. cílový objekt
  ref  .. kořenová reference. Pokud není prázdná musí kounčit tečkou.
  obj  .. objekt, který se má rozpouzdřit. Složky null nahradí FieldValue.delete()
          podobjekty, které mají složky jako čas konvertuje na firestore.Timestamp
-------------------------------------------------------------------------------------------------*/
function expand_object(dest, ref, obj)
{
 for(const k in obj)
    {
     const v = obj[k];
     if(v === null)
       {
        dest[ref+k] = firebase.firestore.FieldValue.delete();
       }
     else if(typeof v === 'object' && !(v instanceof firebase.firestore.Timestamp) && !Array.isArray(v))
       {
        if(typeof v.sec === 'number')
           dest[ref+k] = profile_normalize_value('tstamp', v, true);
        else
           expand_object(dest, ref+k+'.', v);
       }
     else
       {
        dest[ref+k] = v;
       }
    }
}

/*-------------------------------------------------------------------------------------------------
Z dat profilu vytvoří objekt, která se předá operaci 'update' dokumentu databáze.
Členy, které jsou null převede na požadavek k vymazání
-------------------------------------------------------------------------------------------------*/
function profile_to_db_update(profile_changes)
{
 let r = {};

 const keys = Object.keys(profile_changes);
 for(let i = 0; i < keys.length; i++)
    {
     const key = keys[i];
     let val = profile_changes[key];

     const pdef = profile_def[key]
     if(!pdef)
       { 
        console.error(`Unknown profile member ${key}`);
        continue; // členy neodpovídající definici zahazovat
       }

     if(pdef.nostore)
        continue;
     
     // Pokud se mění údaje, ovlidňující matchování, aktualizovat timestamp matchovacích dat:
     if(pdef.match && !r.mdts)
        r.mdts = firebase.firestore.FieldValue.serverTimestamp();

     let ref = pdef.index || key==="access" ? key : "data."+key;

     if(pdef.type === "map")
       {
        ref += ".";

        if(key === "conn")
          {
           val = conn_db_conv(val, true);
          }

        expand_object(r, ref, val);
       }
     else
       {
        val = profile_normalize_value(pdef, val, true);
        r[ref] = val === null ? firebase.firestore.FieldValue.delete()  : val;
       }
    }

 r.ts = firebase.firestore.FieldValue.serverTimestamp();

 return r;
}

let profile_write_pending = false;

/*-------------------------------------------------------------------------------------------------
Uloží změny ve vlastním profilu do databáze.
-------------------------------------------------------------------------------------------------*/
master_def.response.db_write_own_profile = true;
master_def.db_write_own_profile = function()
{
 if(profile_write_pending)
    return Promise.resolve(null);

 console.log("db_write_own_profile");
 let profile_changes = db_get_data('profile_changes');

 if(profile_changes === null)
    return Promise.resolve(null);

 let profile_changes_back = {...profile_changes};

 let profile_db = db_get_data('profile_db');
 if(!profile_db)
    return Promise.resolve(null);

 // Nastavit user_state na 0 (běžný), pokud není nastaven:
 if(profile_changes.user_state === undefined && profile_db.user_state === undefined)
    profile_changes.user_state = 0;

 // seznam uživatelů, jimž se má zaslat notifikace o invalidování profilu (kvůli změně vztahu):
 let invp_list = [];
 if(profile_changes.conn)
   {
    for(const uid in profile_changes.conn)
       {
        const uc = profile_changes.conn[uid];
        if(typeof uc.rel === 'number')
           invp_list.push(uid);
       }
   }

 // aktualizovat členy 'last_active' a 'days_active':
 let last_active = db_get_data(['state', 'last_active']);

 if(last_active)
   {
    let prev_last_active = profile_db.last_active;

    if(prev_last_active)
      {
       const pla = new Date(norm_time_to_ms(prev_last_active));
       const la = new Date(last_active); 
       if(pla.getFullYear() !== la.getFullYear() ||
          pla.getMonth() !== la.getMonth() ||
          pla.getDate() !== la.getDate())
         {
          if(profile_db.days_active)
             profile_changes.days_active = profile_db.days_active + 1;
          else
             profile_changes.days_active = 1;
         }
      }

    profile_changes.last_active = ms_to_db_time(last_active);
   }

 // aktualizovat XP:
 let rxp_ts_upd = false;
 let nxp_ts_upd = false;

 const pxpd = profile_xp_diff(profile_db, profile_changes);
 const rxpi = (profile_changes.rxpi||0) + pxpd;
 const nxpi = (profile_changes.nxpi||0) + pxpd;

 if(rxpi)
   {
    profile_changes.real_xp = Math.max(0, get_xp(profile_db, 'real_xp', false) + rxpi);
    rxp_ts_upd = true;
   }

 if(nxpi)
   {
    profile_changes.net_xp = Math.max(0, get_xp(profile_db, 'net_xp', false) + nxpi);
    nxp_ts_upd = true;
   }

 delete profile_changes.rxpi;
 delete profile_changes.nxpi;

 // Vyvolat požadované blokace:
 if(profile_changes.conn)
   {
    const conn = profile_changes.conn;
    for(const uid in conn)
       {
        const uc = conn[uid];
        if(uc.rel === CONN_REL_BLOCKED)
          {
           const block_user = firebase_functions.httpsCallable('block_user');
           block_user({block: true, block_uid: uid})
           .catch(err => console.error("block_user", err));
           // FIXME chyba se ignoruje; místo toho dát do profilu seznam uživatelů čekajících na zablokování
           //       a zkoušet to při každém uložení profilu
          }
       }
   }

 //---------------
 
 let profile = object_merge(profile_db, profile_changes, profile_def);

 normalize_profile(profile, profile_changes);

 db_set_data('profile_db',      'set', profile);
 db_set_data('profile_changes', 'set', null);

 let uid = profile_db.uid;

 let db_user_ref = firestore.collection('user').doc(uid);

 const db_doc = profile_to_db_update(profile_changes);

 if(rxp_ts_upd)
    db_doc.rxp_ts = firebase.firestore.FieldValue.serverTimestamp();

 if(nxp_ts_upd)
    db_doc.nxp_ts = firebase.firestore.FieldValue.serverTimestamp();

 profile_write_pending = true;

 return db_user_ref.update(db_doc)
        .then(() => 
             {
                 //FIXME až bude uživatelů víc, odstranit aktualizaci indexu zde a nechat pouze pravidelnou každodenní aktualizaci
                 if(db_doc.mdts)
                   {
                    const update_user_index = firebase_functions.httpsCallable('update_user_index');
                    update_user_index({force: true/*vynutit vytvoření, pokud neexistuje*/})
                    .catch(err => console.error("update_user_index", err));
                    // na výsledek nečekat
                   }


              profile_write_pending = false;
       
              let chng = storage_get('.chng');
              if(chng)
                {
                 delete chng.profile;
                 delete chng.profile_aux;
                 delete chng.profile_imm;
                 storage_set('.chng', chng);
                }

              invp_list.forEach(uid =>  master.send_user_notification(uid, 'invp')); // notifikace o invalidování profilu
             })
        .catch(error => 
              {
               profile_write_pending = false;
       
               db_set_data('profile_changes', 'set', profile_changes_back);
               db_set_data('profile_db',      'set', profile_db);
       
               console.error("db_write_own_profile error", error, uid);
               db_set_data("state.fatal_error", "set", {code: 2, msg: t('err_prof_wr')});
              });
}

/*-------------------------------------------------------------------------------------------------
Aktualizuje záznam o propojeném uživateli. Provádí tyto operace:
1. Aktulizuje čas zobrazení profilu, pokud je to žádoucí
2. Aktualizuje cachované informace o uživateli
3. Změní další předané položky.

Paramtery:
  other_profile .. profil uživatele s nímž se má aktualizovat propojení
                   může jít pouze o uid, potom bude vyvoláno načtení profilu 
                   a funkce budu znovu zavolána asynchronně (potom vrací Promise).
  displayed     .. profil byl zobrazen, má se aktualizovat 'engage_time',
                   pokud je to nutné
  value         .. další hodnoty, které se mají nastavit
-------------------------------------------------------------------------------------------------*/
export function db_update_conn(other_profile, displayed, value)
{
 if(typeof other_profile === 'string')
   {
    return get_user_profile_async(other_profile)
           .then(profile => db_update_conn(profile, displayed, value));
   }

 console.log("db_update_conn ", other_profile.uid, displayed, value);

// FIXME ..asi chceme zobrazit a propojit i uživatele bez správně načteného profilu.
//         Ve view profilu by měl být error indicator.
// if(other_profile.$error)
//   {
//    console.log("db_update_conn profile error", other_profile);
//    return;
//   }

 const uid = other_profile.uid;

 if(!uid || uid === current_uid || !current_uid)
    return;

 let profile = db_get_data('profile');

 let conn = profile.conn ? profile.conn[uid] : null;

 if(!conn)
   {
    if(!value)
      {
       if(!displayed)
          return; // nepřidávat záznam, pokud neexistuje a pouze se načetl (nezobrazil, nemá se měnit)
       
       const state = db_get_data('state');

       if(state && 
          ((state.odate_match && state.odate_match[uid]) || 
           (state.ochat_match && state.ochat_match[uid])))
         {
          return; // pokud je není zadána hodnota (tj. neposílá se zpráva), výsledky hledání
                  // nezařazovat do spojení
         }
      }

    conn = {};

    // Pokud jde o nové propojení, vyhodit daného uživatele z výsledků hledání:
    remove_from_search_result(uid);
   }
 else
   { // pokud se změnil typ vztahu, aktualizovat přístupová práva k obrázkům pro daného uživatele:
    if(value && typeof value.rel === 'number' && typeof conn.rel === 'number')
      {
       const rel_new = value.rel & CONN_REL_BASE;
       const rel_old = conn.rel & CONN_REL_BASE;
       if(rel_new !== rel_old)
          profile_images_update_access(profile, uid, rel_new, rel_old);
      }
   }

 if(displayed && !conn.create_time && !conn.msg_time && !conn.remove_time)
   {
    const ts = Date.now();
    if(!conn.engage_time || ts - conn.engage_time > 60000)
      { // změnu kratší než minuta nezaznamenávat kvůli optimalizaci
       conn.engage_time = ts;
      }
   }

 const upd_conn = create_user_conn(other_profile, true);

 if(!upd_conn)
    return; // profil druhéh uživatele byl zrušen

 if(conn.nick_name !== upd_conn.nick_name || 
    conn.sex       !== upd_conn.sex ||
    conn.full_name !== upd_conn.full_name)
   {
    object_assign(conn, upd_conn);
   }

 if(value)
   {
    if(value.msg_time)
      {
       if(!conn.msg_time || value.msg_time > conn.msg_time)
         {
          conn.msg_time = value.msg_time;
          if(value.lim)
            {  
             conn.lim = value.lim;
             conn.lom = null;
            }
          else
            {
             conn.lom = value.lom;
             conn.lim = null;
            }
         }

       if(conn.engage_time)
          conn.engage_time = null;
       
       if(!conn.create_time)
          conn.create_time = value.msg_time;

       // Smazat informace z objektu value, protože mohou být zastíněny
       // infomací o později odeslané zprávě (viz výše), takže se nemají
       // přiřadit do nového stavu:
       delete value.lim;
       delete value.lom;
       delete value.msg_time;
      }

    object_assign(conn, value)

    if(value.rel === CONN_REL_REMOVED || value.rel === CONN_REL_BLOCKED)
      { // nastavit čas odstranění a zároveň odstranit zbytečná data:
       conn.remove_time = Date.now();
       conn.favorite    = false;
       conn.engage_time = null;
       conn.msg_time    = null;
       conn.full_name   = null;
       conn.sex         = null;
       conn.lom         = null;
       conn.lim         = null;
       conn.ic          = null;
       conn.ip          = null;
       conn.profile_ts  = null;
       conn.update_ts   = null;
      }
   }

 db_set_data(['profile', 'conn', uid], 'set', conn);
}
