/*#################################################################################################

Jedna položka dojmu z uživatele

#################################################################################################*/

import React from 'react';
import {AccControl} from '../controls/AccControl'
import {Slider} from '../controls/Slider';
const {ACCESS_PRIVATE} = require('../profile_def.js');

/*-------------------------------------------------------------------------------------------------
props  data_id .. id editované položky
       data    .. aktuální stav
       setter  .. parametry: (hodnota, data_id)
-------------------------------------------------------------------------------------------------*/
export function ImpressionItem(props) 
{
 const data_id = props.data_id;

 let c = props.data.charCodeAt(data_id);

 if(c === 0)
    c = false; 
 else
    c -= 51;

 return <div className = "pr-q-item acc-private-bg" 
             id={data_id}>
              {props.acc_icon  && 
               <AccControl value  = {ACCESS_PRIVATE} />}

               <Slider data_id      = {data_id}
                       value        = {c}
                       min          = {-50}
                       max          = {50}
                       red_min      = {-38}
                       red_max      = {38}
                       step         = {1}
                       width        = {530}
                       text_bold    = {props.text_bold}
                       text_main    = {props.text_main}
                       text_min     = {props.text_min}
                       text_zero    = {props.text_zero}
                       text_max     = {props.text_max}
                       text_red_min = {props.text_red_min}
                       text_red_max = {props.text_red_max}
                       ncount       = {7}
                       setter       = {props.setter}
                     />
        </div>;
}
