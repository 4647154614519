/*#################################################################################################

Stromeček se seznamy uživatelů pro domovskou stránku a chat.

#################################################################################################*/

import React from 'react';
import {TreeItem} from './TreeItem';
import {UserList} from './UserList';
import {useTranslation} from '../translations/useTranslation';

/*-------------------------------------------------------------------------------------------------
Z dané skupiny uživatel odfiltruje ty podle odate_filter.
-------------------------------------------------------------------------------------------------*/
function filter_odate(conn, odate_filter, group)
{
 if(odate_filter === undefined)
    return group;
 
 return group.filter(e => !!conn[e].open_date === odate_filter);
}

/*-------------------------------------------------------------------------------------------------
props  conn          .. seznam všech propojených uživatelů (profile.conn)
       conn_groups   .. seskupené seznamy propojených uživatelů (viz App.js, conn_groups)
       selected      .. uid uživatele, který má být zvýrazněn
       peer_state    .. objekt s informacemi o aktivitě ostatních uživatelů
       page_id       .. identifikátor stránky, kde je uzel zobrazen ("" = home, "chat")
       click_on_user .. funkce vyvolaná při kliknutí na položku uživatele
       media         .. vlastnosti okna browseru; objekt s členy:
                        narrow .. okno je užší než 900px   
-------------------------------------------------------------------------------------------------*/
export function UserTree(props) 
{
 const t = useTranslation();

 const conn = props.conn;
 const conn_groups = props.conn_groups;
 const selected_user = props.selected;
 const peer_state = props.peer_state;
 const page_id = props.page_id;
 const bold_users = props.conn_groups.unread_msgs;
 const media = props.media;
 const click_on_user = props.click_on_user

 let open_date;

 if(page_id === 'chat')
    open_date = false;

 if(page_id === 'odate')
    open_date = true;

 let gr_favorite = filter_odate(conn, open_date, conn_groups.favorite);
 let gr_friend   = filter_odate(conn, open_date, conn_groups.friend);
 let gr_acq      = filter_odate(conn, open_date, conn_groups.acq);
 let gr_other    = filter_odate(conn, open_date, conn_groups.other);

 // Příznak, že existují i jiné skupiny uživatelů, než základní:
 const gr = gr_favorite.length > 0 ||
            gr_friend.length > 0 ||
            gr_acq.length > 0;

 return <>
          {gr_favorite.length > 0 && 
           <TreeItem title   = {t('userg_fav')}
                     bold    = {true}
                     subtree = {<UserList conn        = {conn}
                                          users       = {gr_favorite}
                                          selected    = {selected_user}
                                          peer_state  = {peer_state}
                                          bold        = {bold_users}
                                          page_id     = {page_id}
                                          media       = {media}
                                          on_click    = {click_on_user}/>}
            />}
          {gr_friend.length > 0 && 
           <TreeItem title   = {t('userg_friend')}
                     bold    = {true}
                     subtree = {<UserList conn        = {conn}
                                          users       = {gr_friend}
                                          selected    = {selected_user}
                                          peer_state  = {peer_state}
                                          bold        = {bold_users}
                                          page_id     = {page_id}
                                          media       = {media}
                                          on_click    = {click_on_user}/>}
            />}
          {gr_acq.length > 0 && 
           <TreeItem title   = {t('userg_acq')}
                     bold    = {true}
                     subtree = {<UserList conn        = {conn}
                                          users       = {gr_acq}
                                          selected    = {selected_user}
                                          peer_state  = {peer_state}
                                          bold        = {bold_users}
                                          page_id     = {page_id}
                                          media       = {media}
                                          on_click    = {click_on_user}/>}
            />}
          {(!gr || gr_other.length > 0) &&
           <TreeItem title   = {t(gr ? 'userg_other' : 'userg_con')}
                     bold    = {true}
                     subtree = {<UserList conn        = {conn}
                                          users       = {gr_other}
                                          selected    = {selected_user}
                                          peer_state  = {peer_state}
                                          bold        = {bold_users}
                                          empty_title = {t('userg_empty')}
                                          page_id     = {page_id}
                                          media       = {media}
                                          on_click    = {click_on_user}/>}
             />}
        </>;
}
