import React from 'react';
/*import {profile_def,
        ACCESS_PRIVATE} from '../profile_def';*/
import {AccControl} from '../controls/AccControl'
import {Slider} from '../controls/Slider';
import {db_set_data} from '../db/app_state';
const {profile_def,
       ACCESS_PRIVATE} = require('../profile_def.js');


/**
 * Editor jedné položky profilu - osobnostního dotazníku. Rozsah hodnot se převezme z profile_def.
 * 
 * @param {any} props  data_id  .. id položky profilue
 *                     text_bold .. tučný nadpis nad celou škálou
 *                     text_main .. hlavní text pro celou škálu; pokud je přítomen text_min a text_max jsou pod škálou
 *                     text_min .. text pro minimální hodnotu
 *                     text_max .. text pro maximální hodnotnu
 *                     ncount   .. počet rysek na pravítku, koncové nepočítaje (default = 5)
 *                     acc_icon .. true, pokud má být zobrazena ikona přístupových práv
 *                     group    .. seznam polože, které sdílejí položku "bez odpovědi".
 *                                 Pokud je group nastaveno, položka "bez odpovědi" je 
 *                                 zobrazena jen u prvního prvku seznamu group.
 *                                 Pokud uživatel označí "bez odpovědi" zruší se hodnoty
 *                                 všech prvku skupiny. Pokud je některý prvek skupiny nastaven,
 *                                 nastaví se ostatní na 0.
 *                     hide_na  .. pokud je true, není zobrazena možnot "bez odpovědi"
 *                     profile  .. objekt profilu
 *                     setter   .. volitelně; defaulně nastavuje položku profilu data_id
 */
export function ProfileQItem(props)
{
 const profile = props.profile;
 const ui_lang = profile.ui_lang;

 const data_id = props.data_id;
 const min = profile_def[data_id].min;
 const max = profile_def[data_id].max;
 const step = 1;

 // ------------------------
 // Setter vzít buď z props nebo vytvořit defaultní podle data_id a group
 let setter = props.setter ||
 (val => 
    {
     let obj = {};

     if(profile.qlangm)
       {
        obj.qlangm = {...profile.qlangm, [data_id]: ui_lang};
       }
     else if(profile.qlang)
       {
        if(profile.qlang !== ui_lang)
          {// sestavit seznam dříve nastavených položek dotazníku a zaznamenat u nich původní jazyk:
           const prev_lang = profile.qlang;

           let qlangm = {};

           for(const [key, val] of Object.entries(profile))
              {
               if(val !== undefined && val !== null && profile_def[key] && profile_def[key].q)
                  qlangm[key] = prev_lang;
              }

           qlangm[data_id] = ui_lang;

           obj.qlangm = qlangm;
           obj.qlang = null;
          }
       }
     else
       {
        obj.qlang = ui_lang;
       }

     if(props.group)
       {
        for(let i=0; i < props.group.length; i++)
           {
            const k = props.group[i];
        
            if(val === false)
              {
               obj[k] = false;
              }
            else if(k === data_id)
              {
               obj[k] = val;
              }
            else if(!profile[k] && profile[k]!==0)
              {
               obj[k] = 0;
              }
           }
       }
     else
       {
        obj[data_id] = val;
       }

     db_set_data(['profile'], 'merge', obj);
    })



 return <div className = "pr-q-item acc-private-bg" 
             id={data_id}>
              {props.acc_icon  && 
               <AccControl value  = {ACCESS_PRIVATE} />}

               <Slider value     = {profile[data_id]}
                       min       = {min}
                       max       = {max}
                       step      = {step}
                       width     = {580}
                       text_bold = {props.text_bold}
                       text_main = {props.text_main}
                       text_min  = {props.text_min}
                       text_zero = {props.text_zero}
                       text_max  = {props.text_max}
                       red_min   = {props.red_min}
                       red_max   = {props.red_max}
                       ncount    = {props.ncount}
                       hide_na   = {props.hide_na || (props.group && props.group[0]!==data_id)}
                       setter    = {setter}
                     />
        </div>;
}

