import React from 'react';
import {Selection} from './Selection';
import {useTranslationDef} from '../translations/useTranslationDef';
import './DateEditor.css';

/*-------------------------------------------------------------------------------------------------
Vrátí počet dní v měsíci. Pokud je měsíc neurčený, vrátí 31
Parametry:
 month .. měsíc (0 = neurčený)
 year  .. rok (0 = neurčený)
-------------------------------------------------------------------------------------------------*/
function month_day_count(month, year)
{
 if(!month)
    return 31

 if(month === 2)
    return 28 + (year%4 === 0 && (year%100 !== 0 || year%400 === 0));

 return [31,28,31,30,31,30,31,31,30,31,30,31][month-1];
}

/*-------------------------------------------------------------------------------------------------
Editor měsíce a dne.

props  year   .. rok, ke kterému se 
       month  .. aktuální měsíc (0 nebo null = nenastavený)
       day    .. aktuální den (0 nebo null = nenastavený)
       setter .. call-back pro nastavení hodnoty jako aktivní stránky 
                 (parametr: objekt s členy {month, day})
-------------------------------------------------------------------------------------------------*/
export function DateEditor(props) 
{
 const tr_def = useTranslationDef();
 const ui_lang = tr_def.lang;

 const year  = props.year  || 0;
 const month = props.month || 0;
 const day   = props.day   || 0;

 let msel = [];

 let date = new Date('1970-01-01');

 for(let i = 0; i < 12; i++) 
    {
     date.setMonth(i);
     msel.push({v: i+1, 
                t: date.toLocaleString(ui_lang ,{month: 'long'})});
    }

 msel.push({v: 0, tid: 'not_indicated', s: " ", c: "italic"});

 const month_sel = <Selection content = {msel}
                              value = {month}
                              setter = {m => props.setter({month: m, day: day <= month_day_count(m, year) ? day : 0})}
                              type = "int"/>

 let md = month_day_count(month, year);

 let dsel = [];
 let a = [];
 for(let i = 1; ; i++)
    {
     a.push(<div className="wnd-item" value={i} key={i}>{i}</div>);

     if(i%7 === 0 || i >= md)
       {
        dsel.push(<div className="day-row" key={i}>{a}</div>)
        a = [];
        if(i >= md)
           break;

       }
    }

 const day_sel = <Selection rawContent = {dsel}
                            value = {day || ""}
                            type = "int"
                            setter = {d => props.setter({month: month, day: d})}/>

 return <div className="date-editor">
          {month_sel}{day_sel}
        </div>
}
