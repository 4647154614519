import React from 'react';
import {db_set_data} from '../db/app_state';
import {RadioButtons} from '../lib/RadioButtons'
import {LineEditor} from '../lib/LineEditor';
import {DateEditor} from '../lib/DateEditor';
import {SchoolEditor} from '../controls/SchoolEditor';
import {ProfileItem} from './ProfileItem';
import {BottomNav} from './BottomNav';

/*------------------------------------------------------------------------------------------------- 
Vrací editor nastavení profilu 'životní styl'.
------------------------------------------------------------------------------------------------- */ 
export function profile_edit_ls(props, tr_def)
{
 const profile = props.profile;
 const media = props.media;
 const narrow = media.narrow;
 
 const t = tr_def.translate;

 return <>
      <h1>{props.back_btn}{t('prf_ls')}</h1>

      <div className = "desc">
      </div>

      <ProfileItem data_id  = "birth_year"
                   profile  = {profile}
                   validate = {x => {if(x === null) return null;
                                     const y = new Date().getFullYear();
                                     if(x < y-120 || x > y-10) return t('err_bad_val');
                                     return null;}}/>

      <ProfileItem data_id = "birth_day"
                   control = {<DateEditor year   = {profile.birth_year}
                                          month  = {profile.birth_day ? Math.floor(profile.birth_day/100) : 0}
                                          day    = {profile.birth_day ? profile.birth_day%100 : 0}
                                          setter = {val => 
                                                    db_set_data(['profile', 'birth_day'], 
                                                                'set', 
                                                                 val.month ? (val.month || 0)*100 + (val.day || 0) : null)}
                               />}
                   profile = {profile}/>

      <ProfileItem data_id  = "education"
                   profile = {profile}/>

      {profile.education && profile.education >= 4 &&
       <ProfileItem data_id  = "schools"
                    control  = {<SchoolEditor value       = {profile.schools}
                                              rank        = {profile.srnk}
                                              setter      = {val => db_set_data(['profile', 'schools'], 'set', val)}
                                              rank_setter = {val => db_set_data(['profile', 'srnk'],    'set', val)}/>}
                    sub = {t('schools.sub')}
                    profile = {profile}/>
      }

      {profile.education && profile.education >= 4 &&
       <ProfileItem data_id  = "edu_subj"
                    profile = {profile}/>}

      <ProfileItem data_id  = "occupation"
                   profile = {profile}/>

      <ProfileItem data_id  = "income"
                   profile = {profile}/>

      <ProfileItem data_id   = "relation_status"
                   profile = {profile}/>

      <ProfileItem data_id  = "child_num"
                   control  = {<>
                                <RadioButtons type = "int"
                                              optional
                                              value = {profile.child_num}
                                              content = {[{v: 0, t: t('no_children')},
                                                          {v: 1, t: "1"},
                                                          {v: 2, t: "2"},
                                                          {v: 3, t: "3"}]}
                                              setter = {val => db_set_data(['profile', 'child_num'], 'set', val)}/>
                                 &emsp;&emsp;{t('ch_more')}:&nbsp;<LineEditor type = "int" 
                                                   value = {profile.child_num && profile.child_num > 3 ? profile.child_num : ""}
                                                   maxlength = "4"
                                                   write_through = {false}
                                                   setter = {val => db_set_data(['profile', 'child_num'], 'set', val)}/>
                               </>
                              }
                   profile = {profile}/>

      {profile.child_num!==null && profile.child_num > 0 &&
       <ProfileItem label     = { profile.child_num === 1 ? t('ch_age') : 
                                 (profile.child_num === 2 ? t('ych2_age') : t('ychm_age'))}
                    data_id   = "yc_birth_year"
                    maxlength = "4"
                    value     = {profile.yc_birth_year!==null ? new Date().getFullYear() - profile.yc_birth_year : ""}
                    setter    = {i => db_set_data(['profile', 'yc_birth_year'], 
                                                  'set', 
                                                  i!==null ? new Date().getFullYear()-i : null)}
                    profile   = {profile}/>
      }
      {profile.child_num!==null && profile.child_num > 1 &&
       <ProfileItem label    = {profile.child_num === 2 ? t('ych2_age') : t('ychm_age')}
                    data_id  = "oc_birth_year"
                    maxlength = "4"
                    value    = {profile.oc_birth_year!==null ? new Date().getFullYear() - profile.oc_birth_year : ""}
                    setter   = {i => db_set_data(['profile', 'oc_birth_year'], 
                                                  'set', 
                                                  i!==null ? new Date().getFullYear()-i : null)}
                    profile = {profile}/>
      }
      <ProfileItem label    = {profile.child_num ? t('wchildm') : undefined}
                   data_id  = "wchild"
                   value    = {profile.wchild}
                   profile = {profile}/>

      <ProfileItem data_id  = "sex_o"
                   profile = {profile}/>

      <ProfileItem data_id  = "religion"
                   profile = {profile}/>

      <ProfileItem data_id  = "religion_rel"
                   profile = {profile}/>

      <BottomNav left  = {{tid: 'prf_main', url: "/profile/main"}}
                 right = {narrow ? {tid: 'qt.lbl',   url: "/profile/qt"}
                                 : {tid: 'prf_q',    url: "/profile/q"}}/>

    </>;
}

