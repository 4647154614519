/*#################################################################################################

  Obsah stránky s profilem

#################################################################################################*/

import React from 'react';
import {useHistory} from 'react-router-dom'
import {ProfileEditor} from './ProfileEditor';
import {ProfileView} from './ProfileView';
import {Settings} from './Settings';

/*-------------------------------------------------------------------------------------------------
props  subpage .. identifikátor podstránky
       profile .. profil přihlášeného uživatele
       profile_summary .. výsledek volání get_profile_summary()
       media   .. vlastnosti okna browseru; objekt s členy:
                  narrow .. okno je užší než 900px   
-------------------------------------------------------------------------------------------------*/
export function Profile(props) 
{
 const subpage = props.subpage;
 const media = props.media;
 const narrow = media.narrow;

 const url_history = useHistory();

 let back_btn;

 if(narrow)
   {
    back_btn = <span className = 'back-btn'
                     onClick  = {() => url_history.goBack()}></span>;
   }

 if(subpage === 'settings')
    return <Settings profile  = {props.profile}
                     back_btn = {back_btn}
                     media    = {media}/>;

 const pg_view = subpage === "" || subpage === "public" || subpage === "acq" || subpage === "friend";

 if(pg_view)
    return <ProfileView profile  = {props.profile} 
                        acc      = {subpage}
                        back_btn = {back_btn}
                        media    = {media}/>;
 else         
    return <ProfileEditor subpage  = {subpage}
                          profile  = {props.profile}
                          profile_summary = {props.profile_summary}
                          back_btn = {back_btn}
                          media    = {media}/>;
}
