import React from 'react';
import {get_lang_list} from '../utils';
import {db_set_data,
        Data_op} from '../db/app_state';
import {LanguageEditor} from '../controls/LanguageEditor';
import {GeoLocation} from '../controls/GeoLocation';
import {ProfileItem} from './ProfileItem';
import {ProfileTextEditor} from './ProfileTextEditor';
import {ImageList} from '../controls/ImageList';
import {BottomNav} from './BottomNav';

/*------------------------------------------------------------------------------------------------- 
Nastaví do profilu hodnoty geo-lokace podle objektu val. Pokud je home = true, nastaví hodnotám
prefix "home_"
------------------------------------------------------------------------------------------------- */ 
function set_geo_loc(val, home)
{
 let obj = {};

 const klist = ["country", "city", "address", "geo_loc", "geo_rx", "geo_ry", "prnk"];
 const pref = home ? "home_" : "";

 for(let i=0; i<klist.length; i++)
    {
     const k = klist[i];
     obj[pref + k] = val[k] || null;
    }

 db_set_data(['profile'], 'merge', obj);
}

/*------------------------------------------------------------------------------------------------- 
Normalizuje přezdívku a uloží ji do dat.
------------------------------------------------------------------------------------------------- */ 
function normalize_nick(nick)
{
 let nn = (nick||"").trim();
 nn = nn.replace(/\p{Zs}+/gu, x => ' ');
 if(nn !== nick)
    db_set_data(new Data_op(['profile', 'nick_name'], 'set', nn));
}

/*------------------------------------------------------------------------------------------------- 
Vrací editor základních nastavení profilu.
------------------------------------------------------------------------------------------------- */ 
export function profile_edit_main(props, tr_def)
{
 const profile = props.profile;
 const language = props.language;
 const media = props.media;
 const narrow = media.narrow;

 const t = tr_def.translate;
 const cc_loc = tr_def.country_names;
 const lang_loc = tr_def.lang_names;

 const cc = Object.keys(cc_loc);
 let country_sel = cc.map(cc => ({v: cc, t:cc_loc[cc]}));
 country_sel.sort((e1, e2) => e1.t.localeCompare(e2.t));
 
 const geo_loc_home = {country: profile.home_country, 
                       city:    profile.home_city, 
                       address: profile.home_address, 
                       geo_loc: profile.home_geo_loc, 
                       geo_rx:  profile.home_geo_rx, 
                       geo_ry:  profile.home_geo_ry, 
                       prnk:    profile.home_prnk};

 const geo_loc = {country: profile.country, 
                  city:    profile.city, 
                  address: profile.address, 
                  geo_loc: profile.geo_loc, 
                  geo_rx:  profile.geo_rx, 
                  geo_ry:  profile.geo_ry, 
                  prnk:    profile.prnk};

 const lang_list = get_lang_list(profile.languages, lang_loc);

 return (
    <>
      {!narrow &&
       <>
        <h1>{t('pictures')}</h1>
        <ImageList images={profile.images}
                   edit
                   limit = {4}/>
       </>}

      <h1>{props.back_btn}{t('prof_basic')}</h1>

      <div className = "desc">
       {!narrow &&
        <p>
         {t('prof_basic_desc')}
        </p>}
       <p><span className="mandatory">*</span>) {t('prof_mandatory')}</p>
      </div>

      <ProfileItem label     = {t('login.lbl')} 
                   data_id   = "primary_mail"
                   control   = {profile.primary_mail}
                   profile   = {profile}/>
                             
{/*      <ProfileItem label     = "Zasílejte mi novinky e-mailem" 
                   control   = "toggle"
                   data_id   = "enable_newsletter"
                   sub       = "Nezmeškejte poslední novinky z Glagoil.net. Žádný spam vám posílat nebudeme."
                   profile = {profile}/> */}
                             
      <ProfileItem label     = {t('mail_srch')}
                   control   = "toggle"
                   data_id   = "find_by_mail"
                   sub       = {t('mail_srch.sub')}
                   profile   = {profile}/>
                             
      <ProfileItem data_id   = "nick_name"
                   write_through
                   mandatory
                   on_focus  = {f => !f && normalize_nick(profile.nick_name)}
                   sub       = {t('nick_name.sub')}
                   profile   = {profile}/>

      <ProfileItem data_id   = "sex"
                   control   = "radio_buttons"
                   optional
                   profile   = {profile}/>
                   
      <ProfileItem data_id   = "first_name"
                   profile   = {profile}/>

      <ProfileItem data_id   = "last_name"
                   profile   = {profile}/>
                             
      <ProfileItem data_id   = "languages"
                   mandatory
                   control   = {<LanguageEditor value  = {profile.languages}
                                                setter = {val => db_set_data(['profile', 'languages'], 'set', val)}/>}
                   sub       = {t('languages.sub')}
                   profile   = {profile}/>

      {profile.languages &&
       <ProfileTextEditor value   = {profile.profile_text}
                          profile = {profile}
                          langs   = {lang_list}
                          setter  = {val => db_set_data(['profile', 'profile_text'], 'set', val)}/>}

      <ProfileItem label = {t('home_geo_loc.lbl')}
                   no_access
                   no_right_padding
                   control = {<GeoLocation value          = {geo_loc_home}
                                           data_id_prefix = "home_"
                                           language       = {language}
                                           profile        = {profile}
                                           setter         = {val => set_geo_loc(val, true)}/>}
                   sub = {t('home_geo_loc.sub')}
                   profile = {profile}/>

      <ProfileItem label    = {t('geo_loc.lbl')}
                   no_access
                   no_right_padding
                   control  = {<GeoLocation value          = {geo_loc}
                                            home           = {geo_loc_home}
                                            data_id_prefix = ""
                                            language       = {language}
                                            profile        = {profile}
                                            setter         = {val => set_geo_loc(val, false)}/>}
                   sub = {t('geo_loc.sub')}
                   profile = {profile}/>

      <BottomNav left = {[{tid: 'hdr_odate', url: "/odate"},
                          {tid: 'hdr_chat',  url: "/chat"}]}
                 right = {{tid: 'prf_ls',    url: "/profile/ls"}}/>
    </>);
}
