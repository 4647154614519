/*#################################################################################################

Základní funkce pro přístup k Firebase Cloud Storage.

#################################################################################################*/

import firebase from 'firebase/app';
import {storage_get,
        storage_set} from './app_state';


let buckets = {}; // cache objektů Storage odpovídajících bucketům

/*-------------------------------------------------------------------------------------------------
Vrací objekt Storage bucketu s daným id. Pokud id není zadáno, vrací defaultní bucket aplikace.
-------------------------------------------------------------------------------------------------*/
export function cloud_storage_bucket(bucket_id)
{
 bucket_id = bucket_id || "";

 if(!buckets[bucket_id]) 
     buckets[bucket_id] = firebase.app().storage(bucket_id ? "gs://"+bucket_id : undefined);

 return buckets[bucket_id];
}

/*-------------------------------------------------------------------------------------------------
Pro daný bucket a jméno souboru vrací příslušné URL. Funkce je asynchronní, vrací Promise na
výsledné URL. Jednou vytvořená URL cachuje.



Parametry:
  bucket_id .. identifikátor bucketu. Pokud není zadán, jde o defaultní bucket projektu.
  file_name .. název souboru v bucketu.
               Pokud je zadán jen jeden parametr, předpokádá se, že jde o file_name
               v defaultním bucketu.               
-------------------------------------------------------------------------------------------------*/
export function cloud_storage_url(bucket_id, file_name)
{
 if(!file_name)
   {
    file_name = bucket_id;
    bucket_id = "";
   }
 else
   {
    bucket_id = bucket_id || "";
   }

 let cache_id = 'urls.'+bucket_id;

 const sidx = file_name.indexOf('/');

 if(sidx >= 0)
    cache_id += '.' + file_name.substr(0, sidx);

 let cache = storage_get(cache_id);

 if(cache && cache[file_name])
    return Promise.resolve(cache[file_name]);

 return cloud_storage_bucket(bucket_id).ref(file_name).getDownloadURL()
        .then(url => 
             {
              let cache = storage_get(cache_id) || {};
              cache[file_name] = url;
              storage_set(cache_id, cache);
              return url;
             });
}
