import React from 'react';
import {Link} from 'react-router-dom'
import {get_profile_access, 
        normalize_profile} from '../user';
import {master} from '../db/master';
import {current_uid} from '../db/db';
import {query_peer_state} from '../db/user_state';
import {useUserProfile} from '../db/useUserProfile';
import {parse_schools} from '../controls/SchoolEditor';
import {WaitIndicator} from '../lib/WaitIndicator';
import {ProfileViewItem} from './ProfileViewItem';
import {Subheader} from '../controls/Subheader';
import {get_loc_string,
        get_lang_list} from '../utils';
import {useTranslationDef} from '../translations/useTranslationDef';
import {ImageList} from '../controls/ImageList';
const  {ACCESS_PRIVATE, 
        ACCESS_PUBLIC, 
        ACCESS_ACQ, 
        ACCESS_FRIEND} = require('../profile_def.js');

/*------------------------------------------------------------------------------------------------- 
Podle přístupových práv přidá do pole list danou hodnotu nebo null.

Parametry: 
  list    Pole, kam požadovanou hodnotu přidat
  val     Hodnota, která má být vrácena, pokud je null, vrací příslušnou položku profilu
  data_id Název datové položky, jejíž přístupová práva se testují
  profile Profil, z něhož se položka bere.
  acc     Minimální požadovaná přístupová práva.
------------------------------------------------------------------------------------------------- */ 
function add_acc_cond(list, val, data_id, profile, acc)
{
 if(profile[data_id]===undefined || profile[data_id]===null) 
    return;

 if(acc!==undefined && get_profile_access(profile, data_id) < acc)
    return;

 list.push(val===null ? profile[data_id] : val);
}

/*-------------------------------------------------------------------------------------------------
  Zobrazení profilu (vlastního nebo cizího)
  
 props  uid      .. uid, které se má zobrazit 
        profile  .. objekt profilu, který se má zobrazit 
                    (buď je zadán profil nebo uid)
        acc      .. (volitelně) Pokud se zobrazuje vlastní profil,
                    určuje úroveň přístupových práv, z jejichž hlediska se profil
                    zobrazuje.
        conn     .. pokud se zobrazuje cizí profil, položka propojení daného uživatele
                    (z profile.conn přihlášeného uživatele)
        page_id  .. id stránky, na které je profil zobrazen
        back_btn .. pokud se má v titulcích podstránek zobrazovat šipka "zpět", zde je její definice.
                    jinak undefined. (Pro úzké zobrazení.)
        media    .. vlastnosti okna browseru; objekt s členy:
                    narrow .. okno je užší než 900px   
-------------------------------------------------------------------------------------------------*/
export function ProfileView(props) 
{
 const tr_def = useTranslationDef();
 const ui_lang = tr_def.lang;
 const cc_loc = tr_def.country_names;
 const lang_loc = tr_def.lang_names;
 const t = tr_def.translate;

 let profile = props.profile;
 const media = props.media;
 const narrow = media.narrow;

 if(profile)
   {
    profile = {...profile};
    normalize_profile(profile);
   }

 let uid = props.uid;
 const uid_profile = useUserProfile(uid && !profile ? uid : null);

 if(uid && !profile)
    profile = uid_profile
 
 if(!uid && profile)
    uid = profile.uid;

 React.useEffect(() =>
                {
                 if(uid && uid !== current_uid) 
                    query_peer_state(uid);
                });

 if(!profile)
    return null;

 let tr_opt; // parametry překladové funkce (pokud zobrazujeme profil jiného uživatele, definuje jeho gramatický rod)
 if(uid !== current_uid) 
    tr_opt = {gender: profile.sex && +(profile.sex===2)};

 let acc
 
 switch(props.acc)
       {
        case "public": acc = ACCESS_PUBLIC; break;
        case "acq":    acc = ACCESS_ACQ; break;
        case "friend": acc = ACCESS_FRIEND; break;
        default:
        case "":       acc = ACCESS_PRIVATE; break;
       }
 
 // Jméno:
 let subname = [];
 add_acc_cond(subname, null, "first_name", profile, acc);
 add_acc_cond(subname, null, "last_name", profile, acc);
 if(subname.length > 0)
    subname = subname.join(" ");
 else
    subname = null;
    
 let anomalous;

 if(profile.$loading)
   {
    anomalous = <WaitIndicator size={16}/>;
   }
 else if(profile.$error)
   {
    anomalous = <>
                 <div className = 'error'>
                  {t(profile.$error==='not-found'?'err_profile_nfnd':'err_profile')}
                 </div>
                 <button onClick={() => master.db_read_profile(uid)}>{t('btn_reload')}</button>
                </>;
   }

 let content;

 if(anomalous)
   {
    content = <div className = "page-content">
                 <br/>
                 {anomalous}
              </div>;
   }
 else
   {
    // Obrázky:
    let images = profile.images;
    if(images && props.acc !== undefined)
      { // odstranit obrázky, které nevyhovují přístupovým právům
       images = images.filter(img => img.acc >= acc);
      }
    
    // Narození:
    let bday_lbl = null;
    let byear = [];
    add_acc_cond(byear, null, "birth_year", profile, acc);
    byear = byear.length ? byear[0] : null;
    
    let bday = [];
    add_acc_cond(bday, null, "birth_day", profile, acc);
    
    bday = bday.length ? bday[0] : null;
    if(bday && bday > 100 && bday % 100 > 0)
      {
       const bmonth = Math.floor(bday / 100);
       bday = bday % 100;
    
       bday_lbl = t('birth_day.lbl', tr_opt);
    
       if(byear)
          bday = (new Date(byear, bmonth-1, bday)).toLocaleDateString(ui_lang, {year: 'numeric', month: 'long', day: 'numeric'}); 
       else
          bday = (new Date(2004, bmonth-1, bday)).toLocaleDateString(ui_lang, {month: 'long', day: 'numeric'}); 
      }
    else
      {
       if(byear)
         {
          bday_lbl = t('birth_year.lbl', tr_opt)
          bday = byear;
         }
      }
    
    // Domovské místo:
    let hgl = [];
    add_acc_cond(hgl, null, "home_address", profile, acc);
    add_acc_cond(hgl, null, "home_city", profile, acc);
    add_acc_cond(hgl, cc_loc[profile.home_country], "home_country", profile, acc);
    
    // Aktuální místo:
    let gl = [];
    add_acc_cond(gl, null, "address", profile, acc);
    add_acc_cond(gl, null, "city", profile, acc);
    add_acc_cond(gl, cc_loc[profile.country], "country", profile, acc);
    
    // Vyskoé školy:
    let sch = [];
    add_acc_cond(sch, null, "schools", profile, acc);
    if(sch.length>0)
      {
       sch = parse_schools(sch[0]).map(e => <tr className="school-item" key={e.url}>
                                              <td className="sch-name">
                                                {e.loc_name ?
                                                 <>
                                                  <div>{e.loc_name}</div>
                                                  <div className="l2">{e.name}</div>
                                                 </>
                                                 :
                                                  e.name
                                                }
                                              </td>
                                            </tr>);
      }
    else
      {
       sch = null;
      }
    
    // Děti (počet, věk):
    let ch = null;
    let cn = [];
    add_acc_cond(cn, null, "child_num", profile, acc);
    if(cn.length > 0)
      {
       cn = cn[0];
       if(cn === 0)
         {
          ch = t('no_children', tr_opt);
         }
       else 
         {
          ch = cn.toString();
    
          let yca = [];
          add_acc_cond(yca, null, "yc_birth_year", profile, acc);
          yca = yca.length > 0 ? new Date().getFullYear() - yca[0] : null;
    
          let oca = [];
          add_acc_cond(oca, null, "oc_birth_year", profile, acc);
          oca = oca.length > 0 ? new Date().getFullYear() - oca[0] : null;
    
          if(yca === null && oca !== null)
            {
             yca = oca;
             oca = null;
            }
    
          if(yca !== null)
            {
             ch += ", "+t('ch_age_short', tr_opt)+": " + yca.toString(); 
    
             if(cn > 1 && oca!==null)
               {
                ch += cn===2 ? ", " : " – ";
                ch += oca.toString()
               }
            }
         }
      }
    else 
      {
       cn = null;
      }
    
    const lang_list = get_lang_list(profile.languages, lang_loc).map(lc => lang_loc[lc]);
    
    content = <>
             <div className = "profile-view">
               {images &&
                <ImageList images = {images}
                           uid    = {uid}/>}
             
               <p style={{whiteSpace:"pre-wrap"}}>{get_loc_string(ui_lang, profile.profile_text)}</p>
             
               <ProfileViewItem data_id = "primary_mail"
                                profile = {profile}
                                tr_opt  = {tr_opt}
                                acc     = {acc}/>
             
               <ProfileViewItem data_id = "sex"
                                profile = {profile}
                                tr_opt  = {tr_opt}
                                acc     = {acc}/>
             
               {profile.languages &&
                <ProfileViewItem data_id = "languages"
                                 value = {lang_list.join(", ")}
                                 tr_opt  = {tr_opt}/>}
             
               {bday_lbl &&
                <ProfileViewItem label = {bday_lbl}
                                 value = {bday}
                                 tr_opt  = {tr_opt}/>}
             
               {hgl.length>0 &&
                <ProfileViewItem label = {t('home_geo_loc.lbl_view', tr_opt)}
                                 value = {<div className="disp-col">
                                          {hgl.map((v, i) => <div key={i}>{v}</div>)}
                                          </div>}
                                 tr_opt  = {tr_opt}/>}
               {gl.length>0 &&
                <ProfileViewItem label = {t('geo_loc.lbl_view', tr_opt)}
                                 value = {<div className="disp-col">
                                          {gl.map((v, i) => <div key={i}>{v}</div>)}
                                          </div>}
                                 tr_opt  = {tr_opt}/>}
             
               <ProfileViewItem data_id  = "education"
                                profile = {profile}
                                tr_opt  = {tr_opt}
                                acc     = {acc}/>
             
               {sch &&
                <ProfileViewItem data_id  = "schools"
                                 value    = {<table className="school-editor"><tbody>{sch}</tbody></table>}
                                 tr_opt  = {tr_opt}/>}
             
               <ProfileViewItem data_id  = "edu_subj"
                                profile = {profile}
                                tr_opt  = {tr_opt}
                                acc     = {acc}/>
             
               <ProfileViewItem data_id = "occupation"
                                profile = {profile}
                                tr_opt  = {tr_opt}
                                acc     = {acc}/>
             
               <ProfileViewItem data_id = "income"
                                profile = {profile}
                                tr_opt  = {tr_opt}
                                acc     = {acc}/>
             
               <ProfileViewItem data_id = "relation_status"
                                profile = {profile}
                                tr_opt  = {tr_opt}
                                acc     = {acc}/>
             
               {ch &&
               <ProfileViewItem label   = {t('children.lbl', tr_opt)}
                                value   = {ch}
                                tr_opt  = {tr_opt}/>}
             
               <ProfileViewItem data_id = "sex_o"
                                profile = {profile}
                                tr_opt  = {tr_opt}
                                acc     = {acc}/>
             
               <ProfileViewItem data_id = "wchild"
                                label   = {cn ? t('wchildm', tr_opt) : undefined}
                                profile = {profile}
                                tr_opt  = {tr_opt}
                                acc     = {acc}/>
             
               <ProfileViewItem data_id  = "religion"
                                profile = {profile}
                                tr_opt  = {tr_opt}
                                acc     = {acc}/>
             
               <ProfileViewItem data_id  = "religion_rel"
                                profile = {profile}
                                tr_opt  = {tr_opt}
                                acc     = {acc}/>
               {narrow &&
                <>
                 <div className="pr-item">
                   <Link className="big" to="/profile/settings">{t('prf_settings')}</Link>
                 </div>
                 <div className="pr-item">
                   <a href="/terms-of-service.html" target="_blank">{t('terms_of_service')}</a>
                 </div>
                </>}
             </div>
         </>;
   }

 return <>
         <Subheader type    = 'profile'
            conn    = {props.conn}
            page_id = {props.page_id}
            profile = {profile}
            acc     = {props.acc}
            sub     = {subname}
            media   = {media}/>
         <div id = "layout-content">
          <div className = "page-content unindented">
           {content}
          </div>
         </div>
        </>

}
