import React from 'react';
//import {profile_def} from '../profile_def';
import {get_profile_access} from '../user';
import {get_enum_text} from '../utils';
import {useTranslation} from '../translations/useTranslation';
const {profile_def} = require('../profile_def.js');

/*------------------------------------------------------------------------------------------------- 
Zobrazení jedné položky profilu

props  profile .. zobrazovaný profil
       acc     .. požadovaná přístupová práva (položky, které vyžadují silnější, se nezobrazí)
       data_id .. id zobrazované položky
       label   .. popisek; pokud není zadán, vezme se z překládaných textů pod id <data_id + '.lbl'>
       value   .. zobrazovaná hodnota; pokud není zadána, vezme se z profilu podle data_id
       tr_opt  .. (volitelně) parametry překladové funkce
------------------------------------------------------------------------------------------------- */ 
export function ProfileViewItem(props)
{
 const t = useTranslation();

 const data_id = props.data_id;
 const tr_opt = props.tr_opt;

 let label = props.label;
 let value = props.value;

 if(data_id)
   {
    if(!profile_def[data_id])
       return null;

    if(props.acc!==undefined && get_profile_access(props.profile, data_id) < props.acc)
       return null;

    if(!label)
       label = t(data_id+'.lbl', tr_opt);

    if(value===null || value===undefined)
      {
       value = props.profile[props.data_id];

       if(value === null || value === undefined)
          return null;

       if(profile_def[data_id].enum)
          value = get_enum_text(value, profile_def[data_id].enum, t, tr_opt);
      }
   }

 return (<div className="pr-item" 
              id={data_id}>
           <div className="pr-inner">
             <div className="label">
               {label}
             </div>
             {value}
           </div>
         </div>);
}

