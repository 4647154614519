import React from 'react';
import {PopupWindow} from './PopupWindow';
import {call_setter} from './call_setter';
import {NULL_STRING} from './normalize_value';
import {useTranslation} from '../translations/useTranslation';


/*------------------------------------------------------------------------------------------------- 
Props:
 content    .. Pole hodnot selectionu. Pole objektů s následujícími prvky
               v: hodnota (může být i null)
               t: titulek zobrazený pro hodnotu v menu
               tid: identifikáto překladu titulku (pro useTranslation())
               s: (short-title) volitelná hodnota. Pokud je přítomna, zobrazuje
                  se v selectionu pokud je daná hodnota aktuální
               sid: identifikáto překladu krátkého titulku (pro useTranslation())
               c: volitelně: jméno třídy, které se přidá k příslušnému HTML prvku
                  (jak v menu tak ve vlastním selectionu, když je vybraná daná hodnota)
                  Může se použít pro speciální ostylování. Např. c:"italic" udělá kursívu (styl třídy
                  "italic" je definován v glagoli.css).
 rawContent .. Obsah pop-up okna. Měl by obsahovat elementy třídy "wnd-item" s atributem 'value',
               jehož hodnota se použije při vybrání daného elementu.
               Pokud je tento parametr zadán, 'content' se ignoruje
 value      .. aktuální hodnota; pokud je zadán parametr rawContent, jde přímo o zobrazovaný řetězec.
 setter     .. funkce vyvolaná při změně hodnoty. Nová hodnota se předá jako jediný parametr.
 data_id    .. (nepovinné) identifikátor nastavený jako atribut "id" a "name"
 type       .. volitelně typ hodnoty, která se má zapsat ("int" nebo "string"). Pokud není přítomna,
               použije se hodnota z content bez konverze. null se nikdy nekonvertuje
 align_right .. pokud je nastaveno, zarovnává se menu s pravým okrajem obdélníku anchor, jinak s levým.
 className  .. jméno třídy vnějšího elementu (defaultně: "selection-box")
------------------------------------------------------------------------------------------------- */ 
export function Selection(props) 
{
 const init_state = 
    {
     is_open: false // true, pokud je okno selectionu otevřené
    }

 const [state, set_state] = React.useState(init_state);
 const t = useTranslation();

 // ----------------
 // Kliknutí na selection
 const on_click_selection = e => 
    {
     set_state({...state, is_open: true, wnd_anchor: e.target.getBoundingClientRect()});
    }

 // ----------------
 // Kliknutí při otevřeném okně na položku okna nebo mimo okno
 const on_click_window = (node, e) => 
    {
     if(node !== null)
       {
        call_setter(props, node.attributes.value.value);
        e.stopPropagation();
       }

     if(state.is_open)
        set_state({...state, is_open: false, wnd_anchor: undefined});
    }


 let curr_txt;

 // pokud se má otvírat okno, vytvořit seznam jeho prvků, jinak jen najít text k aktuální hodnotě:
 let btn_list = [];

 if(props.rawContent)
   {
    if(state.is_open)
       btn_list = props.rawContent;
    if(props.value)
       curr_txt = props.value;
   }
 else
   {
    btn_list = [];
    let i;
    for(i = 0; i<props.content.length; i++)
       {
        if(props.value === props.content[i].v)
          {
           curr_txt = props.content[i].s || (props.content[i].sid && t(props.content[i].sid)) || 
                      props.content[i].t || (props.content[i].tid && t(props.content[i].tid));
           if(curr_txt === ' ' || curr_txt === '') 
             curr_txt = ' '; // normální mezeru nahradit za &nbsp (elementu musí mít obsah, jinak se mu nedá nastavit šířka; normální mezera se nepočítá)
           if(props.content[i].c)
               curr_txt = <span className={props.content[i].c}>{curr_txt}</span>;
          }
    
        if(state.is_open)
          {
           let val = props.content[i].v;
           if(val===null)
              val = NULL_STRING;
    
           let txt = props.content[i].t || (props.content[i].tid && t(props.content[i].tid));
           if(props.content[i].c)
              txt = <span className={props.content[i].c}>{txt}</span>;
    
           btn_list.push(<div className = "wnd-item"
                              key = {val}
                              value = {val}>
                           {txt}
                         </div>);
          }
       }
    }

 if(curr_txt === undefined)
    curr_txt = <span className="placeholder">{props.placeholder || " "}</span>;

 return <div className={props.className || "selection-box"}>
          <div className = "selection-control" onClick={on_click_selection}>
           {btn_list.length>0 && 
            <PopupWindow content     = {btn_list}
                         anchor      = {state.wnd_anchor}
                         align_right = {props.align_right}
                         on_click    = {on_click_window}/>}
            {curr_txt}
          </div>
        </div>;
}
