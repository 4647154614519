/*#################################################################################################

Bubblina s informací u uzlu

#################################################################################################*/

import React from 'react';
import './NodeBubble.css';

/*-------------------------------------------------------------------------------------------------
props  content   .. obsažený text
       right     .. má být zarován na pravý okraj
       dx        .. odsazení hrotu šipky vůči levému (resp. pravému pro right) okraji rodiče.
                    (defaultně je na střed)
       dy        .. odsazení hrotu šipky vůči spodnímu okraji rodiče.
       on_click  .. funkce vyvolaná při kliknutí
 -------------------------------------------------------------------------------------------------*/
export function NodeBubble(props) 
{
 if(!props.content)
    return null;

 let cname = "node-bubble";
 if(props.right) cname += " right";
 if(props.on_click) cname += " clickable";

 let style = {};

 if(typeof props.dy === 'number') style.top = `calc(100% + ${props.dy}px)`;
 if(typeof props.dx === 'number') 
   {
    let str = `${props.dx-32}px`;
    if(props.right)
       style.right = str;
    else
       style.left = str;
   }

 return <div className = {cname}
             style     = {style}
             onClick   = {props.on_click}>
         {props.content}
        </div>;
}
