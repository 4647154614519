/*#################################################################################################

  Navigace při patě stránky

#################################################################################################*/

import React from 'react';
import {Link} from 'react-router-dom'
import {useTranslation} from '../translations/useTranslation';
import './BottomNav.css';

/*------------------------------------------------------------------------------------------------- 
Vytvoří jeden odkaz podle definičního objektu.
------------------------------------------------------------------------------------------------- */ 
function create_link(def, t, left)
{
 let title = def.t || t(def.tid);
 if(left)
    title = "‹ " + title;
 else
    title = title + " ›";

 return <Link to = {def.url} key = {def.url}>{title}</Link>;
}

/*------------------------------------------------------------------------------------------------- 
props  left .. Odkazy zobrazené vlevo.
               Pole objektů nebo jeden objekt se složkami:
                - t   .. text titulku
                - tid .. id lokalizace titulku (je zadáno buď 't' nebo 'tid')
                - url .. URL odkazu
       right .. Odkazy zobrazené v pravo (analogicky).
------------------------------------------------------------------------------------------------- */ 
export function BottomNav(props) 
{
 const left = props.left;
 const right = props.right;
 const t = useTranslation();

 let left_col = [];
 let right_col = [];

 if(left)
   {
    if(Array.isArray(left))
      {
       for(let i = 0; i < left.length; i++)
          {
           left_col.push(create_link(left[i], t, true));
          }
      }
    else
      {
       left_col.push(create_link(left, t, true));
      }
   }

 if(right)
   {
    if(Array.isArray(right))
      {
       for(let i = 0; i < right.length; i++)
          {
           right_col.push(create_link(right[i], t));
          }
      }
    else
      {
       right_col.push(create_link(right, t));
      }
   }

 return <div className='bottom-nav'>
          <div className='left'>
            {left_col}
          </div>
          <div className='right'>
            {right_col}
          </div>
        </div>

}
