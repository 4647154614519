/*#################################################################################################

  Editor obsahu vlastního profilu.

#################################################################################################*/

import React from 'react';
import {Redirect,
        Link} from 'react-router-dom'
import {ImageList} from '../controls/ImageList';
import {useTranslationDef} from '../translations/useTranslationDef';
import {profile_edit_main} from './profile_edit_main';
import {profile_edit_ls}   from './profile_edit_ls';
import {profile_edit_q}    from './profile_edit_q';
import {profile_edit_qt}   from './profile_edit_qt';
import {profile_edit_qr}   from './profile_edit_qr';
import {profile_edit_qm}   from './profile_edit_qm';
import {profile_edit_qe}   from './profile_edit_qe';
import {profile_edit_qv}   from './profile_edit_qv';
import './ProfileEditor.css';

/*-------------------------------------------------------------------------------------------------
Výchozí stránka editace profilu pro úzké zobrazení (kde není levý panel)
-------------------------------------------------------------------------------------------------*/
function profile_edit_index(profile, ps, tr_def)
{
 const t = tr_def.translate;

 return <>
          <h1>{t('pictures')}</h1>
           <ImageList images={profile.images}
                      edit
                      limit = {4}/>
          <h1>{t('prf.title')}</h1>
          <div className = "desc">
           <p>
            {t('prof_basic_desc')}
           </p>
          </div>
          <div className="pr-item">
           <div>
            <Link className="big" to="/profile/main">{t('prof_basic')}</Link>
            {ps.main_missing ? <span className="nf-val">{ps.main_missing}</span>: null}
           </div>
          </div>
          <div className="pr-item">
            <Link className="big" to="/profile/ls">{t('prf_ls')}</Link>
          </div>
          {profile_edit_q(tr_def, ps)}
          <div className="pr-item">
            <Link className="big" to="/profile/settings">{t('prf_settings')}</Link>
          </div>
          <div className="pr-item">
            <a href="/terms-of-service.html" target="_blank">{t('terms_of_service')}</a>
          </div>
        </>;
}

/*-------------------------------------------------------------------------------------------------
props  subpage  .. identifikátor podstránky profilu
       profile  .. objekt profilu
       profile_summary .. výsledek volání get_profile_summary()
       back_btn .. pokud se má v titulcích podstránek zobrazovat šipka "zpět", zde je její definice.
                   jinak undefined. (Pro úzké zobrazení.)
       media    .. vlastnosti okna browseru; objekt s členy:
                   narrow .. okno je užší než 900px   
-------------------------------------------------------------------------------------------------*/
export function ProfileEditor(props) 
{
 const profile = props.profile;
 const media = props.media;
 const narrow = media.narrow;

 const tr_def = useTranslationDef();

 // Když se změní stránka, odscrollovat nahoru:
 React.useLayoutEffect(() => 
                       {
                        const pane = document.getElementById('layout-content');
                        if(pane)
                           pane.scrollTo(0, 0);
                       },
                       [props.subpage]);

 let content;

 switch(props.subpage)
       {
        case "index": 
             if(!narrow)
                return <Redirect to="/profile/main"/>;

             content = profile_edit_index(profile, props.profile_summary, tr_def);
             break;

        case "main":  content = profile_edit_main(props, tr_def);
                      break;
        case "ls":    content = profile_edit_ls(props, tr_def);
                      break;
        case "q":     content = profile_edit_q(tr_def);
                      break;
        case "qt":    content = profile_edit_qt(props, tr_def);
                      break;
        case "qr":    content = profile_edit_qr(props, tr_def);
                      break;
        case "qm":    content = profile_edit_qm(props, tr_def);
                      break;
        case "qe":    content = profile_edit_qe(props, tr_def);
                      break;
        case "qv":    content = profile_edit_qv(props, tr_def);
                      break;
        default:
             if(narrow)
                return <Redirect to="/profile/index"/>;
             else
                return <Redirect to="/profile/main"/>;
       }

 return <div id = "layout-content">
          <div className = "page-content unindented profile-editor">
            {content}
          </div>
        </div>;
}
