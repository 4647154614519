import React from 'react';
import {call_setter} from './call_setter';
import {useTranslation} from '../translations/useTranslation';

/*------------------------------------------------------------------------------------------------- 
Props:
 content    .. Pole hodnot selectionu. Pole objektů s následujícími prvky
               v: hodnota (může být i null)
               t: titulek zobrazený pro hodnotu v menu
               tid: identifikáto překladu titulku (pro useTranslation())
 rawContent .. Obsah pop-up okna. Měl by obsahovat elementy třídy "wnd-item" s atributem 'value',
               jehož hodnota se použije při vybrání daného elementu.
               Pokud je tento parametr zadán, 'content' se ignoruje
 value      .. aktuální hodnota; pokud je zadán parametr rawContent, jde přímo o zobrazovaný řetězec.
 data_id    .. předá se jako druhý parametr setteru
 setter     .. funkce vyvolaná při změně hodnoty. Nová hodnota se předá jako první parametr.
 type       .. volitelně typ hodnoty, která se má zapsat ("int" nebo "string"). Pokud není přítomna,
               použije se hodnota z content bez konverze. null se nikdy nekonvertuje
 className  .. jméno třídy vnějšího elementu 
------------------------------------------------------------------------------------------------- */ 
export function RadioButtons(props) 
{
 const t = useTranslation();

 //----------
 const on_click = e => 
    {
     //if(e.target.checked)
     if(e.target.getAttribute("mark") === "1")
       {
        props.setter(null);
       }
    }

//FIX_ME z nějakého záhadného důvodu se atribut 'checked' nepřenastavuje při opětovném renderování
//       proto jsem ho nahradit atributem 'mark'
 let btn_list = props.content.map(c => (<div className="rb-item" key = {c.v}>
                                         <input type  = "radio" 
                                                id    = {c.v}
                                                name  = {c.v}
                                                value = {c.v}
                                                checked = {props.value === c.v}
                                                mark = {props.value === c.v ? "1" : "0"}
                                                onClick = {props.optional ? on_click : null}
                                                onChange = {e => call_setter(props, e.target.value)}
                                                />
                                         <label htmlFor={c.v}>
                                           {c.t || (c.tid && t(c.tid))}
                                         </label>
                                        </div>));

 return (<div className={"radio-buttons" + (props.className ? " " + props.className : "") }>
          {btn_list}
         </div>
);
}
