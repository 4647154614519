import React, {useEffect} from 'react';
import {Header}   from './Header';
import {WaitIndicator} from './lib/WaitIndicator';
import {master} from './db/master';
import {useDbData} from './db/useDbData';
import {useTranslation} from './translations/useTranslation';
import './LoadingPage.css';

/*-------------------------------------------------------------------------------------------------
Přihlašovací stránka. Zobrazuje wait-indicator a případnou chybu při selhání přihlášení.
-------------------------------------------------------------------------------------------------*/
export function LoadingPage(props) 
{
 const init_state = 
    {
     display_progress: false 
    }

 const [state, set_state] = React.useState(init_state);
 let fatal_error = useDbData(['state', 'fatal_error']);
 const t = useTranslation();

 // loadovací obrazovku zobrazit až po 3 s, aby zbytečně neproblikla, když se profil načte hned:
 useEffect(() => {let t = setTimeout(() => set_state({...state, display_progress: true}), 3000);
                  return () => clearTimeout(t);});

 return <>
         <Header page_ref = {[]} />
         {state.display_progress &&
          <div className="loading-page">
           <div>
            {fatal_error ? 
              <>
               <div className="fatal-error"><b>{t('err_login')}</b></div>
               <div className="fatal-error">{fatal_error.msg}</div>
              </> : 
              <>
               <WaitIndicator delay={0}/>
               <div className="msg">{t('wait_login')}</div>
              </>}
             </div>
            <button onClick={() => master.db_logout()}>{t('hdr_logout')}</button>
          </div>}
        </>;
}
