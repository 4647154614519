/*#################################################################################################

Definuje hook na přístup k aktuální verzi překladu.

#################################################################################################*/

import React from 'react';
import {TranslationContext} from './translation';

/*-------------------------------------------------------------------------------------------------
Vrátí aktuální definici překladu.
Objekt definice má tyto členy:
    - lang          .. kód jazyka, jehož se definice týká
    - dict          .. slovník zpráv
    - country_names .. seznam lokalizovaných názvů států
    - lang_names    .. seznam lokalizovaných názvů jazyků 
    - translate     .. překladová funkce
-------------------------------------------------------------------------------------------------*/
export function useTranslationDef() 
{
 return React.useContext(TranslationContext);
}
