/*#################################################################################################

Stromeček domovské stránky.

#################################################################################################*/

import React from 'react';
import {useHistory} from 'react-router-dom';
import {analytics} from './config';
import {useDbData} from './db/useDbData';
import {SearchBox} from './controls/SearchBox';
import {TreeItem} from './controls/TreeItem';
import {UserList} from './controls/UserList';
import {UserTree} from './controls/UserTree';
import {master} from './db/master';
import {nav_view_user,
        nav_user_chat} from './navigate';
import {useTranslation} from './translations/useTranslation'
import './HomeTree.css';
import {WaitIndicator} from './lib/WaitIndicator';

/*------------------------------------------------------------------------------------------------- 
props  conn        .. seznam všech propojených uživatelů (profile.conn)
       conn_groups .. seskupené seznamy propojených uživatelů (viz App.js, conn_groups)
       url_params  .. parametry URL jako objekt URLSearchParams
       media       .. vlastnosti okna browseru; objekt s členy:
                      narrow .. okno je užší než 900px   
------------------------------------------------------------------------------------------------- */ 
export function HomeTree(props) 
{
 const conn = props.conn;
 const media = props.media;

 const init_state = 
   {
    search_result: null, // false = in progress; jinak pole vyhledaných profilů
    search_pin: {}       // uživatelé z výsledku, kteří se nemají vyhodit, ani když jsou v propojeních
                         // (použito pro výsledky nalezené v propojeních, aby se hned nevyhodili) 
   }

 const [state, set_state] = React.useState(init_state);
 const peer_state = useDbData(['state', 'peer_state']) || {};
 const url_history = useHistory();
 const t = useTranslation();

 React.useEffect(() =>
                { // Vyhodit výsledky, které už jsou v seznamu uživatelů:
                 if(state.search_result)
                   {
                    let new_sr = [];

                    for(let i = 0; i < state.search_result.length; i++)
                       {
                        const uid = state.search_result[i];
                        if(!conn[uid] || state.search_pin[uid])
                           new_sr.push(uid);
                       }
                    
                    if(!new_sr.length)   
                       set_state({...state, search_result: null});
                    else if(new_sr.length !== state.search_result.length)
                       set_state({...state, search_result: new_sr});
                   }
                }, [state, conn]);
 
 //------------
 // Vyhledání profilů podle řetězce
 const search = str =>
    {
     if(str === null)
       {
        set_state({...state, search_result: null}); // zrušit vyhledávání
        return;
       }
     
     analytics.logEvent("search_user");

     set_state({...state, search_result: false}); // označit, že probíhá hledání

     master.db_search_user(str)
     .then(result => 
          {
           let pin = {}; // označit ty z výsledku, kteří už byly v propojeních
           result.forEach(uid => {if(conn[uid]) pin[uid] = true;});
           set_state({...state, search_result: result, search_pin: pin});
          });
    }
 
 const selected_user = props.url_params.get("view_user");

 return <div className = "home-tree">
          {!selected_user &&
           <SearchBox placeholder = {t('srchu_lbl')}
                      tool_tip    = {t('srchu_ttip')}
                      search      = {search}/>}

          {state.search_result === false &&
           <WaitIndicator size = {16} />}

          {state.search_result && 
           <TreeItem title   = {t('srchu_rtitle')}
                     bold    = {true}
                     subtree = {<UserList conn        = {conn}
                                          users       = {state.search_result}
                                          selected    = {selected_user}
                                          peer_state  = {peer_state}
                                          page_id     = ""
                                          empty_title = {t('srchu_empty')}
                                          media       = {media}
                                          on_click    = {profile => nav_view_user(url_history, "/", profile)}/>}
            />}

          <UserTree conn          = {conn}
                    conn_groups   = {props.conn_groups}
                    selected      = {selected_user}
                    peer_state    = {peer_state}
                    page_id       = ""
                    media         = {media}
                    click_on_user = {profile => nav_user_chat(url_history, profile.uid)}/>
        </div>;
}

