/*#################################################################################################

Prvek umožňující měnit vlastnosti jednoho obrázku profilu.

#################################################################################################*/

import React from 'react';
import {AccControl} from './AccControl';
import {ImageView} from './ImageView';
import {profile_image_set_access,
        profile_image_delete} from '../db/images';
import './ImageEditor.css';

/*-------------------------------------------------------------------------------------------------
props  images .. seznam obrázků ve formátu profile.images
       n      .. pořadové číslo obrázku v seznamu, který se edituje. 
-------------------------------------------------------------------------------------------------*/
export function ImageEditor(props) 
{
 const images = props.images;
 const n = props.n;
 const img_def = images[n];

/*
 const init_state = 
   {
    mouse_in: false
   }

 const [state, set_state] = React.useState(init_state);

 return <div className = 'image-editor'
             onMouseEnter = {() => {if(!state.mouse_in) set_state({...state, mouse_in: true})}}
             onMouseLeave = {() => {if( state.mouse_in) set_state({...state, mouse_in: false})}}>
 */

 const overlay = <AccControl value = {img_def.acc}
                             no_private
                             setter = {val => profile_image_set_access(n, val)}
                             on_delete = {() => profile_image_delete(n)}/>

 return <div className = 'image-editor'>
          <ImageView images = {images} 
                     n = {n}
                     overlay = {overlay}/>
        </div>;
}
