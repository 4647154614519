import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter} from "react-router-dom";

// pro seznam Firebase komponent viz: https://firebase.google.com/docs/web/setup#libraries-bundle
import {firebase_init} from './config';
import './glagoli.css';
import {Main} from './Main';
import {db_init} from './db/db';


/*-------------------------------------------------------------------------------------------------
Vstupní bod aplikace, připojí Firebase a renderuje komponentu Reactu Main (z Main.js).
Funkce je vyvolána níže po testu speciálního URL.
-------------------------------------------------------------------------------------------------*/
function glagoli_main()
{
 firebase_init();
 db_init();

 // kód pro 'react-snap':
 const content = <React.StrictMode>
                   <BrowserRouter>
                     <Main/>
                   </BrowserRouter>
                 </React.StrictMode>;

 const root_element = document.getElementById("root");

 if(root_element.hasChildNodes()) 
    ReactDOM.hydrate(content, root_element);
 else
    ReactDOM.render(content, root_element);

/* ReactDOM.render(<React.StrictMode>
                   <BrowserRouter>
                     <Main/>
                   </BrowserRouter>
                 </React.StrictMode>,
                 document.getElementById('root'));*/
 
 // If you want your app to work offline and load faster, you can change
 // unregister() to register() below. Note this comes with some pitfalls.
 // Learn more about service workers: https://bit.ly/CRA-PWA
 // serviceWorker.unregister();
}

/*************************************************************************************************/
// Začátek skriptu

// Detekovat URL "email_verified"; je vyvoláno potom, co je na straně serveru ověřena emailová 
// adresa účtu. Zapíšeme příznak do local-storage, takže ostatní otevřené panely s Glagoli budou 
// moci refeshovat údaje o uživateli. 
// (Viz komentář v send_verification_mail.js)
if(window.location.pathname === '/email_verified')
  {
   try
      {
       localStorage.setItem('.email_verified', Date.now()); // zapsat aktuální čas pro případ, že by hodnota nebyla 
                                                           // ze storage vymazána, aby byla stejně odeslána 
                                                           // notifikace o její změně
       document.getElementById('root').innerHTML = "<p style='text-align: center; margin-top: 30px; font-size: 32px'; font-weight: 500><span style='color: #40C000'>✓</span> OK</p>";
      }
   catch(e) {}
  }
else
  {
   glagoli_main();
  }


