/*#################################################################################################

Obsah stránky chatu.

#################################################################################################*/

import React from 'react';
import {useLocation,
        useHistory} from 'react-router-dom';
import {useUserProfile} from './db/useUserProfile';
import {useTranslation} from './translations/useTranslation';
import {query_peer_state} from './db/user_state';
import {on_message_typing,
        mark_messages_read} from './db/messages';
import {ProfileView} from './profile/ProfileView';
import {SendRow} from './controls/SendRow';
import {MessagePane} from './controls/MessagePane';
import {UnreadMessages} from './controls/UnreadMessages';
import {Subheader} from './controls/Subheader';
import {ChatTree} from './ChatTree';

/*-------------------------------------------------------------------------------------------------
 props  profile     .. profil přihlášeného uživatele
        conn_groups .. propojení uživatelé rozdělení do skupin
        url_params  .. parametry URL jako objekt URLSearchParams
        media       .. vlastnosti okna browseru; objekt s členy:
                       narrow .. okno je užší než 900px   
-------------------------------------------------------------------------------------------------*/
export function Chat(props) 
{
 const profile     = props.profile;
 const conn_groups = props.conn_groups;
 const url_params  = props.url_params;
 const uid         = profile.uid;
 const media       = props.media;
 const narrow      = media.narrow;

 const location = useLocation();
 const url_history = useHistory();
 const t = useTranslation();

 const pm = location.pathname.match(/^\/chat\/(.+)$/);
 const other_uid = pm && pm[1];
 const view_user = props.url_params.get("view_user");

 const other_profile = useUserProfile(other_uid);

 React.useEffect(() => query_peer_state(other_uid));

 let content;

 if(view_user)
   {
    content = <ProfileView uid     = {view_user}
                           conn    = {profile.conn[view_user]}
                           page_id = 'chat'
                           media   = {media}/>;
   }
 else if(other_uid)
   {
    if(other_uid === uid)
      { // deviantní případ, že podle URL máme chatovat sami se sebou
       url_history.replace("/chat/");
       return null;
      }

    const conn = profile.conn[other_uid];

    content = <div id = "chat-main">
                {narrow &&
                 <Subheader type    = 'chat'
                            conn    = {conn}
                            page_id = 'chat'
                            profile = {other_profile}
                            sub     = {null/*subname*/}
                            media   = {media}/>}

                <MessagePane nick_name     = {profile.nick_name}
                             conn          = {conn}
                             other_profile = {other_profile}
                             page_id       = 'chat'/>
  
                {other_profile.$error !== 'not-found' ?
                 <SendRow rcv_uid = {other_uid}
                          onClick = {() => mark_messages_read(other_uid)}
                          on_key  = {() => on_message_typing(other_uid)}/> :
                 <div className = 'no-send-err'>
                  {t('err_no_send')}
                 </div>}
              </div>;
   } 
 else
   {
    let unread_messages;

    if(conn_groups.new_ochat.length || 
       conn_groups.new_other.length || 
       conn_groups.unread_msgs_chat.length)
      {
       unread_messages= <UnreadMessages conn_groups = {conn_groups}
                                        profile     = {profile}  
                                        page_id     = 'chat'/>;
      }

     
     content = <div id = 'layout-content'>
                 <div className = 'page-content'>
                   {unread_messages}
                 
                   {narrow && 
                    <ChatTree profile = {profile}
                              conn_groups = {conn_groups}
                              url_params = {url_params}
                              page_id = 'chat'
                              media = {props.media}/>}
                 
                   <h1>{t('ch_help_title')}</h1>
                   <ol>
                    <li>
                     {t('ch_help1')}
                    </li>
                    <li>
                     {t('ch_help2')}
                    </li>
                    <li>
                     {t('ch_help3')}
                    </li>
                    <li>
                     {t('ch_help4',
                        <span className="icon impress" key="icon impress"></span>)}
                    </li>
                    <li>
                     {t('ch_help5')}
                    </li>
                   </ol>
                 </div>
                </div>;
   }

 return content;
}
