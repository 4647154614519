/*#################################################################################################

Prvek zobrazující obrázek z profilu.

TODO: dodělat sideshow obrázků

#################################################################################################*/

import React from 'react';
import {profile_image_url} from '../db/images';
import './ImageView.css';

/*-------------------------------------------------------------------------------------------------
props  images  .. seznam obrázků ve formátu profile.images
       n       .. pořadové číslo obrázku v seznamu, který se edituje. 
       uid     .. uživatel, jemuž obrázky náležejí (pokud není zadán, jde o přihlášeného uživatele)
       overlay .. další uzly umístěné k obrázku (např. ikona vlastností)
-------------------------------------------------------------------------------------------------*/
export function ImageView(props) 
{
 const images = props.images;
 const n = props.n;
 const uid = props.uid;

 const img_def = images[n];

 const init_state = 
    {
     thumb_url: null,  // URL thumbnailu (false = probíhá zjišťování)
     slideshow: false, // true, pokud je obrázek zvětšený na celou obrazovku
     img_n: null,      // pro slideshow: index do images; aktuální zobrazený obrázek
     img_url: null     // URL plného obrázku (false = probíhá zjišťování)
    };

 const [state, set_state] = React.useState(init_state);

 React.useEffect(() => 
                {
                 if(state.thumb_url === null)
                   {
                    profile_image_url(img_def.id, true, uid)
                    .then(img_url => set_state(state => ({...state, thumb_url: img_url})))
                    .catch(err => console.error("Unable get thumb URL", err));
                    set_state({...state, thumb_url: false}); // označit, že načítání URL bylo zahájeno
                   } // eslint-disable-next-line react-hooks/exhaustive-deps
                }, [img_def.id, uid]);

 //-------------
 const start_slideshow = event =>
    {
     if(event.target.className.indexOf("acc-control") === -1)
       { // když se kliklo na access-control, nezvětšovat obrázek
        set_state({...state, 
                   slideshow: true, 
                   img_n: n,
                   img_url: false});
        
        profile_image_url(img_def.id, false, uid)
        .then(img_url => set_state(state => ({...state, img_url: img_url})))
        .catch(err => console.error("Unable get img URL", err));

        event.preventDefault();
       }
    }

 //-------------
 const end_slideshow = event =>
    {
     set_state({...state,
                slideshow: false, 
                img_n: null,
                img_url: null});

     event.stopPropagation();
     event.preventDefault();
    }

 return <div className = {'image-view' + (props.overlay ? '' : ' box')}
              onClick = {start_slideshow}>
          <img src = {state.thumb_url||""} alt = ""/>
          {props.overlay}
          {state.slideshow &&
           <div className='slide'
                onClick = {end_slideshow}>
           <img src = {state.img_url||""} alt = ""/>
          </div>}
        </div>;
}
