import React from 'react';
import {ProfileQItem} from './ProfileQItem';
import {BottomNav} from './BottomNav';

/*------------------------------------------------------------------------------------------------- 
Vrací editor dotazníku "morálka".
------------------------------------------------------------------------------------------------- */ 
export function profile_edit_qm(props, tr_def)
{
 const profile = props.profile;

 const t = tr_def.translate;

 return <>
      <h1>{props.back_btn}{t('qm.lbl')}</h1>

      <div className = "desc">
       <p>{t('qm.desc')}</p>
       <p>{t('qm.desc2')}</p>
      </div>

      <ProfileQItem data_id = "qm1"
                    text_main = {t('qm1.main')}
                    text_min = {t('q_nimp')}
                    text_max = {t('q_vimp')}
                    acc_icon
                    profile = {profile}/>

      <ProfileQItem data_id = "qm2"
                    text_main = {t('qm2.main')}
                    text_min = {t('q_nimp')}
                    text_max = {t('q_vimp')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qm3"
                    text_main = {t('qm3.main')}
                    text_min = {t('q_nimp')}
                    text_max = {t('q_vimp')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qm4"
                    text_main = {t('qm4.main')}
                    text_min = {t('q_nimp')}
                    text_max = {t('q_vimp')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qm5"
                    text_main = {t('qm5.main')}
                    text_min = {t('q_nimp')}
                    text_max = {t('q_vimp')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qm6"
                    text_main = {t('qm6.main')}
                    text_min = {t('q_nimp')}
                    text_max = {t('q_vimp')}
                    profile = {profile}/>

      <div className = "desc">
       <p>{t('qm.desc3')}</p>
      </div>

      <ProfileQItem data_id = "qm7"
                    text_bold = {t('qm7.ttl')}
                    text_main = {"– " +
                                 t('qm7.a') + "\n– " +
                                 t('qm7.b')
                                 }
                               // You see a teenage boy chuckling at an amputee he passes by while on the subway.
                               // You see a woman swerving her car in order to intentionally run over a cat.
                    text_min = {t('q_nimm')}
                    text_max = {t('q_vsrs')}
                    acc_icon
                    profile = {profile}/>

      <ProfileQItem data_id = "qm8"
                    text_bold = {t('qm8.ttl')}
                    text_main = {"– " +
                                 t('qm8.a') + "\n– " +
                                 t('qm8.b')
                                 }
                               // You see a runner taking a shortcut on the course during the marathon in order to win. 
                               // You see a professor giving a bad grade to a student just because he dislikes him.
                    text_min = {t('q_nimm')}
                    text_max = {t('q_vsrs')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qm9"
                    text_bold = {t('qm9.ttl')}
                    text_main = {"– " +
                                 t('qm9.a') + "\n– " +
                                 t('qm9.b')
                                 }
                               // You see the US Ambassador joking in Great Britain about the stupidity of Americans.
                               // You see an employee joking with competitors about how bad his company did last year.
                    text_min = {t('q_nimm')}
                    text_max = {t('q_vsrs')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qm10"
                    text_bold = {t('qm10.ttl')}
                    text_main = {"– " +
                                 t('qm10.a') + "\n– " +
                                 t('qm10.b')
                                 }
                               // You see a woman spray painting graffiti across the steps of the local courthouse.
                               // You see a staff member talking loudly and interrupting the mayor's speech to the public.
                    text_min = {t('q_nimm')}
                    text_max = {t('q_vsrs')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qm11"
                    text_bold = {t('qm11.ttl')}
                    text_main = {"– " +
                                 t('qm11.a') + "\n– " +
                                 t('qm11.b')
                                 }
                               // You see a very drunk woman making out with multiple strangers on the city bus
                               // You see a teenager urinating in the wave pool at a crowded amusement park.
                    text_min = {t('q_nimm')}
                    text_max = {t('q_vsrs')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qm12"
                    text_bold = {t('qm12.ttl')}
                    text_main = {"– " +
                                 t('qm12.a') + "\n– " +
                                 t('qm12.b')
                                 }
                               // You see a man forbidding his wife to wear clothing that he has not first approved.
                               // You see a man telling his fiance that she has to switch to his political party.
                    text_min = {t('q_nimm')}
                    text_max = {t('q_vsrs')}
                    profile = {profile}/>

      <BottomNav left  = {{tid: 'qr.lbl', url: "/profile/qr"}}
                 right = {{tid: 'qe.lbl', url: "/profile/qe"}}/>
    </>;
}

