/*#################################################################################################

Prvek umožňující vybrat nový obrázek profilu.

#################################################################################################*/

import React from 'react';
import {profile_image_add} from '../db/images';
import {useTranslation} from '../translations/useTranslation';
import './ImagePicker.css';

/*-------------------------------------------------------------------------------------------------
props limit .. (volitelné) maximální počet obrázků (pouze pro zobrazení informace uživateli)
-------------------------------------------------------------------------------------------------*/
export function ImagePicker(props) 
{
 const [state, set_state] = React.useState({over_target: false});
 const drop_tgt_ref = React.useRef(null);
 const file_input_ref = React.useRef(null);
 const t = useTranslation();

 //------------
 // Vrací true, když jde event e přes drop target.
 const over_target = e => drop_tgt_ref.current.contains(e.target);

 //------------
 // Vrací true, když jde instanci File s obrázkem.
 const is_image = f => ['image/jpeg', 'image/gif', 'image/png', 'image/bmp'].indexOf(f.type) >= 0;

 //------------
 // Vyvoláno, když je obrázek zadán pomocí dialogu na výběr souboru
 const on_file_selected = e =>
    {
     const file = e.target.files[0];
     if(file && is_image(file))
        profile_image_add(file);
    }
 
 //------------
 const on_drag_move = e =>
    {
     e.dataTransfer.dropEffect = 'none';

     if(is_image(e.dataTransfer.items[0]))
       {
        if(over_target(e))
          {
           if(!state.over_target)
              set_state({over_target: true});

           e.dataTransfer.dropEffect = 'copy';
          }
        else
          {
           if(state.over_target)
              set_state({over_target: false});
          }
       }

     e.stopPropagation();
     e.preventDefault();
    }

 //------------
 const on_drop = e =>
    {
     const f = e.dataTransfer.files[0];

     if(is_image(f) && over_target(e))
        profile_image_add(f);

     set_state({over_target: false});     

     e.stopPropagation();
     e.preventDefault();
    }
 
 React.useEffect(() =>
                {
                 window.addEventListener("dragenter", on_drag_move);
                 window.addEventListener("dragover",  on_drag_move);
                 window.addEventListener("drop",      on_drop);

                 return () => 
                        {
                         window.removeEventListener("dragenter", on_drag_move);
                         window.removeEventListener("dragover",  on_drag_move);
                         window.removeEventListener("drop",      on_drop);
                        }
                });

 
 return <>
          <div className = {'image-picker' + (state.over_target ? ' drag' : '')}
               onClick   = {() => file_input_ref.current.click()}
               ref = {drop_tgt_ref}>
           <div>
            <span className='plus'>+</span><br/>
            {t('clict_or_drop')}
            {props.limit && 
             <>
              <br/>
              <span className='small'>({t('max_items', props.limit)})</span>
             </>
            }
           </div>
          </div>
          <input type     = "file" 
                 accept   = ".jpg,.jpeg,.png,.gif" 
                 style    = {{'display':'none'}}
                 onChange = {on_file_selected}
                 ref      = {file_input_ref}/>
        </>;
}
