/*#################################################################################################

Kořenová komponenta aplikace, pokud je uživatel přihlášen.

#################################################################################################*/

import React from 'react';
import {Redirect,
        useLocation} from 'react-router-dom';
import {config,
        remote_config} from './config';
import {defer_call} from './utils';
import {master} from './db/master';
import {useDbData} from './db/useDbData';
import {get_profile_summary} from './user';
import {LoadingPage} from './LoadingPage';
import {DisabledPage} from './DisabledPage';
import {Header} from './Header';
import {HomeTree} from './HomeTree';
import {Home} from './Home';
import {OpenDate} from './OpenDate';
import {ChatTree} from './ChatTree';
import {Chat} from './Chat';
import {ProfileTree} from './profile/ProfileTree';
import {Profile} from './profile/Profile';
import {NotificationArea} from './controls/NotificationArea';
import {ImpressionEditor} from './controls/ImpressionEditor';
import {set_message_box_set_state,
        MessageBox} from './controls/MessageBox';
const {CONN_REL_FRIEND,
       CONN_REL_ACQ,
       CONN_REL_REMOVED,
       CONN_REL_BLOCKED} = require('./profile_def.js');

let prev_main_page_id = ""; // id hlavní stránky použité při posledním renderování
let remote_config_fetch_pending = false;
export let remove_uid_from_url;  // funkce, jíž se předá UID, která má být odstraněno z URL

let logout_timer = 0; // id timeru na odhlášení, pokud jsme přihlášeni pod zakázaným profilem

let message_blinker_id = 0; // id timeru na blikání titulku při doručené zprávě
let message_blinker_start; // timestamp začátku blikání (bliká se max 1 hodinu)
const MSG_TITLE_PREFIX = "* ";

/*-------------------------------------------------------------------------------------------------
Call-back na timer pro blikání hvězdičky v titulku.
-------------------------------------------------------------------------------------------------*/
function msg_blinker()
{
 if(Date.now() - message_blinker_start > 3600000 /*1h*/)
   { // po hodině doblikat, ale nechat hvězdičku
    if(!document.title.startsWith(MSG_TITLE_PREFIX))
       document.title = MSG_TITLE_PREFIX + document.title;

    clearInterval(message_blinker_id);
    message_blinker_id = 0;

    return;
   }

 document.title = document.title.startsWith(MSG_TITLE_PREFIX) ?
                     document.title.substr(MSG_TITLE_PREFIX.length)
                   : MSG_TITLE_PREFIX + document.title;
}

/*-------------------------------------------------------------------------------------------------
Zapne/vypne blikání hvězdičky v titulku.
-------------------------------------------------------------------------------------------------*/
function set_message_blinker(b)
{
 if(b)
   {
    if(message_blinker_id) return;

    message_blinker_start = Date.now();
    message_blinker_id = setInterval(msg_blinker, 1500);
   }
 else
   {
    // Hvězdička mohla být nechána v titulku, když blikání po hodině přestalo:
    if(document.title.startsWith(MSG_TITLE_PREFIX))
       document.title = document.title.substr(MSG_TITLE_PREFIX.length);

    if(!message_blinker_id) return;

    clearInterval(message_blinker_id);
    message_blinker_id = 0;
   }
}

/*-------------------------------------------------------------------------------------------------
props  media .. vlastnosti okna browseru; objekt s členy:
                narrow .. okno je užší než 900px   
-------------------------------------------------------------------------------------------------*/
export function AppLoggedIn(props) 
{
 const media = props.media;
 const narrow = media.narrow;

 const location = useLocation();
 const profile  = useDbData(['profile']);
 const [message_box_state, message_box_set_state] = React.useState({});
 const [url_remove, set_url_remove] = React.useState();
 const [wnd_height, set_wnd_height] = React.useState(window.innerHeight);
   // wnd_height je vnitří velikost okna, která se níže nastavuje jako výška hlavního elementu layoutu.
   // Je to potřeba kvůli mobilům, které zmenšují plochu okna při zobrazení adresního řádku a
   // virtuální klávesnice. Tyto změny se různě nekonsistentně promítají do CSS jednotek vh,
   // takže při použití 100vh někdy bývá např. odesílací řádek zprávy chatu překryt klávesnicí.
   // Na desktopu je to jedno, tak je to tu ponecháno obecně.

 set_message_box_set_state(message_box_set_state);

 const conn = profile && profile.conn;
 const unread_msgs = profile && profile.cache && profile.cache.unread_msgs;

 React.useEffect(() =>
                {
                 const upd_wnd_height = () => set_wnd_height(window.innerHeight);

                 window.addEventListener('resize', upd_wnd_height);

                 return () => 
                        {
                         window.removeEventListener('resize', upd_wnd_height);
                         set_message_blinker(false);
                        }
                }, []);

 React.useEffect(() =>
                {
                 // Pokud jsme přihlášeni se zakázaným profilem, naplánovat odhlášení:
                 if(profile && profile.user_state && profile.user_state < 0)
                   {
                    if(!logout_timer)
                       logout_timer = setTimeout(() => master.db_logout(), 60000/*1 minuta*/);
                   }
                 else
                   { // pokud byl profil obnoven, zrušit timer na odhlášení
                    if(logout_timer)
                      {
                       clearTimeout(logout_timer);
                       logout_timer = 0;
                      }
                   }

                 // Zkontrolovat, jestli se nemá aplikace znovu načíst, protože je na serveru nové verze
                 if(!remote_config) return;

                 // - zkontrolovat, jestli se nemá znovu načíst Remote Config se serveru:
                 if(!remote_config_fetch_pending &&
                    (remote_config.fetchTimeMillis < 0 ||
                     Date.now() - remote_config.fetchTimeMillis > remote_config.settings.minimumFetchIntervalMillis))
                   {
                    remote_config_fetch_pending = true;

                    remote_config.fetchAndActivate()
                                 .then(() => 
                                      {
                                       console.log("#RemoteConfig fetch app_version:", remote_config.getNumber('app_version'));
                                       remote_config_fetch_pending = false;
                                      })
                                 .catch(err =>
                                      {
                                       console.error("#RemoteConfig error:", err);
                                       remote_config_fetch_pending = false;
                                      })
                   }

                 // - zkontrolovat verzi:
                 const app_version_remote = remote_config.getNumber('app_version');

                 if(app_version_remote > config.app_version && Date.now() - config.app_load_time > remote_config.settings.minimumFetchIntervalMillis/12/*3600000*//*1h*/)
                    window.location.reload(); // Aplikaci nikdy nereloadovat dříve než za hodinu pos spuštění pro případ, 
                                              // že by v Remote Config byla chyba. Potom by se aplikace reloadovala neustále.
                                              // (minimální fetch interval je stejně 12h)
                                              // FIXME dát čas posledního reloadu do sessionStorage, aby poprvé reloadovala hned
                });

 React.useEffect(() =>
                {
                 remove_uid_from_url = set_url_remove;
                 return () => remove_uid_from_url = null;
                }, []);

 // Roztřídění propojených uživatelů podle úrovně propojení.
 // Všechny skupiny jsou pole 'uid' utříděné podle klesajícího času 
 // msg_time, create_time a engage_time (použije se první definovaný v uvedeném pořadí).
 // Ve skupinách favorite a open_date jsou uživatelé duplicitně vzhledem ke skupinám friend/acq/other.
 const conn_groups = React.useMemo(() =>
    {
     let conn_groups = {friend:            [],
                        acq:               [],
                        other:             [],
                        favorite:          [], 
                        open_date:         [],
                        unread_msgs:       [],  // uživatelé s nepřečtenými zprávami, kromě nových uživatelů (ty jsou v new_..)
                        unread_msgs_odate: [],  // - z toho uživatelé z open-date
                        unread_msgs_chat:  [],  // - zbylí uživatelé
                        new_odate:         [],  // noví uživatelé z open-date
                        new_ochat:         [],  // noví uživatelé z open-chat
                        new_other:         []}; // ostatní noví uživatelé (např. vyhledané podle mailu/přezdívky)
    
     if(conn)
       {
        for(let uid in conn)
           {
            let c = conn[uid];
            if(c.rel !== CONN_REL_REMOVED && c.rel !== CONN_REL_BLOCKED)
              {
               if(c.rel === CONN_REL_FRIEND)
                  conn_groups.friend.push(uid);
               else if(c.rel === CONN_REL_ACQ)
                  conn_groups.acq.push(uid);
               else if(!c.favorite) // když je 'oblíbený', nedávat ho do 'ostatních'; pokud je však zároveň 'přítel'/'známý', nechat ho i v té skupině, aby byla vidět jeho přístupová práva
                  conn_groups.other.push(uid);
               
               if(c.favorite)
                  conn_groups.favorite.push(uid);
               if(c.open_date)
                  conn_groups.open_date.push(uid);
              }
           }
        
        const cmp_fn = (a, b) => {const ac = conn[a];
                                  const bc = conn[b];
                                  const at = ac.msg_time || ac.create_time || ac.engage_time;
                                  const bt = bc.msg_time || bc.create_time || bc.engage_time;
                                  return bt - at;}
        
        conn_groups.friend.sort(cmp_fn);
        conn_groups.acq.sort(cmp_fn);
        conn_groups.other.sort(cmp_fn);
        conn_groups.favorite.sort(cmp_fn);
        conn_groups.open_date.sort(cmp_fn);
       }
     
     for(let k in unread_msgs)
        {
         if(unread_msgs[k] && unread_msgs[k].length)
           {
            const m = unread_msgs[k][0];

            if(m.new_user)
              {
               if(m.aim === 'odate')
                  conn_groups.new_odate.push(k);
               else if(m.aim === 'ochat')
                  conn_groups.new_ochat.push(k);
               else
                  conn_groups.new_other.push(k);
              }
            else
              {
               conn_groups.unread_msgs.push(k);
              }
           }
        }
     
     // upořádat podle času poslední zprávy (poslední dřív):
     conn_groups.unread_msgs.sort((a, b) => unread_msgs[b][unread_msgs[b].length-1].t -
                                            unread_msgs[a][unread_msgs[a].length-1].t);

     // roztřídit uživatele s novými zprávamy na ty z open-date a z chatu:
     for(let i = 0; i < conn_groups.unread_msgs.length; i++)
        {
         const uid = conn_groups.unread_msgs[i];
         if(conn[uid] && conn[uid].open_date)
            conn_groups.unread_msgs_odate.push(uid);
         else
            conn_groups.unread_msgs_chat.push(uid);
        }
     
     return conn_groups;
    }, [conn, unread_msgs]);

 React.useEffect(() => {if(media.hover) // media.hover je přibližný test, zda je o desktop (na mobilu neblikat, titulek se stejně nezobrazuje)
                           set_message_blinker(conn_groups.unread_msgs.length);});

 if(!profile) return null; // probíhá odhlašování

 const page_ref = location.pathname.substring(1).split("/");
 const main_page_id = page_ref[0] || "";

 let url_params = new URLSearchParams(location.search);

 if(main_page_id !== 'profile' && prev_main_page_id === 'profile')
    master.db_flush('profile'); // pokud se aplikace přepne mimo profil, vynutit uložení změn
 
 prev_main_page_id = main_page_id;

 if(!profile.nick_name && profile.$loading)
   { // probíhá přihlašování
    return <div className = "layout-top" style = {{height: wnd_height}}>
            <LoadingPage/>
           </div>;
   }

 if(profile.user_state && profile.user_state < 0)
   {
    return <div className = "layout-top" style = {{height: wnd_height}}>
            <DisabledPage profile = {profile}/>
           </div>;
   }

 // Pokud je naplánováno vyhození UID z URL, vyhodit ho a provést redirekci:
 if(url_remove)
   {
    let redir_path;
    let params_chng = false;

    if(page_ref[1] === url_remove)
       redir_path = "/"+page_ref[0];

    if(url_params.get('view_user') === url_remove)
      {
       url_params.delete('view_user');
       params_chng = true;
      }

    defer_call(() => set_url_remove(null));

    if(redir_path || params_chng)
       return <Redirect to={(redir_path || location.pathname)+url_params.toString()} />;
   }

 const profile_summary = get_profile_summary(profile);

 let page_tree;
 let page_content;
 let display_header = !url_params.get('view_user');

 switch(main_page_id)
       {
        case "":
             if(!narrow)
                page_tree = <HomeTree conn        = {profile.conn}
                                      conn_groups = {conn_groups}
                                      url_params  = {url_params}
                                      media       = {media}/>;

             page_content =  <Home profile     = {profile}
                                   conn        = {profile.conn}
                                   conn_groups = {conn_groups}
                                   url_params  = {url_params}
                                   media       = {media}/>
             break;

        case "odate":
             if(!narrow)
                page_tree = <ChatTree profile     = {profile}
                                      conn_groups = {conn_groups}
                                      url_params  = {url_params}
                                      page_id     = 'odate'
                                      media       = {media}/>;

             page_content = <OpenDate profile     = {profile}
                                      conn_groups = {conn_groups}
                                      url_params  = {url_params}
                                      media       = {media}/>;

             display_header = page_ref.length===1;
             break;

        case "chat":
             if(!narrow)
                page_tree = <ChatTree profile     = {profile}
                                      conn_groups = {conn_groups}
                                      url_params  = {url_params}
                                      page_id     = 'chat'
                                      media       = {media}/>;

             page_content = <Chat profile     = {profile}
                                  conn_groups = {conn_groups}
                                  url_params  = {url_params}
                                  media       = {media}/>;

             display_header = page_ref.length===1;
             break;

        case "profile":
            {
             let subpage = page_ref[1] || "";

             if(!narrow)
                page_tree = <ProfileTree subpage = {subpage}
                                         profile = {profile}
                                         profile_summary = {profile_summary}
                                         media   = {media} />;
             
             // Podle id = "layout-content" se vyhledává panel se scroll-barem.
             // Pokud se prvek přejmenuje nebo odstraní, musí se upravit kód v ProfileEditor.
             page_content = <Profile subpage = {subpage}
                                     profile = {profile}
                                     profile_summary = {profile_summary}
                                     media   = {media}/>
             break;
            }
        default:
             return <Redirect to="/" />;
       }
 
 /* Regulérní layout aplikace má tyto panely (rozmístěné vedle sebe, pokrývají viditelnou část obrzovky,
    scrollování probíhá uvnitř nich):
    - Header (#header-bg #header)
    - #layout-main            - vše kromě headeru
      - #layout-tree          - levý panel (proměnná page_tree)
      - #layout-content-main  - pravý panel (celá část napravo od layout-tree)
        - #nf-area            - nescroluje, je vždy nahoře nad vlastním obsahem
                                (obsahuje např. chybové hlášení)
        - #layout-content     - vlastní obsah stránky (proměnná page_content)
                                vpřípadě potřeby zobrazuje obsahu scroll-bar
 */

 return <>
        <div className = "layout-top" style = {{height: wnd_height}}>
          {(!narrow || display_header) &&
           <Header profile         = {profile}
                   profile_summary = {profile_summary}
                   conn_groups     = {conn_groups}
                   page_ref        = {page_ref}
                   media           = {media}/>}

          <div id="layout-main">
            {page_tree && 
             <div id="layout-tree">
              {page_tree}
             </div>}
            <div id="layout-content-main">
              <NotificationArea profile = {profile}
                                media   = {media}/> 
              {page_content}
            </div>
          </div>
         {url_params.get('impress') && 
           <div className = 'modal-window'>
            {<ImpressionEditor url_params = {url_params}
                               profile    = {profile}
                               media      = {media}/>}
           </div>}
         {message_box_state.displayed &&
           <div className = 'modal-window'>
            <MessageBox def   = {message_box_state}
                        media = {media}/>
           </div>}
        </div>
        {/*<div className = 'TEST'></div>*/}
        </>;
}

