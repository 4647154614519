import React from 'react';
import {ProfileQItem} from './ProfileQItem';
import {BottomNav} from './BottomNav';

/*------------------------------------------------------------------------------------------------- 
Vrací editor dotazníku "úspěchy a neúspěchy" (explanatory style).
------------------------------------------------------------------------------------------------- */ 
export function profile_edit_qe(props, tr_def)
{
 const profile = props.profile;

 const t = tr_def.translate;

 const si_group = ["qe1", "qe2", "qe3"];
 const fi_group = ["qe6", "qe7", "qe8"];

 return <>
      <h1>{props.back_btn}{t('qe.lbl')}</h1>

      <div className = "desc">
       <p>{t('qe.desc')}</p>
      </div>

      <ProfileQItem data_id   = "qe1"
                    text_main = {t('qe1.main')}
                    text_min  = {t('q_dagr')}
                    text_max  = {t('q_agr')}
                    group     = {si_group}
                    acc_icon
                    profile   = {profile}/>

      <ProfileQItem data_id = "qe2"
                    text_main = {t('qe2.main')}
                    text_min = {t('q_dagr')}
                    text_max = {t('q_agr')}
                    group = {si_group}
                    profile = {profile}/>

      <ProfileQItem data_id = "qe3"
                    text_main = {t('qe3.main')}
                    text_min = {t('q_dagr')}
                    text_max = {t('q_agr')}
                    group = {si_group}
                    profile = {profile}/>

      <ProfileQItem data_id = "qe4"
                    text_min = {t('qe4.min')}
                    text_max = {t('qe4.max')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qe5"
                    text_min = {t('qe5.min')}
                    text_max = {t('qe5.max')}
                    profile = {profile}/>

      <div className = "desc">
       <p>{t('qe.desc2')}</p>
      </div>
      <ProfileQItem data_id = "qe6"
                    text_main = {t('qe6.main')}
                    text_min = {t('q_dagr')}
                    text_max = {t('q_agr')}
                    group = {fi_group}
                    acc_icon
                    profile = {profile}/>

      <ProfileQItem data_id = "qe7"
                    text_main = {t('qe7.main')}
                    text_min = {t('q_dagr')}
                    text_max = {t('q_agr')}
                    group = {fi_group}
                    profile = {profile}/>

      <ProfileQItem data_id = "qe8"
                    text_main = {t('qe8.main')}
                    text_min = {t('q_dagr')}
                    text_max = {t('q_agr')}
                    group = {fi_group}
                    profile = {profile}/>

      <ProfileQItem data_id = "qe9"
                    text_min = {t('qe9.min')}
                    text_max = {t('qe9.max')}
                    profile = {profile}/>

      <ProfileQItem data_id = "qe10"
                    text_min = {t('qe10.min')}
                    text_max = {t('qe10.max')}
                    profile = {profile}/>

      <div className = "desc">
       <p>{t('qe.desc3')}</p>
      </div>
      <ProfileQItem data_id = "qe11"
                    text_min = {t('qe11.min')}
                    text_max = {t('qe11.max')}
                    acc_icon
                    profile = {profile}/>

      <BottomNav left  = {{tid: 'qm.lbl', url: "/profile/qm"}}
                 right = {{tid: 'qv.lbl', url: "/profile/qv"}}/>
    </>;
}

