/*#################################################################################################

Definuje hook na profil daného uživatele. Pokud profil není načten, zahájí jeho načítání.

#################################################################################################*/

import React from 'react';
import {useDbData} from './useDbData';
import {master} from './master';
import {db_get_data,
        storage_get} from './app_state';
import {current_master} from './master';

/*-------------------------------------------------------------------------------------------------
Vrací profil daného uživatele. Musí jít o jiného uživatele, než je nyní přihlášený.
Pokud profil není načtený, zahájí jeho načítání.
Pokud je profil načítaný, obsahuje položku $loaging. Pokud při načítání nastala chyba, obsahuje
$error = true;
Pokud profil ještě není načtený, pokusí se převzít známá data o uživateli z connections.
V tom případě obsahuje místo first_name a last_name položku full_name.
Pokud je uid false/null/undefined, vrací null. To lze použít pro podmíněný přístup k profilu.
Pokud je parametr cache_only true, vrací pouze data z cache/conn, neinicializuje nové načtení
-------------------------------------------------------------------------------------------------*/
export function useUserProfile(uid, cache_only=false) 
{
 const profile = useDbData(uid ? ['user', uid] : null);

 React.useEffect(() =>
                {
                 if(!uid || cache_only)
                    return;

                 if(profile && (!profile.$loading || profile.$loading === current_master))
                   {
                    // otestovat, jestli není cache prošlá
                    const sid = 'user.'+uid+'$state';
                    const $state = storage_get(sid);
   
                    if($state && $state.acc && (Date.now() - $state.rd) > 3*60*60*1000)
                      { // cache profilu expiruje po 3 hodinách
                        // (pokud spolu chatují, timestamp profilu se přenáší se zprávami,
                        //  takže se změny načítají okamžitě)
                       master.db_read_profile(uid);
                      }

                    return; // profil byl načten, nemusí se načítat znovu.
                   }

                 // Při vstupu do useUserProfile() nebyl profil načten:
                 const p = db_get_data(['user', uid]); // Načíst profil uživatele z dat znovu,
                 // protože mohlo být ve frontě více volání useEffect a tedy načítání již
                 // mohlo být vyvoláno v přechozím useEffece a zde by se profil načítal
                 // zbytenčně podruhé.

                 // FIXME něco udělat při erroru
                 if(!p || /*p.$error ||*/ (p.$loading && p.$loading !== current_master))
                    master.db_read_profile(uid)
                });

 if(!uid)
    return null;
 
 let result = profile || {uid: uid, $incomplete: true};
 
 if(!result.nick_name)
   { // profil je prázdný, převzít známé položky z connections
    const conn = db_get_data(['profile', 'conn', uid]);
    if(conn)
      {
       result = {...result};
       if(conn.nick_name)
          result.nick_name = conn.nick_name;
       if(conn.sex)
          result.sex = conn.sex;
       if(conn.full_name)
          result.full_name = conn.full_name;
      }
   }

 return result;
}
