/*#################################################################################################

Seznam uživatelů (pro levý panel)

#################################################################################################*/

import React from 'react';
import {UserEntry} from './UserEntry';
import {useTranslation} from '../translations/useTranslation';
import './UserList.css';

/*-------------------------------------------------------------------------------------------------
 props  conn        .. seznam všech propojených uživatelů (profile.conn)
        users       .. seznam (pole) uid, která se mají zobrazit
        selected    .. uid uživatele, který má být zvýrazněn (nebo null)
        peer_state  .. objekt s informacemi o aktivitě ostatních uživatelů
        bold        .. seznam uživatelů, kteří mají být vykresleni s tučným písmem
        page_id     .. identifikátor stránky, kde je uzel zobrazen ("" = home, "chat")
        empty_title .. titule zobrazený, když je seznam prázdný (default: "Prázdný")
        media       .. vlastnosti okna browseru; objekt s členy:
                       narrow .. okno je užší než 900px   
        on_click    .. funkce vyvolaná při kliknutí na položku; parametrem je profile
 -------------------------------------------------------------------------------------------------*/
export function UserList(props) 
{
 const t = useTranslation();

 const users = props.users;
 const media = props.media;

 let content = [];

 for(let i = 0; i < users.length; i++)
    {
     const u = users[i];

     const ps = props.peer_state && props.peer_state[u];
     const active = ps && ps.active;

     content.push(<UserEntry key        = {u}
                             conn       = {props.conn}
                             uid        = {u}
                             selected   = {u === props.selected}
                             active     = {active}
                             bold       = {!!props.bold && props.bold.indexOf(u) >= 0}
                             page_id    = {props.page_id}
                             media      = {media}
                             on_click   = {props.on_click}/>);
    }
 
 if(content.length === 0)
    return <div className='user-list-empty'>{props.empty_title || t('empty_list')}</div>;
 
 return content;
}
