/*#################################################################################################

Komunikace stavu uživatele serveru.

Relevantní aspekty stavu:

  status  .. stav uživatele: 
             'active' .. uživatel aplikaci aktivně používá
             'sleep'  .. uživatel aplikaci nezavřel, ale po 15 minut s ní neintaragoval
             'close'  .. uživatel zavřel poslední okno bez odhlášení
             'off'    .. uživatel se odhlásil.
  ochat   .. (bool) je povolen otevřený chat?
  odate   .. (bool) je povoleno otevřené setkání?
             Atributy 'ochat' a 'odate' se nastavují automaticky podle změny profilu uživatele.
             Aplikaci stačí změnit je v profilu.

#################################################################################################*/

import {firebase_functions} from '../config';
import {norm_time_to_ms} from '../user';
import {master} from './master';
import {db_get_data, 
        db_set_data,
        db_watch_data,
        db_unwatch_data} from './app_state';
import {current_uid} from './db';

const {CONN_REL_FRIEND,
       CONN_REL_ACQ,
       CONN_REL_REMOVED,
       CONN_REL_BLOCKED} = require('../profile_def.js');

let log_pending = false; // pokud je true, má se zaznamenat aktivita, až se načte profil
let keep_alive_timer_id = 0;
let state_generation = 0;

/*-------------------------------------------------------------------------------------------------
Vyvolá funkci pro zjištění stavu daných uživatelů.

Poznámka: stav vybraných uživatelů se zjišťuje pokaždé, když hlásíme serveru vlastní aktivitu
          (tj. každých 15 minut). Tuto funkce je vhodné vyvolat, pokud se má aktivita daného
          uživatele ověřit okamžitě (např. při zobrazení profilu nebo přepnutí na chat.
          Pokud byla aktivita uživatele ověřována nedávno, nedělá nic.

Parametry:
 peers .. pole UID uživatelů, jejichž stav nás zajímá. Lze předat i jediné UID přímo.
          Pokud je null/nezadán, nedělá nic.
-------------------------------------------------------------------------------------------------*/
export function query_peer_state(peers)
{
 if(!peers) return;

 if(!Array.isArray(peers))
    peers = [peers];

 let q;

 const peer_state_ref = ['state', 'peer_state'];
 let peer_state = db_get_data(peer_state_ref);

 if(peer_state)
   {
    q = [];

    const now = Date.now();
    const ts_lim = now - 5*60000/*5 minut*/;

    // Odfiltrovat uživatele, na které jsme se v posledních 5 minutách už ptali:
    for(let i = 0; i < peers.length; i++)
       {
        const uid = peers[i];
        let ps = peer_state[uid];

        if(!ps || ps.ts < ts_lim)
          {
           q.push(uid);

           // Aktualizovat 'ts' v peer_state:
           let ns = {ts: now};
           if(ps && ps.active !== undefined)
              ns.active = ps.active;

           peer_state[uid] = ns;
          }
       }

    db_set_data(peer_state_ref, 'set', peer_state);
   }
 else
   {
    q = peers;
   }

 if(q.length > 0)
    keep_alive(q);
}

/*-------------------------------------------------------------------------------------------------
Vyvolá funkci fn a parametrem param. Pokud funkce selže, po chvíli ji zkusí vyvolat znovu.

parametry:
  fn    .. funkce, která se má vyvolat (set_user_state na serveru)
  param .. parametr funkce
  c     .. čítač opakování (vyvolá se s 0, při každém pokusu se zvýší o 1)
           Prodleva před dalším pokusem je 2^c · 100 ms.
  lim   .. hraniční hodnota c. Když se jí dosáhne, funkce se již dále nevyvolává.
  sgen  .. hodnota globální proměnné state_generation při prvním vyvolání. Pokud je při opakovaném
           vyvolání hodnota větší, přestanese, protože byla funkce vyvolána znovu na novější stav.
  next  .. funkce, které se předá výsledek při úspěšném volání.
-------------------------------------------------------------------------------------------------*/
function retry_call(fn, param, c, lim, sgen, next)
{
 if(state_generation > sgen)
    return;

 fn(param)
 .then(val => next(val.data))
 .catch(err =>
       {
        if(c >= lim)
          {
           console.error(`set_user_state failed after ${c} retries`, err);
           return;
          }

        console.warn("set_user_state failed, retrying...");
        setTimeout(() => retry_call(fn, param, c+1, lim, sgen, next),
                   (1<<c)*100);
       });
}

/*-------------------------------------------------------------------------------------------------
Funkce pro zpracování výsledku volání 'set_user_state' na serveru. Zpracuje informace o aktivitě
uživatelů. Parametr data je výsledek volání (hodnota členu 'data' vráceného proxy funkcí)
-------------------------------------------------------------------------------------------------*/
export function process_peer_state(data)
{
 if(!data) return;

 const now = Date.now();
 const tdiff = data.server_time - now;
 console.log("server-time-diff: ", tdiff);

 const peer_state_ref = ['state', 'peer_state'];
 let peer_state = db_get_data(peer_state_ref) || {};

 // Přidat nové záznamy do seznamu aktivit uživatelů:
 const act = data.active;
 for(const uid in act)
    {
     peer_state[uid] = {ts: now, active: act[uid]};
    }

 // Odstranit položky starší než 15 minut:
 const ts_lim = now - 15*60000/*15 minut*/;
 for(const uid in peer_state)
    {
     const ps = peer_state[uid];
     if(ps.ts < ts_lim)
        delete peer_state[uid];
    }

 db_set_data(peer_state_ref, 'set', peer_state);
}

/*-------------------------------------------------------------------------------------------------
Sestaví seznam uživatelů, u nichž chceme znát, jestli jsou aktivní. Funkce vrátí pole UID.
Volitelný parametr preselected může obsahovat uživatele, kteří nás zajímají především.
-------------------------------------------------------------------------------------------------*/
function build_peers_list(preselected)
{
 const MAX_PEER_QUERY = 20; // maximální počet uživatelů v dotazu na stav

 let result = preselected ? [...preselected] : [];

 let state = db_get_data(['state']) || {};
 let peer_state = state.peer_state || {};

 // Přidat uživatele z výsledku hledání:
 if(state.odate_match || state.ochat_match)
   {
    let mr = state.odate_match ? Object.keys(state.odate_match) : [];
    
    if(state.ochat_match)
       mr = [...mr,  Object.keys(state.ochat_match)];

    for(let i = 0; i < mr.length; i++)
       {
        const uid = mr[i];
        if(result.indexOf(uid) < 0)
           result.push(uid);
       }
   }

 // Přidat nejvhodnější kandidáty z propojených uživatelů:
 let conn = db_get_data(['profile', 'conn']);

 const now = Date.now();
 const ts_lim = now - 5*60000/*5 minut*/;
 const ts_msg_lim = now - 15*60000/*15 minut*/;

 let uids = [];    // seznam všech přípustných uživatelů (tj. všech mimo odstraněné a blokované, ty v preselected a ty, pro které se stav zjišťoval nedávno)
 let weights = []; // váhy důležitosti uživatelů (v prvním průchodu obsahuje čas posledního kontaktu)
 let ts_max = 0;               
 let ts_min = Number.MAX_VALUE;

 // - odfiltrovat přípustné uživatele a určit celkový rozsah časů posledního kontaktu:
 for(const uid in conn)
    {
     const uc = conn[uid];

     if(uc.rel === CONN_REL_REMOVED || uc.rel === CONN_REL_BLOCKED || 
        (preselected && preselected.indexOf(uid) >= 0))
        continue;

     const ts = uc.msg_time || uc.create_time || uc.engage_time;

     if(peer_state[uid] && peer_state[uid].ts >= ts_lim && ts < ts_msg_lim)
        continue; // pokud jsme se na uživatele ptali v posledních 5 minutách, neptat se znovu,
                  // vyjma případu, že jsme si s ním psali v posledních 15 minutách, potom se zeptat vždy

     if(ts < ts_min) ts_min = ts;
     if(ts > ts_max) ts_max = ts;

     uids.push(uid);
     weights.push(ts);
    }

 // - určit váhy uživatelů:
 let order = new Array(uids.length);
 const ts_span = ts_max - ts_min + 1; // +1, aby nikdy nebyl 0
 for(let i = 0; i < uids.length; i++)
    {
     const uid = uids[i];
     const uc = conn[uid];

     // Faktor váhy za čas posledního kontaktu:
     let tw = (weights[i]-ts_min)/ts_span;

     // Faktor váhy za typ vztahu:
     const rel = uc.rel;
     let rw = rel === CONN_REL_FRIEND ? 0 : rel === CONN_REL_ACQ ? 0.8 : 0.6;
     if(!uc.favorite)
        rw += 0.4;
     
     // Celková váha:
     weights[i] = 0.8*tw + 0.2*rw;

     order[i] = i;
    }

 // - uspořádat uživatele podle váhy:
 order.sort((a, b) => weights[a] - weights[b]);

 // - přidat nejvhodnější uživatele do výsledku:
 for(let i = 0; i < order.length; i++)
    {
     if(result.length >= MAX_PEER_QUERY)
        break;

     result.push(uids[order[i]]);
    }

 return result;
}

/*-------------------------------------------------------------------------------------------------
Vlastní volání serveru při změně stavu.

Parametry:
 status     .. 'active' .. uživatel aplikaci aktivně používá
               'sleep'  .. uživatel aplikaci nezavřel, ale po 15 minut s ní neintaragoval
               'close'  .. uživatel zavřel poslední okno bez odhlášení
               'off'    .. uživatel se odhlásil nebo je účet deaktivovaý/označený ke smazání.
 user_state .. struktury s členy 'ochat', 'odate', oba bool
 peer       .. (nepovinné) pole UID uživatelů, jejichž stav se má zjistit. (Funkce v každém případě
               odešle seznam uživatelů, jejichž stav je vhodné zjistit. Tyto další uživatele
               přidá k těm přidaným)
-------------------------------------------------------------------------------------------------*/
function set_user_state(status, state_user, peers)
{
 if(!state_user)
   {
    log_pending = true;
    return;
   }

 console.log('set_user_state:', status, state_user, new Date());

 let param = {op:     'set_user_state',
              status: status, 
              ochat:  state_user.ochat, 
              odate:  state_user.odate};

 if(status === 'active')
    param.q = build_peers_list(peers);

 const user_manip = firebase_functions.httpsCallable('user_manip');

 //-------------
 // Call-back pro vyvolání user_manip('set_user_state').
 // Pokud se uživatel odhlásí, vyvolávání ukončí.
 const set_user_state = param =>
    {
     if(current_uid) return user_manip(param);
     return Promise.resolve(null);
    }

 retry_call(set_user_state, param, 0, 8, ++state_generation, process_peer_state); 

 log_pending = false;
}

/*-------------------------------------------------------------------------------------------------
Pokud aktivita překročila hranici dne, vynutit uložení profilu (aby se aktualizoval 'days_active'):
-------------------------------------------------------------------------------------------------*/
function update_activity_record()
{
 let last_active = db_get_data(['state', 'last_active']);
 let prev_last_active = db_get_data(['profile', 'last_active']);

 if(prev_last_active && last_active)
   {
    const pla = new Date(norm_time_to_ms(prev_last_active));
    const la = new Date(last_active); 
    if(pla.getFullYear() !== la.getFullYear() &&
       pla.getMonth() !== la.getMonth() &&
       pla.getDate() !== la.getDate())
      {
       master.db_data_changed('profile_aux');
      }
   }
}

/*-------------------------------------------------------------------------------------------------
Periodicky vyvolávaná funkce, když je uživatel aktivní. Oznamuje setrvalou aktivitu serveru.
Parametr peers je volitelný seznam uživatelů, jejichž stav se má zjistit.
-------------------------------------------------------------------------------------------------*/
function keep_alive(peers)
{
 keep_alive_stop(); // pokud je vyvolán jinak než timerem, stejně timer ukončit

 const user_active = db_get_data(['state', 'user_active']);
 if(!user_active)
    return;

 let state_user = db_get_data(['state','user']);

 if(state_user)
   {
    const pus = db_get_data(['profile', 'user_state']);
    const enabled = !(pus && pus<0);
    if(enabled)
       set_user_state('active', state_user, peers);
   }

 update_activity_record();

 keep_alive_start(); // naplánovat další vyvolání
}

/*-------------------------------------------------------------------------------------------------
Nastaví timer na pravidelné odesílání notifikace o aktivitě uživatele
-------------------------------------------------------------------------------------------------*/
function keep_alive_start()
{ 
 if(!keep_alive_timer_id)
   {
    update_activity_record();
    keep_alive_timer_id = setTimeout(keep_alive, 15*60000/*15 minut*/); 
   }
}

/*-------------------------------------------------------------------------------------------------
Ukončí pravidelné odesílání notifikace o aktivitě uživatele
-------------------------------------------------------------------------------------------------*/
function keep_alive_stop()
{
 if(keep_alive_timer_id)
   {
    clearTimeout(keep_alive_timer_id);
    keep_alive_timer_id = 0;
   }
}

/*-------------------------------------------------------------------------------------------------
Vyvoláno, když se uživatel přihlásil/odhlásil. 
Voláno jen v master session z db.js/firebase_on_auth_change() a db.js/db_logout().

Parametr:
 login .. true, pokud se přihlásil
-------------------------------------------------------------------------------------------------*/
export function user_state_login(login)
{
 const state_user_ref = ['state', 'user'];
 let state_user = db_get_data(state_user_ref);
 
 const pus = db_get_data(['profile', 'user_state']);
 const enabled = !(pus && pus<0);
 
 if(enabled)
    set_user_state(login ? 'active' : 'off', state_user);

 if(login)
    keep_alive_start();
 else
    keep_alive_stop();
}

/*-------------------------------------------------------------------------------------------------
Vyvoláno, při zavírání posledního okna aplikace.
Voláno z db.js/on_app_shutdown().
-------------------------------------------------------------------------------------------------*/
export function user_state_shutdown()
{
 const state_user_ref = ['state', 'user'];
 let state_user = db_get_data(state_user_ref);

 set_user_state('close', state_user); 
}

/*-------------------------------------------------------------------------------------------------
Vyvoláno, když se session stala/přestala být masterem.
Voláno z db.js/on_master_changed().
-------------------------------------------------------------------------------------------------*/
export function user_state_master_changed(is_master)
{
 if(is_master)
   {
    const state_user_ref = ['state','user'];

    let state_user = db_get_data(state_user_ref); // objekt obsahuje informace o stavu reportovaném na server
    if(!state_user)
      { // pokud objekt není vytvořen, inicializovat ho
       const profile = db_get_data('profile_db');
       if(profile && !profile.$loading)
         {
          const enabled = !(profile.user_state && profile.user_state<0);

          state_user = {ochat: enabled && !!profile.enable_open_date, 
                        odate: enabled && !!profile.enable_open_chat,
                        enabled: enabled};

          db_set_data(state_user_ref, 'merge', state_user);
         }
      }

    if(current_uid)
       keep_alive_start();

    db_watch_data(['profile_db'], on_profile_read);
    db_watch_data(['profile_changes'], on_profile_change);
    db_watch_data(['state', 'user_active'], on_global_activity_change);
   }
 else
   {
    keep_alive_stop();

    db_unwatch_data(['profile_db'], on_profile_read);
    db_unwatch_data(['profile_changes'], on_profile_change);
    db_unwatch_data(['state', 'user_active'], on_global_activity_change);
   }
}

/*-------------------------------------------------------------------------------------------------
Call-back vyvolaný při změně aktivity uživatele.
-------------------------------------------------------------------------------------------------*/
export function on_global_activity_change()
{
 if(current_uid)
   {
    const user_active = !!db_get_data(['state', 'user_active']);
    
    const state_user_ref = ['state','user'];
    let state_user = db_get_data(state_user_ref);
    
    const pus = db_get_data(['profile', 'user_state']);
    const enabled = !(pus && pus<0);

    if(enabled)
       set_user_state(user_active ? 'active' : 'sleep', state_user);

    if(user_active)
       keep_alive_start();
    else
       keep_alive_stop();
   }
}

/*-------------------------------------------------------------------------------------------------
Call-back vyvolaný při načtení profilu z databáze. Inicializuje lokální informaci o stávajícím
stavu uživatele.
-------------------------------------------------------------------------------------------------*/
export function on_profile_read()
{
 const profile_db = db_get_data(['profile_db']);

 if(profile_db && !profile_db.$loading)
   {
    const enabled = !(profile_db.user_state && profile_db.user_state<0);

    let state_user = {odate: enabled && !!profile_db.enable_open_date,
                      ochat: enabled && !!profile_db.enable_open_chat,
                      enabled: enabled};

    db_set_data(['state', 'user'], 'merge', state_user);

    if(log_pending && enabled)
       set_user_state('active', state_user);
   }
}

/*-------------------------------------------------------------------------------------------------
Call-back vyvolaný při změně profilu uživatelem.
-------------------------------------------------------------------------------------------------*/
export function on_profile_change()
{
 const profile = db_get_data(['profile']);

 if(profile && !profile.$loading)
   {
    const enabled = !(profile.user_state && profile.user_state<0);

    const odate = enabled && !!profile.enable_open_date;
    const ochat = enabled && !!profile.enable_open_chat;
    
    const state_user_ref = ['state', 'user'];
    let state_user = db_get_data(state_user_ref);
    
    if(state_user.odate !== odate || state_user.ochat !== ochat || state_user.enabled !== enabled)
      {
       state_user.odate = odate;
       state_user.ochat = ochat;
       state_user.enabled = enabled;
       db_set_data(state_user_ref, 'merge', state_user);

       set_user_state(enabled ? 'active' : 'off', state_user);
      }
   }
}
