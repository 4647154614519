/*#################################################################################################

Blok pro přihlášení/vytvoření účtu na úvodní obrazovce.

#################################################################################################*/

import React from 'react';
import firebase from 'firebase/app';
import {analytics} from './config';
import {html} from './utils';
import {useTranslationDef} from './translations/useTranslationDef';
import {send_verification_mail} from './send_verification_mail';
import {WaitIndicator} from './lib/WaitIndicator';
import password_show_svg from './graphics/password-show.svg';
import password_hide_svg from './graphics/password-hide.svg';
import './LoginBox.css';

/*-------------------------------------------------------------------------------------------------
Pro daný event zjistí, jestli byl vyvolán v prvním nebo druhém login-boxu
-------------------------------------------------------------------------------------------------*/
function get_box_id(event)
{
 return event.target.closest("#login2") ? 2 : 1;
}

/*-----------------------------------------------------------------------------------------------*/
export function LoginBox(props) 
{
 const init_state = 
    {
     email: "",
     password: "",
     password2: "", // podruhé zadané heslo při vytváření účtu
     signup: false, // uživatel stiskl tlačítko pro vytvoření účtu, ale nejsou vyplněny všechny údaje
     pwd_visible: false,
     bad_pwd: false, // příznak, že bylo zadáno špatné heslo
     msg: null, // hlášení zobrazené uživateli, které není chyba
     error: null, // chybové hlášení
     err_frame_email: false, // příznak, že se okolo políčka emalu má udělat červený rámeček
     in_progress: false
    }

 const [state, set_state] = React.useState(init_state);
 let password_ref = React.useRef();
 let password2_ref = React.useRef();
 const tr_def = useTranslationDef();
 const ui_lang = tr_def.lang;
 const t = tr_def.translate;

 //----------------------
 // napsání znaku v některém políčku
 const on_input_change = event => 
    {
     set_state({...state,
                [event.target.name]: event.target.value,
                error: null,
                bad_pwd: false,
                msg: null,
                err_frame_email: false
               });
    };
 
 //----------------------
 // stisknutí Enteru v některém políčku
 const key_pressed = event =>
    {
     if(event.key==="Enter" || event.keyCode === 13)
       {
        event.preventDefault();
        event.stopPropagation();

        if(event.target.name === "email")
          {
           const elem = password_ref.current;
           if(elem) elem.focus();
           return false;
          }

        if(event.target.name === "password" && state.signup && !state.pwd_visible)
          {
           const elem = password2_ref.current;
           if(elem) elem.focus();
           return false;
          }
        
        if(state.signup)
           signup_email(event);
        else
           login_email(event);
        
        return false;
       }
    }


 //----------------------
 // Přihlášení přes email
 const login_email = e =>
    {
     if(state.email==="")
       {
        set_state({...state, 
                   error: t('login_email_msg'),
                   err_frame_email: true,
                   signup: false,
                   bad_pwd: false, 
                   msg: ""});
        return;
       }

     if(state.password==="")
       {
        set_state({...state, 
                   error: t('login_pwd_msg'),
                   signup: false,
                   bad_pwd: false, 
                   msg: ""});
        return;
       }

     set_state({...state, in_progress: true});

     // Zde se ošetří pouze error, vlastní aktualizace stavu se realizuje autonomě 
     // v call-backu nastaveném v App.js pomocí onAuthStateChanged:
     firebase.auth().signInWithEmailAndPassword(state.email, state.password) 
     .then(() => analytics.logEvent('login', {method: 'email', box_id: get_box_id(e)}))
     .catch(function(error) 
        {
         console.log("Error authenticating (e-mail):", error);

         let new_state = {...state, 
                          err_frame_email: false, 
                          signup: false, 
                          bad_pwd: false, 
                          msg: "", 
                          in_progress: false};

         switch(error.code)
               {
                case "auth/invalid-email":
                case "auth/user-disabled":
                     new_state.error = t(error.code);
                     new_state.err_frame_email = true;
                     break;

                case "auth/user-not-found":
                     new_state.error = t('login_not_ex');
                     new_state.err_frame_email = true;
                     new_state.bad_pwd = false;
                     break;

                case "auth/wrong-password":
                     new_state.error = t('login_pwd_err');
                     new_state.bad_pwd = true;
                     break;

                case "auth/network-request-failed":
                     new_state.error = t(error.code);
                     break;

                default:
                     new_state.error = t('err_login') + " (" + error.message + ")"; 
                     break;
               }

         set_state(new_state);
        })
    }

 //----------------------
 // Vytvoření účtu přes email
 const signup_email = e =>
    {
     if(state.email==="")
       {
        set_state({...state, 
                   signup: true,
                   error: null,
                   err_frame_email: false,
                   bad_pwd: false, 
                   msg: t('loginc_email_pwd')});
        return;
       }

     if(state.password==="")
       {
        set_state({...state, 
                   signup: true,
                   error: null,
                   err_frame_email: false,
                   bad_pwd: false, 
                   msg: t('loginc_pwd')});
        return;
       }

     if(!state.pwd_visible && !state.signup)
       {
        set_state({...state, 
                   signup: true,
                   error: null,
                   err_frame_email: false,
                   bad_pwd: false, 
                   msg: t('loginc_pwd2')});
        return;
       }
       

     if(!state.pwd_visible && state.password !== state.password2)
       {
        set_state({...state, 
                   signup: true,
                   err_frame_email: false,
                   error: t('loginc_pwd2_err')});
        return;
       }

     set_state({...state, in_progress: true});

     const auth = firebase.auth();

     //auth.useDeviceLanguage();
     auth.languageCode = ui_lang;

     // Zde se ošetří pouze error a odeslání verifikačního mailu, 
     // vlastní aktualizace stavu se realizuje autonomě 
     // v call-backu nastaveném v App.js pomocí onAuthStateChanged:
     auth.createUserWithEmailAndPassword(state.email, state.password) 
     .then(user_credential =>
        { // (Viz komentář v send_verification_mail.js)
         analytics.logEvent('sign_up', {method: 'email', box_id: get_box_id(e)});

         const user = user_credential.user;
         if(user) 
            send_verification_mail(user);
        })
     .catch(error =>
        {
         console.log("Error sign-up (e-mail):", error);

         let new_state = {...state, err_frame_email: false, in_progress: false};

         switch(error.code)
               {
                case "auth/invalid-email":
                case "auth/email-already-in-use":
                     new_state.error = t(error.code);
                     new_state.err_frame_email = true;
                     break;

                case "auth/weak-password":
                case "auth/network-request-failed":
                     new_state.error = t(error.code);
                     break;

                default:
                     new_state = {...state, error: t('loginc_err') + " (" + error.message + ")"}; 
                     break;
               }

         set_state(new_state);
        })
    }

 //----------------------
 // Vyvolání resetu hesla.
 const reset_pwd = e =>
    {
     const auth = firebase.auth();
     auth.languageCode = ui_lang;

     auth.sendPasswordResetEmail(state.email)
     .then(() => 
          {
           analytics.logEvent('reset_pwd', {box_id: get_box_id(e)});
 
           set_state({...state,
                     bad_pwd: false,
                     error: null,
                     msg: t('mailv_sent_msg', state.email)})
          })
     .catch(error => 
            set_state({...state,
                       msg: null,
                       error: t('mailv_sent_msg') + " (" + error.message + ")"}));
    }

 //----------------------
 // Přepíná viditelnost hesla
 const toggle_pwd_visible = (e) =>
    {
     e.preventDefault();
     let new_state = {...state, pwd_visible: !state.pwd_visible}
     if(new_state.pwd_visible)
        new_state.password2 = "";
     set_state(new_state);
    }

 //-----------------------
 // Přihlášení přes Facebook:
 const login_facebook = e =>
    {
     const auth = firebase.auth();
     auth.languageCode = ui_lang;
    
     set_state({...state, signup: false, msg: "", bad_pwd: false/*, in_progress: true*/});

     let provider = new firebase.auth.FacebookAuthProvider();
     provider.setCustomParameters({display: 'popup'});
    
     // Zde se ošetří pouze error, vlastní aktualizace stavu se realizuje autonomě 
     // v call-backu nastaveném v App.js pomocí onAuthStateChanged:
     firebase.auth().signInWithPopup(provider)
     .then(() => analytics.logEvent('login', {method: 'facebook', box_id: get_box_id(e)}))
     .catch(function(error) 
          {
           console.log("Error authenticating (Facebook):", error);
    
           let new_state  = {...state, signup: false, msg: "", bad_pwd: false, in_progress: false};
    
           switch(error.code)
                 {
                  case "auth/cancelled-popup-request":
                  case "auth/popup-closed-by-user":
                       return; // uživatel zrušil přihlášení => nic nedělat
    
                  case "auth/account-exists-with-different-credential":
                       new_state.error = t('loginc_err_dupl'); 
                       break;
    
                  case "auth/popup-blocked":
                       new_state.error = t('loginc_err_pwnd'); 
                       break;
    
                  case "auth/network-request-failed":
                       new_state.error = t(error.code);
                       break;

                  default:
                       new_state.error = t('err_login') + " (" + error.message + ")"; 
                       break;
                 }

           set_state(new_state);
          });
    }

 //-----------------------
 // Přihlášení přes Google:
 const login_google = e =>
    {
     const auth = firebase.auth();
     auth.languageCode = ui_lang;
    
     set_state({...state, signup: false, msg: "", bad_pwd: false/*, in_progress: true*/});

     let provider = new firebase.auth.GoogleAuthProvider();
     provider.addScope('profile');
     provider.addScope('email');
     provider.setCustomParameters({prompt: 'select_account'});
    
     // Zde se ošetří pouze error, vlastní aktualizace stavu se realizuje autonomě 
     // v call-backu nastaveném v App.js pomocí onAuthStateChanged:
     firebase.auth().signInWithPopup(provider)
     .then(() => analytics.logEvent('login', {method: 'google', box_id: get_box_id(e)}))
     .catch(function(error) 
          {
           console.log("Error authenticating (Google):", error);
    
           let new_state  = {...state, signup: false, msg: "", bad_pwd: false, in_progress: false};
    
           switch(error.code)
                 {
                  case "auth/cancelled-popup-request":
                  case "auth/popup-closed-by-user":
                       return; // uživatel zrušil přihlášení => nic nedělat
    
                  case "auth/account-exists-with-different-credential":
                       new_state.error = t('loginc_err_dupl'); 
                       break;
    
                  case "auth/popup-blocked":
                       new_state.error = t('loginc_err_pwnd'); 
                       break;
    
                  case "auth/network-request-failed":
                       new_state.error = t(error.code);
                       break;

                  default:
                       new_state.error = t('err_login') + " (" + error.message + ")"; 
                       break;
                 }

           set_state(new_state);
          });
    }


 return <div className="login-box">
           <div className="msg-box">
             {state.msg && <div className="msg">{state.msg}</div>}
             {state.error && <div className="error">{state.error}</div>}
             {state.in_progress && <WaitIndicator size = {16}/>}
           </div>
           <div className={"input-box" + (state.err_frame_email ? " error-frame" : "")}>
             <input type        = "email" 
                    name        = "email" 
                    placeholder = {t('login_email_lbl')} 
                    value       = {state.email} 
                    onKeyDown   = {key_pressed}
                    onChange    = {on_input_change}/><br/>
           </div>
           <div className={"input-box" + (state.pwd_visible ? " pwd_visible" : "")}>
             <input type        = {state.pwd_visible ? "text" : "password"}
                    name        = "password" 
                    placeholder = {t('login_pwd_lbl')}
                    value       = {state.password} 
                    onKeyDown   = {key_pressed}
                    onChange    = {on_input_change}
                    ref         = {password_ref}/>
             <img className   = "pwd-visible" 
                  src         = {state.pwd_visible? password_show_svg : password_hide_svg}
                  onMouseDown = {toggle_pwd_visible}
                  alt         = {t('login_pwd_vis')}/>
           </div>
           {state.signup && !state.pwd_visible &&
            <div className={"input-box" + (state.pwd_visible ? " pwd-visible" : "")}>
              <input type        = {state.pwd_visible ? "text" : "password"}
                     name        = "password2" 
                     placeholder = {t('login_pwd2_lbl')} 
                     value       = {state.password2} 
                     onKeyDown   = {key_pressed}
                     onChange    = {on_input_change}
                    ref          = {password2_ref}/>
            </div>}
           <div className = 'btn-row'>
            <button type="submit" className="btn1" onClick={login_email}>{t('login_btn')}</button>
            {state.bad_pwd ?
              <button type="button" className="btn1" onClick={reset_pwd}>{t('login_chpwd')}</button> :
              <button type="button" className="btn1" onClick={signup_email}>{t('login_create')}</button>}
           </div>
           <button type="button" className="btn2" onClick={login_facebook}><span id="facebook">{t('login_facebook')}</span></button><br />
           <button type="button" className="btn2" onClick={login_google}><span id="google">{t('login_google')}</span></button>
           <p>
             <small>
               {t('login_fb_discl')}
             </small>
           </p>
           <p>
             <small>
               {html(t('login_terms'))}
             </small>
           </p>
        </div>;
}
