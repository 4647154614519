/*#################################################################################################

Ikona pro otevření editor dojmu z uživatele

#################################################################################################*/

import React from 'react';
import {open_impression_editor} from '../controls/ImpressionEditor';
import {useTranslation} from '../translations/useTranslation';
import './ImpressionIcon.css';


/*-------------------------------------------------------------------------------------------------
props uid         .. uid uživatele, jehož se editor týká
      nick_name   .. nick uživatele, jehož se editor týká
      page_id     .. id stránky, kde je ikona umístěna ("" = home, "chat" = chat)
      location    .. výsledek useLocation()
      url_history .. výsledek useHistory()
-------------------------------------------------------------------------------------------------*/
export function ImpressionIcon(props) 
{
 const t = useTranslation();

 return <div className = 'impression-icon'
             title = {t('impress_title', props.nick_name)}
             onClick = {event => {event.stopPropagation();
                                  open_impression_editor(props.url_history, props.location, 
                                                         props.uid, (props.page_id!=='odate')|0);}}>
        </div>;
}
