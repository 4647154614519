/*#################################################################################################

  Indikátor načítání nebo jiné operace – zobrazuje animované kolečko.
  Na specifickém místě mohou být jeho rozměry a umístění upraveny nastavením stylů 
  pro třídu "wait-indicator".

#################################################################################################*/

import React, {useEffect} from 'react';
import loading_gif from './WaitIndicator.gif';
import './WaitIndicator.css';

/*-------------------------------------------------------------------------------------------------
props dalay .. prodleva před zobrazením indikátoru v ms (default = 1000 ms)
      size  .. velikost v pixelech (nepovinné, potom se musí velikost nastavit pomocí stylu)
      title .. (nepovinné) zobrazený titulek (className = 'title'
-------------------------------------------------------------------------------------------------*/
export function WaitIndicator(props) 
{
 const [disp, set_disp] = React.useState(false); // je zobrazen?
 const delay = typeof props.delay === 'number' ? props.delay : 1000; 

 // indikátor zobrazit s prodlenou, aby zbytečně neproblikl, když se profil načte hned:
 useEffect(() => {let t = setTimeout(() => set_disp(true), delay);
                  return () => clearTimeout(t);});

 return disp &&
        <div className='wait-indicator'>
          <img src={loading_gif} 
               style = {props.size && {width: props.size}}
               alt="" />
          {props.title && <span className='title'>&nbsp;{props.title}</span>}
        </div>;
}
