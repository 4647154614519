import {t} from '../translations/translation';

export const NULL_STRING = "\x1bnull"; // pro překódování null na string, aby se dal nastavit jako atribut

/*------------------------------------------------------------------------------------------------- 
Pomocná funkce pro normalizaci hodnoty z ovládacích prvků.
Funkce vrací buď příslušnou normalizovanou hodnotu nebo objekt Error s příslušným hlašením
(test: x instanceof Error, záskání hlášení: x.message)

Parametry:
 props  .. objekt props daného volajícího ovládacího prvku. Obsahuje tyto prvky:
           data_id       .. předá se jako druhý parametr setteru
           type          .. volitelně typ hodnoty, která se má zapsat ("int" nebo "string"). 
                            Pokud není přítomna, použije se parametr val bez konverze. 
                            null se nikdy nekonvertuje
           required      .. prázdná hodnota není přípustná. Pokud není 'required', prázdný řetězec
                            se převede na null.
           allow_empty   .. pro string: je přípustná prázdná hodnota, ale ponechá se "" 
                            (nepřevede se na null)
           validator     .. funkce vrací string - chybové hlášení, 
                            pokud je hodnota neplatná nebo null, pokud je v pořádku.
                            První parametr je hodnota, druhý je data_id.
                        
 val    .. hodnota, která se má normalizovat
------------------------------------------------------------------------------------------------- */ 
export function normalize_value(props, val) 
{
 let v;
 let err = null;

 if(val === NULL_STRING)
    val = null;

 if(val===null || val==="")
   {
    if(!props.required)
       v = (props.allow_empty && val==="") ? "" : null;
    else if(props.allow_empty && (!props.type || props.type==="string"))
       v = "";
    else 
       err = t('err_val');
   }
 else if(props.type === "int")
    v = parseInt(val, 10);
 else if(props.type === "float")
    v = parseFloat(val);
 else  
    v = val;

 if(!err)
   {
    if(typeof(v)==="number" && isNaN(v))
       err = t('err_num');
    
    if(props.validator)
       err = props.validator(v, props.data_id);
   }

 if(err)
    return new Error(err);

 return v;
}
