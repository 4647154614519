/*#################################################################################################

Oštření obrázků (resize a upload)

Poznámka: • přístupová práva by možná bylo možné ošetřit změnou "access tokenu" u dotčených souborů
            s tím, že zakázané obrázky nejsou zahrnuty ve vráceném profilu
            (viz firebaseStorageDownloadTokens, https://stackoverflow.com/questions/59432624/how-can-i-generate-access-token-to-file-uploaded-to-firebase-storage)
          • Jiná (možná lepší) možnost by byla vytvářet URL na serveru při získávání profilu
            pomocí getSignedUrl().

#################################################################################################*/

import {config} from '../config';
import {db_get_data, 
        db_set_data} from './app_state';
import {current_uid} from './db';
import {cloud_storage_bucket,
        cloud_storage_url} from './cloud_storage';
const {ACCESS_FRIEND,      
       ACCESS_ACQ,         
       ACCESS_PUBLIC,      
       CONN_REL_NONE,      
       CONN_REL_ACQ,       
       CONN_REL_FRIEND} = require('../profile_def.js');

const IMAGE_SIZE_LIMIT = 80000; // limit velikosti obrázku v bytech při kterém se zmenšuje
const IMAGE_DIM_LIMIT = 1024;   // limit výšky/šířky obrázku v pixelech
const IMAGE_THUMB_SIZE = 200;   // velikost thumbnailu v pixelech
const IMAGE_JPEG_QUALITY = 0.6; // kvalita pro kódování JPEGu

/*-------------------------------------------------------------------------------------------------
Vrací objekt Storage bucketu obrázků.
-------------------------------------------------------------------------------------------------*/
const image_bucket = () => cloud_storage_bucket(config.images_bucket);

/*-------------------------------------------------------------------------------------------------
Vrátí cestu k obrázku relativně ke kořeni bucketu.

Parametry:
  image_id .. id obrázku (profile.images[].id)
  thumb    .. (bool) true, pokud se má vrátit adresa thumbnailu, false pro plný obrázek
  uid      .. UID uživatele, jemož obrázek patří; pokud nezadán, jde o přihlášeného uživatele
-------------------------------------------------------------------------------------------------*/
function image_path(img_id, thumb, uid=null)
{
 return `${uid||current_uid}/${thumb?'thumb':'img'}_${img_id}.jpg`;
}

/*-------------------------------------------------------------------------------------------------
Asynchronní načtení obrázku.

Parametry:
  file .. instance File (standardní objekt browseru)

Výsledek:
  Promise na HTMLImageElement. Při chybě rejectuje Error.
-------------------------------------------------------------------------------------------------*/
function read_image(file)
{
 return new Promise((resolve, reject) => 
   {
    const img = new Image()

    img.onload = () => resolve(img);
    img.onerror = (msg, url, ln, cl, err) => reject(err);
    
    img.src = URL.createObjectURL(file)
   });
}

/*-------------------------------------------------------------------------------------------------
Připraví obrázek k uploadu. Pokud velikost vstupního obrázku překračuje zadané parametry nebo
není JPEG, obrázek překóduje na změnšený JPEG. Jinak vrátí původní file.

Parametry:
  file       .. instance File (standardní objekt browseru)
  max_size   .. maximální velikost souboru obrázku v bytech
                (po soubor nepřekračuje velikost, nekontrolují se jeho rozměry).
  max_width  .. maximální šířka obrázku v pixelech
  max_height .. maximální výška obrázku v pixelech
  quality    .. kvalita kódování JPEGu (číslo (0..1))

Výsledek:
  Promise na výsledný obrázek, File nebo Blob.
-------------------------------------------------------------------------------------------------*/
function prepare_image(file, 
                       max_size = IMAGE_SIZE_LIMIT, 
                       max_width = IMAGE_DIM_LIMIT, max_height = IMAGE_DIM_LIMIT, 
                       quality = IMAGE_JPEG_QUALITY)
{
 if(file.size > max_size || file.type !== 'image/jpeg')
   {
    return read_image(file)
           .then(img => 
                {
                 const dx_org = img.naturalWidth;
                 const dy_org = img.naturalHeight;

                 const fx = dx_org > max_width ? max_width/dx_org : 1;
                 const fy = dy_org > max_height ? max_height/dy_org : 1;

                 const f = Math.min(fx, fy);

                 const dx = (dx_org*f)|0;
                 const dy = (dy_org*f)|0;

                 const canvas = document.createElement('canvas');

                 canvas.width = dx;
                 canvas.height = dy;
                 canvas.getContext('2d').drawImage(img, 0, 0, dx, dy);

                 return new Promise(resolve => canvas.toBlob(resolve, 'image/jpeg', quality)); 
                });
   }
 else
   {
    return Promise.resolve(file);
   }
}

/*-------------------------------------------------------------------------------------------------
Zahájí upload obrázku.

Parametry:
  tgt_name .. jméno obrázku (plná cesta) v cílovém bucketu cloud storage
  src      .. File/Blob, který se má odeslat.

Výsledek:
  Promise na UploadTask (jde o Promise-like objekt)
-------------------------------------------------------------------------------------------------*/
function upload_image(tgt_name, src)
{
 const file_metadata = {customMetadata: {access: 'public'},
                        cacheControl: "max-age: 600"}; // po 10 minutách expiruje, kvůli změnám přístupových práv

 return image_bucket().ref(tgt_name).put(src, file_metadata);
}

/*-------------------------------------------------------------------------------------------------
Vrátí URL daného obrázku.

Parametry:
  img_id .. identifikátor obrázku (profile.images[].id)
  thumb  .. true, pokud jde o thumbnail, false, pokud o plný obrázek
  uid    .. UID uživatele, jemuž obrázek náleží. Pokud není zadán, jde o přihlášeného uživatele.

Výsledek:
  Promise na URL.
-------------------------------------------------------------------------------------------------*/
export function profile_image_url(img_id, thumb, uid=null)
{
 return cloud_storage_url(config.images_bucket, image_path(img_id, thumb, uid));
}

/*-------------------------------------------------------------------------------------------------
Přidá obrázek do profilu. Před tím omezí jeho velikost. Zároveň vytvoří thumbnail obrázku.
Všechny obrázky konvertuje na JPEG.

Parametry:
  file .. instance File (standardní objekt browseru)

Výsledek:
  Promise na upload obrázku i thumbnailu. 
  Při úspěchu přidá informaci o novém obrázku do profilu.
  Při chybě nic do profilu nepřidává a smaže případný částečný upload.
-------------------------------------------------------------------------------------------------*/
export function profile_image_add(file)
{
 const img_id = Date.now();
 const img_path = image_path(img_id, false);
 const thumb_path = image_path(img_id, true);

 const img_upload_ref = ['state', 'img_upload', img_id+''];
 db_set_data(img_upload_ref, 'set', true); // zaznamenat, že se uploaduje, aby bylo možné zjistit,
     // kolik obrázků již bylo zadáno, kvůli testu limitu počtu obrázků

 let promises = [];

 promises.push(prepare_image(file)
               .then(src => upload_image(img_path, src)));

 promises.push(prepare_image(file, 0, IMAGE_THUMB_SIZE, IMAGE_THUMB_SIZE)
               .then(src => upload_image(thumb_path, src)));

 return Promise.all(promises)
        .then(() =>
             {
              const pr_images_ref = ['profile', 'images'];
              let pr_images = db_get_data(pr_images_ref) || [];
              pr_images.push({id: img_id, acc: ACCESS_PUBLIC});
              db_set_data(pr_images_ref, 'set', pr_images);
              db_set_data(img_upload_ref, 'set', undefined);
             })
        .catch(err =>
             {
              let bucket = image_bucket();
 
              bucket.ref(img_path).delete().catch(err => console.error(err));
              bucket.ref(thumb_path).delete().catch(err => console.error(err));

              db_set_data(img_upload_ref, 'set', undefined);

              throw err;
             });
}

/*-------------------------------------------------------------------------------------------------
V seznamu obrázku (profile.images) najde obrázek s daným id a vrátí jeho index.
Pokud ho nenalezl, vrátí null.
-------------------------------------------------------------------------------------------------*/
function image_idx_by_id(images, img_id)
{
 for(let i=0; i<images.length; i++)
    {
     if(images[i].id === img_id)
        return i;
    }

 return null;
}

/*-------------------------------------------------------------------------------------------------
Odstraní obrázek z profilu a z cloud storage. Vrací promise splněnou po dokočení operace.
Chybu při mazání vypíše na konzoli a požere ji.

Parametry:
  n .. pořadové číslo obrázku (v poli profile.images)
-------------------------------------------------------------------------------------------------*/
export function profile_image_delete(n)
{
 const pr_images_ref = ['profile', 'images'];
 let pr_images = db_get_data(pr_images_ref);

 if(!pr_images || n >= pr_images.length)
    return Promise.resolve();

 let img_id = pr_images[n].id;
 pr_images.splice(n, 1);

 let bucket = image_bucket();

 return Promise.all([bucket.ref(image_path(img_id, false)).delete()
                     .catch(err => 
                           {
                            if(err.code !== 'storage/object-not-found')
                               throw err;
                           }),
                     bucket.ref(image_path(img_id, true)).delete()
                     .catch(err => 
                           {
                            if(err.code !== 'storage/object-not-found')
                               throw err;
                           })])
 .then(() => 
      {
       // Znovu načíst a nalézt mazaný obrázek a odstranit ho ze seznamu:
       let pr_images = db_get_data(pr_images_ref) || [];
       const idx = image_idx_by_id(pr_images, img_id);

       if(idx !== null)
         {
          pr_images.splice(idx, 1);
          db_set_data(pr_images_ref, 'set', pr_images);
         }
      })
 .catch(err => console.error('profile_image_delete', err));
}

/*-------------------------------------------------------------------------------------------------
Pro daná přístupová práva vrátí minimální požadovaný typ vztahu.
-------------------------------------------------------------------------------------------------*/
const access_to_rel = acc => acc === ACCESS_FRIEND ? CONN_REL_FRIEND :
                             acc === ACCESS_ACQ    ? CONN_REL_ACQ : CONN_REL_NONE;

/*-------------------------------------------------------------------------------------------------
Pro daná přístupová práva vrátí řetězcovou reprezentaci.
-------------------------------------------------------------------------------------------------*/
const access_to_string = acc => acc === ACCESS_FRIEND ? 'friend' :
                                acc === ACCESS_ACQ    ? 'acq' : 'public';

/*-------------------------------------------------------------------------------------------------
Vrátí řetězec se seznamem uživatelů, kteří mají povolen přístup k datům na úrovni acc.

Parametry:
  conn .. seznam propojených uživatelů (profile.conn)
  acc .. nová přístupová práva (konstanty ACCESS_...)
-------------------------------------------------------------------------------------------------*/
function users_by_acc(conn, acc)
{
 const min_rel = access_to_rel(acc);
 let result = "";

 for(let uid in conn)
    {
     const uc = conn[uid];
     if(uc.rel >= min_rel)
       {
        if(result) result += ",";
        result += uid;
       }
    }

 return result;
}

/*-------------------------------------------------------------------------------------------------
Změní přístupová práva obrázku

Parametry:
  n   .. pořadové číslo obrázku (v poli profile.images)
  acc .. nová přístupová práva (konstanty ACCESS_...)
-------------------------------------------------------------------------------------------------*/
export function profile_image_set_access(n, acc)
{
 let profile = db_get_data(['profile']);

 let pr_images = profile.images;

 if(!pr_images || n >= pr_images.length)
    return;
 
 const img_def = pr_images[n];
 
 if(img_def.acc === acc)
    return Promise.resolve();

 const metadata = 
    {
     customMetadata: {access:     access_to_string(acc),
                      allow_list: acc===ACCESS_PUBLIC ? null : users_by_acc(profile.conn, acc)}     
    }

 const img_id = img_def.id;
 let bucket = image_bucket();

 return Promise.all([bucket.ref(image_path(img_id, false)).updateMetadata(metadata),
                     bucket.ref(image_path(img_id, true)).updateMetadata(metadata)])
        .then(() =>
             {
              const pr_images_ref = ['profile', 'images'];
              let pr_images = db_get_data(pr_images_ref) || [];
              const idx = image_idx_by_id(pr_images, img_id);
       
              if(idx !== null)
                {
                 pr_images[idx].acc = acc;
                 db_set_data(pr_images_ref, 'set', pr_images);
                }
             });
               
}

/*-------------------------------------------------------------------------------------------------
Aktualizuje přístupová práva k obrázkům po změně typu vztahu uživatele.

Parametry:
  profile      .. profil přihlášeného uživatele
  uid          .. uživatel, jehož typ vztahu se změnil
  conn_rel_new .. nový typ vztahu (konstanty CONN_REL..)
  conn_rel_old .. předchozí typ vztahu
-------------------------------------------------------------------------------------------------*/
export function profile_images_update_access(profile, uid, conn_rel_new, conn_rel_old)
{
 const images = profile.images;

 if(!images || !images.length)
    return Promise.resolve();

 let conn = profile.conn;

 const store_uc = conn[uid]; // uschovat původní záznam

 // Vypočítat seznamy přístupových práv pro starý typ vzhahu:                               
 conn[uid] = {rel: conn_rel_old};

 const al_old = {[ACCESS_FRIEND]: users_by_acc(conn, ACCESS_FRIEND),
                 [ACCESS_ACQ]:    users_by_acc(conn, ACCESS_ACQ)};

 // Vypočítat seznamy přístupových práv pro nový typ vztahu a připravit je jako metadata:
 conn[uid] = {rel: conn_rel_new};

 const metadata = 
   {[ACCESS_FRIEND]: {customMetadata: {access: 'friend',
                                       allow_list: users_by_acc(conn, ACCESS_FRIEND)}},
    [ACCESS_ACQ]:    {customMetadata: {access: 'acq',
                                       allow_list: users_by_acc(conn, ACCESS_ACQ)}}};

 conn[uid] = store_uc; // vrátit původní záznam

 // Pokud se seznam přístupových práv nezměnil, rovnou ho odstranit, aby se vědělo, že není třeba ho nastavovat)
 if(metadata[ACCESS_FRIEND].customMetadata.allow_list === al_old[ACCESS_FRIEND])
    delete metadata[ACCESS_FRIEND];
 if(metadata[ACCESS_ACQ].customMetadata.allow_list === al_old[ACCESS_ACQ])
    delete metadata[ACCESS_ACQ];

 // Projít obrázky a těm, jimž se má změnit seznam přípustných uživatelů, ho nastavit:
 let bucket = image_bucket();
 let promises = [];

 for(let i = 0; i < images.length; i++)
    {
     const img_def = images[i];
     const img_id = img_def.id;
     const acc = img_def.acc;

     if(acc !== ACCESS_PUBLIC && metadata[acc])
       {
        promises.push(bucket.ref(image_path(img_id, false)).updateMetadata(metadata[acc]));
        promises.push(bucket.ref(image_path(img_id, true)).updateMetadata(metadata[acc]));
       }
    }
 
 return Promise.all(promises);
}

