/*#################################################################################################

Panel se zprávami chatu

#################################################################################################*/

import React from 'react';
import {date_time_to_loc_string} from '../utils';
import {UserIcon} from './UserIcon';
import {master} from '../db/master';
import {current_uid} from '../db/db';
//import {useDbData} from '../db/useDbData';
import {useMessages} from '../db/useMessages';
import {mark_messages_read} from '../db/messages';
import {WaitIndicator} from '../lib/WaitIndicator';
import {useTranslationDef} from '../translations/useTranslationDef';


const MSG_BREAK_TIME = 1800000; // 30 minut; čas, po kterém se považuje konverzace za přerušenou

/*-------------------------------------------------------------------------------------------------
Konvertuje zprávy z objektu přijatých/odeslaných zpráv do seznamu zpráv ke zobrazení.
Seznam není utříděn, utřídit ho musí volající.

Vrací počet paketů zpráv, které se právě načítají.

Parametry:
  mlist - pole, kam se mají zprávy přidat
  base  - zdrojový objekt (buď messages.in nebo messages.out). 
          Předpokládá se, že zprávy jsou prvky, jejichž jméno začíná na '#'.
  out   - pokud je true, jde o odeslané zprávy. Potom se ke každé zprávě ve výsledku přidá
          člen out: true.
  lmp   - last message packet; id posledního paketu, který se má do výsledku zahrnout.
-------------------------------------------------------------------------------------------------*/
function msgs_from_obj(mlist, base, archive, out, lmp)
{
 let pkt = base;
 let aid = null;
 let loading = 0;

 // Smyčka přes posloupnost paketů počínajíc základním, až po lmp:
 for(;;)
    {
     if(!pkt)
        break;
     
     if(pkt.$loading)
       {
        loading++;
       }
     else
       {
        for(let key in pkt.msg)
           {
            let m = pkt.msg[key];
            if(out) m = {...m, out: true};
            mlist.push(m);
           }
       }

     if(/*aid === lmp ||*/ !pkt.aid)
        break;
 
     aid = pkt.aid;
     pkt = archive && archive[aid];
    }

 return loading; 
}

/*-------------------------------------------------------------------------------------------------
Vyhledá paket, který je třeba načíst vzhledem k času ts. Tj. paket, který leží něj (nikoli jako 
poslední zpráva), nebo první nenačtený paket, pokud předchozí leží za časem ts.
Vrací aid paketu.
-------------------------------------------------------------------------------------------------*/
function packet_by_time(base, archive, ts)
{
 let pkt = base;
 let aid = null;

 // Smyčka přes posloupnost paketů počínajíc základním.
 // Najít ten, který obsahuje ts a a nejde o poslední zprávu, nebo první nenačtený.
 for(;;)
    {
     if(!pkt)
        break;
     
     if(pkt.first_msg)
       {
        const tmin = pkt.first_msg.t;
        if(ts > tmin)
           break; // zpráva je uvnitř paketu
       }

     if(!pkt.aid)
        break;
 
     aid = pkt.aid;
     pkt = archive && archive[aid];
    }

 return aid;
}

 //--------
 //Podle času poslední zobrazené zprávy určí, které pakety zpráv je ještě třeba načíst.
function update_packet_req(pane, messages, set_state, mounted)
{
 if(!messages) return;

 const pane_top = pane.getBoundingClientRect().y;

 //let new_ilmp;
 //let new_olmp;
 let ns = {ilmp: null, olmp: null};

 const update_state = state =>
    {
     if(!mounted.state || (ns.ilmp === state.ilmp && ns.olmp === state.olmp))
        return state;
     console.log("update_packet_req", ns.ilmp, ns.olmp);
     return {...state, ilmp: ns.ilmp, olmp: ns.olmp};
    }

 // Smyčka přes jednotlivé skupiny zpráv:
 let msg_block = pane.getElementsByClassName('message-block')[0];
 let group_list = msg_block.childNodes;
 for(let i = 0; i < group_list.length; i++)
    {
     const grp = group_list[i];
     //const grp_top = grp.getBoundingClientRect().y;
     //if(grp_top >= pane_top)
     //  { // je celý zobrazený
     const grp_bottom = grp.getBoundingClientRect().bottom;
     if(grp_bottom >= pane_top)
       { // první aspoň částečně zobrazený
        let ts = parseInt(grp.getAttribute('ts'));
        if(ts) // timestamp mají pouze skupiny zpráv, mezilehlé uzly ne
          { 
           ns.ilmp = packet_by_time(messages.in,  messages.in_archive,  ts+1);
           ns.olmp = packet_by_time(messages.out, messages.out_archive, ts+1);
         
           set_state(update_state);

           break;
          }
       }
    }
}

/*-------------------------------------------------------------------------------------------------
 props  nick_name     .. nick přihlášeného uživatele
        other_uid     .. uid chatujícího protějšku
        other_profile .. profile chatujícího protějšku
        page_id       .. id stránky, kde je panel zobrazen
-------------------------------------------------------------------------------------------------*/
export function MessagePane(props) 
{
 const conn          = props.conn;
 const other_profile = props.other_profile;
 const other_uid     = other_profile.uid;

 const init_state = 
    {
     bottom_pos: 0, // pozice spodního okraje obsahu panelu zpráv vůči spodnímu okraji panelu
     last_in_msg_id: null,  // id poslední příchozí zprávy, pro niž je určeno bottom_pos (další zpráva ho resetuje)
     last_out_msg_id: null, // -"- ale odchozí
     ilmp: null, // incomming last message packet; poslední packet příchozích zpráv, který má být zobrazený
     olmp: null  // - totéž pro odchozí zprávy
    };

 const [state, set_state] = React.useState(init_state);
 const messages = useMessages(other_uid, state.ilmp, state.olmp);
 //const unread   = useDbData(['profile', 'cache', 'unread_msgs', other_uid]);
 const unread_ts = (conn && conn.last_read) || Date.now(); // timestamp poslední přečtené zprávy protějšku
 const pane_ref = React.useRef(null);
 const tr_def   = useTranslationDef();
 const t = tr_def.translate;
 const ui_lang = tr_def.lang;

 let mounted = {state: true}; // proměnná indikující, jestli je komponenta pořád přimountovaná,
                             // aby se nevolalo set_state na nepřimountované komponentě (je to error Reactu)

 // Při odmountování komponenty zaznamenat, že jse odmountovaní, aby se nevyvolával set_state():
 React.useEffect(() => () => mounted.state = false);
 
 // Označit příchozí zprávy za přečtené, pokud je uživatel aktivní:
/* React.useEffect(() => {if(unread)
                          {
                           if(is_user_active)
                             {
                              mark_messages_read(other_uid);
                             }
                           else
                             {
                              const on_act_chng = act => {if(act) mark_messages_read(other_uid)};
                              on_user_activity_change(on_act_chng);
                              return () => on_user_activity_change_remove(on_act_chng);
                             }
                          }
                       },
                 [unread, other_uid]);*/

 const last_in_msg_id = messages.in && messages.in.last_msg_id;
 const last_out_msg_id = messages.out && messages.out.last_msg_id;

 //--------
 // Listener na změnu velikosti panelu
 const scroll_pane = React.useCallback(param => 
    {
     const pane = pane_ref.current;

     if(!pane) return;

     let pos = typeof param === 'number' ? param : state.bottom_pos;

     const ph = pane.clientHeight;
     const content = pane.getElementsByClassName('message-block')[0];
     const ch = content.offsetHeight;
     if(ch > ph)
       {
        //console.log("PRE scrollTo: pane="+ph+" child="+ch+" scrollTop="+pane.scrollTop+" to="+(ch-ph));
        pane.scrollTo(0, ch-ph - pos);
        //console.log("POST scrollTo: scrollTop="+pane.scrollTop);
       }
    }, [pane_ref, state.bottom_pos]);

 React.useEffect(() =>
                {
                 const pane = pane_ref.current;
                 
                 setTimeout(() => update_packet_req(pane, messages, set_state, mounted), 100); 

                 window.addEventListener('resize', scroll_pane);

                 return () => window.removeEventListener('resize', scroll_pane);
                });

 // Odscrollování na konec zpráv při zobrazení:
 React.useLayoutEffect(() => 
                      {
                       if(last_in_msg_id !== state.last_in_msg_id || last_out_msg_id !== state.last_out_msg_id)
                         { // přišla nová zpráva, resetovat pozici obsahu:
                          set_state(state => ({...state, 
                                               bottom_pos: 0, 
                                               last_in_msg_id: last_in_msg_id,
                                               last_out_msg_id: last_out_msg_id}));
                          scroll_pane(0);
                         }

                      }, [scroll_pane, state.last_in_msg_id, state.last_out_msg_id, last_in_msg_id, last_out_msg_id]); 
                          // odscrollovat dolů, jen když přijde nebo odejde nová zpráva

 //--------
 // Listener na scrollování obsahu
 const on_scroll = e => 
    {
     const pane = e.target;
     const ph = pane.clientHeight;
     const child = pane.firstChild;
     const ch = child.offsetHeight;

     let new_state = {...state};
     let state_chng = false;

     const bottom_pos = ch-ph - pane.scrollTop;
     if(bottom_pos !== state.bottom_pos)
       {
        new_state.bottom_pos = bottom_pos;
        new_state.last_in_msg_id = last_in_msg_id;
        new_state.last_out_msg_id = last_out_msg_id;
        state_chng = true;
       }

     //console.log("scroll: pane="+ph+" child="+ch+" scrollTop="+pane.scrollTop+"(pane_ref.scrollTop="+pane_ref.current.scrollTop+") bottom_pos="+bottom_pos);
     if(state_chng)
        set_state(new_state);

     setTimeout(() => update_packet_req(pane, messages, set_state, mounted), 100); 
    }

 //--------
 // Vyvolá nové odeslání všech zpráv s errorem
 const resend_messages = () =>
    {
     if(!messages.out) return;

     for(let key in messages.out.msg)
        {
         const m = messages.out.msg[key];
         if(m.$error)
            master.db_send_message(other_uid, null, key);
        }
    }

 const other_nick = other_profile.nick_name;

 // Sestavit seznam zpráv ke zobrazení:
 let mlist = [];
 let msg_loading = 0;

 if(messages)
   {
    msg_loading += msgs_from_obj(mlist, messages.in,  messages.in_archive,  false, state.ilmp);
    msg_loading += msgs_from_obj(mlist, messages.out, messages.out_archive, true, state.olmp);
    mlist.sort((a, b) => a.t - b.t);
   }

 // Poznamenat poslední přečtenou zprávu:
 const last_read = (messages.in && messages.in.last_read) || 0;
 let last_read_msg = null;

 if(last_read && mlist.length > 0 && mlist[mlist.length-1].out)
   {// Pokud je poslední zpráva příchozí, je jasné, že protějšek naše zprávy četl, 
    // tak indikátor přečtené zprávy vůbec nezobrazovat.

    // Najít první odeslanou zprávu s časem menším, než je last_read:
    for(let i = mlist.length-1; i >= 0; i--)
       {
        const m = mlist[i];
        if(!m.out) break;
        if(m.t < last_read)
          {
           last_read_msg = m;
           break;
          }
       }
   }

 // Vytvořit zobrazení zpráv:
 let content = [];
 let unread_msgs = false; // nastaveno na true na první přijaté nepřečtené zprávě

 for(let i = 0; i < mlist.length;)
    {
     let gr = [];
     const gr_ts = mlist[i].t;
     const gr_out = !!mlist[i].out;

     if(i === 0 || gr_ts - mlist[i-1].t >= MSG_BREAK_TIME)
       {
        const tstr = date_time_to_loc_string(mlist[i].t, ui_lang, true);
        content.push(<div className = "brk" key = {"brk"+gr_ts}>{tstr}</div>);
       }
     
     if(!gr_out && !unread_msgs && gr_ts > unread_ts)
       {
        unread_msgs = true;
        content.push(<div className = "brk unread" key = {"brk-unr"+gr_ts}>{t('msg_unread')}</div>);
       }

     let i0 = i;

     // Sestavit skupinu zpráv. Skupinu tvoří správy od jednoho odesílatele, pokud nejsou příliš časově vzdálené:
     while(i===i0 || (i < mlist.length && !!mlist[i].out === gr_out && mlist[i].t - mlist[i-1].t < MSG_BREAK_TIME &&
                      (mlist[i].out || unread_msgs || mlist[i].t <= unread_ts /* break na první nepřečtené přijěté zprávě*/)))
          {
           const msg = mlist[i];

           let sending = null;

           let title = "";
           if(msg.$sending)
              sending = <WaitIndicator/>;   
           else
              title = t('msg_send_time') + " " + date_time_to_loc_string(msg.t, ui_lang, true);

           let nc = 'msg';

           if(!gr_out && unread_msgs)
              nc += ' unread-in';

           let mnode = <div className={nc} key={msg.t} title={title}>{sending}{msg.m}</div>;

           let wrap_class;
           let mnote;
           if(msg.$error)
             {
              wrap_class = "msg-wrap err"
              mnote = <div className="note err">{t('err_send_msg')}<br/>{t('err_send_msg2')}</div>;
             }
           else if(last_read_msg === msg)
             { // označit zprávu jako poslední přečtenou
              wrap_class = "msg-wrap"
              const str = t('msg_read_time') + " " + date_time_to_loc_string(last_read, ui_lang, true);
              mnote = <div className="note rd">{str}</div>;
             }

           if(mnote)
             {
              mnode = <div className = {wrap_class}
                           key       = {"n"+msg.t}
                           onClick   = {resend_messages}>
                       {mnode}
                       {mnote}
                      </div>
             }
           

           gr.push(mnode);
           i++;
          }
     
     content.push(<div className = {"message-group" + (gr_out ? " out" : "")} 
                       key       = {gr_ts}
                       ts        = {gr_ts}>

                    <UserIcon title = {gr_out ? props.nick_name : other_nick}
                              uid   = {gr_out ? current_uid : other_uid}/>
                    <div className="msgs">
                      {gr}
                    </div>
                  </div>);
    }
 
 return <div id = "layout-content" 
             ref = {pane_ref}
             onClick = {() => mark_messages_read(other_uid)}
             onScroll = {on_scroll}>
          {!!msg_loading && <WaitIndicator/>}

          <div className="message-block">
           {content}
          </div>
        </div>;
}
