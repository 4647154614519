/*#################################################################################################

Funkce pro načítání profilů ostatních uživatelů a udržování jejich kopií ve stavu aplikace

#################################################################################################*/

import firebase from 'firebase/app';
import {firestore,
        firebase_functions} from '../config';
import {defer_call} from '../utils';
import {session_id,
        master_def,
        master} from './master';
import {current_uid} from './db';
import {db_set_data,
        db_get_data,
        db_watch_data,
        db_unwatch_data} from './app_state';
import {profile_from_db,
        db_update_conn} from './own_profile';
const {CONN_REL_BLOCKED} = require('../profile_def');


/*-------------------------------------------------------------------------------------------------
Vyvolá načtení profilu uživatele uid. 
Stav průběhu a výsledná data nastavuje do stavového objektu 'user/<uid>'.
V průběhu načítání je v objektu položka $loading = true.
Pokud došlo k chybě, je v objektu položka $error = true.
-------------------------------------------------------------------------------------------------*/
master_def.db_read_profile = function(uid)
{
 console.log("db_read_profile", uid);

 const user_ref = ['user', uid];

 const prof = db_get_data(user_ref);

 if(prof && prof.$loading === session_id)
    return;

 db_set_data(user_ref,
             'merge',
             {uid: uid,
              $loading: session_id}); // zda jde o stejné session_id se testuje výše a v useUserProfile a get_user_profile_async

 const user_profile = firebase_functions.httpsCallable('user_profile');

 user_profile({uid: uid})
 .then(resp => 
      {
       const profile = profile_from_db(resp.data); 
       db_set_data(user_ref, 'set', profile);
       db_update_conn(profile);
       return profile;
     })
 .catch(err =>
       {
        console.error(err);
        db_set_data(user_ref,
                    'merge',
                    {$loading: null,
                     $error: err.code});
       });
}

/*-------------------------------------------------------------------------------------------------
Vrátí promise na profile uživatele s daným uid.
-------------------------------------------------------------------------------------------------*/
export function get_user_profile_async(uid)
{
 const user_ref = ['user', uid];
 const profile = db_get_data(user_ref);

 if(profile && !profile.$loading)
    return Promise.resolve(profile);

 return new Promise(resolve => 
                   {
                    let resolved = false; // Volání call-backu mohou být ve frontě opakovaně.
                    // Tato proměnná se nastaví, pokud byl promise resolvován a call-back
                    // na data ostraněn. (Především je nutné zabránit ostraňování již 
                    // odstraněného call-backu).

                    // Call-back na změnu profilu:
                    const cb = () =>
                       {
                        if(resolved) return;

                        const p = db_get_data(user_ref);
                        if(p)
                          {
                           if(!p.$loading)
                             {
                              resolved = true;
                              resolve(p);
                              defer_call(() => db_unwatch_data(user_ref, cb));
                             }
                          }
                        else
                          {
                           master.db_read_profile(uid);
                          }
                       }

                    // Nastavit callback na data profilu:
                    db_watch_data(user_ref, cb);
                   });
}

/*-------------------------------------------------------------------------------------------------
Zahájí vyhledávání uživatelů podle řetězce (hledají se v profile.serach_str)
Vrací Promise na seznam vyhledaných profilů (pole uid).
Zároveň nalezené profily uloží do cache.
-------------------------------------------------------------------------------------------------*/
master_def.response.db_search_user = true;
master_def.db_search_user = function(str)
{
 str = str.trim().toLowerCase()

 // Zkusit najít uživatele lokálně:
 const profile = db_get_data('profile');

 if(profile)
   {
    let result = [];

    if(str === '' || str === (profile.primary_mail || "").toLowerCase())
       return Promise.resolve(result); // prázdný řetězec a sebe hledat nebudeme
    
    // - prohledat propojené uživatele:
    const conn = profile.conn;
    if(conn)
      {
       for(let uid in conn)
          {
           const c = conn[uid];

           if(str === (c.nick_name || "").toLowerCase() ||
              str === (c.full_name || "").toLowerCase())
              result.push(uid);
          }
      }
    
    // - prohledat cache profilů:
    for(let k in localStorage)
       {
        if(!localStorage.hasOwnProperty(k))
           continue;
        
        // Klíč má tvar 'user.<uid>':
        if(k.match(/^(user)\.(?:[^.$]+)$/))
          {
           const p = JSON.parse(localStorage.getItem(k));

           if(result.indexOf(p.uid) >= 0 || 
              (conn[p.uid] && conn[p.uid].rel === CONN_REL_BLOCKED))
              continue;

           if((str === (p.nick_name || "").toLowerCase() ||
               str === (p.primary_mail || "").toLowerCase()))
              result.push(p.uid);
          }
       }

    if(result.length > 0)
       return Promise.resolve(result);
   }

 const search_user = firebase_functions.httpsCallable('search_user');

 return search_user({search_str: str, limit: 5})
        .then(resp => 
             {
              let result = [];
        
              resp.data.forEach(prof => 
                 {
                  const profile = profile_from_db(prof);
                  const uid = profile.uid;
        
                  // Uložit načtené profily do cache:
                  db_set_data(['user', uid], 'set', profile);
        
                  db_update_conn(profile);
        
                  result.push(uid)
                 });
              return result;
            })
        .catch(err =>
              {
               console.error(err);
              });
}

/*-------------------------------------------------------------------------------------------------
Zapíše hodnocení dojmu z uživatele.

Parametry:
 uid       .. uid hodnoceného uživatele
 data_id   .. 'ic' nebo 'ip', podle toho, jdestli jde o dojem z chatu nebo osobního setkání
 data      .. dojem; řetězec 8 nebo 9 znaků
 prev_data .. předchozí dojen z téhož uživatele (nebo undefined, pokud je hodnocený poprvé)

Vrací Promise na bool; true = úspěch, false = data se nepodařilo zapsat.
-------------------------------------------------------------------------------------------------*/
master_def.response.db_write_user_impression = true;
master_def.db_write_user_impression = function(uid, data_id, data, prev_data)
{
 console.log("db_write_user_impression", uid, data_id, data);

 // Zapisuje se jako batch (atomicky) do seznamu aktivního dojmu a do souhrnu pasivního
 // dojmu cílového uživatele.
 let batch = firestore.batch();

 // - přidat záznam do seznamu aktivních dojmů:
 const field_id = Date.now()+"_"+data_id;

 let ia_upd = {data: {[uid]: {[field_id]: data}},
               ts: firebase.firestore.FieldValue.serverTimestamp()};

 let ref = firestore.doc(`user/${current_uid}/impress/0`);
 batch.set(ref, ia_upd, {merge: true});

 // - aktualizovat souhrn pasivních dojmů cílového uživatele:
 let ip_data = {};
 let dcount;
 let nid;
 if(data_id === 'ic')
   {
    dcount = 8;
    nid = 'nc';
   }
 else
   {
    dcount = 9;
    nid = 'np';
   }

 // -- sestavit seznam inkrementů položek odpovídajících součtu impressí jednotlivých faktorů
 //    a počtu hodnocení:
 for(let i = 0; i < dcount; i++)
    {
     const c = data.charCodeAt(i);
     const pc = prev_data ? prev_data.charCodeAt(i) : 0; 

     let inc_i = 0;
     let inc_n = 0;

     if(c)
       {
        inc_i = c-51;
        inc_n = 1;
       }
     
     if(pc)
       {
        inc_i -= pc-51;
        inc_n -= 1;
       }

     // pozn.: nezapsané číslo = 0
     if(inc_i)
        ip_data[data_id+i] = firebase.firestore.FieldValue.increment(inc_i);

     if(inc_n)
        ip_data[nid+i] = firebase.firestore.FieldValue.increment(inc_n);
    }

 let ip_upd = {data: ip_data,
               ts: firebase.firestore.FieldValue.serverTimestamp()};

 ref = firestore.doc(`impress_pass/${uid}`);
 batch.set(ref, ip_upd, {merge: true});

 // - provést dávku:
 return batch.commit()
             .then(() => true)
             .catch(err => 
                   {
                    console.error("db_write_user_impression", err);
                    return false;
                   });
}
