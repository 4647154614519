/*#################################################################################################

Přepínač mezi true/false.

#################################################################################################*/

import React from 'react';
import {call_setter} from './call_setter';

/*-------------------------------------------------------------------------------------------------
props  data_id       .. (nepovinné) identifikátor nastavený jako atribut "id" a "name"
       value         .. iniciální hodnota zobrazená v editoru
       setter        .. funkce vyvolaná při změně stavu. 
                        Parametr: hodnota (bool)
 -------------------------------------------------------------------------------------------------*/
export function Toggle(props) 
{
 //----------
 const on_click = event => 
    {
     call_setter(props, event.currentTarget.attributes.value.value==="0");
    }

 return (<div className="toggle-button" 
              value={props.value ? "1" : "0"}
              onClick={on_click}>
          <span className="knob"></span>
         </div>
);
}
