import React from 'react';
import {ProfileQItem} from './ProfileQItem';
import {BottomNav} from './BottomNav';

/*------------------------------------------------------------------------------------------------- 
Vrací editor dotazníku "hodnoty".
------------------------------------------------------------------------------------------------- */ 
export function profile_edit_qv(props, tr_def)
{
 const profile = props.profile;

 const t = tr_def.translate;

 return <>
      <h1>{props.back_btn}{t('qv.lbl')}</h1>

      <div className = "desc">
       <p>{t('qv.desc')}</p>
       <p>{t('qv.desc2')}</p>
      </div>

      <ProfileQItem data_id = "qv1"
                    text_bold = {t('qv1.ttl')}
                    text_main = {t('qv1.main')}
                    text_min  = {t('q_val_contr')}
                    text_zero = {t('q_val_nimp')}
                    text_max  = {t('q_val_vimp')}
                    ncount = {4}
                    red_min = {0}
                    acc_icon
                    profile = {profile}/>

      <ProfileQItem data_id = "qv2"
                    text_bold = {t('qv2.ttl')}
                    text_main = {t('qv2.main')}
                    text_min  = {t('q_val_contr')}
                    text_zero = {t('q_val_nimp')}
                    text_max  = {t('q_val_vimp')}
                    ncount = {4}
                    red_min = {0}
                    profile = {profile}/>

      <ProfileQItem data_id = "qv3"
                    text_bold = {t('qv3.ttl')}
                    text_main = {t('qv3.main')}
                    text_min  = {t('q_val_contr')}
                    text_zero = {t('q_val_nimp')}
                    text_max  = {t('q_val_vimp')}
                    ncount = {4}
                    red_min = {0}
                    profile = {profile}/>

      <ProfileQItem data_id = "qv4"
                    text_bold = {t('qv4.ttl')}
                    text_main = {t('qv4.main')}
                    text_min  = {t('q_val_contr')}
                    text_zero = {t('q_val_nimp')}
                    text_max  = {t('q_val_vimp')}
                    ncount = {4}
                    red_min = {0}
                    profile = {profile}/>

      <ProfileQItem data_id = "qv5"
                    text_bold = {t('qv5.ttl')}
                    text_main = {t('qv5.main')}
                    text_min  = {t('q_val_contr')}
                    text_zero = {t('q_val_nimp')}
                    text_max  = {t('q_val_vimp')}
                    ncount = {4}
                    red_min = {0}
                    profile = {profile}/>

      <ProfileQItem data_id = "qv6"
                    text_bold = {t('qv6.ttl')}
                    text_main = {t('qv6.main')}
                    text_min  = {t('q_val_contr')}
                    text_zero = {t('q_val_nimp')}
                    text_max  = {t('q_val_vimp')}
                    ncount = {4}
                    red_min = {0}
                    profile = {profile}/>

      <ProfileQItem data_id = "qv7"
                    text_bold = {t('qv7.ttl')}
                    text_main = {t('qv7.main')}
                    text_min  = {t('q_val_contr')}
                    text_zero = {t('q_val_nimp')}
                    text_max  = {t('q_val_vimp')}
                    ncount = {4}
                    red_min = {0}
                    profile = {profile}/>

      <ProfileQItem data_id = "qv8"
                    text_bold = {t('qv8.ttl')}
                    text_main = {t('qv8.main')}
                    text_min  = {t('q_val_contr')}
                    text_zero = {t('q_val_nimp')}
                    text_max  = {t('q_val_vimp')}
                    ncount = {4}
                    red_min = {0}
                    profile = {profile}/>

      <ProfileQItem data_id = "qv9"
                    text_bold = {t('qv9.ttl')}
                    text_main = {t('qv9.main')}
                    text_min  = {t('q_val_contr')}
                    text_zero = {t('q_val_nimp')}
                    text_max  = {t('q_val_vimp')}
                    ncount = {4}
                    red_min = {0}
                    profile = {profile}/>

      <ProfileQItem data_id = "qv10"
                    text_bold = {t('qv10.ttl')}
                    text_main = {t('qv10.main')}
                    text_min  = {t('q_val_contr')}
                    text_zero = {t('q_val_nimp')}
                    text_max  = {t('q_val_vimp')}
                    ncount = {4}
                    red_min = {0}
                    profile = {profile}/>

      <BottomNav left  = {{tid:  'qe.lbl',    url: "/profile/qe"}}
                 right = {[{tid: 'hdr_odate', url: "/odate"},
                          {tid:  'hdr_chat',  url: "/chat"}]}/>
    </>;
}

