import React from 'react';
import {PopupWindow} from '../lib/PopupWindow';
import {useTranslation} from '../translations/useTranslation';
import './AccControl.css';
const {ACCESS_PRIVATE, 
        ACCESS_PUBLIC, 
        ACCESS_ACQ, 
        ACCESS_FRIEND} = require('../profile_def.js');

const acc_desc = 
{
 [ACCESS_PRIVATE]: "acc_priv",
 [ACCESS_FRIEND]:  "acc_friend",
 [ACCESS_ACQ]:     "acc_acq",
 [ACCESS_PUBLIC]:  "acc_publ" 
};

/*------------------------------------------------------------------------------------------------- 
Zobrazuje přístupová práva k položce a případně je umožňuje měnit.
Props:
 value      .. stávající přístupová práva
 no_private .. pokud je true, nebude obsažena možnost 'soukromé'
 setter     .. funkce umožňující změnu přístupovách práv. Pokud není zadaná, jsou jen read-only.
 on_delete  .. funkce pro smazání položky. Pokud je zadána, je do menu přidána položky 'odstranit'.
------------------------------------------------------------------------------------------------- */ 
export function AccControl(props) 
{
 const init_state = 
    {
     is_open: false // true, pokud je okno selectionu otevřené
    }

 const [state, set_state] = React.useState(init_state);
 const t = useTranslation();

 // ----------------
 // Kliknutí na AccControl:
 const on_click_accc = e => 
    {
     if(props.setter)
       {
        if(state.is_open)
           set_state({...state, is_open: false, wnd_anchor: null});
        else
           set_state({...state, is_open: true, wnd_anchor: e.target.getBoundingClientRect()});
       }

     e.preventDefault();
    }

 // ----------------
 // Kliknutí při otevřeném okně na položku okna nebo mimo okno:
 const on_click_window = (node, e) => 
    {
     if(node !== null)
       {
        const val = node.attributes.value.value;

        if(val === 'delete')
           props.on_delete();
        else
           props.setter(parseInt(val, 10));
       }

     if(state.is_open)
        set_state({...state, is_open: false, wnd_anchor: undefined});

     e.stopPropagation();
     e.preventDefault();
    }

 let cname = "acc-control";

 switch(props.value)
       {
        case ACCESS_PUBLIC:
             cname += " acc-public";
             break;
        case ACCESS_ACQ:
             cname += " acc-acq";
             break;
        case ACCESS_FRIEND:
             cname += " acc-friend";
             break;
        default:
             cname += " acc-private";
             break;
       }

 if(props.setter)
    cname += " wr";

 let content;
 if(state.is_open)
   {
    content = <>
               {!props.no_private &&
                <div className="wnd-item" value={ACCESS_PRIVATE}>{t(acc_desc[ACCESS_PRIVATE])}</div>}
               <div className="wnd-item" value={ACCESS_FRIEND }>{t(acc_desc[ACCESS_FRIEND]) }</div>
               <div className="wnd-item" value={ACCESS_ACQ    }>{t(acc_desc[ACCESS_ACQ])    }</div>
               <div className="wnd-item" value={ACCESS_PUBLIC }>{t(acc_desc[ACCESS_PUBLIC]) }</div>
               {props.on_delete &&
                <div className="wnd-item" value='delete'>{t('btn_delete')}</div>}
              </>;
   }

 return (<span className={cname} onClick={on_click_accc} title={t(acc_desc[props.value])}>
          {state.is_open && 
           <PopupWindow content={content}
                        anchor={state.wnd_anchor}
                        align_right={true}
                        on_click={on_click_window}/>}
         </span>);
}
