import React from 'react';
import {get_profile_access} from '../user';
import {AccControl} from '../controls/AccControl'
import {TextEditor} from '../lib/TextEditor';
import {useTranslationDef} from '../translations/useTranslationDef';

/**
 * Editor profilových textů (pro každý jazyk jeden text)
 * 
 * @param {any} props  value   .. výchozí hodnota (objekt, jehož členy jsou texty pojmenované zkratkami jazyků.
 *                     langs   .. seznam přípustných jazyků (pole zkratek jazyků)
 *                     setter  .. funkce pro nastavení nové hodnoty
 *                     profile .. objekt profilu
 */
export function ProfileTextEditor(props) 
{
 const tr_def = useTranslationDef();
 const lang_loc = tr_def.lang_names; 
 const t = tr_def.translate;

 //-----------------
 // Nastaví hodnotu jednoho jazyka
 const set_subvalue = (lng, txt) =>
    {
     const new_val = {...props.value, [lng]: txt};
     if(txt === "")
        delete new_val[lng];
     props.setter(new_val);
    }

 return  <div className="pr-item">
           <AccControl value  = {get_profile_access(props.profile, "profile_text")} />
           {props.langs.map(lng => (<div className="profile-text-item" key={lng}>
                                  <div className="label wide">
                                   {t('profile_text.lbl')} {props.langs.length>1 && ("("+lang_loc[lng]+")")}
                                  </div>
                                  <TextEditor value={props.value && props.value[lng] ? props.value[lng] : ""}
                                              maxlength = {4096}
                                              lang = {lng}
                                              setter={txt => set_subvalue(lng, txt)}/>
                                </div>))}
         </div>;
}
