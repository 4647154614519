/*#################################################################################################

Stromeček stránky profilu.

#################################################################################################*/

import React from 'react';
import {Redirect,
        useLocation,
        useHistory} from 'react-router-dom';
import {ProfDispSel} from './ProfDispSel';
import {TreeItem} from '../controls/TreeItem';
import {useTranslation} from '../translations/useTranslation';
import './ProfileTree.css';

/*------------------------------------------------------------------------------------------------- 
props  subpage .. identifikátor podstránky
       profile .. objekt profilu
       profile_summary .. výsledek volání get_profile_summary()
       media   .. vlastnosti okna browseru; objekt s členy:
                  narrow .. okno je užší než 900px   
------------------------------------------------------------------------------------------------- */ 
export function ProfileTree(props) 
{
 const location = useLocation();
 const url_history = useHistory();
 const t = useTranslation();

 const url_path = location.pathname;
 const subpage = props.subpage;
 const ps      = props.profile_summary;

 if(subpage === "" && ps.main_missing)
    return <Redirect to="/profile/main"/>;

 const pg_view = subpage === 'settings' || subpage === '' || subpage === 'public' || subpage === 'acq' || subpage === 'friend';

 let subcontent = null;
 if(!pg_view || ps.q_missing)
   {
    subcontent = <>
                  {subpage !== "" && 
                   <>
                    <TreeItem title = {t('prf_main')}
                              path = "/profile/main"
                              url_path = {url_path}
                              nf_val = {ps.main_missing}/>
                    <TreeItem title = {t('prf_ls')}
                              path = "/profile/ls"
                              url_path = {url_path}/> 
                   </>}
                   <TreeItem title = {t('prf_q')}
                             path = "/profile/q"
                             exact
                             url_path = {url_path}
                             nf_val = {ps.q_missing}
                             subtree = {<>
                                         <TreeItem title = {t('qt.lbl')}
                                                   path = "/profile/qt"
                                                   url_path = {url_path}
                                                   nf_val = {ps.qt_missing} />
                                         <TreeItem title = {t('qr.lbl')}
                                                   path = "/profile/qr"
                                                   url_path = {url_path}
                                                   nf_val = {ps.qr_missing} />
                                         <TreeItem title = {t('qm.lbl')}
                                                   path = "/profile/qm"
                                                   url_path = {url_path}
                                                   nf_val = {ps.qm_missing} />
                                         <TreeItem title = {t('qe.lbl')}
                                                   path = "/profile/qe"
                                                   url_path = {url_path}
                                                   nf_val = {ps.qe_missing} />
                                         <TreeItem title = {t('qv.lbl')}
                                                   path = "/profile/qv"
                                                   url_path = {url_path}
                                                   nf_val = {ps.qv_missing} />
                                        </>}
                  
                   />
                 </>;
   }

 return <div className="profile-tree">
         <h1>
          <span className = 'clickable'
                onClick = {() => url_history.push("/profile")}>
           {t('prf.title')}
          </span>
          {pg_view &&
           <span className = 'edit-icon'
                 onClick   = {() => url_history.push("/profile/main")}></span>}
         </h1>
         <ProfDispSel subpage     = {subpage}
                      pg_view     = {pg_view}
                      narrow      = {false}
                      url_history = {url_history}/>
         {subcontent}
         <TreeItem title    = {t('prf_settings')}
                   path     = "/profile/settings"
                   url_path = {url_path}/>
         <TreeItem title    = {t('terms_of_service')}
                   path     = "/terms-of-service.html"
                   external = {true}
                   url_path = {url_path}/>
        </div>;
}
