/*#################################################################################################

Funkce pro správu globálního stavu aplikace

#################################################################################################*/

import {defer_call,
        object_assign,
        object_get, 
        object_set,
        object_is_empty,
        object_is_equal2} from '../utils';
import {is_master,
        master_def,
        master} from './master';
import {write_rd_marks,
        normalize_msg_packet} from './messages';
import {note_user_activity_global} from '../user_activity';
const {profile_def} = require('../profile_def.js');

console.log("LOAD app_state");

/*-------------------------------------------------------------------------------------------------
Načte hodnotu z local-storage a deparsuje ji. Pokud hodnota není přítomna, vrací null.
-------------------------------------------------------------------------------------------------*/
export const storage_get = id => JSON.parse(localStorage.getItem(id));

/*-------------------------------------------------------------------------------------------------
Převede hodnotu na řetězec a uloží ji do local-storage a deparsuje ji. 
Pokud je val = null, hodnotu smaže.
-------------------------------------------------------------------------------------------------*/
export const storage_set = (id, val) => val===null || val===undefined 
                                          ? localStorage.removeItem(id)
                                          : localStorage.setItem(id, JSON.stringify(val));


/*-------------------------------------------------------------------------------------------------
Call-back na local-storage. V local storage se primárně udržuje stávající stav připojení k 
databázi. Pomocí local storage zároveň probíhá komunikace mezi jednotlivými instancemi.
-------------------------------------------------------------------------------------------------*/
function on_storage_change(e)
{
 switch(e.key)
       {
        case '.chng':
             if(is_master)
                console.error("Only master can write '.chng'");
             break;

        case '.uact':
             if(is_master)
                note_user_activity_global();
             break;

        case '.email_verified':
            { // signál, že byla potvrzena emailová adresa účtu (Viz komentář v send_verification_mail.js)
             if(e.newValue !== null)
               {
                try
                   {
                    localStorage.removeItem(".email_verified");
                   }
                catch(e) 
                   {
                    console.error("Unable to write localStorage", e); 
                   }

                if(is_master)
                   db_set_data('state.mail_ver_pending', 'set', null);

                window.location.reload();
               }
             break;
            }

        case 'profile_db':
             /* eslint-disable-next-line no-fallthrough */
        case 'profile_changes':
             const ref = ['profile'];
             db_watch_invoke(new Data_op(ref, 'set', db_get_data(ref)));
             break;
        
        case 'wr_msg':
             db_watch_invoke(new Data_op(['wr_msg']));
             break;

        case 'state':
             db_watch_invoke(new Data_op(['state']));
             break;
           
        default: 
             let m = e.key.match(/^messages\.([^.$]+)$/);
             if(m)
                db_watch_invoke(new Data_op(['messages', m[1]]));

             m = e.key.match(/^user\.([^.$]+)$/);
             if(m)
                db_watch_invoke(new Data_op(['user', m[1]]));
             break;
       }
}

/*-------------------------------------------------------------------------------------------------
Strom call-back funkcí na změnu globálního stavu aplikace.
Struktura stromu kopíruje strukturu stavu. Každý uzel má seznam poduzlů a navíc položku '$',
která obsahuje seznam call-back funkcí vyvolaných při zněně odpovídajícího uzlu globálních dat.
-------------------------------------------------------------------------------------------------*/
let watches = {}; 

/*-------------------------------------------------------------------------------------------------
Vyvolá rekursivně všechny handlery v podstromě definic handlerů wobj.
-------------------------------------------------------------------------------------------------*/
function watch_invoke_tree(wobj, data_op)
{
 const prev_ref = data_op.ref;
 data_op.ref = [...prev_ref, ""];

 for(let key in wobj)
    {
     if(key === '$')
       {
        for(let j = 0; j < wobj.$.length; j++)
           {
            const fn = wobj.$[j];
            defer_call(() => fn(data_op));
           }
       }
     else
       {
        data_op.ref[prev_ref.length] = key;
        watch_invoke_tree(wobj[key], data_op);
       }
    }

 data_op.ref = prev_ref;
}

/*-------------------------------------------------------------------------------------------------
Pomocná funkce pro operace nad seznamem funkcí pro sledování změn globálního stavu.

Parametry:
  ref   .. pole složek cesty k uzlu, jehož se operace týká (např. ['users', user-id]).
           Může být zakódovaná i jako řetězec oddělený tečkami (např. "users.user-id")
  op    .. operace:
           0 = nastavit handler (param = call-back fn)
           1 = vyvolat handler (param = instance Data_op)
           2 = zrušit handler (param = call-back fn; musí být identická jako při nastavování)
  param .. parametr operace
-------------------------------------------------------------------------------------------------*/
function watch_op(ref, op, param)
{
 let wobj = watches;

 if(typeof ref === "string")
    ref = ref.split(".");

 for(let i = 0; i < ref.length; i++)
    {
     if(op === 1 && wobj.$) // vyvolat handlery i transitivně na vyšších objektech
       {
        const p = param;
        for(let j = 0; j < wobj.$.length; j++)
           {
            const fn = wobj.$[j];
            defer_call(() => fn(p)); 
           }
       }
       
     const id = ref[i];
     if(!wobj[id])
       {
        if(op !== 0)
           return; // víme, že tam handler není (chybí celý objekt), a nemá se nastavovat => skončit

        wobj[id] = {};
       }

     wobj = wobj[id];
    }

 switch(op)
       {
        case 0: // nastavit handler
             if(wobj.$)
               wobj.$.push(param);
             else
               wobj.$ = [param];

             // Při nastavení handleru ho zároveň vyvolat:
             param = new Data_op(ref, "set", db_get_data(ref));

             /* eslint-disable-next-line no-fallthrough */
        case 1: // vyvolat handler
             if(wobj.$)
                for(let j = 0; j < wobj.$.length; j++)
                   {
                    const fn = wobj.$[j];
                    defer_call(() => fn(param));
                   }

             let sub_data_op = null;

             for(let key in wobj)
                {
                 if(key !== '$')
                   {
                    if(!sub_data_op)
                       sub_data_op = new Data_op([...ref, key]);

                    sub_data_op.ref[ref.length] = key;

                    watch_invoke_tree(wobj[key], sub_data_op);
                   }
                }
             break;

        case 2: // odstranit handler
             const i = wobj.$.indexOf(param);
             if(i > -1)
                wobj.$.splice(i, 1);
             else 
               {
                console.error("db_unwatch_data: unknown fn")
                debugger;
               }
             break;
        default:
             console.error("watch_op: bad op");
       }
}

let watch_invoking = false; // příznak, že probíhá vyvolávání handlerů
// operace na globálním stavu se nesmějí provádě v průběhu vyvolávání handlerů
// je třeba použít defer_call

/*-------------------------------------------------------------------------------------------------
Nastaví handler fn na globální data s referencí ref.
-------------------------------------------------------------------------------------------------*/
export function db_watch_data(ref, fn)
{
 if(watch_invoking)
   {
    debugger;
    console.error("db_watch_data in invoke");
   }

 watch_op(ref, 0, fn);
}

/*-------------------------------------------------------------------------------------------------
Zruší handler nastavený pomocí db_watch_data().
-------------------------------------------------------------------------------------------------*/
export function db_unwatch_data(ref, fn)
{
 if(watch_invoking)
   {
    debugger;
    console.error("db_unwatch_data in invoke");
   }

 watch_op(ref, 2, fn);
}

/*-------------------------------------------------------------------------------------------------
Vyvolá handler po změně dat.
-------------------------------------------------------------------------------------------------*/
function db_watch_invoke(data_op)
{
 if(watch_invoking)
   {
    debugger;
    console.error("db_watch_invoke in invoke");
   }

 watch_invoking = true;
 watch_op(data_op.ref, 1, data_op);
 watch_invoking = false;
}


/*-------------------------------------------------------------------------------------------------
Definice operace nad globálními data.

Parametry: 
 ref .. pole složek cesty k uzlu, jehož se operace týká (např. ['users', user-id]).
        Může bý zakódovaná i jako řetězec oddělený tečkami (např. "users.user-id")

 op  .. 'set' - data se na cílovém místě nahradí
        'merge' - data se na cílovém místě sloučí funkcí object_assign
        null - neznámá operace

 data .. data, která se mají nastavit/sloučit
-------------------------------------------------------------------------------------------------*/
export function Data_op(ref, op, data)
{
 this.ref = typeof ref === 'string' ? ref.split("."): ref;
 this.op = op;
 this.data = data;
}

/*-------------------------------------------------------------------------------------------------
Zapíše část globálního stavu aplikace.

Parametry:
 data_op .. instance Data_op popisující požadovanou operaci
 .. pokud data_op není instací Data_op, sestaví se Data_op interně ze tří předaných parametrů

Příklad:
 db_set_data(new Data_op(['profile', 'nick_name'], 'set', "Joe")
 zjednodušená verze:
 db_set_data('profile.nick_name', 'set', "Joe")
-------------------------------------------------------------------------------------------------*/
export function db_set_data(data_op, p1, p2)
{
 if(!(data_op instanceof Data_op))
    data_op = new Data_op(data_op, p1, p2);
 
 const ref = data_op.ref;
 switch(ref[0])
       {
        case 'profile':
              let profile_changes = storage_get('profile_changes') || {};
              let profile_db = storage_get('profile_db');
              let chng_subobj = object_get(profile_changes, ref, 1);
              let db_subobj = object_get(profile_db, ref, 1);

              if(object_is_equal2(data_op.data, chng_subobj, db_subobj, data_op.op==='merge'))
                 return;

              // Pokud se mění některý objekt připojení, zapamatovat jeho původní hodnotu,
              // aby se na konci dalo zjistit, které položky se změnily:
              let conn_org;
              if(ref.length > 2 && ref[1] === 'conn')
                {
                 conn_org = db_subobj;

                 if(ref.length === 3)
                   {
                    conn_org = {...conn_org};
                    const conn_chng = object_get(profile_changes, ref, 1);
                    object_assign(conn_org, conn_chng);
                   }
                 else if(ref.length === 4)
                   {
                    const conn_chng = object_get(profile_changes, ref, 1);
                    conn_org = conn_chng || conn_org;
                   }
                }

              if(object_set(profile_changes, data_op.op, data_op.data, ref, 1, profile_def))
                {
                 // Pokud se mění relevantní položky, aktualizovat pole vyhledávacích řetězců:
                 // FIXME .. logiku přesunout do db_write_own_profile
                 if(profile_changes.nick_name !== undefined || 
                    profile_changes.find_by_mail !== undefined ||
                    profile_changes.primary_mail)
                   {
                    const nick_name = profile_changes.nick_name || profile_db.nick_name;
                    const find_by_mail = profile_changes.find_by_mail !== null && profile_changes.find_by_mail !== undefined ?
                                         profile_changes.find_by_mail : profile_db.find_by_mail;
                    const primary_mail = profile_changes.primary_mail || profile_db.primary_mail;

                    let search_str = [];
    
                    if(nick_name)
                       search_str.push(nick_name.toLowerCase());
                    if(primary_mail && find_by_mail)
                       search_str.push(primary_mail.toLowerCase());

                    profile_changes.search_str = search_str; // handler se nevyvolává, protože aplikace změny této položky nesleduje
                   }
                    
                 storage_set('profile_changes', profile_changes);

                 data_op.ref = ['profile_changes'];
                 db_watch_invoke(data_op);

                 data_op.ref = ref;
                 db_watch_invoke(data_op);

                 // Zanamenat změny dat:
                 let seg_ref = 'profile';

                 if(ref.length > 2 && ref[1] === 'conn')
                   {
                    seg_ref = 'profile_aux';

                    const uid = ref[2];
                    
                    if(profile_changes.conn[uid])
                      {
                       const conn = profile_changes.conn[uid];

                       if(ref.length === 3)
                         { // zapisuje se celý objekt jednoho propojení, podívat se, která data se mění:
                          if(conn.rel !== conn_org.rel)
                             seg_ref = 'profile_imm';
                          else if(conn.last_read !== conn_org.last_read)
                             seg_ref = ['rd_mark', uid];
                         }
                       else if(ref.length === 4)
                         { // zapisuje se jedna položka objektu propojení, podívat se, která:
                          if(ref[3] === 'rel' && conn.rel !== conn_org)
                             seg_ref = 'profile_imm';
                          else if(ref[3] === 'last_read' && conn.last_read !== conn_org)
                             seg_ref = ['rd_mark', uid];
                         }
                      }
                   }
                 else if(ref.length > 1)
                   {
                    if(ref[1] === 'cache' || ref[1] === 'rpxi' || ref[1] === 'npxi')
                       seg_ref = 'profile_aux';
                    else if(ref[1] === 'access' || ref[1] === 'user_state')
                       seg_ref = 'profile_imm';
                   }
                 else
                   {
                    if(profile_changes.user_state !== undefined)
                       seg_ref = 'profile_imm';
                   }

                 master.db_data_changed(seg_ref);
                }
             break;
        
        case 'profile_changes':
            { // jen pro vynulování nebo nastavení celého objektu
             if(ref.length === 1 && data_op.op === 'set')
               {
                storage_set('profile_changes', data_op.data);
                let profile = storage_get('profile_db') || {};
                let dop = new Data_op(['profile_changes'], 'set', profile)
                db_watch_invoke(dop);
                dop.ref = ['profile'];
                db_watch_invoke(dop);
               }
             else
               {
                console.error("db_set_data failed");
               }
             break;
            }

        case 'profile_db':
            { // jen pro načtení profilu z databáze
             if(data_op.data)
               {
                let profile_db = storage_get('profile_db') || {};
                if(object_set(profile_db, data_op.op, data_op.data, ref, 1, profile_def))
                  {
                   storage_set('profile_db', profile_db);
                   db_watch_invoke(data_op);
                   db_watch_invoke(new Data_op(['profile'], null, null));
                  }
               }
             else
               { // data jsou null => odhlášení uživatele, odstranit profil
                if(ref.length === 1 && data_op.op === 'set')
                  {
                   storage_set('profile_db', null);
                   storage_set('profile_changes', null);

                   db_watch_invoke(data_op);
                   db_watch_invoke(new Data_op(['profile'], null, null));
                  }
                else
                  {
                   console.error("db_set_data failed");
                  }
               }

             break;
            }

        case 'user':
            {
             if(ref.length < 2)
               {
                console.error("db_set_data: bad ref ('user')");
                break;
               }

             const ts = Date.now();
             const uid = ref[1];
             const sid = 'user.'+uid;

             if(ref.length === 2 && data_op.op === 'set')
               {
                storage_set(sid, data_op.data);
               }
             else
               {
                let user = storage_get(sid) || {};
                if(object_set(user, data_op.op, data_op.data, ref, 2))
                  {
                   storage_set(sid, user);
                   db_watch_invoke(data_op);
                  }
               }

             storage_set(sid+'$state', {rd: ts, acc: ts});

             db_watch_invoke(data_op);

             break;
            }
        
        case 'messages':
            {
             let sid = 'messages.' + ref[1];

             let start_idx = 2;
             let msg_obj;

             if(ref.length >= 4 && (ref[2] === 'in_archive' || ref[2] === 'out_archive' ))
               {
                sid += (ref[2] === 'in_archive' ? '.iarc.' : '.oarc.') + ref[3];
                start_idx = 4;
                msg_obj = storage_get(sid) || {};
               }
             else
               {
                msg_obj = storage_get(sid) || {in: null, out: null};
               }

             if(object_set(msg_obj, data_op.op, data_op.data, ref, start_idx))
               {
                storage_set(sid, msg_obj);
                db_watch_invoke(data_op);
               }

             break;
            }

        case 'wr_msg':
             let wr_msg = storage_get('wr_msg') || {};
             // FIXME vypustit příliš staré rozepsané zprávy
             if(data_op.data)
                wr_msg[ref[1]] = {m: data_op.data, t: Date.now()};
             else
                delete wr_msg[ref[1]];

             storage_set('wr_msg', wr_msg);
             db_watch_invoke(data_op);
             break;

        case 'state':
            {
             let state = storage_get('state') || {};
             if(object_set(state, data_op.op, data_op.data, ref, 1))
               {
                storage_set('state', state);
                db_watch_invoke(data_op);
               }
             break;
            }

        default:
            console.error("db_set_data: bad ref", ref.join('.'));
       }
}

/*-------------------------------------------------------------------------------------------------
Načte všechny archivní pakety zpráv uživatele uid a uloží je do objektu dest.
Parametr out:
 = true  .. pouze odchozí pakety 
 = false .. pouze příchozí pakety
 = undefined .. příchozí i odchozí (ukládajít se do do dest.out_archive resp. dest.in_archive)
-------------------------------------------------------------------------------------------------*/
function get_archive_packets(dest, uid, out)
{
 for(let k in localStorage)
    {
     // Když má klíč tvar 'messages.<uid>.(i|o)arc.<aid>
     let m = k.match(/^messages\.([^.$]+)\.(i|o)arc\.([^.$]+)?$/);
     if(m)
       {
        if(m[1] !== uid)
           continue; // špatné UID

        const aid = m[3];
        
        let pkt_obj;

        if(out === undefined)
          {
           const dir = m[2]==='o' ? 'out_archive' : 'in_archive';
           let dobj = dest[dir] = dest[dir] || {};
           dobj[aid] = pkt_obj = storage_get(k);
          }
        else
          {
           if((m[2]==='o') !== out)
              continue; // paket jiného než požadovaného směru (in/out)

           dest[aid] = pkt_obj = storage_get(k);
          }

        normalize_msg_packet(pkt_obj);
       }
   }
}

/*-------------------------------------------------------------------------------------------------
Načte část globálního stavu aplikace.

Parametr:
 ref .. pole složek cesty k uzlu, jehož se operace týká (např. ['users', user-id]).
        Může bý zakódovaná i jako řetězec oddělený tečkami (např. "users.user-id")
-------------------------------------------------------------------------------------------------*/
export function db_get_data(ref)
{
 if(typeof ref === 'string')
    ref = ref.split(".");

 switch(ref[0])
       {
        case 'profile':
             let profile = storage_get('profile_db');
             let profile_changes = storage_get('profile_changes');
            
             if(!profile)
               {
                if(!profile_changes)
                   return null;
                profile = {};
               }
             
             object_assign(profile, profile_changes, profile_def);
            
             return object_get(profile, ref, 1);

        case 'profile_db':
             if(ref.length > 1) break;
             return storage_get('profile_db');

        case 'profile_changes':
             if(ref.length > 1) break;
             return storage_get('profile_changes');

        case 'user':
            {
             if(ref.length < 2)
                break;

             const uid = ref[1]; 

             let sid = 'user.'+uid
             const user = storage_get(sid);

             if(user)
               {
                // zaznamenat čas přístupu:
                sid += '$state';
                let $state = storage_get(sid);
                $state.acc = Date.now();
                storage_set(sid, $state);

                return object_get(user, ref, 2);
               }

             return null;
            } 

        case 'messages':
            {
             let sid = 'messages.' + ref[1];

             if(ref.length >= 4 && (ref[2] === 'in_archive' || ref[2] === 'out_archive' ))
               {// načítají se data z jednoho určitého archivního paketu
                sid += (ref[2] === 'in_archive' ? '.iarc.' : '.oarc.') + ref[3];
                let msg_obj = storage_get(sid);
                normalize_msg_packet(msg_obj);
                return object_get(msg_obj, ref, 4);
               }
             
             if(ref.length === 3 && (ref[2] === 'in_archive' || ref[2] === 'out_archive' ))
               { // načíst všechny archivní pakety daného směru
                let result = {};
                get_archive_packets(result, ref[1], ref[2] === 'out_archive');
                return result;
               }

             let msg_obj = storage_get(sid);

             if(msg_obj)
               {
                normalize_msg_packet(msg_obj.in);
                normalize_msg_packet(msg_obj.out);
                
                if(ref.length === 2)
                   get_archive_packets(msg_obj, ref[1]);
                
                return object_get(msg_obj, ref, 2);
               }

             return null;
            }

        case 'wr_msg':
            {
             const wr_msg = storage_get('wr_msg');
             if(!wr_msg)
                return "";
             const mrec = wr_msg[ref[1]];
             if(!mrec)
                return "";
             return mrec.m || "";
            }

        case 'state':
            {
             let state = storage_get('state');
             return object_get(state, ref, 1);
            }
             
        default:
             break;
       }

 console.error("db_get_data: bad ref");
}

let profile_wr_timeout_id = 0;
let profile_write_due_time;

/*-------------------------------------------------------------------------------------------------
Funkce vyvolaná po uplynutí intervalu k zapsání změn profilu do databáze.
Pokud mezi tím došlo k dalším změnám, naplánuje zápis na další časový interval.
-------------------------------------------------------------------------------------------------*/
function timed_write_profile()
{
 const time_diff = profile_write_due_time - Date.now();

 profile_wr_timeout_id = 0;

 if(time_diff > 0)
   {
    profile_wr_timeout_id = setTimeout(timed_write_profile, time_diff);
    return;
   }
 
 let chng = storage_get(".chng");

 if(!chng)
    return;
 
 master.db_write_own_profile();
}

/*-------------------------------------------------------------------------------------------------
Pomocná funkce schedule_write() pro naplánování zápisu profilu.
-------------------------------------------------------------------------------------------------*/
function schedule_write_profile(chng)
{
 let pwdt = Number.MAX_SAFE_INTEGER;

 if(chng.profile) 
    pwdt = Math.min(pwdt, chng.profile + 300000/*5 minut*/);
 
 if(chng.profile_aux)
    pwdt = Math.min(pwdt, chng.profile_aux + 900000/*15 minut*/);
 
 if(chng.profile_imm)
    pwdt = Math.min(pwdt, chng.profile_imm + 10000/*10 sekund*/);

 const now = Date.now();

 if(pwdt !== Number.MAX_SAFE_INTEGER)
   {
    if(profile_wr_timeout_id)
      { // timer již existuje
       if(profile_write_due_time <= pwdt)
         { // Čas zápisu se má posunou do budoucnosti => jen nastavit nový, 
           // časovač se přeplánuje ve vyvolání stávajícího
          profile_write_due_time = pwdt;
          console.log("schedule_write_profile: profile_write_due_time = ", pwdt);
          return;
         }
       
       clearTimeout(profile_wr_timeout_id);
       profile_wr_timeout_id = 0;
      }

    if(pwdt <= now)
      {
       console.log("schedule_write_profile: call db_write_own_profile()");
       master.db_write_own_profile();
      }
    else
      {
       console.log("schedule_write_profile: timeout=",  new Date(pwdt - now).toLocaleString('cs', {hour: '2-digit', minute: '2-digit', second: '2-digit'}));
       profile_write_due_time = pwdt;
       profile_wr_timeout_id = setTimeout(timed_write_profile, pwdt - now);
      }
   }
}

let rd_mark_wr_timeout_id = 0;
let rd_mark_write_due_time;

/*-------------------------------------------------------------------------------------------------
Funkce vyvolaná po uplynutí intervalu k zapsání oznámení o přečtení zpráv do databáze.
Pokud mezi tím došlo k dalším změnám, naplánuje zápis na další časový interval.
-------------------------------------------------------------------------------------------------*/
function timed_write_rd_marks()
{
 const now = Date.now();
 const time_diff = rd_mark_write_due_time - now;

 if(time_diff > 0)
   {
    console.log("timed_write_rd_marks reschedule: timeout =",  
                new Date(time_diff).toLocaleString('cs', {hour: '2-digit', minute: '2-digit', second: '2-digit'}),
                "due =", new Date(rd_mark_write_due_time), "at =", new Date(now));
    rd_mark_wr_timeout_id = setTimeout(timed_write_rd_marks, time_diff);
    return;
   }
 
 let chng = storage_get(".chng");

 if(!chng || !chng.rd_mark)
   {
    rd_mark_wr_timeout_id = 0;
    return;
   }

 const rd_mark = chng.rd_mark;

 let rmwdt = Number.MAX_SAFE_INTEGER;
 let to_write = [];

 for(let k in rd_mark)
    {
     const dt = rd_mark[k] + 60000/*1 minuta*/;
     if(dt <= now)
        to_write.push(k);
     else
        rmwdt = Math.min(rmwdt, dt);
    }

 if(to_write.length > 0)
    write_rd_marks(to_write);

 if(rmwdt !== Number.MAX_SAFE_INTEGER)
   {
    console.log("timed_write_rd_marks schedule further: timeout =",  
                new Date(rmwdt - now).toLocaleString('cs', {hour: '2-digit', minute: '2-digit', second: '2-digit'}),
                "due =", new Date(rmwdt), "at =", new Date(now));
    rd_mark_write_due_time = rmwdt;
    rd_mark_wr_timeout_id = setTimeout(timed_write_rd_marks, rmwdt - now);
   }
}


/*-------------------------------------------------------------------------------------------------
Pomocná funkce schedule_write() pro naplánování zápisu oznámení o přečtení zpráv.
-------------------------------------------------------------------------------------------------*/
function schedule_write_rd_marks(chng)
{
 const rd_mark = chng.rd_mark;

 if(!rd_mark) return;

 let rmwdt = Number.MAX_SAFE_INTEGER;

 for(let k in rd_mark)
    {
     rmwdt = Math.min(rmwdt, rd_mark[k] + 60000/*1 minuta*/);
    }

 const now = Date.now();

 if(rmwdt !== Number.MAX_SAFE_INTEGER)
   {
    if(rd_mark_wr_timeout_id)
      { // timer již existuje
       if(rd_mark_write_due_time >= rmwdt)
         { // Čas zápisu se má posunou do budoucnosti => jen nastavit nový, 
           // časovač se přeplánuje ve vyvolání stávajícího
          console.log("reschedule_write_rd_marks: timeout =",  
                      new Date(rmwdt - now).toLocaleString('cs', {hour: '2-digit', minute: '2-digit', second: '2-digit'}),
                      "due =", new Date(rmwdt), "at =", now);
          rd_mark_write_due_time = rmwdt;
          return;
         }
       
       clearTimeout(rd_mark_wr_timeout_id);
       rd_mark_wr_timeout_id = 0;
      }

    rd_mark_write_due_time = rmwdt;

    if(rmwdt <= now)
      {
       console.log("schedule_write_rd_marks: call timed_write_rd_marks()");
       timed_write_rd_marks();
      }
    else
      {
       console.log("schedule_write_rd_marks: timeout =",  
                   new Date(rmwdt - now).toLocaleString('cs', {hour: '2-digit', minute: '2-digit', second: '2-digit'}),
                   "due =", new Date(rmwdt), "at =", now);
       rd_mark_wr_timeout_id = setTimeout(timed_write_rd_marks, rmwdt - now);
      }
   }
}

/*-------------------------------------------------------------------------------------------------
Naplánuje uložení změněných částí stavu podle objektu vytvořeného funkcí db_data_changed().
-------------------------------------------------------------------------------------------------*/
export function schedule_write(chng)
{
 schedule_write_profile(chng);
 schedule_write_rd_marks(chng);
}

/*-------------------------------------------------------------------------------------------------
Vyvoláno při změně dat pro naplánování uložení změn do databáze.

Jednotlivé segmenty stavu se zapisují s různou prodlenou (definováno v schedule_write() resp.
schedule_write_profile()). Opakované volání db_data_changed() posouvá konečný čas zápisu.
Tuto funkci typicky není třeba volat zvenku, volá ji automaticky db_set_data().

Odeslání oznámení o přečtení zpráv se odesílá po minutě a je průběžně oddalováno při
psaní zprávy (odpovědi) jejímu odesílateli (viz on_message_typing() v messages.js). Tím by se mělo
zabránit, aby během plynulé konverzace nebyly neustále zapisovány a čteny časy přečtení zpráv.

Údaje o změně se ukládají do localStorage, takže přežijí reload stránky i změnu mastera.

Poznámka: údaje o změně se odstraní v příslušné zápisové funkci (db_write_own_profile()
          a write_rd_marks()).

Parametr:
 seg_ref .. identifikátor segmentu data, který se změnil. Tyto případy:
            'profile'        .. běžná data profilu. Zapisují se po 5 minutách.
            'profile_aux'    .. pomocná, méně důležitá data. Zapisují se po 15 minutách
            'profile_imm'    .. důležitá data, zapisují se okamžitě
            ['rd_mark', uid] .. označení, že zprávy od uživatele uid byly přečteny
-------------------------------------------------------------------------------------------------*/
master_def.db_data_changed = function(seg_ref)
{
 console.log("db_data_changed", seg_ref);

 let chng = storage_get('.chng') || {};

 const ts = Date.now();

 if(typeof seg_ref === 'string')
    chng[seg_ref] = ts;
 else
    object_set(chng, 'set', ts, seg_ref);

 storage_set('.chng', chng);

 schedule_write(chng);
}

/*-------------------------------------------------------------------------------------------------
Vynutí okamžité zapsání změněných dat. Parametr seg_ref, pokud je zadán, určuje, která část dat
se má zapsat. Může být: 'profile' nebo 'rd_mark'.
Vrací promise resolvovanou po dokončení zápisu.
-------------------------------------------------------------------------------------------------*/
master_def.response.db_flush = true;
master_def.db_flush = function(seg_ref)
{
 let chng = storage_get('.chng');
 if(!chng) return Promise.resolve(null);

 console.log("db_flush", seg_ref);

 let promises = [];

 if((!seg_ref && (chng.profile || chng.profile_aux || chng.profile_imm)) ||
    (seg_ref === 'profile' && chng.profile))
    promises.push(master.db_write_own_profile());
 
 if((!seg_ref || seg_ref === 'rd_mark') && (!chng.rd_mark || object_is_empty(chng.rd_mark)))
    promises.push(write_rd_marks());

 return Promise.all(promises);
}

/*-------------------------------------------------------------------------------------------------
Odstraní veškerý stav aplikace (použito při odhlášení uživatele).
-------------------------------------------------------------------------------------------------*/
export function db_remove_app_state()
{
 console.log("db_remove_app_state");

 db_set_data('profile_db', 'set', null);

 localStorage.removeItem('.email_verified');
 localStorage.removeItem('.chng');

 localStorage.removeItem('state');
 db_watch_invoke(new Data_op(['state'], 'set', null));

 localStorage.removeItem('wr_msg');
 db_watch_invoke(new Data_op(['wr_msg'], 'set', null));

 let del = {}; // složky stavu, které byly reálně smazány, zde budou mít složku 'true'

 for(let k in localStorage)
    {
     if(!localStorage.hasOwnProperty(k))
        continue;
     
     // Když má klíč tvar 'messages.<uid>[$state]' nebo 'user.<uid>[$state]':
     let m = k.match(/^(messages|user)\.(?:[^.$]+)(?:(?:\$state)|(?:\..*))?$/);
     if(m)
       {
        del[m[1]] = true;
        localStorage.removeItem(k);
       }
     
     if(k.startsWith('urls.'))
        localStorage.removeItem(k);
     
     // Volání mastera (neměly by tu být):
     m = k.match(/^\.(mcl|mrsp)\.([^.]+)\.([0-9]+)$/);
     if(m)
       {
        console.error(" - orphan master call:", localStorage.getItem(k));
        localStorage.removeItem(k);
       }
    }

 if(del.messages)
    db_watch_invoke(new Data_op(['messages'], 'set', null));

 if(del.user)
    db_watch_invoke(new Data_op(['user'], 'set', null));
}


/*-------------------------------------------------------------------------------------------------
Inicializace modulu. Musí být vyvoláno při inicializaci aplikace (nyní vyvoláno z db_init())
-------------------------------------------------------------------------------------------------*/
export function app_state_init()
{
 window.addEventListener("storage", on_storage_change); 
}