/*#################################################################################################

 Záhlaví obsahu stránky– obsahuje nick, jméno a menu 
 (použito v zobrazení profilu a chatu při úzkém zobrazen)

#################################################################################################*/

import React from 'react';
import {useLocation,
        useHistory} from 'react-router-dom';
import {nav_user_chat,
        nav_view_user} from '../navigate';
import {create_user_conn} from '../user';
import {current_uid} from '../db/db';
import {ImpressionIcon} from '../controls/ImpressionIcon';
import {ProfileMenu} from '../profile/ProfileMenu';
import {ProfDispSel} from '../profile/ProfDispSel';
import {NodeBubble} from '../lib/NodeBubble';
import {useTranslation} from '../translations/useTranslation';
import './Subheader.css';
import { db_get_data } from '../db/app_state';

/*-------------------------------------------------------------------------------------------------
props  type        .. typ headeru: 
                      'profile' - zobrazení profilu
                      'chat'    - okno chatu s uživatelem
       conn        .. položka propojení daného uživatele (z profile.conn přihlášeného uživatele)
                      pokud prohlížíme vlastní profil, nebo uživatel ještě není v conn,
                      je undefined.
       profile     .. zobrazovaný profil uživatele
       acc         .. Pokud se zobrazuje vlastní profil,
                      určuje úroveň přístupových práv, z jejichž hlediska se profil
                      zobrazuje.
       page_id     .. id stránky, na které je profil zobrazne
       media       .. vlastnosti okna browseru; objekt s členy:
                      narrow .. okno je užší než 900px   
-------------------------------------------------------------------------------------------------*/
export function Subheader(props) 
{
 const type    = props.type;
 const profile = props.profile;
 let   conn    = props.conn;
 const media   = props.media;
 const narrow  = media.narrow;

 const location = useLocation();
 const url_history = useHistory();
 const t = useTranslation();

 //---------------
 // Pokud je v URL parametr 'view_user', zruší ho (tím se vrátí z režimu prohlížení profilu)
 const back_button = () =>
    {
     const url_params = new URLSearchParams(location.search);
     if(url_params.get("view_user"))
       {
        url_params.delete("view_user");

        let params = url_params.toString();
        let url = location.pathname;
        if(params) url += '?' + params;
       
        url_history.push(url);
       }
     else if(location.pathname.endsWith(profile.uid))
       {
        const pn = location.pathname+"";
        url_history.push(pn.substr(0, pn.length-profile.uid.length-1));
       }
    }

 let wr_msg = false; // má se zobrazit bublina "Napište mu zprávu!"?

 if(profile.uid && !profile.$error && !profile.$loading)
   {
    const uc = db_get_data(['profile', 'conn', profile.uid]);
    if(uc)
      {
       if(!uc.msg_time) // pokud jsme si s protějškem ještě nepsali, vyzvat k napsání zprávy
          wr_msg = true; 
       if(!conn) conn = uc;
      }
    else
      {
       wr_msg = true; 
       if(!conn) conn = create_user_conn(profile);
      }
   }

 return <div className = {"sub-header" + (props.sub ? " sub-present" : "")}>
          {profile.uid && profile.uid !== current_uid && 
           <div className="arrow"
                onClick={back_button}></div>}

          <div className = "disp-col">
            <h1>{profile.nick_name}</h1>
            {props.sub && <div className="sub">{props.sub}</div>}
          </div>

          {type === 'chat' &&
            <div className = "prof-btn"
                 title = {t('pmenu_vprof')}
                 onClick = {() => nav_view_user(url_history, location.pathname, profile)}>
            </div>}
          {profile.uid && profile.uid !== current_uid && 
           <>
            {type !== 'chat' &&
             <div className = "msg-btn"
                  title = {t('pmenu_send_msg')}
                  onClick = {() => nav_user_chat(url_history, profile.uid)}>
              {wr_msg && 
               <NodeBubble content = {t('wr_msg', {peer_gender: profile.sex && profile.sex-1})}
                           dx = {4}/>}
             </div>}

            {((conn && !conn.no_connection) || type === 'chat') &&
             <ImpressionIcon uid         = {profile.uid}
                             nick_name   = {profile.nick_name}
                             page_id     = {props.page_id}
                             location    = {location}
                             url_history = {url_history}/>}
            <div className = "align-right">
              <ProfileMenu conn        = {conn}
                           profile     = {profile}
                           page_id     = {props.page_id}
                           profile_hdr = {true}
                           impress     = {true}
                           height      = {18}
                           align_right = {true}/>
            </div>
           </>}
          {profile.uid === current_uid && narrow &&
            <div className = "align-right">
             <ProfDispSel subpage     = {props.acc}
                          pg_view     = {true}
                          narrow      = {true}
                          url_history = {url_history}/>
             
             {/*<div className = "edit-btn"
                  onClick = {() => url_history.push("/profile/index")}>
             </div>*/}
            </div>}
        </div>;
}
