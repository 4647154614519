/*#################################################################################################

View/editor všech obrázků profilu

#################################################################################################*/

import React from 'react';
import {useDbData} from '../db/useDbData';
import {ImageEditor} from './ImageEditor';
import {ImageView} from './ImageView';
import {ImagePicker} from './ImagePicker';
import './ImageList.css';

/*-------------------------------------------------------------------------------------------------
props  images .. seznam obrázků (profile.images)
       uid    .. uživatel, jemuž obrázky náležejí (pokud není zadán, jde o přihlášeného uživatele)
       edit   .. pokud je true, obrázky jdou editovat, jinak jsou pouze zobrazeny
       limit  .. maximální počet obrázků
-------------------------------------------------------------------------------------------------*/
export function ImageList(props) 
{
 const images = props.images || [];
 const limit = props.limit || null; // do useDbData se musí dát null

 const img_upload = useDbData(limit && ['state', 'img_upload']);
 let upload_count = 0; // počet obrázků, které se právě uploadují (kvůli kontrole limitu)

 if(limit)
   {
    for(let k in img_upload)
       {
        if(img_upload[k])
           upload_count++;
       }
   }

 let content;

 if(props.edit)
   {
    content = images.map((idef, n) => <ImageEditor images = {images}
                                                   n = {n}
                                                   key = {idef.id}/>);
    if(!limit || images.length+upload_count < limit)
       content.push(<ImagePicker limit = {limit}
                                 key = 'picker'/>);
   }
 else
   {
    content = images.map((idef, n) => <ImageView images = {images}
                                                 n = {n}
                                                 uid = {props.uid}
                                                 key = {idef.id}/>);
   }

 return <div className = 'image-list'>
         {content}
        </div>;
}
