/*#################################################################################################

Řádek k odeslání zprávy

#################################################################################################*/

import React from 'react';
import {master} from '../db/master';
import {db_set_data} from '../db/app_state';
import {useDbData} from '../db/useDbData';
import {TextEditor} from '../lib/TextEditor';
import {useTranslation} from '../translations/useTranslation';
import './SendRow.css';

/*-------------------------------------------------------------------------------------------------
Odešle uživateli zprávu
-------------------------------------------------------------------------------------------------*/
function send_message(rcv_uid, msg)
{
 master.db_send_message(rcv_uid, msg);
 db_set_data(['wr_msg', rcv_uid], 'set', null)
}


/*-------------------------------------------------------------------------------------------------
props  rcv_uid  .. uid příjemce
       onClick  .. standardní call-back onClick text editoru
       on_key   .. call-back vyvolaný při stisknutí klávesy v editoru řádku
-------------------------------------------------------------------------------------------------*/
export function SendRow(props) 
{
 const rcv_uid = props.rcv_uid;
 const wr_msg_ref = ['wr_msg', rcv_uid];
 const msg = useDbData(wr_msg_ref);
 const t = useTranslation();

 //----------------
 // Funkce vyvolaná při stisknutí klávesy v řádkovém editoru.
 const on_key = e =>
    {
     if(props.on_key)
        props.on_key(e);

     if((e.key==="Enter" || e.keyCode === 13) && !e.shiftKey)
       {
        send_message(rcv_uid, msg);
        return "";
       }
    }

 return <div className="send-row"
             onClick = {props.onClick}>
          <TextEditor value = {msg}
                      setter = {msg => db_set_data(wr_msg_ref, 'set', msg)}
                      on_key = {on_key}
                      placeholder = {t('msg_lbl')}
                      write_through
                      autoFocus/>
          <div id="send-btn"
               onClick = {() => send_message(rcv_uid, msg)}></div>
        </div>
}
