/*#################################################################################################

  Sleduje aktivitu uživatele a umožňuje detekovat, kdy byl neaktivní.
  Aktivita se detekuje lokálně (jen pro danou session) a globálně (pro všechna okna aplikace).

  Lokální změna aktivity je indikovaná proměnnou is_user_active a lze na její změnu nastavit 
  listener. Globální aktivita je indikována členem stavu aplikace 'state.user_active'.
 
  Publikované symboly:
  - is_user_active            .. (bool) indikátor lokální aktivity
  - on_user_activity_change   .. registrace listeneru na změnu aktivity uživatele
  - on_user_activity_change_remove 
                              .. zrušení registrace listeneru
  - user_activity_init        .. inicializace modulu, musí být zavolána po spuštění
  Pomocné funkce:
  - note_user_activity        .. funkci může aplikace vyvolat pro oznámení aktivity uživatele,
                                 která není zachycena standardními listenery (klávesnice, myš).
  - note_user_activity_global .. aplikace musí funkce zavolat, pokud je master session
                                 a změnila se hodnota localStorage '.uact'.

#################################################################################################*/

import {is_master} from './db/master';
import {db_set_data} from './db/app_state';
import {current_uid} from './db/db';

console.log("LOAD db");

const INACTIVITY_LIMIT = 5*60*1000; // limit, po jehož uběhnutí bez aktivity se uživatel prohlásí za neaktivního

let local_timer_id = 0; 
let global_timer_id = 0;

export let is_user_active = true; // indikátor lokální aktivity

let last_uact = 0; // Čas poslední zpracované aktivitě uživatele.
                   // Z důvodu efektivity aktivita nezpracovává častěji než jednou za 2 s

let local_listeners = [];

/*-------------------------------------------------------------------------------------------------
Registrace listeneru na změnu aktivity uživatele. Call-back dostává parametr se stavem 
nové aktivity (true = stal se aktivní, false = stal se neaktivní).
-------------------------------------------------------------------------------------------------*/
export const on_user_activity_change = fn => local_listeners.push(fn);

/*-------------------------------------------------------------------------------------------------
Zrušení registrace listeneru zaregistrovaného pomocí on_user_activity_change().
-------------------------------------------------------------------------------------------------*/
export function on_user_activity_change_remove(fn)
{
 let i = local_listeners.indexOf(fn);
 if(i >= 0)
    local_listeners.splice(i, 1);
 else
    console.error("on_user_activity_change_remove: unknown fn");
}

/*-------------------------------------------------------------------------------------------------
Vyvoláno, pokud doběh timeout neaktivity uživatele.
-------------------------------------------------------------------------------------------------*/
function local_inactive()
{
 console.log("local_inactive");

 is_user_active = false;

 for(let i = 0; i < local_listeners.length; i++)
     local_listeners[i](false);

 local_timer_id = 0;
}

/*-------------------------------------------------------------------------------------------------
Vyvoláno, pokud doběh timeout globální neaktivity uživatele.
-------------------------------------------------------------------------------------------------*/
function global_inactive()
{
 console.log("global_inactive");
 
 if(current_uid)
    db_set_data(['state', 'user_active'], 'set', false);

 global_timer_id = 0;
}


/*-------------------------------------------------------------------------------------------------
Funkce vyvolaná při akci uživatele v této session.
Funkce se typicky volá automaticky na základě nastavených listenerů. Je možné ji zavolat z vnějšku
při akci uživatele, která by nevyvolala listener.
-------------------------------------------------------------------------------------------------*/
export function note_user_activity()
{
 if(!current_uid)
    return;

 const ts = Date.now();

 if(!local_timer_id)
   {
    console.log("local_active");
    is_user_active = true;
    for(let i = 0; i < local_listeners.length; i++)
        local_listeners[i](true);
   }
 else
   {
    if(ts - last_uact < 2000)
       return; // z důvodu efektivity nezpracovávat informace o aktivitě častěji než jednou za 2 s
       
    window.clearTimeout(local_timer_id);
   }
 
 last_uact = ts;
 local_timer_id = window.setTimeout(local_inactive, INACTIVITY_LIMIT);
 localStorage.setItem('.uact', ts);

 if(is_master)
    note_user_activity_global();
}

/*-------------------------------------------------------------------------------------------------
Funkce vyvolaná globálně při akci uživatele v kterékoli session.
(Funkce je vyvolána z app_state.js z on_storage_change() při změně globální informace o akci 
uživatele.)
Funkce se může vyvolat jen v kontextu master session (viz modul master.js)
-------------------------------------------------------------------------------------------------*/
export function note_user_activity_global()
{
 if(current_uid)
   { // obě položky nastavit zvlášť, jinak by se handler na 'user_active' vyvolával nespecificky i beze změny hodnoty
    db_set_data(['state', 'user_active'], 'set', true);
    db_set_data(['state', 'last_active'], 'set', Date.now());
   }

 if(!global_timer_id)
   {
    console.log("global_active");
   }
 else
    window.clearTimeout(global_timer_id);
 
 global_timer_id = window.setTimeout(global_inactive, INACTIVITY_LIMIT);
}

/*-------------------------------------------------------------------------------------------------
Inicializace modulu.
-------------------------------------------------------------------------------------------------*/
export function user_activity_init()
{
 console.log("user_activity_init");

 document.addEventListener("mousemove", note_user_activity, true);
 document.addEventListener("mousedown", note_user_activity, true);
 document.addEventListener("keydown",   note_user_activity, true);
 document.addEventListener("touchmove", note_user_activity, true);
  
 note_user_activity();
}
