/*#################################################################################################

 Oblast nad vlastním obsahem stránky obsahující upozornění pro uživatele (jako chybová hlášení...)

#################################################################################################*/

import React from 'react';
import {useHistory} from 'react-router-dom';
import {send_verification_mail} from '../send_verification_mail';
import {useDbData} from '../db/useDbData';
import {useTranslation} from '../translations/useTranslation';

/*-------------------------------------------------------------------------------------------------
Oblast notifikací aplikace.

props  profile .. profil přihlášeného uživatele
       media   .. vlastnosti okna browseru

-------------------------------------------------------------------------------------------------*/
export function NotificationArea(props) 
{
 const profile = props.profile;

 const url_history = useHistory();

 let mail_ver_pending = useDbData(['state', 'mail_ver_pending']);
 const t = useTranslation();

 let nf_list = [];

 //---------------
 // Znovu odešle potvrzující mail 
 const resend_mail = () =>
    {
     if(mail_ver_pending)
       {
        send_verification_mail();
        window.alert(t('mailv_resent_msg'));
       }
     else
       {
        window.alert(t('mailv_aver_msg'));
        window.location.reload();
       }
    }

 if(!profile.nick_name)
   {
    let nf = <div className="cbox bg-orange" key="emv">
              <div className="cbox-inner">
               <h2>{t('err_no_nick.title')}</h2>
               <p>{t('err_no_nick',
                     <span className="icon profile" onClick={() => url_history.push("/profile")} key='ipr'></span>)}</p>
              </div>
             </div>

    nf_list.push(nf);
   }

 if(mail_ver_pending)
   {
    let nf = <div className="cbox bg-orange" key="emv">
              <div className="cbox-inner">
               <h2>{t('mailv_title')}</h2>
               <p>{t('mailv_desc')}</p>
               <button className="small" onClick={resend_mail}>{t('mailv_resend_btn')}</button>
              </div>
             </div>

    nf_list.push(nf);
   }

 return nf_list.length!==0 && 
        <div id="nf-area">
         {nf_list}
        </div>;
}
