/*#################################################################################################

Stromeček stránky chatu.

#################################################################################################*/

import React from 'react';
import {useLocation,
        useHistory} from 'react-router-dom';
import {nav_user_chat,
        nav_view_user} from './navigate';
import {UserTree} from './controls/UserTree';
import {UserList} from './controls/UserList';
import {TreeItem} from './controls/TreeItem';
import {ChatMiniToolbar} from './controls/ChatMiniToolbar';
import {MatchControl} from './controls/MatchControl';
import {useDbData} from './db/useDbData';
import {useUserProfile} from './db/useUserProfile';
import {useTranslation} from './translations/useTranslation';

/*-------------------------------------------------------------------------------------------------
props  profile     .. profile přihlášenéh uživatele
       conn_groups .. seskupené seznamy propojených uživatelů (viz App.js, conn_groups)
       url_params  .. parametry URL jako objekt URLSearchParams
       page_id     .. 'chat'/'odate'
       media       .. vlastnosti okna browseru; objekt s členy:
                      narrow .. okno je užší než 900px   
 -------------------------------------------------------------------------------------------------*/
export function ChatTree(props) 
{
 const page_id = props.page_id;
 const aim = page_id==='odate' ? 'odate':'ochat';
 const media = props.media;

 const location = useLocation();
 const url_history = useHistory();
 const result_obj = useDbData(['state', aim+'_match']) || {};
 const peer_state = useDbData(['state', 'peer_state']) || {};
 const t = useTranslation();

 const pm = location.pathname.match(/^\/[a-z]+\/(.+)$/);
 const chat_user = pm && pm[1];

 const chat_user_profile = useUserProfile(chat_user);

 let result_list = Object.keys(result_obj);
 result_list.sort((a, b) => result_obj[b] - result_obj[a]);

 const profile = props.profile;
 const conn = profile.conn;

 let selected_user = props.url_params.get("view_user");
 if(!selected_user)
    selected_user = chat_user;

 return <>
          <h1>{t('hdr_'+page_id) + (chat_user ? (" – " + chat_user_profile.nick_name) : "")}</h1> 

          {chat_user && <ChatMiniToolbar profile = {chat_user_profile}
                                         page_id = {page_id}/>}

          {!chat_user && <MatchControl profile = {profile}
                                       aim     = {aim}/>}
          {result_list.length>0 && 
           <TreeItem title   = {t('srchu_rtitle')}
                     bold    = {true}
                     subtree = {<UserList conn        = {profile.conn}
                                          users       = {result_list}
                                          selected    = {selected_user}
                                          peer_state  = {peer_state}
                                          page_id     = {page_id}
                                          media       = {media}
                                          on_click    = {profile => nav_view_user(url_history, 
                                                                                  location.pathname, 
                                                                                  profile)}/>}
            />}

          <UserTree conn          = {conn}
                    conn_groups   = {props.conn_groups}
                    selected      = {selected_user}
                    peer_state    = {peer_state}
                    page_id       = {page_id}
                    media         = {media}
                    click_on_user = {profile => nav_user_chat(url_history, profile.uid)}/>
        </>;
}
