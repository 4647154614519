/*#################################################################################################

Ověření emailové adresy při vytvoření účtu na základě mailu a hesla:

1. Po vytvoření účtu (LoginBox.js: signup_email()) se vyvolá tato funkce, která vyvolá požadavek
   na odeslání ověřovacího mailu. Zároveď zaregistruje pokračovací URL 
   (<url_base>/email_verified), které bude zobrazeno potom, co uživatel klikne na link v 
   potvrzovacím mailu. 

2. S nepotvrzeným mailem se uživatel může přihlásit, ale nemůže kontaktovat jiné uživatele
   (identifikováno položkou stavu aplikace state.mail_ver_pending)

3. Kliknutí na potvrzovací mail se uživateli otevře nový panel s URL ".../email_verified".
   Toto URL se detekuje hned na začátku index.js. Pokud se nalezne, zapíše se do local-storage
   klíč ".email_verified". V panelu se zobrazí pouze "OK", aby ho uživatel zavřel. 
   (Z JavaScriptu panel zavřít nelze)

4. Ostatní existující panely detekují zápis tohoto klíče do local-storage 
   (db/db.js: on_storage_change()) a refeshují stránku, čímž se aktualizuje stav uživatele.

#################################################################################################*/

import firebase from 'firebase/app';

/**
 * Odešle email pro ověření emailové adresy účtu právě přihlášeného uživatele.
 */
export function send_verification_mail()
{
 const firebase_user = firebase.auth().currentUser;
 const url_base = /^([^:]*):\/\/([^/?]*)/.exec(window.location)[0]; // získat základ stávajícího URL
 firebase_user.sendEmailVerification({url: url_base+"/email_verified"})
 .catch(error => alert(error.message)); // FIXME ověřit správnost; nějak lépe zobrazit hlášení
}