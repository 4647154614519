import React from 'react';
import {call_setter} from './call_setter';
import {normalize_value} from './normalize_value';
import './TextEditor.css';

/*-------------------------------------------------------------------------------------------------
Textový editor.

props  data_id       .. (nepovinné) identifikátor nastavený jako atribut "id" a "name"
       required      .. prázdná hodnota není přípustná. Pokud není 'required', prázdný řetězec
                        se převede na null.
       allow_empty   .. pro string: je přípustná prázdná hodnota, ale ponechá se "" 
                        (nepřevede se na null)
       maxlength     .. maximální délka řetězce (více editor nedovolí zapsat)
       value         .. iniciální hodnota zobrazená v editoru
       setter        .. funkce vyvolaná při změně obsahu editoru. 
                        Parametr: hodnota (volitelně přetypovaná podle parametru type)
       write_through .. pokud je nastaven, každá změna obsahu vyvolá setter. Jinak pouze
                        Enter, Tab a deaktivace editoru (včetně přepnutí stránky)
       validator     .. funkce jedné proměnné, která vrací string - chybové hlášení, 
                        pokud je hodnota neplatná nebo null, pokud je v pořádku.
       show_error    .. zobrazí případný error vrácený validační funkcí
       write_invalid .. zapisuje i hodnotu, kterou validátor označit za chybnou
                        Pokud je false, nedovolí opustit editor s chybnou hodnotou; 
                        při chybě pak zobrazuje message-box.
       placeholder   .. (nepovinné) text obrazený v editoru, když není nic zadáno
       on_key        .. funkce vyvolaná při stisknutí klávesy. Parametry: event, input-string
                        Pokud vrací string, nastaví se jako nový stav a klávesa se nepropaguje.
       on_focus      .. call-back na změnu fokusu (tj. začátku a konci editace)
                        První parametr je true při získání a false při ztrátě fokusu.
                        Druhý parametry je data_id.
       on_change     .. call-back na změnu obsahu editoru. Je vyvolán při každé změně 
                        obsahu a to i v případě, že obsah není validní nebo se nezapisuje.
                        Vyvolá se vždy před setterem
                        První parametr je nový obsah editoru.
                        Druhý parametry je data_id.
       autoFocus     .. dostane fokus poté, co je renderován
       className     .. (nepovinné) jméno třídy nastavené prvku <input>
       lang          .. jazyk spell-checkeru
-------------------------------------------------------------------------------------------------*/
export function TextEditor(props) 
{
 const write_through = props.write_through;

 const init_state = 
    { 
     editing: false, // probíhá editace (má focus) 
     org_val: null, // původní hodnota
     buff: null, // aktuální obsah editoru, pokud má focus (musí tu být, jinak při asynchronním zápisu do
                 // data kursor skáče na konec editoru) 
     err: null  // chybové hlášení vztahující se k obsahu bufferu (pokud je buff = nul je vždy i err = null)
    };

 const textarea_ref = React.useRef();
 const [state, set_state] = React.useState(init_state);

 const update_size = node =>
    {
     node.style.height = "0px";
     const cs = window.getComputedStyle(node);
     const pad = parseFloat(cs.getPropertyValue("padding-top")) +
                 parseFloat(cs.getPropertyValue("padding-bottom"));
     node.style.height = node.scrollHeight-pad+"px";
    }

 React.useLayoutEffect(() => update_size(textarea_ref.current));


 //-----------------------
 // Call-back vyvolaný při změna obsahu editoru
 const on_change = e =>
    {
     update_size(e.target);

     const val =  e.target.value;

     if(props.on_change)
        props.on_change(val, props.data_id);

     if(write_through)
       {
        let err = call_setter(props, val);

        let ns = {...state, buff: val, err: err};

        /*if(err && !props.write_invalid)
          {
           ns.buff = val;
           ns.err = err;
          }
        else
          {
           ns.buff = ns.err = null;
          }*/

        set_state(ns);
       }
     else
       {
        set_state({...state, buff: val});
       }
    }

 //-----------------------
 // Call-back vyvolaný při započetí editace
 const on_focus = e =>
    {
     if(props.autoFocus)
       {
        let val = e.target.value; // aby se kursor při autofocusu nastavil na konec
        e.target.value = '';
        e.target.value = val;
       }

     set_state({editing: true,
                org_val: e.target.value,
                buff:    /*write_through ? null : */e.target.value,
                err:     null});

     if(props.on_focus)
        props.on_focus(true, props.data_id);
    }

 //---------------------
 // Call-back na ztrátu fokusu
 const on_focus_lost = e =>
    {
     let err;

     if(state.buff !== null)
       {
        err = state.err || call_setter(props, state.buff);
       }
     else
       {
        let v = normalize_value(props, e.target.value);
        if(v instanceof Error)
           err = v.message;
       }

     if(err && !props.write_invalid)
       {
        e.target.focus(); // vrátit focus
        alert(err);
        set_state(state); // vrátit původní stav, který se změnil v on_fucus na volání focus() výše
        return;
       }
     
     if(props.on_focus)
        props.on_focus(false, props.data_id);

     set_state(init_state);
    }

 //---------------------
 // Call-back na stisknutí klávesy
 const on_key = e =>
    {
     if(state.editing)
       {
        if(e.key === "Escape" || e.keyCode === 27)
          {
           e.target.value = state.org_val;
           on_change(e);
           return;
          }

        if(e.key === "Tab" || e.keyCode === 9)
           return;

        if(state.err !== null)
           set_state({...state, err: null});
       }

     if(props.on_key) 
       {
        const r = props.on_key(e, state.buff);
        if(typeof r === 'string')
          {
           set_state({...state, buff: r});
           e.stopPropagation();
           e.preventDefault();
          }
       }
    }

 //----------------------
 let val;
 let err = null;

 if(state.buff !== null)
   {
    val = state.buff;
    if(props.show_error)
       err = state.err;
   }
 else
   {
    val = props.value;
   }

 if(!err)
   {
    let v = normalize_value(props, val);
    if(props.show_error && v instanceof Error)
       err = v.message;
   }

 return <span className = {"text-editor control" + (props.className ? " "+props.className : "")}>
          <span className = "input-box" type={props.type}>
           <textarea name        = {props.data_id} 
                     id          = {props.data_id}
                     value       = {val || ""}
                     maxLength   = {props.maxlength}
                     placeholder = {props.placeholder || ""}
                     spellCheck  = "true"
                     lang        = {props.lang}
                     onChange    = {on_change}
                     onLoad      = {e => {update_size(e.target)}}
                     onKeyDown   = {on_key}
                     onFocus     = {on_focus}
                     onBlur      = {on_focus_lost}
                     autoFocus   = {props.autoFocus}
                     ref         = {textarea_ref}/>
           {err && <div className="error">{err}</div>}
          </span>
        </span>
}

