/*#################################################################################################

Obsah domovské stránky.

#################################################################################################*/

import React from 'react';
import {useHistory} from 'react-router-dom';
import {ProfileView} from './profile/ProfileView';
import {UnreadMessages} from './controls/UnreadMessages';
import {html} from './utils';
import {useTranslation} from './translations/useTranslation';
import {HomeTree} from './HomeTree';
import girl1_fajn_lidi from './graphics/girl1-fajn-lidi.jpg';
import './Home.css';

/*-------------------------------------------------------------------------------------------------
 props  profile     .. profil přihlášeného uživatele
        conn_groups .. propojení uživatelé rozdělení do skupin
        url_params  .. parametry URL jako objekt URLSearchParams
        media       .. vlastnosti okna browseru; objekt s členy:
                       narrow .. okno je užší než 900px   
-------------------------------------------------------------------------------------------------*/
export function Home(props) 
{
 const url_history = useHistory();
 const t = useTranslation();

 const profile     = props.profile;
 const conn        = props.conn;
 const conn_groups = props.conn_groups;
 const url_params  = props.url_params;
 const media       = props.media;
 const narrow      = media.narrow;

 const view_user = props.url_params.get("view_user");

 let content;
 let content_class = 'page-content';

 if(view_user)
   {
    return <ProfileView uid     = {view_user}
                        conn    = {profile.conn[view_user]}
                        page_id = ''
                        media   = {media}/>
   }
 else
   {
    let unread_messages;

    if(conn_groups.new_odate.length || 
       conn_groups.new_ochat.length || 
       conn_groups.new_other.length || 
       conn_groups.unread_msgs.length)
      {
       unread_messages= <UnreadMessages conn_groups = {conn_groups}
                                        profile     = {profile}  
                                        page_id     = ''/>;
      }

    content = <>
                {unread_messages}

                 {narrow && 
                  <HomeTree conn        = {conn}
                            conn_groups = {conn_groups}
                            url_params  = {url_params}
                            media       = {props.media}/>}

               <h2>Zkušební provoz</h2>
               <p><i><strong>Glagoli.net</strong> je nyní ve zkušebním provozu. Občas něco nemusí fungovat. 
                  Jakékoli problémy, dotazy a náměty směrujte na <a href="mailto:info@glagoli.net">info@glagoli.net</a> nebo
                  pošlete zprávu na Facebooku: <a href="https://www.facebook.com/glagoli.net">https://www.facebook.com/glagoli.net</a>.</i></p>

               <h1>{t('gl_welcome')}</h1>
               <p>{t('gl_welcome_sub')}</p>
               <ol>
                <li>
                  {t('gl_help1',
                     <span className="icon profile" onClick={() => url_history.push("/profile")} key='ipr'></span>)}
                </li>
                <li>
                  {t('gl_help2',
                     <span className="icon odate" onClick={() => url_history.push("/odate")} key='iod'></span>,
                     <span className="icon chat"  onClick={() => url_history.push("/chat")} key='ich'></span>)}
                  <img id="fajn-lidi" src = {girl1_fajn_lidi} alt=""/>
                </li>
                <li>
                  {t('gl_help3', <span className="icon impress" key='iim'></span>)}
                </li>
                <li>
                  {html(t('gl_help4'))}
                </li>
                <li>
                  {html(t('gl_help5'))}
                </li>
               </ol>
              </>;
   }

 return <div id="layout-content">
          <div className = {content_class} id = 'home'>
           {content}
          </div>
        </div>;
}
